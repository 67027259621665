<template>
  <v-dialog persistent scrollable v-model="dialog" :width="dialogWidth">
    <v-card :class="showValidationPopup ? errorExpendDialog : dialogCols" style="max-width: 100%; padding: 0px; overflow: visible; "
    :style="{ width: showHistoryDialog || (showValidationPopup || isTableError) ? '58.3%' : '100%' }">
      <v-card-title
        style="
          background-color: #ffffff;
          color: #2a409a;
          font-size: 20px;
          font-weight: 500;
          display:flex;
          flex-direction: row;
          justify-content:space-between; 
          align-items: center;
          align-items: flex-start;
        "
        >
        <p>Update Record</p>
        <v-btn v-if="showHistoryButton" @click="showHistoryComponent" color="rgb(42, 64, 154)" style="color:white;"> <v-icon>mdi-history</v-icon> </v-btn>
        
        </v-card-title
      >
      <v-divider />
      <v-card-text
        class="scroll-2 dialogWrapper flex-grow-0 pa-4" 
        style="overflow-y: scroll; overflow-x: hidden" id="divSelector"
      >
        <v-slide-y-transition hide-on-leave>
        <v-container v-show="!showAssigneeSection || updateFieldDialogObject.viewType == 'master'">
          <div class="flexColumn" v-for="fieldItem in fieldDefinition" v-bind:key="fieldItem">
            <div v-if="!fieldItem.isDependent && !fieldItem.hasOwnProperty('parentKey') && fieldItem.renderField">
              <div
                v-if="
                  fieldItem.isVisible && fieldItem.input_type === 'label'
                "
                class="mb-2 pl-2 pb-1" :style="getLabelStyle(fieldItem)"
              >
                <div style="width: 100%; margin-bottom: 6px" align="left" class= "pl-5 pr-5">
                  <label
                    >{{ fieldItem.display_name[$i18n.locale] }}
                    <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                  </label>
                </div>
              </div>
              <div
                v-if="fieldItem.isVisible && fieldItem.input_type === 'input'"
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-text-field
                    outlined
                    background-color="#F2F2F2"
                    :disabled="fieldItem.disable"
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    v-model="fields.field_name[fieldItem.key]"
                  ></v-text-field>
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible &&
                  fieldItem.input_type === 'optionsWithSearch'
                "
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }}  <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-autocomplete
                    outlined
                    background-color="#F2F2F2"
                    :disabled="fieldItem.disable"
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    v-model="fields.field_name[fieldItem.key]"
                    :items="fieldItem.option_values"
                    attach
                  >
                    <template v-slot:prepend-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-text-field
                            outlined
                            background-color="#F2F2F2"
                            :rules="
                              fieldItem.validators && fieldItem.validators.required === true
                                ? rulesForRequired
                                : rulesForNotRequired
                            "
                            v-model="searchTerm"
                            placeholder="Search"
                            @input="searchFruits"
                          ></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible &&
                  fieldItem.input_type === 'workflowoptions'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span>
                    <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-autocomplete
                    style="border-radius: 10px"
                    outlined
                    background-color="#F2F2F2"
                    :items="fieldItem.option_values"
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    item-value="value"
                    item-text="value"
                    v-model="fields.field_name[fieldItem.key]"
                    disabled
                  ></v-autocomplete>
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible && fieldItem.input_type === 'priority'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label
                    >
                    <div style='display: flex; flex-direction: row;'>
                      <div>{{ fieldItem.display_name[$i18n.locale] }}</div>
                      <span
                        v-if="
                          fieldItem.validators &&
                          fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      ><span
                        ><HelpToolTip
                          v-if="fieldItem.helpTooltipText"
                          :ToolMessage="fieldItem.helpTooltipText"
                        ></HelpToolTip
                      ></span>
                      <img v-if="fieldItem.clearable" src="../assets/close.png" style=" cursor: pointer; width: 25px; height: 90%;" @click="clearInputData(fieldItem.key)"></img>
                    </div>
                  </label>
                </div>
                <div
                  v-if="fieldItem.view_type === 'reference'"
                  style="width: 100%"
                  align="right"
                >
                  <v-radio-group style="margin-top: 8px;" v-model="fields.field_name[fieldItem.key]" :disabled="fieldItem.disable">
                    <v-radio
                      v-for="radioItem in fieldItem.option_values"
                      :key="radioItem.key"
                      :label="radioItem.displayText"
                      :value="radioItem.key"
                      :disabled="radioItem.isDisabled"
                      :rules="
                        fieldItem.validators &&
                        fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                    ></v-radio>
                  </v-radio-group>
                  <v-btn
                    :disabled="!fields.field_name[fieldItem.key]"
                    v-if="fieldItem.isSpotlight"
                    @click="openSpotlight(fieldItem)"
                    >{{
                      fieldItem.spotlightConfiguration.buttonText[
                        $i18n.locale
                      ]
                    }}
                  </v-btn>
                </div>
                <div v-else style="width: 100%" align="right">
                  <v-radio-group style="margin-top: 8px;" v-model="fields.field_name[fieldItem.key]" :disabled="fieldItem.disable">
                        <v-radio
                          v-for="radioItem in fieldItem.option_values"
                          :key="radioItem.key"
                          :label="radioItem.displayText"
                          :value="radioItem.key"
                          :disabled="radioItem.isDisabled"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                        ></v-radio>
                      </v-radio-group>
                  <v-btn
                    :disabled="!fields.field_name[fieldItem.key]"
                    v-if="fieldItem.isSpotlight"
                    @click="openSpotlight(fieldItem)"
                    >{{
                      fieldItem.spotlightConfiguration.buttonText[
                        $i18n.locale
                      ]
                    }}
                  </v-btn>
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible && fieldItem.input_type === 'apiOptions'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-autocomplete
                    style="border-radius: 10px"
                    outlined
                    background-color="#F2F2F2"
                    :disabled="fieldItem.disable"
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    :items="apiOptionValues"
                    v-model="fields.field_name[fieldItem.key]"
                  ></v-autocomplete>
                </div>
              </div>

              <div
                style="margin-bottom:15px;"
                  v-if="
                    fieldItem.isVisible &&
                    fieldItem.input_type === 'checklist'
                  "
                  class="input_field_div"
                >
                <div style="width: 100%;" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>  </label>
                </div>
                <div style="width: 100%" align="right">
                  <ChecklistComponent
                      :ChecklistOptionsData = "fieldItem.option_values"
                      :checklistUpdateDisable = "fieldItem.disable"
                      :addAllowBtn = "fieldItem"
                      v-model="fields.field_name[fieldItem.key]"
                    >
                  </ChecklistComponent>
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible &&
                  fieldItem.input_type === 'multiselect'
                  "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px;">
                  <label>
                    {{ fieldItem.display_name[$i18n.locale] }}
                    <span
                      v-if="
                        fieldItem.validators &&
                        fieldItem.validators.required
                      "
                      style="color: red"
                    >*</span>
                    <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                  </label>
                </div>
                <div style="width: 100%; margin-bottom:20px;">
                  <MultiselectComponent
                    :multiselectOptionsData = "fieldItem.option_values"
                    v-model="fields.field_name[fieldItem.key]"
                  >
                  </MultiselectComponent>
                </div>
              </div>
              <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'number'
                  "
                  class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label
                    >{{ fieldItem.display_name[$i18n.locale] }}
                    <span
                      v-if="
                        fieldItem.validators &&
                        fieldItem.validators.required
                      "
                      style="color: red"
                      >*</span
                    > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                  </label>
                </div>
                <div style="width: 100%" align="right">
                  <NumberInputComponent
                  :updateAccess = "fieldItem.disable"
                  :numberConfiguration = "fieldItem.number_configuration"
                    :rules="
                      fieldItem.validators &&
                      fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "                      
                    v-model="fields.field_name[fieldItem.key]"
                  ></NumberInputComponent>
                </div>
              </div>
              <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'table'
                  "
                  class="input_field_div" :id="'click'+fieldItem.key" @click="renderComponent(fieldItem.key)"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label
                    >{{ fieldItem.display_name[$i18n.locale] }}
                    <span
                      v-if="
                        fieldItem.validators &&
                        fieldItem.validators.required
                      "
                      style="color: red"
                      >*</span
                    > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                  </label>
                </div>
                <div v-if="showTable[fieldItem.key]">
                  <TableComponent
                    :mode = "tableMode"
                    :workFlowId = "fieldUpdateItem.workflow_id"
                    :currentStatus = "fieldUpdateItem.current_status"
                    :roleGroup = "userRoleGroup"
                    :addNewRow = "fieldItem.table_configuration.addNewRow"
                    :tableConfiguration = "fieldItem.table_configuration"
                    :dataItems = "fields.field_name[fieldItem.key]"                 
                    v-model="fields.field_name[fieldItem.key]"
                    :showFooter=false
                    :disable="fieldItem.disable"
                    :key="fields.field_name[fieldItem.key]"
                  ></TableComponent>
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible &&
                  fieldItem.input_type === 'ConditionalOptions'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-autocomplete
                    style="border-radius: 10px"
                    outlined
                    :disabled="fieldItem.disable"
                    background-color="#F2F2F2"
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    :items="fieldItem.option_values"
                    v-model="fields.field_name[fieldItem.key]"
                  ></v-autocomplete>
                </div>
              </div>
              <div
                v-if="fieldItem.isVisible && fieldItem.input_type === 'options'"
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div
                  v-if="fieldItem.view_type === 'reference'"
                  style="width: 100%"
                  align="right"
                >
                  <div class="pb-7">
                    <v-autocomplete
                      :disabled="fieldItem.disable"
                      style="border-radius: 10px"
                      outlined
                      background-color="#F2F2F2"
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      :items="fieldItem.option_values"
                      item-value="key"
                      item-text="value"
                      v-model="fields.field_name[fieldItem.key]"
                      hide-details
                    ></v-autocomplete>
                    <MasterViewComponent  v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] && showMasterViewFields" v-model="viewData"
                    :keyName="fieldItem.key" 
                    ></MasterViewComponent>
                  </div>
                </div>
                <div v-else style="width: 100%" align="right">
                  <v-autocomplete
                    style="border-radius: 10px"
                    outlined
                    :disabled="fieldItem.disable"
                    background-color="#F2F2F2"
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    :items="fieldItem.option_values"
                    v-model="fields.field_name[fieldItem.key]"
                  ></v-autocomplete>
                </div>
              </div>
              <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'optionsGroup'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-autocomplete
                      style="border-radius: 10px"
                      outlined
                      :disabled="fieldItem.disable"
                      background-color="#F2F2F2"
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      :items="fieldItem.option_values"
                      v-model="fields.field_name[fieldItem.key]"
                    ></v-autocomplete>
                  </div>
                </div>
              <div
                v-if="
                  fieldItem.isVisible && fieldItem.input_type === 'hierarchicalDropdown'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label
                    >{{ fieldItem.display_name[$i18n.locale] }}
                    <span
                      v-if="
                        fieldItem.validators &&
                        fieldItem.validators.required
                      "
                      style="color: red"
                      >*</span
                    ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                  </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-autocomplete
                    style="border-radius: 10px"
                    outlined
                    background-color="#F2F2F2"
                    :rules="
                      fieldItem.validators &&
                      fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    :items="fieldItem.option_values"
                    v-bind="{ ...(fieldItem.view_type === 'reference' ? { 'itemValue':'key', 'itemText':'value' } : {})}"
                    v-model="fields.field_name[fieldItem.key]"
                    :disabled="fieldItem.disable"
                  ></v-autocomplete>
                </div>
                <MasterViewComponent style="margin-bottom: 20px; margin-top: -15px;" v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] && showMasterViewFields" v-model="viewData"
                :keyName="fieldItem.key" 
                ></MasterViewComponent>
              </div>
              <div
                v-if="
                  fieldItem.isVisible &&
                  fieldItem.input_type === 'datetime'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>
                    {{ fieldItem.display_name[$i18n.locale] }}
                    <span
                      v-if="
                        fieldItem.validators &&
                        fieldItem.validators.required
                        "
                      style="color: red"
                    >*</span
                    >
                    <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                  </label>
                  </div>
                    <div style="width: 100%" align="right"> 
                      <DateTimeComponent 
                        :dateTimeDetails="fieldItem"
                        :min="startDate[fieldItem.key]"
                        :max="endDate[fieldItem.key]"
                        v-model="fields.field_name[fieldItem.key]"
                        :disable="fieldItem.disable"
                        :tzConfig="additionalFieldInformation[fieldItem.key]"
                        >
                        </DateTimeComponent>
                      </div>
                  </div>
              <div
                v-if="fieldItem.isVisible && fieldItem.input_type === 'toggle'"
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-switch
                    :disabled="fieldItem.disable"
                    v-model="fields.field_name[fieldItem.key]"
                  ></v-switch>
                </div>
              </div>
              <div
                v-if="fieldItem.isVisible && fieldItem.input_type === 'date'"
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span></label>
                </div>
                <div style="width: 100%" align="right">
                  <v-menu
                    v-model="dateMenu[fieldItem.key]"
                    :disabled="fieldItem.disable"
                    :close-on-content-click="true"
                    :nudge-right="60"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        :disabled="fieldItem.disable"
                        background-color="#F2F2F2"
                        v-model="fields.field_name[fieldItem.key]"
                        append-icon="mdi-calendar"
                        :rules="
                          fieldItem.validators && fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        persistent-hint
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      outlined
                      :disabled="fieldItem.disable"
                      background-color="#F2F2F2"
                      :min="startDate[fieldItem.key]"
                      :max="endDate[fieldItem.key]"
                      v-model="fields.field_name[fieldItem.key]"
                      @change="dateMenu[fieldItem.key] = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible && fieldItem.input_type === 'fileupload'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span></label>
                </div>
                <div>
                    <v-container>
                      <v-row>
                        <v-col class="text-left"
                        >
                        <div v-if="fields.field_name[fieldItem.key]!=='' && fields.field_name[fieldItem.key]!==undefined" style="padding:10px">
                          <v-label class="pa-2" tile >
                            {{formatName(fields.field_name[fieldItem.key] ,fieldItem.key)}} <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>                 
                          </v-label>
                        </div>
                        <div v-else  style="padding:10px">
                          No File attachment
                        </div>
                        </v-col>
                        <v-col class="text-right"
                        >
                          <v-btn
                            v-if= "fields.field_name[fieldItem.key] && checkFileExits(fields.field_name[fieldItem.key])"
                            class="pa-2"
                            style="
                            background-color: rgb(42, 64, 154);
                            color: rgb(255, 255, 255);
                            margin: 8px;width:140px"
                            elevation="0"
                            align="right"
                            @click="clickView(fields.field_name[fieldItem.key])"
                            :disabled="fields.field_name[fieldItem.key]==undefined ||fields.field_name[fieldItem.key]==''"
                          >
                            view
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                <div
                  v-if="!fieldItem.disable"
                  style="width: 100%"
                  align="right"
                >
                  <FileUploadComponent
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    :fieldKey="fieldItem.key"
                    :fieldValue="fields.field_name[fieldItem.key]"
                    :workFlowId="fieldUpdateItem.workflow_id"
                    :viewid="updateFieldDialogObject.viewId"
                    :disabled="fieldItem.disable"
                    :fileDefinition="fieldItem"
                    @getBase64="getBase64"
                    @revertOldFile="revertOldFile"
                  ></FileUploadComponent>
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible && fieldItem.input_type === 'multifileupload'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span></label>
                </div>
                <div
                  v-if="!fieldItem.disable"
                  style="width: 100%"
                  align="right"
                >
                  <MultiFileUploadComponent
                     :rules="
                       fieldItem.validators &&
                       fieldItem.validators.required === true
                         ? rulesForRequired
                         : rulesForNotRequired
                     "
                     :fieldKey="fieldItem.key"  
                     :workFlowId="fieldUpdateItem.workflow_id"
                     :viewid="updateFieldDialogObject.viewId"
                     :disabled="fieldItem.disable"
                     :fileDefinition="fieldItem"
                     @multifileCheck="multifileCheck"
                     @multiFileRemove="multiFileRemove"
                  ></MultiFileUploadComponent>
                </div>
                <div style="padding-bottom:10px">
                  <div v-if="fields.field_name[fieldItem.key]!=='' && fields.field_name[fieldItem.key]!==undefined">
                    <div v-if="(typeof fields.field_name[fieldItem.key]!=='string')">
                      <v-chip 
                          v-for="(file,index) in fields.field_name[fieldItem.key]" :key="index"
                          v-if="checkFile(file,fieldItem.key)"
                          style="width:260px;margin:5px"
                          class="mr-1"
                          :title="formatFileTitle(file,fieldItem.key)"
                        >
                        {{formatName(file,fieldItem.key)}}
                        <v-icon style="font-size: 18px;padding-left: 2px;" @click="viewFile(file)">
                            mdi mdi-eye
                          </v-icon>&nbsp;
                        <v-icon style="font-size: 18px;" v-if="!fieldItem.disable"  @click="removeFile(index,fieldItem.key)">
                            mdi-close-circle
                        </v-icon>
                      </v-chip>
                    </div>
                    <div v-else>
                        <v-container>
                          <v-row>
                            <v-col class="text-left"
                            >
                              <div v-if="fields.field_name[fieldItem.key]!==undefined && fields.field_name[fieldItem.key]!==''" style="padding:10px;">
                                <v-label class="pa-2" tile >
                                  {{formatName(fields.field_name[fieldItem.key],fieldItem.key)}} <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                                </v-label>
                              </div>
                              <div v-else  style="padding:10px">
                                No File attachment
                              </div>
                            </v-col>
                            <v-col class="text-right"
                            >
                              <v-btn
                                class="pa-2"
                                style="
                                background-color: rgb(42, 64, 154);
                                color: rgb(255, 255, 255);
                                margin: 8px;width:140px"
                                elevation="0"
                                align="right"
                                @click="clickView(fields.field_name[fieldItem.key])"
                                :disabled="fields.field_name[fieldItem.key]==undefined ||fields.field_name[fieldItem.key]==''"
                              >
                              view
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="fieldItem.isVisible && fieldItem.input_type === 'radio'"
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>                      
                    <div style="display: flex; flex-direction: row;">
                      <div align="left">{{ fieldItem.display_name[$i18n.locale] }}</div>
                      <span
                        v-if="
                          fieldItem.validators &&
                          fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      <img v-if="fieldItem.clearable && !fieldItem.disable" src="../assets/close.png" style=" cursor: pointer; width: 25px; height: 90%;" @click="clearInputData(fieldItem.key)"></img>
                  </div>
                  </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-radio-group v-model="fields.field_name[fieldItem.key]">
                    <v-radio
                      v-for="radioItem in fieldItem.option_values"
                      :key="radioItem"
                      :label="radioItem"
                      :value="radioItem"
                      :disabled="fieldItem.disable"
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                    ></v-radio>
                  </v-radio-group>
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible && fieldItem.input_type === 'textarea'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }}  <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-textarea
                    :disabled="fieldItem.disable"
                    outlined
                    background-color="#F2F2F2"
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    v-model="fields.field_name[fieldItem.key]"
                  ></v-textarea>
                </div>
              </div>
              <div
                v-if="fieldItem.isVisible && fieldItem.input_type === 'phone'"
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }}  <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span></label>
                </div>
                <div style="width: 100%; margin-bottom: 16px" align="right">
                  <VuePhoneNumberInput
                    defaultCountryCode="MM"
                    valid-color="#ccc"
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    v-model="fields.field_name[fieldItem.key]"
                    :disabled="fieldItem.disable"
                  />
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible &&
                  fieldItem.input_type === 'alert'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label
                    >{{ fieldItem.display_name[$i18n.locale] }}
                    <span
                      v-if="
                        fieldItem.validators &&
                        fieldItem.validators.required
                      "
                      style="color: red"
                      >*</span
                    >
                    <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                  </label>
                </div>
                <div style="width: 100%" align="right"> 
                  <AlertDateTimeComponent 
                  :alertDetails="fieldItem"
                  v-model="fields.field_name[fieldItem.key]"
                  >
                  </AlertDateTimeComponent>
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible &&
                  fieldItem.input_type === 'phonenumber'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label
                    >{{ fieldItem.display_name[$i18n.locale] }}
                    <span
                      v-if=" 
                        fieldItem.validators &&
                        fieldItem.validators.required
                      "
                      style="color: red"
                      >*</span
                    >
                    <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                  </label>
                </div>
                <div style="width: 100%" align="right"> 
                  <PhonenumberComponent
                    :additionalFieldInformation="fields.field_name.AdditionalFieldInformation[fieldItem.key]"
                    :phonenumberDetail="fieldItem"
                    v-model="fields.field_name[fieldItem.key]"
                    :disabled="fieldItem.disable"
                    @additionalField="additionalField(fieldItem.key, ...arguments)"
                  >
                  </PhonenumberComponent>
                  <v-btn
                    :disabled="!fields.field_name[fieldItem.key]"
                    v-if="fieldItem.isSpotlight"
                    @click="openSpotlight(fieldItem)"
                    >{{
                      fieldItem.spotlightConfiguration.buttonText[
                        $i18n.locale
                      ]
                    }}
                  </v-btn>
                </div>
              </div>
              <div
                v-if="fieldItem.isVisible && fieldItem.input_type === 'email'"
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }}  <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-text-field
                    :disabled="fieldItem.disable"
                    outlined
                    background-color="#F2F2F2"
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForEmailRequired
                        : rulesForNotRequired
                    "
                    v-model="fields.field_name[fieldItem.key]"
                  ></v-text-field>
                </div>
              </div>
               <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'URL'"
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-text-field
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForURLRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.disable"
                      ></v-text-field>
                    </div>
                </div>
                <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type == 'nrc'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <NRCNumberComponent
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :toDisable="fieldItem.disable"
                      ></NRCNumberComponent>

                    </div>
                </div>
              <div
                v-if="
                  fieldItem.isVisible && fieldItem.input_type === 'currency'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }}  <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div style="width: 100%" align="right">
                  <CurrencyComponent
                    v-model="fields.field_name[fieldItem.key]"
                    :currencyData = "currencyData"
                    :fieldData = fieldItem
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    :isdisabled="fieldItem.disable"
                    @addCurrencyDetails="addCurrencyDetails"
                  >
                  </CurrencyComponent>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                v-if="fieldItem.renderField"
              >
              <div
                v-if="
                  fieldItem.isVisible && fieldItem.input_type === 'label'
                "
                class="mb-2 pl-2 pb-1" :style="getLabelStyle(fieldItem)"
              >
                <div style="width: 100%; margin-bottom: 6px" align="left" class= "pl-5 pr-5">
                  <label
                    >{{ fieldItem.display_name[$i18n.locale] }}
                    <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                  </label>
                </div>
              </div>
                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'number'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >{{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators &&
                          fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <NumberInputComponent
                    :updateAccess = "fieldItem.disable"
                    :numberConfiguration = "fieldItem.number_configuration"
                      :rules="
                        fieldItem.validators &&
                        fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "                      
                      v-model="fields.field_name[fieldItem.key]"
                    ></NumberInputComponent>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'URL'"
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >{{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators &&
                          fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-text-field
                      outlined
                      background-color="#F2F2F2"
                      :rules="
                        fieldItem.validators &&
                        fieldItem.validators.required === true
                          ? rulesForURLRequired
                          : rulesForNotRequired
                      "
                      v-model="fields.field_name[fieldItem.key]"
                      :disabled="fieldItem.disable"
                    ></v-text-field>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'table'
                  "
                  class="input_field_div" :id="'click'+fieldItem.key" @click= "renderComponent(fieldItem.key)"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >{{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators &&
                          fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div v-if="showTable[fieldItem.key]">
                    <TableComponent
                      :mode = "tableMode"
                      :workFlowId = "fieldUpdateItem.workflow_id"
                      :currentStatus = "fieldUpdateItem.current_status"
                      :roleGroup = "fieldUpdateItem.assignee_user_group"
                      :addNewRow = "fieldItem.table_configuration.addNewRow"
                      :tableConfiguration = "fieldItem.table_configuration"
                      :dataItems = "fields.field_name[fieldItem.key]"                 
                      v-model="fields.field_name[fieldItem.key]"
                      :showFooter=false
                      :disable="fieldItem.disable"
                      :key="fields.field_name[fieldItem.key]"
                    ></TableComponent>
                  </div>
                </div>
                <div
                  v-if="fieldItem.isVisible && fieldItem.input_type === 'input'"
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }}  <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-text-field
                      outlined
                      background-color="#F2F2F2"
                      :disabled="fieldItem.disable"
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      v-model="fields.field_name[fieldItem.key]"
                    ></v-text-field>
                  </div>
                </div>
                <div
                  v-if="fieldItem.isVisible && fieldItem.input_type === 'date'"
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-menu
                      v-model="dateMenu[fieldItem.key]"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :disabled="fieldItem.disable"
                      :min="startDate[fieldItem.key]"
                      :max="endDate[fieldItem.key]"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          background-color="#F2F2F2"
                          :disabled="fieldItem.disable"
                          :rules="
                            fieldItem.validators && fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          v-model="fields.field_name[fieldItem.key]"
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        outlined
                        :disabled="fieldItem.disable"
                        background-color="#F2F2F2"
                        v-model="fields.field_name[fieldItem.key]"
                        @change="dateMenu[fieldItem.key] = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>

                <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'checklist'
                    "
                    class="input_field_div"
                  >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <ChecklistComponent
                       :ChecklistOptionsData = "fieldItem.option_values"
                       :addAllowBtn = "fieldItem"
                       :checklistUpdateDisable = "fieldItem.disable"
                       v-model="fields.field_name[fieldItem.key]"
                     >
                    </ChecklistComponent>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible &&
                    fieldItem.input_type === 'multiselect'
                    "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px;">
                    <label>
                      {{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators &&
                          fieldItem.validators.required
                          "
                        style="color: red"
                      >*</span>
                      <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div style="width: 100%; margin-bottom:20px;">
                    <MultiselectComponent
                      :multiselectOptionsData = "fieldItem.option_values"
                      v-model="fields.field_name[fieldItem.key]"
                    >
                    </MultiselectComponent>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'priority'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >
                      <div style='display: flex; flex-direction: row;'>
                        <div>{{ fieldItem.display_name[$i18n.locale] }}</div>
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span
                          ><HelpToolTip
                            v-if="fieldItem.helpTooltipText"
                            :ToolMessage="fieldItem.helpTooltipText"
                          ></HelpToolTip
                        ></span>
                        <img v-if="fieldItem.clearable" src="../assets/close.png" style=" cursor: pointer; width: 25px; height: 90%;" @click="clearInputData(fieldItem.key)"></img>
                    </div>
                    </label>
                  </div>
                  <div
                    v-if="fieldItem.view_type === 'reference'"
                    style="width: 100%"
                    align="right"
                  >
                    <v-radio-group style="margin-top: 8px;" v-model="fields.field_name[fieldItem.key]" :disabled="fieldItem.disable">
                      <v-radio
                        v-for="radioItem in fieldItem.option_values"
                        :key="radioItem.key"
                        :label="radioItem.displayText"
                        :value="radioItem.key"
                        :disabled="radioItem.isDisabled"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                      ></v-radio>
                    </v-radio-group>
                    <v-btn
                      :disabled="!fields.field_name[fieldItem.key]"
                      v-if="fieldItem.isSpotlight"
                      @click="openSpotlight(fieldItem)"
                      >{{
                        fieldItem.spotlightConfiguration.buttonText[
                          $i18n.locale
                        ]
                      }}
                    </v-btn>
                  </div>
                  <div v-else style="width: 100%" align="right">
                    <v-radio-group style="margin-top: 8px;" v-model="fields.field_name[fieldItem.key]" :disabled="fieldItem.disable">
                      <v-radio
                        v-for="radioItem in fieldItem.option_values"
                        :key="radioItem.key"
                        :label="radioItem.displayText"
                        :value="radioItem.key"
                        :disabled="radioItem.isDisabled"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                      ></v-radio>
                    </v-radio-group>
                    <v-btn
                      :disabled="!fields.field_name[fieldItem.key]"
                      v-if="fieldItem.isSpotlight"
                      @click="openSpotlight(fieldItem)"
                      >{{
                        fieldItem.spotlightConfiguration.buttonText[
                          $i18n.locale
                        ]
                      }}
                    </v-btn>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'apiOptions'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>  </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-autocomplete
                      style="border-radius: 10px"
                      outlined
                      :disabled="fieldItem.disable"
                      background-color="#F2F2F2"
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      :items="apiOptionValues"
                      v-model="fields.field_name[fieldItem.key]"
                    ></v-autocomplete>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'options'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                  </div>
                  <div
                    v-if="fieldItem.view_type === 'reference'"
                    style="width: 100%"
                    align="right"
                  >
                    <div class="pb-7">
                      <v-autocomplete
                        style="border-radius: 10px"
                        outlined
                        :disabled="fieldItem.disable"
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators && fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :items="fieldItem.option_values"
                        item-value="key"
                        item-text="value"
                        v-model="fields.field_name[fieldItem.key]"
                        hide-details
                      ></v-autocomplete>
                      <MasterViewComponent  v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] && showMasterViewFields" v-model="viewData"
                      :keyName="fieldItem.key" 
                      ></MasterViewComponent>
                    </div>
                  </div>
                  <div v-else style="width: 100%" align="right">
                    <v-autocomplete
                      style="border-radius: 10px"
                      outlined
                      :disabled="fieldItem.disable"
                      background-color="#F2F2F2"
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      :items="fieldItem.option_values"
                      v-model="fields.field_name[fieldItem.key]"
                    ></v-autocomplete>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'optionsGroup'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-autocomplete
                      style="border-radius: 10px"
                      outlined
                      :disabled="fieldItem.disable"
                      background-color="#F2F2F2"
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      :items="fieldItem.option_values"
                      v-model="fields.field_name[fieldItem.key]"
                    ></v-autocomplete>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'hierarchicalDropdown'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >{{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators &&
                          fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-autocomplete
                      style="border-radius: 10px"
                      outlined
                      background-color="#F2F2F2"
                      :rules="
                        fieldItem.validators &&
                        fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      :items="fieldItem.option_values"
                      v-bind="{ ...(fieldItem.view_type === 'reference' ? { 'itemValue':'key', 'itemText':'value' } : {})}"
                      v-model="fields.field_name[fieldItem.key]"
                      :disabled="fieldItem.disable"
                    ></v-autocomplete>
                  </div>
                  <MasterViewComponent style="margin-bottom: 20px; margin-top: -15px;" v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] && showMasterViewFields" v-model="viewData"
                  :keyName="fieldItem.key" 
                  ></MasterViewComponent>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible &&
                    fieldItem.input_type === 'datetime'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>
                      {{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators &&
                          fieldItem.validators.required
                          "
                          style="color: red"
                      >*</span
                      >
                      <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                    </div>
                      <div style="width: 100%" align="right"> 
                        <DateTimeComponent 
                        :dateTimeDetails="fieldItem"
                        :min="startDate[fieldItem.key]"
                        :max="endDate[fieldItem.key]"
                        v-model="fields.field_name[fieldItem.key]"
                        :disable="fieldItem.disable"
                        :tzConfig="additionalFieldInformation[fieldItem.key]"
                        >
                        </DateTimeComponent>
                      </div>
                  </div>
                <div
                  v-if="
                    fieldItem.isVisible &&
                    fieldItem.input_type === 'ConditionalOptions'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-autocomplete
                      outlined
                      :disabled="fieldItem.disable"
                      background-color="#F2F2F2"
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      :items="fieldItem.option_values"
                      v-model="fields.field_name[fieldItem.key]"
                    ></v-autocomplete>
                  </div>
                </div>
                <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type == 'nrc'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <NRCNumberComponent
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :toDisable="fieldItem.disable"
                      ></NRCNumberComponent>
                      
                    </div>
                  </div>
                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'toggle'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-switch
                      :disabled="fieldItem.disable"
                      v-model="fields.field_name[fieldItem.key]"
                    ></v-switch>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'fileupload'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{fieldItem.display_name[$i18n.locale]}} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                  </div>
                  <div>
                    <v-container>
                      <v-row>
                        <v-col class="text-left" v-bind:key="fileUploadRefresher"
                        >
                        <div v-if="fields.field_name[fieldItem.key]!==undefined && fields.field_name[fieldItem.key]!==''" style="padding:10px;">
                          <v-label class="pa-2" tile >
                            {{formatName(fields.field_name[fieldItem.key] ,fieldItem.key)}} <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                          </v-label>
                        </div>
                        <div v-else  style="padding:10px">
                          No File attachment
                        </div>
                        </v-col>
                        <v-col class="text-right"
                        >
                          <v-btn
                          v-if= "fields.field_name[fieldItem.key] && checkFileExits(fields.field_name[fieldItem.key])"
                            class="pa-2"
                            style="
                            background-color: rgb(42, 64, 154);
                            color: rgb(255, 255, 255);
                            margin: 8px;width:140px"
                            elevation="0"
                            align="right"
                            @click="clickView(fields.field_name[fieldItem.key])"
                            :disabled="fields.field_name[fieldItem.key]==undefined ||fields.field_name[fieldItem.key]==''"
                          >
                            view
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                  <div 
                    v-if="!fieldItem.disable" 
                    style="width: 100%" 
                    align="right">
                    <FileUploadComponent
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      :workFlowId="fieldUpdateItem.workflow_id"
                      :viewid="updateFieldDialogObject.viewId"
                      :fieldKey="fieldItem.key"
                      :fieldValue="fields.field_name[fieldItem.key]"
                      :fileDefinition="fieldItem"
                      :disabled="fieldItem.disable"
                      @getBase64="getBase64"
                      @revertOldFile="revertOldFile"
                    ></FileUploadComponent>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'multifileupload'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span></label>
                  </div>
                  <div
                    v-if="!fieldItem.disable"
                    style="width: 100%"
                    align="right"
                  >
                    <MultiFileUploadComponent
                      :rules="
                        fieldItem.validators &&
                        fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      :fieldKey="fieldItem.key"  
                      :workFlowId="fieldUpdateItem.workflow_id"
                      :viewid="updateFieldDialogObject.viewId"
                      :disabled="fieldItem.disable"
                      :fileDefinition="fieldItem"
                      @multifileCheck="multifileCheck"
                      @multiFileRemove="multiFileRemove"
                    ></MultiFileUploadComponent>
                  </div>
                  <div style="padding-bottom:10px">
                    <div v-if="fields.field_name[fieldItem.key]!=='' && fields.field_name[fieldItem.key]!==undefined">
                      <div v-if="(typeof fields.field_name[fieldItem.key]!=='string')">
                        <v-chip 
                          v-for="(file,index) in fields.field_name[fieldItem.key]" :key="index"
                          v-if="checkFile(file,fieldItem.key)"
                          style="width:260px;margin:5px"
                          class="mr-1"
                          :title="formatFileTitle(file,fieldItem.key)"
                        >
                        {{formatName(file,fieldItem.key)}}
                        <v-icon style="font-size: 18px;padding-left: 2px;" @click="viewFile(file)">
                            mdi mdi-eye
                          </v-icon>&nbsp;
                        <v-icon style="font-size: 18px;" v-if="!fieldItem.disable"  @click="removeFile(index,fieldItem.key)">
                            mdi-close-circle
                        </v-icon>
                      </v-chip>
                      </div>
                      <div v-else>
                        <v-container>
                          <v-row>
                            <v-col class="text-left"
                            >
                              <div v-if="fields.field_name[fieldItem.key]!==undefined && fields.field_name[fieldItem.key]!==''" style="padding:10px;">
                                <v-label class="pa-2" tile >
                                  {{formatName(fields.field_name[fieldItem.key],fieldItem.key)}} <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                                </v-label>
                              </div>
                              <div v-else  style="padding:10px">
                                No File attachment
                              </div>
                            </v-col>
                            <v-col class="text-right"
                            >
                              <v-btn
                                class="pa-2"
                                style="
                                background-color: rgb(42, 64, 154);
                                color: rgb(255, 255, 255);
                                margin: 8px;width:140px"
                                elevation="0"
                                align="right"
                                @click="clickView(fields.field_name[fieldItem.key])"
                                :disabled="fields.field_name[fieldItem.key]==undefined ||fields.field_name[fieldItem.key]==''"
                              >
                              view
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="fieldItem.isVisible && fieldItem.input_type === 'radio'"
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>                        
                      <div style="display: flex; flex-direction: row;">
                        <div align="left">{{ fieldItem.display_name[$i18n.locale] }}</div>
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        <img v-if="fieldItem.clearable" src="../assets/close.png" style=" cursor: pointer; width: 25px; height: 90%;" @click="clearInputData(fieldItem.key)"></img>
                      </div>
                    </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-radio-group v-model="fields.field_name[fieldItem.key]">
                      <v-radio
                        v-for="radioItem in fieldItem.option_values"
                        :key="radioItem"
                        :disabled="fieldItem.disable"
                        :label="radioItem"
                        :value="radioItem"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'textarea'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }}  <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span></label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-textarea
                      outlined
                      :disabled="fieldItem.disable"
                      background-color="#F2F2F2"
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      v-model="fields.field_name[fieldItem.key]"
                    ></v-textarea>
                  </div>
                </div>

                <div
                  v-if="fieldItem.isVisible && fieldItem.input_type === 'phone'"
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span>  <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span></label>
                  </div>
                  <div style="width: 100%; margin-bottom: 16px" align="right">
                    <VuePhoneNumberInput
                      defaultCountryCode="MM"
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      v-model="fields.field_name[fieldItem.key]"
                    />
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible &&
                    fieldItem.input_type === 'alert'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >{{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators &&
                          fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      >
                      <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div style="width: 100%" align="right"> 
                    <AlertDateTimeComponent 
                    :alertDetails="fieldItem"
                    v-model="fields.field_name[fieldItem.key]"
                    >
                    </AlertDateTimeComponent>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible &&
                    fieldItem.input_type === 'phonenumber'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >{{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators &&
                          fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      >
                      <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <PhonenumberComponent
                      :phonenumberDetail="fieldItem"
                      :additionalFieldInformation="fields.field_name.AdditionalFieldInformation[fieldItem.key]"
                      v-model="fields.field_name[fieldItem.key]"
                      :disabled="fieldItem.disable"
                      @additionalField="additionalField(fieldItem.key, ...arguments)"
                    >
                    </PhonenumberComponent>
                    <v-btn
                      :disabled="!fields.field_name[fieldItem.key]"
                      v-if="fieldItem.isSpotlight"
                      @click="openSpotlight(fieldItem)"
                      >{{
                        fieldItem.spotlightConfiguration.buttonText[
                          $i18n.locale
                        ]
                      }}
                    </v-btn>
                  </div>
                </div>
                <div
                  v-if="fieldItem.isVisible && fieldItem.input_type === 'email'"
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span> <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span></label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-text-field
                      outlined
                      :disabled="fieldItem.disable"
                      background-color="#F2F2F2"
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForEmailRequired
                          : rulesForNotRequired
                      "
                      v-model="fields.field_name[fieldItem.key]"
                    ></v-text-field>
                  </div>
                </div>

                <div
                  v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'currency'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <CurrencyComponent
                      v-model="fields.field_name[fieldItem.key]"
                      :currencyData = "currencyData"
                      :fieldData = fieldItem
                      :rules="
                        fieldItem.validators && fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      :isdisabled="fieldItem.disable"
                      @addCurrencyDetails="addCurrencyDetails"
                    >
                  </CurrencyComponent>
                  </div>
                </div>
              </div>              
              </div>              
            </div>
            <div class="flexColumn" v-for="fieldItem in fieldDefinition">
              <div
                v-if="
                  fieldItem.isVisible &&
                  fieldItem.input_type === 'AssigneeUseroptions'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }}  <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-text-field
                    outlined
                    background-color="#F2F2F2"
                    v-model="assignedToUserGroup"
                    :disabled="assigneeUserGroupOptionsDisabled"
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                  ></v-text-field>
                </div>
            </div>
          </div>          
          <!-- <div>
            <v-btn
              outlined
              style="
                background-color: rgb(42, 64, 154);
                color: #ffffff;
                width: 140px;
              "
              v-if="updateFieldDialogObject.viewType != 'master'"
              @click="openAssigneeSection()"
            >
              Assign
            </v-btn>
          </div> -->
        </v-container>
        </v-slide-y-transition>
        <v-slide-y-reverse-transition hide-on-leave>
          <div v-show="showAssigneeSection || updateFieldDialogObject.viewType == 'master'">
            <div class="flexColumn" v-for="fieldItem in fieldDefinition">
              <div
                v-if="
                  fieldItem.isVisible &&
                  fieldItem.input_type === 'AssigneeUseroptions'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }}  <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-text-field
                    outlined
                    background-color="#F2F2F2"
                    v-model="assignedToUserGroup"
                    :disabled="assigneeUserGroupOptionsDisabled"
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                  ></v-text-field>
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible &&
                  fieldItem.input_type === 'assigneeOptions' &&
                  showAssigneeField
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ fieldItem.display_name[$i18n.locale] }} <span v-if="fieldItem.validators && fieldItem.validators.required" style="color:red">*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span> </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-autocomplete
                    style="border-radius: 10px"
                    outlined
                    :disabled="assigneeDisabled || fieldItem.disable"
                    background-color="#F2F2F2"
                    item-text="name"
                    item-value="email"
                    :items="assigneeUserList"
                    :rules="
                      fieldItem.validators && fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    v-model="fields.field_name[fieldItem.key]"
                  >
                    <template slot="item" slot-scope="data" style="width:100%;">
                      <div class="d-flex flex-row" style=" height:70px; padding:5px; width:100%;">
                        <div style="width:40px; height:40px;  border-radius:50%; background-color:rgb(42, 64, 154); color:white;">
                          <p style="font-weight:500; font-size:1.2rem; padding-top:5px; text-align:center;">{{ data.item.name.charAt(0) }}</p>
                        </div>
                        <div class="d-flex flex-column" style="justify-content:space-around; padding-left:10px;">
                          <div class="font-weight-medium">{{ data.item.name }}</div>
                          <div class="font-weight-light">{{ data.item.email }}</div>
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                </div>
              </div>
            </div>
            
          </div>
        </v-slide-y-reverse-transition>
        <div v-if="showConfirmationDialog">
          <ConfirmationDialog
            :confirmationDialogObject="confirmationDialogObject"
            @closeDialog="closeDialog"
          />
        </div>
        <div v-if="showErrorValidationDialog">
          <ErrorValidationDialog
            :displayErrorValidationDialog="showErrorValidationDialog"
            :errorValidationDialogObject="errorValidationDialogObject"
            @closeErrorValidationDialog="closeErrorValidationDialog"
          />
        </div>
      </v-card-text>

      <v-divider />
      <v-card-actions style="display: flex; justify-content: right">
        <div v-if="showAssigneeSection">
          <v-btn
              outlined
              style="
                background-color: rgb(42, 64, 154);
                color: #ffffff;
                width: 140px;
              "
              @click="closeAssigneeSection()"
              v-if="updateFieldDialogObject.viewType != 'master'"
            >
              {{$t("Edit Fields")}}
            </v-btn>
        </div>
        <v-spacer></v-spacer>
        <div style="margin-right: 30px; margin-left: 30px">
          <v-btn
            outlined
            style="
              background-color: #ffffff;
              color: rgb(42, 64, 154);
              border: 1px solid rgb(42, 64, 154);
              width: 100px;
            "
            @click="clickCancel()"
            >{{ $t("Cancel") }}</v-btn
          >
        </div>
        <div v-if="!showAssigneeSection">
            <v-btn
              outlined
              style="
                background-color: rgb(42, 64, 154);
                color: #ffffff;
                width: 100px;
              "
              v-if="updateFieldDialogObject.viewType != 'master'"
              @click="assigneFiles();
                      openAssigneeSection()
                      "
            >
              {{ $t("Next") }}
            </v-btn>
          </div>
        <div v-if="showAssigneeSection || updateFieldDialogObject.viewType == 'master'">
          <v-btn
            style="
              background-color: rgb(42, 64, 154);
              color: #ffffff;
              width: 100px;
            "
            @click="clickSave()"
            >{{ $t("Save") }}</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>

    <div v-if="this.showValidationPopup"  class="spotlightSection flex-grow-0 flex-shrink-0 pa-0 col-5" style="min-width:100px">
      <ValidationErrorAlert
        :errorValidationDialogObject="errorValidationDialogObject"
        @closeErrorExpend="closeErrorExpend"
      >
      </ValidationErrorAlert>
    </div>

    <div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
    <div v-if="this.showHistoryDialog"  class="spotlightSection flex-grow-0 flex-shrink-0 pa-0 col-7 hideScroll" style="min-width:100px; background-color: white; overflow:scroll;" >
      <HistoryComponent
        :historyComments="historyComments"
        :screenDefinition="screenDefinition"
        @closeHistory="closeHistory"
        :dataItem="fieldUpdateItem"
        :currentStatusColor="currentStatusColor"
      ></HistoryComponent>
    </div>
    <v-overlay :value="showSpinner">
      <SpinnerComponent></SpinnerComponent>
    </v-overlay>
  </v-dialog>
</template>

<script>
import axios from "axios";
import HelpToolTip from "../components/HelpToolTip.vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import FileUploadComponent from "./FileUploadComponent.vue";
import ErrorValidationDialog from "./ErrorValidationDialog.vue";
import MessageDialog from "./MessageDialog.vue";
import SpinnerComponent from "./SpinnerComponent.vue";
import Dialog from './Dialog.vue';
import { nrcValidateMixin } from "../mixins/nrcValidation.js";
import { checkFieldHide } from "../mixins/checkFieldHide.js";
import { formatFileNameMixin } from "../mixins/formatFileName.js";
import NRCNumberComponent from "./NRCNumberComponent.vue";
import MultiFileUploadComponent from "./MultiFileUploadComponent.vue";
import ChecklistComponent from "../components/ChecklistComponent.vue";
import NumberInputComponent from "../components/NumberInputComponent.vue";
import CurrencyComponent  from "../components/CurencyComponent.vue";
import PhonenumberComponent from "../components/PhonenumberComponent.vue"
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'
import ValidationErrorAlert from "../components/ValidationErrorAlert.vue"
import AlertDateTimeComponent from "../components/AlertDateTimeComponent.vue"
import DateTimeComponent from "./DateTimeComponent.vue";
import HistoryComponent from "../components/HistoryForStages.vue"
import TableComponent from "./TableComponent.vue";
import validator from "validator";
import {getFromLocalStorage, setToLocalStorage} from "../store/localStorageInterface";
import MasterViewComponent from "./MasterViewComponent.vue";
import { labelStyleMixin } from "../mixins/generateLabelStyle.js";
import { getComputedValue } from "../mixins/getComputedValue";
import moment from "moment-timezone";
import {commonFunction} from "../mixins/commonFunction.js"
import MultiselectComponent from "./MultiselectComponent.vue";
import { checkTableConditionalValidations } from "../mixins/checkTableConditionalValidations";
export default {
  props: [
    "updateFieldDialogObject",
    "fieldDetails",
    "fieldUpdateItem",
    "workflow_id",
    "screenDefinition",
    "selectedScope"
  ],
  components: {
    VuePhoneNumberInput,
    ConfirmationDialog,
    FileUploadComponent,
    ErrorValidationDialog,
    MessageDialog,
    SpinnerComponent,
    NRCNumberComponent,
    Dialog,
    MultiFileUploadComponent,
    ChecklistComponent,
    HelpToolTip,
    NumberInputComponent,
    CurrencyComponent,
    HelpToolTip,
    PhonenumberComponent,
    isPossiblePhoneNumber,
    isValidPhoneNumber,
    ValidationErrorAlert,
    AlertDateTimeComponent,
    HistoryComponent,
    TableComponent,
    MasterViewComponent,
    DateTimeComponent,
    MultiselectComponent
  },
  mixins: [nrcValidateMixin, formatFileNameMixin, labelStyleMixin ,checkFieldHide, getComputedValue, commonFunction, checkTableConditionalValidations],
  data() {
    return {
      userRoleGroup: '',
      dialog: true,
      fieldDefinition: [],
      fields: {
        field_name: {},
      },
      assigneeUserGroupOptions: [],
      assigneeDisabled: false,
      assigneeUserGroupOptionsDisabled: true,
      showConfirmationDialog: false,
      showAssigneeField: false,
      assigneeFieldItem: {},
      confirmationDialogObject: {},
      showErrorValidationDialog: false,
      errorValidationDialogObject: {},
      rulesForNotRequired: [],
      fileAttachmentArray: [],
      assignedToUserGroup: "",
      assigneeUserList: [],
      updateData: "",
      dateMenu: {},
      rulesForNotRequired: [],
      rulesForRequired: [],
      rulesForCurrencyRequired: [],
      rulesForEmailRequired: [],
      rulesForURLRequired:[],
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      showAssigneeSection: false,
      currentScope: {},
      showSpinner: false,
      valueValidationErrorList: [],
      // for burmese validation errors
      valueValidationErrorListInMM: [],
      tableValidationErrorList: [],
      errorListInMM:[],
      isMMSecondarySupportLang:false,
      fileToRemove:"",
      multiFileArray:{},
      fileFieldKey:"",
      currencyData:{},
      additionalFieldInformation:{},
      errorExpendDialog:'35%',
      showValidationPopup:false,
      dialogWidth:'35%',
      dialogCols:'col-12',
      errorList:[],
      showHistoryDialog:false,
      showHistoryButton:true, 
      historyComments:[],
      currentStatusColor:{},
      workflowid: '',
      tableMode: 'UPDATE',
      masterViewAccessFields: {},
      masterDataList: {},
      displayNames: {},
      viewData: {
      },
      showMasterViewFields: false,
      masterDisplayNames: {},
      showTable: {
        
      },
      alreadyRendered: {},
      isTableError : false,
      fieldType: 'UPDATE',
      startDate: {},
      endDate: {},
      fileUploadRefresher: 0,
    };
  },
  watch: {
    "fields.field_name": {
      handler: async function (after, before) {
        for(var fieldItem of this.fieldDefinition){
          if(fieldItem.hasOwnProperty('key')){
            if(fieldItem.key === 'current_status'){
              this.currentStatusColor = fieldItem.color
            }
          }
          if (["optionsWithSearch", "workflowoptions", "apiOptions", "ConditionalOptions","checklist", "options", "hierarchicalDropdown","priority"].includes(fieldItem.input_type)) {
            let selected_fields_children = this.fieldDefinition.filter((field)=> 
              this.fields.field_name.hasOwnProperty(field.key) && 
              field.hasOwnProperty("parentKey") && 
              field.parentKey === fieldItem.key
            )
            for(let child of selected_fields_children) {
              if(this.fields.field_name[fieldItem.key]){
                let child_value = child.options_object[this.fields.field_name[fieldItem.key]]
                if (
                  !this.fields.field_name[fieldItem.key] || 
                  !child_value || 
                  (child_value && !child_value.map(child_option=>child_option.key).includes(this.fields.field_name[child.key]))
                ) {
                  delete this.fields.field_name[child.key];
                  delete child["disable"];
                }
              }
            }
          }
          if ((!fieldItem.isDependent) || 
            ((fieldItem.dependentField == "issue_type") &&
            (this.fields.field_name[fieldItem.dependentField] == fieldItem.dependentValue ||
            fieldItem.dependentValue.includes(this.fields.field_name[fieldItem.dependentField])))
          ){
            fieldItem.renderField = true;
          }
          if(
            fieldItem.hasOwnProperty('parentKey') && 
            fieldItem.hasOwnProperty('options_object')
          ) {
            if(this.fields.field_name.hasOwnProperty(fieldItem.parentKey)) {
              fieldItem.option_values = [];
              if (this.fields.field_name[fieldItem.parentKey] != "") {
                Object.entries(fieldItem.options_object).map(([key, values]) => {
                  if (this.fields.field_name[fieldItem.parentKey] === key) {
                    fieldItem['option_values'] = values
                  }
                })
              } else {
                Object.entries(fieldItem.options_object).map(([key, values]) => {
                  fieldItem['option_values'].push(...values);
                })
              }
              if(fieldItem.option_values.length === 1){
                this.fields.field_name[fieldItem.key] = fieldItem.option_values[0].key;
              }
              fieldItem.renderField = true;
            } else {
              fieldItem.renderField = false;
            }
          }
          if (fieldItem.isDependent && 
            (fieldItem.dependentField != 'issue_type' && fieldItem.dependentField !== 'current_status')
          ){
            if (
              fieldItem.dependentField in this.fields.field_name &&
              fieldItem.dependentValue.includes(this.fields.field_name[fieldItem.dependentField]) &&
              this.fieldDefinition.find(field => field.key == fieldItem.dependentField) &&
              this.fieldDefinition.find(field => field.key == fieldItem.dependentField).renderField
            ){
              fieldItem.renderField = true;
            }
            else{
              fieldItem.renderField = false;
            }
          }
          if (this.fields.field_name[fieldItem.key] && this.masterDataList[fieldItem.view_id] && fieldItem.isMasterViewConfig ) {
            this.masterViewAccessFields[fieldItem.key] = fieldItem.masterViewConfig[this.fieldUpdateItem.workflow_id][this.$props.fieldUpdateItem.current_status][getFromLocalStorage("userGroup")];
            let masterViewField = this.masterDataList[fieldItem.view_id].find((field) => field._id === this.fields.field_name[fieldItem.key]);
            this.displayNames[fieldItem.key] = this.masterDisplayNames[fieldItem.view_id];
            if (masterViewField && this.masterViewAccessFields[fieldItem.key]) {
              this.showMasterViewFields = false;
              this.$set(this.viewData, fieldItem.key, {});
              this.$set(this.viewData[fieldItem.key], 'display_name', {});
              for (let key of this.masterViewAccessFields[fieldItem.key]) {
                if (masterViewField[key]) {
                  this.$set(this.viewData[fieldItem.key], key, masterViewField[key]);
                  this.$set(this.viewData[fieldItem.key]['display_name'], key, this.displayNames[fieldItem.key][key]);
                }
              }
            }
            this.$nextTick(() => {
              this.showMasterViewFields = true;
            });
          } else if (fieldItem.isMasterViewConfig && !this.fields.field_name[fieldItem.key]){
            this.showMasterViewFields = false;
          }
        }
        for (var fieldItem of this.fieldDefinition) {
          if (fieldItem.isDependent && !fieldItem.renderField) {
            delete this.fields.field_name[fieldItem.key];
			      delete this.additionalFieldInformation[fieldItem.key]
          }
          if (fieldItem.renderField && fieldItem.input_type === "datetime"){
            if (!this.additionalFieldInformation[fieldItem.key]){
              if (fieldItem.validators && fieldItem.validators.date 
              && fieldItem.validators.date.timezone ){
                this.additionalFieldInformation[fieldItem.key] = {
                  "timezone": fieldItem.validators.date.timezone
                }
              } else {
                let localTZ = moment.tz.guess(true);
                this.additionalFieldInformation[fieldItem.key] = {
                  "timezone": localTZ
                }
              }
            }
            if (fieldItem.validators && fieldItem.validators.date) {
              if(fieldItem.validators.date.hidePastDates === true) {
                this.startDate[fieldItem.key] = Number(moment().unix());
              }
              if(fieldItem.validators.date.range) {
                if(fieldItem.validators.date.range.from){
                  if(fieldItem.validators.date.range.from.type == 'absolute'){
                    let startDateEpoch = Number(fieldItem.validators.date.range.from.value);
                    this.startDate[fieldItem.key] = startDateEpoch;
                  }
                  else if(fieldItem.validators.date.range.from.type == 'reference'){ 
                    if(this.fields.field_name[fieldItem.validators.date.range.from.value] != null){
                      let startDateEpoch = Number(this.fields.field_name[fieldItem.validators.date.range.from.value]);
                      this.startDate[fieldItem.key] = startDateEpoch;
                    }
                    else{
                      delete this.startDate[fieldItem.key];
                    }
                  }
                }
                if(fieldItem.validators.date.range.to){
                  if(fieldItem.validators.date.range.to.type == 'absolute'){
                    let endDateEpoch = Number(fieldItem.validators.date.range.to.value);
                    this.endDate[fieldItem.key] = endDateEpoch;
                  }
                  else if(fieldItem.validators.date.range.to.type == 'reference'){
                    if(this.fields.field_name[fieldItem.validators.date.range.to.value]!= null){
                      let endDateEpoch = Number(this.fields.field_name[fieldItem.validators.date.range.to.value]);
                      this.endDate[fieldItem.key] = endDateEpoch;
                    }else {
                      delete this.endDate[fieldItem.key];
                    }
                  }
                }
              }
            }
		      }
          if(fieldItem.renderField && fieldItem.input_type === 'date') {
            if (
              fieldItem.validators &&
              fieldItem.validators.date &&
              fieldItem.validators.date.hideFutureDates === true
            ) {
              this.endDate[fieldItem.key] =
                moment().format("YYYY-MM-DD");
            } 
            else if (
              fieldItem.validators &&
              fieldItem.validators.date &&
              fieldItem.validators.date.hidePastDates === true
            ) {
              this.startDate[fieldItem.key] =
                moment().format("YYYY-MM-DD");
            }
            else if (
              fieldItem.default_value &&
              fieldItem.default_value.type &&
              fieldItem.default_value.type === 'today') {
                this.fields.field_name[fieldItem.key] = moment().format("YYYY-MM-DD");
            }
            else if (
              fieldItem.default_value &&
              fieldItem.default_value.type &&
              fieldItem.default_value.type === 'absolute'
            ) {
                let epoch = fieldItem.default_value.value;
                epoch = Number(epoch);
                this.fields.field_name[fieldItem.key] = moment(epoch).format("YYYY-MM-DD");
            } else if (
                fieldItem.validators &&
                fieldItem.validators.date &&
                fieldItem.validators.date.range && 
                fieldItem.validators.date.range.from &&
                fieldItem.validators.date.range.from.value &&
                fieldItem.validators.date.range.to.value
            ) {
                let startDateEpoch = Number(fieldItem.validators.date.range.from.value);
                this.startDate[fieldItem.key] = moment(startDateEpoch).format("YYYY-MM-DD");
                var epoch = Number(fieldItem.validators.date.range.to.value);
                this.endDate[fieldItem.key] = moment(epoch).format("YYYY-MM-DD");
              }
            }
          if (fieldItem.input_type === "optionsGroup") {
            if(this.fields.field_name[fieldItem.options_parent] && this.fields.field_name[fieldItem.options_parent]!=null){
              if(fieldItem.option_values != fieldItem.options_mapping[this.fields.field_name[fieldItem.options_parent]]){
                fieldItem.option_values = fieldItem.options_mapping[this.fields.field_name[fieldItem.options_parent]];
                this.fields.field_name[fieldItem.key] = null;
              }
              fieldItem.renderField = true;
            } else {
              this.fields.field_name[fieldItem.key] = null;
              fieldItem.renderField = false;
            }    
          }
          if(fieldItem.isComputed && 'evaluation' in fieldItem){
            let computedValue = this.getComputedValue(fieldItem.evaluation);
            if(!Number.isNaN(parseFloat(computedValue)) && Number.isFinite(parseFloat(computedValue))){
              this.fields.field_name[fieldItem.key] = computedValue;
            }
            else{
              delete this.fields.field_name[fieldItem.key];
            }
          }
		      if(fieldItem.isDependent && fieldItem.renderField && fieldItem.disable){
			      if(fieldItem.input_type === "currency") {
				      if(fieldItem.currency_configuration && fieldItem.currency_configuration.defaultCurrency) {
                if(this.additionalFieldInformation[fieldItem.key] && this.additionalFieldInformation[fieldItem.key]['currencyCode']) {
                  this.additionalFieldInformation[fieldItem.key]['currencyCode'] = fieldItem.currency_configuration.defaultCurrency;
                } else {
                  this.additionalFieldInformation[fieldItem.key] = {
                    "currencyCode": fieldItem.currency_configuration.defaultCurrency
                  }
                }
				      } else {
					      this.additionalFieldInformation[fieldItem.key] = {
						      "currencyCode": "USD"
					      }
				      }
			      }
			      if(fieldItem.input_type === "phonenumber") {
				      if(fieldItem.phonenumberConfiguration && fieldItem.phonenumberConfiguration.defaultCountryCode) {
					      this.additionalFieldInformation[fieldItem.key] = {
						      "countryCode": fieldItem.phonenumberConfiguration.defaultCountryCode,
                  "callingCode":""
					      }
				      } else{
					      this.additionalFieldInformation[fieldItem.key] = {
                  "countryCode": "MM",
                  "callingCode":""
                }
				      }
			      }
		      }
          if(['number','currency','options','input'].includes(fieldItem.input_type) && fieldItem.copyFieldValueConfig){
            fieldItem.disable = true;
            let copyValueConfig = fieldItem.copyFieldValueConfig
            let canCopyFieldApply = this.copyFieldAccessCheck(fieldItem);
            if(canCopyFieldApply) {
              let parentKey = copyValueConfig['fieldKey'];
              if(this.fields.field_name[fieldItem.key] !== this.fields.field_name[parentKey]){
                await this.handleCopyFieldValueConfig(fieldItem, copyValueConfig, parentKey);
              }
            }
          }
        }
        let fields = this.fieldDefinition.filter((field) => field.disable == true)
        this.hideFields();
      },
	    deep: true
    }
  },
  methods: {
    async handleCopyFieldValueConfig(fieldItem, copyValueConfig, parentKey){
      let copyValue;
      let currencyCode;
      if(copyValueConfig.type == 'entity'){
        copyValue = this.fields.field_name[parentKey]
      } else if(copyValueConfig.type == 'master'){
        let masterViewId = copyValueConfig.viewId;
        let masterReferenceKey = copyValueConfig.referenceKey;
        await this.fetchMasterData(masterViewId)
        let stringifiedMasterData = JSON.parse(JSON.stringify(this.masterDataList));
        let masterViewField = {};
        if(masterViewId){
          if(stringifiedMasterData[masterViewId]){
            masterViewField = stringifiedMasterData[masterViewId].find((field) => field._id === this.fields.field_name[parentKey]);
          }
        }
        copyValue= masterViewField ? masterViewField[masterReferenceKey] : "";
      }
      if(fieldItem.input_type == 'input' || fieldItem.input_type == 'options'){
        this.fields.field_name[fieldItem.key] = copyValue ? copyValue : "";
      } 
      else 
      if(fieldItem.input_type == 'currency'){
        if(Object.keys(this.additionalFieldInformation[parentKey]).includes('currencyCode')) {
          currencyCode = this.additionalFieldInformation[parentKey]['currencyCode'];
        } else if(fieldItem.currency_configuration && fieldItem.currency_configuration.defaultCurrency){
          currencyCode = fieldItem.currency_configuration.defaultCurrency
        }
        this.additionalFieldInformation[fieldItem.key] = {"currencyCode": currencyCode ? currencyCode : 'USD'};
        this.currencyData = this.fields.field_name.AdditionalFieldInformation;
        this.fields.field_name[fieldItem.key] = typeof copyValue === "number" ? copyValue || 0 : parseInt(copyValue) || 0;
      } 
      else if(fieldItem.input_type == 'number'){
        this.fields.field_name[fieldItem.key] = typeof copyValue === "number" ? copyValue || 0 : parseInt(copyValue) || 0;
      }
    },
    copyFieldAccessCheck(fieldItem){
      let workflowId = this.fieldUpdateItem.workflow_id;
      if(fieldItem.field_access && fieldItem.field_access.update){
        var roleGroup = getFromLocalStorage("userGroup");
        let fieldAccessConfig = fieldItem.field_access.update
        let workflowAccess = fieldAccessConfig[workflowId];
        if(!workflowAccess || Object.keys(workflowAccess).length == 0 ) return false;
        let stageName = this.fieldUpdateItem["current_status"]
        let stageAccess = workflowAccess[stageName];
        if(!stageAccess || stageAccess.length == 0) return false;
        if(stageAccess.includes(roleGroup)){
          return true;
        }
        return false;
      }
    },
    async fetchMasterData(masterViewId){
      try {
        let payload = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL + "/displayMasterValue",
            data: {
                applicationId: this.$route.query.applicationId,
                token: getFromLocalStorage("token"),
                authType: getFromLocalStorage("authType"),
                viewId: masterViewId
            },
            headers: {
                "content-type": "application/json",
            },
        };
        let apiResponse = await axios(payload);
        if (apiResponse.data && apiResponse.data.data) {
          this.masterDataList[masterViewId] = apiResponse.data.data;
        }
      } catch (apiError) {
        if (
        apiError.response &&
        apiError.response.status &&
        apiError.response.status == 401
        ) {
          this.$root.$emit('update_session_value', true);
        }
        else {
          this.errorMessageShow = true;
          if(apiError.response && apiError.response.data && apiError.response.data.errorObject){
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: apiError.response.data.errorObject.error_code,
              error_uuid: apiError.response.data.errorObject.error_uuid,
            };
          } else{
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
        }
      }
    },
    calculateDialogWidth(){
      if(Object.keys(this.showTable).length > 0){
        this.dialogWidth = '70%';
      } else {
        this.dialogWidth = '35%';
      }
    },
    clearInputData(key){
      this.fields.field_name[key] = undefined;
    },
    renderComponent(item){
      this.$set(this.showTable, item, true);
    },
    handleScroll(){
      let showTableArr = Object.keys(this.showTable);
      let overallDiv = document.querySelector('#divSelector');
      for(let item of showTableArr){
        let btn = document.querySelector('#click' + item);
        if(btn != null && (!(item in this.alreadyRendered) || !this.alreadyRendered[item]) && (overallDiv.clientHeight - ((btn.getBoundingClientRect().top + 80) - overallDiv.getBoundingClientRect().top) > 0)){
          this.alreadyRendered[item] = true;
          this.$set(this.showTable, item, false);
          this.$nextTick(()=>{
            btn.click();
          })
        }
        if(this.alreadyRendered[item] && !(overallDiv.clientHeight - ((btn.getBoundingClientRect().top + 80) - overallDiv.getBoundingClientRect().top) > 0)){
          this.alreadyRendered[item] = false;
        }
      }
    },
    showHistoryComponent(){
      this.showHistoryDialog = true;
      this.dialogWidth = '90%';
      this.showSpotlightSection = false;
      this.showValidationPopup = false;
    },
    closeHistory(){ 
      this.showHistoryDialog = false;
      this.calculateDialogWidth();
      this.dialogCols = 'col-12'
    },
    formatFileTitle(fileVal,key =""){
      var folderUrl = fileVal.split("/").slice(-1);
      if( this.fields.field_name["AdditionalFieldInformation"] && this.fields.field_name["AdditionalFieldInformation"][key] && this.fields.field_name["AdditionalFieldInformation"][key][folderUrl]) {
        return this.fields.field_name["AdditionalFieldInformation"][key][folderUrl];
      }
      var [first,...rest]= folderUrl[0].split("_")
      var fileName = rest.join('');
      if(fileName) {
        return fileName;
      }
    },
    formatName(fileVal, key = "") {
      var folderUrl = fileVal.split("/").slice(-1);
      if(this.fields.field_name["AdditionalFieldInformation"] && this.fields.field_name["AdditionalFieldInformation"][key] && this.fields.field_name["AdditionalFieldInformation"][key][folderUrl]) {
        return  this.fileNameFormat(this.fields.field_name["AdditionalFieldInformation"][key][folderUrl]);
      }
      var [first,...rest]= folderUrl[0].split("_");
      var fileName = rest.join('');
      if(fileName) {
        return this.fileNameFormat(fileName);
      }
    },
    async openAssigneeSection() {
      this.showHistoryButton = false;
      this.showHistoryDialog = false;
      this.showValidationPopup = false;
      this.valueValidationErrorList = [];
      this.valueValidationErrorListInMM = [];
      this.errorList = [];
      this.errorListInMM = [];
      this.tableValidationErrorList = [];
      var isFormValid = await this.validateFields();
      if(isFormValid){
        this.loadAssigneeUserList(
          this.fields.field_name["assignee_user_group"],
          this.fields.field_name
        );
      }else{
        this.showValidationPopup = true;
        this.showSpotlightSection = false;
      }
    },
    checkFileExits(fileName){
      for(let value of this.fileAttachmentArray){
        if(value.file === fileName){
          return false
        }
      }
      return true
    },
    removeFile(index,key) {
      let fileToRemove = this.fields.field_name[key][index].split("/").slice(-1)[0];
      this.fields.field_name[key].splice(index, 1);
      if(this.additionalFieldInformation[key] && this.additionalFieldInformation[key][fileToRemove]) {
        delete this.additionalFieldInformation[key][fileToRemove];
      }
    },
    checkFile(file,key){
      if(this.multiFileArray[key]===undefined){
        return true
      }
      else{
        if(this.multiFileArray[key].includes(file)){
          return false
        }
        else{
          return true
        }
      }
    },
    multifileCheck(value) {
      if(value.status){
        if(this.multiFileArray[value.key]===undefined){
          this.multiFileArray[value.key]=[];
          this.multiFileArray[value.key].push(value.file);
          this.fileAttachmentArray.push(value);
        }
        else{
          this.multiFileArray[value.key].push(value.file);
          this.fileAttachmentArray.push(value);
        }
        let folderUrl = value.file.split('/').slice(-1)[0];
        let fileObject = {};
        fileObject[folderUrl] = value.currentfileName;
        this.additionalFieldInformation[value.key] = {...this.additionalFieldInformation[value.key], ...fileObject};
      }
    },
    async fileIndexFinder(file,key){
      if(file!==""){
        var folderUrl=file.split("/").slice(-1);
        var [first,...rest]= folderUrl[0].split("_")
        var fileName = rest.join('_');
        if(fileName) {
          return fileName;
        } else if(this.additionalFieldInformation[key] && this.additionalFieldInformation[key][folderUrl]) {
          return this.additionalFieldInformation[key][folderUrl];
        }
      }
    },
    async removefileAttachemnts(files,fileName,key) {
      if(files.hasOwnProperty(key)) {
        for(var val in files[key]) {
          var file = await this.fileIndexFinder(files[key][val] ,key);
          if(file === fileName){
            files[key].splice(val,1);
          }
        } 
      }
      return files
    },
    async multiFileRemove(fileName,key) {
      this.multiFileArray = await this.removefileAttachemnts(this.multiFileArray,fileName,key);
      for(var value in this.fileAttachmentArray){
        var file = await this.fileIndexFinder(this.fileAttachmentArray[value].file ,key);
        if(file === fileName && this.fileAttachmentArray[value]['key'] === key) {
          let fileIndex  = this.fields.field_name[key].indexOf(this.fileAttachmentArray[value].file);
          this.fileAttachmentArray.splice(value,1);
          if(fileIndex > -1) {
            this.fields.field_name[key].splice(fileIndex,1);
          }
        }
      }
    },
    assigneFiles() {
      if(Object.keys(this.multiFileArray).length > 0) {
        for(var key in this.multiFileArray) {
          if(this.fields.field_name[key]!=="" && typeof this.fields.field_name[key]!=="string" && this.fields.field_name[key]!== undefined ) {
            for(var file of this.multiFileArray[key]){
              if(!this.fields.field_name[key].includes(file)){
                this.fields.field_name[key].push(file);
              }
            }
          }
          else{
            this.fields.field_name[key] = [];
            for(var file of this.multiFileArray[key]){
              if(!this.fields.field_name[key].includes(file)){
                this.fields.field_name[key].push(file);
              }
            }
          }
        }
      }
    },
    viewFile(file_name) {
	    var read_body = {
	    	token: getFromLocalStorage("token"),
	    	authType: getFromLocalStorage("authType"),
	    	applicationId: this.$route.query.applicationId,
	    	userId: this.$route.query.userId,
	    	workflow_id:this.workflowId ,
	    	file_name: file_name,
	    };
	    axios
	    	.post(
	    		process.env.VUE_APP_BACKEND_URL + "/getPresignedURLRead",
	    		read_body
	    	)
	    	.then((readSuccess) => {
	    		window.open(readSuccess.data.data, "_blank");
	    	})
	    	.catch((readError) => {
	    	if (
	    		readError.response &&
	    		readError.response.status &&
	    		readError.response.status == 401
	    	) {
	    		this.$root.$emit('update_session_value', true);
	    	}
	    	else{
	    		this.errorMessageShow = true;
	    		if(readError.response && readError.response.data && readError.response.data.errorObject){
	    		this.errorMessageObject = {
	    			errorMessageShow: this.errorMessageShow,
	    			error_code: readError.response.data.errorObject.error_code,
	    			error_uuid: readError.response.data.errorObject.error_uuid,
	    		};
	    		}
	    		else{
	    		this.errorMessageObject = {
	    			errorMessageShow: this.errorMessageShow,
	    			error_code: "",
	    			error_uuid: "",
	    		};
	    	    }
	    	    this.showMessageDialog = true;
	    	}
	    });
	  },
    closeAssigneeSection() {
      this.showHistoryButton = true;
      this.showAssigneeSection = false;
      this.calculateDialogWidth();
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if(emitObject && emitObject.errorDisplayMessage){
        closeDialogFlag = emitObject.errorDisplayMessage
      }
      this.showPaginationSpinner = emitObject.flag;
      this.showSpinner = emitObject.flag;
      this.showMessageDialog = emitObject.flag;
      // this.$emit("closeMessageDialog", false);
      this.$emit("closeDialog", closeDialogFlag);
    },
    clickCancel() {
      this.showSpotlightSection = false;
      this.showValidationPopup = false;
      this.calculateDialogWidth();
      this.dialogCols = "col-12",
      this.dialog = false;
      this.$emit("closeUpdateFieldDialog", false);
    },
    clickView(fileLink) {
      var name = fileLink;
      var read_body = {
        file_name: name,
        token: getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/getPresignedURLRead",
          read_body
        )
        .then((readSuccess) => {
          window.open(readSuccess.data.data, "_blank");
        })
        .catch((readError) => {
          if (
            readError.response &&
            readError.response.status &&
            readError.response.status == 401
          ) {
            this.$root.$emit('update_session_value', true);
          }
          else{
          this.errorMessageShow = true;
           if(readError.response && readError.response.data && readError.response.data.errorObject){
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: readError.response.data.errorObject.error_code,
              error_uuid: readError.response.data.errorObject.error_uuid,
            };
          }
          else{
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
          this.showMessageDialog = true;
          }
        });
    },
    additionalField(key, data){ 
      this.additionalFieldInformation[key] = {}
      this.additionalFieldInformation[key] = {
        "callingCode":data.callingCode,
        "countryCode":data.countryCode
      }
    },
    clickSave() {
	    this.fields.field_name["AdditionalFieldInformation"] = this.additionalFieldInformation;
      this.confirmationDialogObject["display"] = true;
      this.confirmationDialogObject["action"] = "UPDATE";
      this.confirmationDialogObject["viewId"] =
        this.updateFieldDialogObject.viewId;
      this.confirmationDialogObject["fileAttachments"] =
        this.fileAttachmentArray;
      this.confirmationDialogObject["data"] = this.fields.field_name;
      this.showConfirmationDialog = true;
      if (this.assigneeDisabled === true || this.showAssigneeField == false){
          this.confirmationDialogObject["display"] = false;
      }
    },
    validateLengthAndNumber(element){
      var returnValue = true;
      if(element.validators.number){
        const regexExp = /^[0-9]*$/g;
        const str = this.fields.field_name[element.key];
        returnValue = regexExp.test(str);
      }
      if(!returnValue){
        this.valueValidationErrorList.push("Please enter only numbers for " + element.display_name.en)
        if (this.isMMSecondarySupportLang) {
          this.valueValidationErrorListInMM.push("ကျေးဇူးပြု၍ နံပါတ်များကိုသာ ဖြည့်စွက်ပါ " + element.display_name.mm)
        }
      }
      if(element.validators.length){
        if(this.fields.field_name[element.key].length > element.validators.length.max){
          this.valueValidationErrorList.push("Length of " + element.display_name.en +  " should not exceed " + element.validators.length.max)
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm +  " သတ်မှတ်ထားသော အများဆုံးစာလုံးအရေအတွက်ထက် မကျော်လွန်ရပါ။ " + element.validators.length.max)
          }
        }
        if(this.fields.field_name[element.key].length < element.validators.length.min){
          this.valueValidationErrorList.push("Length of " + element.display_name.en +  " should be at least " + element.validators.length.min)
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm +  " သတ်မှတ်ထားသော အနည်းဆုံးစာလုံးအရေအတွက်ထက် မနည်းစေရပါ။ " + element.validators.length.min)
          }
        }
      }
    },
    validateCheckList(element){
      var returnValue = true;
      if(element.validators.list){
        if(element.validators.list.type == 'all'){
          returnValue = true;
              for(var configOption of element.option_values){
                if(configOption.value){
                  for(let dataOption of this.fields.field_name[element.key]){
                    if(dataOption.value == configOption.value && !dataOption.checked){
                      returnValue = false;
                      break;
                    }
                  }
                }
              }
              if(returnValue){
                for(let dataOption of this.fields.field_name[element.key]){
                  if(dataOption.checked == false){
                   returnValue = false;
                    break
                  }
                }
            }else{
            this.valueValidationErrorList.push("Please select all items for " + element.display_name.en)
            if (this.isMMSecondarySupportLang) {
              this.valueValidationErrorListInMM.push("သတ်မှတ်ထားသည်များ အားလုံးကို ရွေးပါ။ " + element.display_name.mm);
            }
          }

        }else if(element.validators.list.type == 'range'){
          returnValue = true;
          if(element.validators.list.config){
            var checkCount = 0;
            for(let item of this.fields.field_name[element.key]){
              if(item.checked == true){
                checkCount += 1;
              }
            }
            if(checkCount > element.validators.list.config.max){
              returnValue = false
              this.valueValidationErrorList.push("Selected items for " + element.display_name.en + " should not exceed " + element.validators.list.config.max)
              if (this.isMMSecondarySupportLang) {
                this.valueValidationErrorListInMM.push("Selected items for " + element.display_name.mm + " should not exceed " + element.validators.list.config.max)
              }
            }
            if(checkCount < element.validators.list.config.min){
              returnValue = false
              this.valueValidationErrorList.push("Selected items for " + element.display_name.en + " should be at least " + element.validators.list.config.min)
              if (this.isMMSecondarySupportLang) {
                this.valueValidationErrorListInMM.push("Selected items for " + element.display_name.mm + " should be at least " + element.validators.list.config.min)
              }

            }
          }
        }
      }        
    },
    validateMultiSelect(element){
      var isvalid = true;
      if(this.fields.field_name[element.key]){
        if(element.validators.list){
          if(element.validators.list.type == 'all'){
            let allSelectedOptions = this.fields.field_name[element.key].map(option => {
              return option.value
            });
            for(let option of element.option_values) {
              if(! (allSelectedOptions.includes(option.value))){
                isvalid = false;
                break;
              }
            }
            if(!isvalid){
              if(element.validators.list.errorMessage){
                this.valueValidationErrorList.push(element.display_name.en + " - " + element.validators.list.errorMessage.en);
                if (this.isMMSecondarySupportLang) {
                  this.valueValidationErrorListInMM.push(element.display_name.mm + " - " + element.validators.list.errorMessage.mm);
                }
              } else {
                this.valueValidationErrorList.push("Please select all items for " + element.display_name.en);
                if (this.isMMSecondarySupportLang) {
                  this.valueValidationErrorListInMM.push("သတ်မှတ်ထားသည်များ အားလုံးကို ရွေးပါ။ " + element.display_name.mm);
                }
              } 
            }
          }
          else if(element.validators.list.type == 'range'){
            if(element.validators.list.config){
              let itemsSelected = this.fields.field_name[element.key].length;
              if(element.validators.list.config.min && itemsSelected < element.validators.list.config.min){
                if(element.validators.list.errorMessage){
                  this.valueValidationErrorList.push(element.display_name.en + " - " + element.validators.list.errorMessage.en);
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(element.display_name.mm + " - " + element.validators.list.errorMessage.mm);
                  }
                } else {
                  this.valueValidationErrorList.push("Selected items for " + element.display_name.en + " should be at least " + element.validators.list.config.min)
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push("Selected items for " + element.display_name.mm + " should be at least " + element.validators.list.config.min)
                  }
                }
              }
              if(element.validators.list.config.max && itemsSelected > element.validators.list.config.max){
                if(element.validators.list.errorMessage){
                  this.valueValidationErrorList.push(element.display_name.en + " - " + element.validators.list.errorMessage.en);
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(element.display_name.mm + " - " + element.validators.list.errorMessage.mm);
                  }
                } else { 
                  this.valueValidationErrorList.push("Selected items for " + element.display_name.en + " should not exceed " + element.validators.list.config.max)
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push("Selected items for " + element.display_name.mm + " should not exceed " + element.validators.list.config.max)
                  }
                }
              }
            }
          }
        }
      }        
    },
    getFormatedFiles(files,key) {
      let formatedFiles = [];
      for (let file of files) {
        let folderUrl = file.split('/').slice(-1);
        let [, ...rest] = folderUrl[0].split('_');
        let fileName = rest.join('_');
        if(fileName) {
          formatedFiles.push(fileName);
        } else if(this.fields.field_name["AdditionalFieldInformation"][key] && this.fields.field_name["AdditionalFieldInformation"][key][folderUrl[0]]) {
          formatedFiles.push(this.fields.field_name["AdditionalFieldInformation"][key][folderUrl[0]])
        }
      }
      return formatedFiles;
    },
    validateMultiFileupload(fieldItem) {
    if (this.fields.field_name[fieldItem.key].length === 0 || this.fields.field_name[fieldItem.key] === undefined) {
          this.valueValidationErrorList.push("Empty files are not allowed in " + fieldItem.display_name.en);
          if (this.isMMSecondarySupportLang) {
              this.valueValidationErrorListInMM.push("Empty files are not allowed in " + fieldItem.display_name.mm);
          }
      }
      let filesToValidate = this.getFormatedFiles(this.fields.field_name[fieldItem.key],fieldItem.key);
      if (new Set(filesToValidate).size !== filesToValidate.length) {
          this.valueValidationErrorList.push("duplicates files are not allowed in " + fieldItem.display_name.en);
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push("duplicates files are not allowed in " + fieldItem.display_name.mm);
          }          
      }
    },
    validateNumberField(element){
      if(isNaN(this.fields.field_name[element.key]) ){
        this.valueValidationErrorList.push("Invalid input. " + element.display_name.en + " should be Number")
        if (this.isMMSecondarySupportLang) {
          this.valueValidationErrorListInMM.push("ထည့်သွင်းမှု မမှန်ကန်ပါ။ " + element.display_name.mm + "နံပါတ်များသာ ဖြည့်စွက်ပါ")
              }
      }
      else if(element.validators.required && element.validators.numberType){
        if(element.validators.numberType == "integer" && this.fields.field_name[element.key] != Math.floor(this.fields.field_name[element.key])){
          this.valueValidationErrorList.push(element.display_name.en + " should be Integer")
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm + " ထည့်သွင်းသည့်နံပါတ်မှာ ကိန်းပြည့်ဖြစ်ရပါမည်။ (ဒဿမကိန်းမဖြစ်ရ)")
              }
        }
      }
      if(element.validators.required && element.validators.range){
        if(this.fields.field_name[element.key] > element.validators.range.max){
          this.valueValidationErrorList.push(element.display_name.en + " should not exceed " + element.validators.range.max)
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm + " သတ်မှတ်ထားသော အများဆုံးစာလုံးအရေအတွက်ထက် မကျော်လွန်ရပါ " + element.validators.range.max)
              }
        }
        else if(this.fields.field_name[element.key] < element.validators.range.min){
          this.valueValidationErrorList.push(element.display_name.en + " should be atleast " + element.validators.range.min)           
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm + " သတ်မှတ်ထားသော အနည်းဆုံးစာလုံးအရေအတွက်ထက် မနည်းစေရပါ " + element.validators.range.min)
              }
        }
      }
    },
    deleteEmptyRow(element) {
      if(this.fields.field_name[element.key].length > 1){
        let result = this.removeEmptyObjects(this.fields.field_name[element.key]);
        this.$set(this.fields.field_name, element.key, result);
      }
    },
    validateTableValue(element){
      let optionErrorList = [];
      let futureDateErrorList = [];
      let pastDateErrorList = [];
      let numberRangeErrorList=[];
      let previousField = true;
      for(let value in this.fields.field_name[element.key]) {
        previousField = element.display_name.en;
        for(let item in element.table_configuration.columns) {
            let hasColumnAccess = this.checkColumnAccess(element.table_configuration.columns[item].access, this.fieldUpdateItem.workflow_id, this.fieldUpdateItem.current_status, getFromLocalStorage("userGroup"));
            let columnName = element.table_configuration.columns[item].name;
            if (hasColumnAccess) {
              let validators = element.table_configuration.columns[item].validators;
              if(element.table_configuration.columns[item].conditionalValidations){
                for (let conditionalStatement of element.table_configuration.columns[item].conditionalValidations) {
                  if (this.checkTableConditionalValidations(conditionalStatement.condition, this.fields.field_name[element.key][value])) {
                    validators = element.table_configuration.columns[item].validatorSet[conditionalStatement.validationSet]
                    break;
                  }
                }
              }
              if (!(this.fields.field_name[element.key][value][columnName])){
                if(validators.required) {
                  if(previousField) {
                    this.errorList.push({
                      'fieldName': element.display_name.en,
                      'type': 'tableError',
                      'columnName': columnName,
                      'row': Number(value)+1
                    });
                    previousField = false;
                  }
                  this.errorList.push({
                    'type': 'tableError',
                    'columnName': columnName,
                    'row': Number(value)+1
                  });
                }
              } else {
                if (this.fields.field_name[element.key][value][columnName] && element.table_configuration.columns[item].type === 'number') {
                  if(validators.range){
                    let numberRangeError= this.checkColumnRange(validators.range, this.fields.field_name[element.key][value][columnName], value, columnName);
                    numberRangeErrorList = numberRangeErrorList.concat(numberRangeError);
                  }
                }           
                if (element.table_configuration.columns[item].type == 'select') {
                  if (!((element.table_configuration.columns[item].option_values).includes(this.fields.field_name[element.key][value][columnName]))) {
                      optionErrorList.push({
                      'columnName': columnName,
                      'row': Number(value) + 1
                    });
                  }
                }
                if(element.table_configuration.columns[item].type == 'date') {
                  if('date' in validators) { 
                    if(('hideFutureDates' in validators["date"]) 
                    && validators["date"]["hideFutureDates"]) {
                      if(new Date(new Date(this.fields.field_name[element.key][value][columnName]).toDateString()) > new Date(new Date().toDateString())){
                        futureDateErrorList.push({
                          'columnName': columnName,
                          'row': Number(value) + 1,
                        });
                      }
                    }
                    if(('hidePastDates' in validators["date"]) 
                    && validators["date"]["hidePastDates"]) {
                      if(new Date(new Date(this.fields.field_name[element.key][value][columnName]).toDateString()) < new Date(new Date().toDateString())){
                        pastDateErrorList.push({
                          'columnName': columnName,
                          'row': Number(value) + 1,
                        });
                      }
                    }                   
                  }
                }
              }
            }
          }
      }
      if(optionErrorList.length > 0 || futureDateErrorList.length > 0 || pastDateErrorList.left > 0 || numberRangeErrorList.length>0) {
        let tableFieldName = element.display_name.en;
        let tableError = {};
        tableError[tableFieldName] = {};
        if(optionErrorList.length > 0) { tableError[tableFieldName]["optionError"] = optionErrorList}
        if(futureDateErrorList.length > 0) { tableError[tableFieldName]["futureDateError"] = futureDateErrorList}
        if(pastDateErrorList.length > 0) { tableError[tableFieldName]["pastDateError"] = pastDateErrorList}
        if(numberRangeErrorList.length > 0) { tableError[tableFieldName]["numberRangeErrorList"] = numberRangeErrorList}
        this.tableValidationErrorList.push(tableError);
      }
    },
    validateCurrency(element){
      if(element.validators.range){
        var minValue=element.validators.range.min;
        var maxValue=element.validators.range.max;
        if(this.fields.field_name[element.key]>Number(maxValue)){
          this.valueValidationErrorList.push("Value of "+ element.display_name.en +" should not be greater then "+maxValue);
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm +" သတ်မှတ်ထားသော အများဆုံးတန်ဖိုးထက် မကျော်လွန်ရပါ "+maxValue);
              }
        }
        else if(this.fields.field_name[element.key]<Number(minValue)){
          this.valueValidationErrorList.push("Value of "+ element.display_name.en +" should not be less then "+minValue);
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm +" သတ်မှတ်ထားသော အနည်းဆုံးတန်ဖိုးထက် မနည်းစေရပါ "+minValue);
              }
        }
      }
    },
    validateDateTime(element){
      if(!this.additionalFieldInformation[element.key].timezone) {
        this.valueValidationErrorList.push("Timezone of "+ element.display_name.en +" should not be empty ");
      }
      let timezone = this.additionalFieldInformation[element.key].timezone;    
      if(this.fields.field_name[element.key]) {
        var value = moment.unix(Number(this.fields.field_name[element.key])).tz(timezone).format("YYYY-MM-DD");
      if(this.startDate[element.key]){
        var min = moment.unix(Number(this.startDate[element.key])).tz(timezone).format("YYYY-MM-DD");
        if(min > value){
          this.valueValidationErrorList.push("Value of "+ element.display_name.en +" should not be lesser then "+ moment.unix(this.startDate[element.key]).tz(timezone).format("DD-MM-YYYY") );
        }
      }
      if(this.endDate[element.key]){
        var max = moment.unix(Number(this.endDate[element.key])).tz(timezone).format("YYYY-MM-DD");
        if(max < value){
          this.valueValidationErrorList.push("Value of "+ element.display_name.en +" should not be greater then "+moment.unix(this.endDate[element.key]).tz(timezone).format("DD-MM-YYYY"));
        }
      }
      }
    },
    async validateFields() {
      this.errorList = [];
      this.errorListInMM = [];
      this.valueValidationErrorList=[];
      this.valueValidationErrorListInMM=[];
      for(var element of this.fieldDefinition) {
        if (element.isVisible && element.validators && element.validators.required) {
          if (element.input_type != "autoincrement" &&
            element.key != "current_status" &&
            element.key != "status_action" &&
            element.key != "consolidated_overall_breach_status" &&
            element.key != "consolidated_stage_breach_status" &&
            element.key != "stage_breach_status" &&
            element.key != "overall_breach_status" &&
            element.input_type != "slaBreachTime" &&
            !element.disable && 
            element.input_type != "label"
          ) {
            if(element.renderField){
              if(element.input_type == "checklist"){
                  await this.validateCheckList(element)
              }
              else if(element.input_type == "number"){
                this.validateNumberField(element);
              }
              else if(element.input_type == "multifileupload") {
                this.validateMultiFileupload(element);
              }
              //currency Validation
              else if(element.input_type == "currency") {
                if(!this.fields.field_name[element.key] && this.fields.field_name[element.key] != 0){
                  this.errorList.push(element.display_name.en);
                  if (this.isMMSecondarySupportLang) {
                    this.errorListInMM.push(element.display_name.mm);
                  }
                }
                if(this.additionalFieldInformation[element.key]){
                  if(!this.additionalFieldInformation[element.key]['currencyCode']){
                    this.valueValidationErrorList.push("Currency Code of "+ element.display_name.en +" should not be empty ");
                    this.valueValidationErrorList.push("Currency Code of "+ element.display_name.mm +" should not be empty ");
                  }
                }else{
                  this.valueValidationErrorList.push("Currency Code of "+ element.display_name.en +" should not be empty ");
                  this.valueValidationErrorList.push("Currency Code of "+ element.display_name.mm +" should not be empty ");
                }
                this.validateCurrency(element);
              }
              else if(element.input_type === "URL") {
                if(!validator.isURL(this.fields.field_name[element.key])){
                  this.valueValidationErrorList.push(
                    "Please enter a valid URL for " + element.display_name.en
                  );
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(
                      "မှန်ကန်သည့် လင့်ခ် ဖြည့်စွက်ပါ။ " + element.display_name.mm
                    );
                  }
                }
              }
              //Required validation
              else if (
                this.fields.field_name[element.key] == undefined ||
                this.fields.field_name[element.key] == "" ||
                this.fields.field_name[element.key] == null
              ) {
                if(element.input_type == 'table'){
                  this.isTableError = true;
                }
                this.errorList.push(element.display_name.en);
                if (this.isMMSecondarySupportLang) {
                  this.errorListInMM.push(element.display_name.mm);
                }
              }
              //Value validation
              else if(element.validators.value && !(element.validators.value == this.fields.field_name[element.key]) && !element.validators.value.includes(this.fields.field_name[element.key])){
                if (element.validators.value.length == 1) {
                  this.valueValidationErrorList.push(element.display_name.en + " should be " + element.validators.value);
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(element.display_name.mm + " should be " + element.validators.value);
              }
                } else {
                  this.valueValidationErrorList.push(element.display_name.en + " should match one of the following: " + element.validators.value.join(", "));
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(element.display_name.mm + " should match one of the following: " + element.validators.value.join(", "));
              }
                }
              } else if(element.input_type == "phonenumber"){ 
                if(isPossiblePhoneNumber(this.fields.field_name[element.key], this.additionalFieldInformation[element.key].countryCode) === false || 
                isValidPhoneNumber(this.fields.field_name[element.key], this.additionalFieldInformation[element.key].countryCode) === false){
                  this.valueValidationErrorList.push("Please enter valid phone number for " + element.display_name.en)
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push("ဖုန်းနံပါတ်များကို စနစ်မှန်ကန်စွာထည့်သွင်းပါ။ ဖုန်းနံပါတ်ရှေ့ဆုံးတွင် (သုည - 0 ) မထည့်ရ။ " + element.display_name.mm);
                  }
                }
              }
              else if(element.input_type == "input"){
                if (element.validators && element.validators.regex) {
                    let value = this.fields.field_name[element.key];
                    let regex = new RegExp(element.validators.regex.expression);
                    let errorMessage;
                    if (element.validators.regex.errorMessage) {
                      errorMessage = element.validators.regex.errorMessage;
                    } else {
                      errorMessage = {
                        'en' : "Please enter valid input for " + element.display_name.en,
                        'mm' : "ကျေးဇူးပြု၍ မှန်ကန်သော ထည့်သွင်းမှုကို ထည့်သွင်းပါ။" + element.display_name.mm
                      }
                    }
                    if (!regex.test(value)) {
                        this.valueValidationErrorList.push(
                            errorMessage.en
                        );
                        if (this.isMMSecondarySupportLang) {
                            this.valueValidationErrorListInMM.push(
                                errorMessage.mm
                            );
                        }
                    }
                  }
                  this.validateLengthAndNumber(element);
              }
            }
          }
        }
        if(element.isVisible && element.renderField &&  element.input_type == "datetime") {
          this.validateDateTime(element);
        }
        if(element.isVisible && element.renderField &&  element.input_type == "multiselect") {
          this.validateMultiSelect(element);
        }
        if(element.isVisible && element.renderField &&  element.input_type == "table") {
          this.deleteEmptyRow(element);
          this.validateTableValue(element)
        }
        //NRC validation
        if(element.isVisible && element.renderField
        &&  element.input_type == "nrc"
        ) {
          this.showSpinner = true;
          this.dialogWidth = '35%'
          this.showHistoryButton = true
          let validNRC = true;
          if(element.validators.required) {
            validNRC = await this.validateNRC(this.fields.field_name[element.key]);
          }
          if(!validNRC){
            this.valueValidationErrorList.push(
              "Please enter a valid NRC for " + element.display_name.en
            );
            if (this.isMMSecondarySupportLang) {
              this.valueValidationErrorListInMM.push(
                "မှတ်ပုံတင်နံပါတ်အား မှန်ကန်စွာဖြည့်သွင်းပါ။ " + element.display_name.mm
              );
            }
          }
          this.showSpinner = false;
          this.dialogWidth = '70%';
        }
      };
      
      if (this.errorList.length > 0 || this.valueValidationErrorList.length > 0 || this.tableValidationErrorList.length > 0) {
        this.dialogWidth = '70%'
        this.errorValidationDialogObject["data"] = { errorList: this.errorList };
        if (this.isMMSecondarySupportLang) {
          this.errorValidationDialogObject["data"] = { errorList: this.errorList, errorListInMM: this.errorListInMM}    ;
          this.errorValidationDialogObject["valueValidationErrorListInMM"] = this.valueValidationErrorListInMM;
          this.errorValidationDialogObject["displayTextInMM"] = "အောက်ပါ အကွက်များတွင် လိုအပ်သော အချက်အလက်များ ဖြည့်စွက်ပါ။ ";
        }
        this.errorValidationDialogObject["viewId"] =
        this.updateFieldDialogObject.viewId;
        this.errorValidationDialogObject["display"] = true;
        this.errorValidationDialogObject["valueValidationErrorList"] =
        this.valueValidationErrorList;
        this.errorValidationDialogObject["tableValidationErrorList"] = this.tableValidationErrorList;
        this.errorValidationDialogObject["displayText"] =
        "Enter the value(s) into the following field(s): "; 
        this.showHistoryButton = true
        return false;
      } else { 
        this.showValidationPopup = false;
        this.dialogWidth = '35%'
		    this.fields.field_name["AdditionalFieldInformation"] = this.additionalFieldInformation;
        this.confirmationDialogObject["data"] = this.fields.field_name;
        this.confirmationDialogObject["fileAttachments"] =
          this.fileAttachmentArray;
        this.confirmationDialogObject["action"] = "UPDATE";
        this.confirmationDialogObject["viewId"] =
          this.updateFieldDialogObject.viewId;
        this.confirmationDialogObject["display"] = true;
        return true;
      }
    },
    closeDialog(flag) {
      this.showSpinner = false;
      this.showConfirmationDialog = false;
      this.$emit("closeUpdateFieldDialog", flag);
    },
    closeErrorExpend(){ 
      this.calculateDialogWidth();
      this.showValidationPopup = false;
    },
    closeErrorValidationDialog(flag) {
      this.showValidationPopup = flag;
      this.valueValidationErrorList = [];
      this.valueValidationErrorListInMM = [];
      this.tableValidationErrorList = [];
      this.$emit("closeErrorValidationDialog", false);
    },
    revertOldFile(key) {
      this.fields.field_name[key] = this.$props.fieldUpdateItem[key]
      if(this.$props.fieldUpdateItem['AdditionalFieldInformation'] && this.$props.fieldUpdateItem['AdditionalFieldInformation'][key]) {
        this.additionalFieldInformation[key] = this.$props.fieldUpdateItem['AdditionalFieldInformation'][key];
      }
      this.fileUploadRefresher++;
    },
    getBase64(value) {
      if(value.status) {
        this.fileAttachmentArray = this.fileAttachmentArray.filter((fileAttachmentObject)=> fileAttachmentObject.key != value.key);
        this.fileAttachmentArray.push(value);
        if (value.file) {
          this.fields.field_name[value.key] = value.file;
        }
        let folderUrl = value.file.split('/').slice(-1)[0];
        let fileObject = {};
        fileObject[folderUrl] = value.currentfileName;
        this.additionalFieldInformation[value.key] = fileObject;
        this.fileUploadRefresher++;
      }
    },
    hideShowAssigneeField(workflowId, fieldItem) {
      if (this.assigneeDisabled) {
        this.showAssigneeField = true;
      } else {
        if (fieldItem.hasOwnProperty("isShown")) {
          if (fieldItem["isShown"].hasOwnProperty(workflowId)) {
            if (
              fieldItem["isShown"][workflowId].hasOwnProperty(
                this.fields.field_name['current_status']
              )
            ) {
              if (
                fieldItem["isShown"][workflowId][this.fields.field_name['current_status']].includes(
                  getFromLocalStorage("userGroup")
                )
              ) {
                this.showAssigneeField = true;
              } else {
                this.showAssigneeField = false;
              }
            } else {
              this.showAssigneeField = false;
            }
          } else {
            this.showAssigneeField = false;
          }
        } else {
          this.showAssigneeField = true;
        }
      }
    },
    loadAssigneeUserList(value, full_definiton) {
      this.dialogWidth = '35%';
      this.showSpinner = true;
      this.showHistoryButton = false
      let tempDefinition = {...this.$props.fieldUpdateItem,...full_definiton};
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/getUserList",
        data: {
          applicationId: this.$route.query.applicationId,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
          userGroup: value,
          definition: tempDefinition,
        },
        headers: {
          "content-type": "application/json",
        },
      })
        .then((apiResponse) => {
          this.showSpinner = false;
          this.assigneeUserList = apiResponse.data.data;
          if (this.assigneeUserList && this.assigneeUserList.length == 1) {
            this.fields.field_name["assignee"] = this.assigneeUserList[0];
            this.assigneeDisabled = true;
          } else if (
            this.fields.field_name["assignee"] &&
            this.fields.field_name["assignee"] != ""
          ) {
            if (
              typeof this.fields.field_name["assignee"] === "string" ||
              this.fields.field_name["assignee"] instanceof String
            ) {
              this.fields.field_name["assignee"] = this.assigneeUserList.find(
                (assigneeObject) => {
                  return (
                    assigneeObject.email == this.fields.field_name["assignee"]
                  );
                }
              );
            }
          }
          this.hideShowAssigneeField(
              this.fields.field_name["workflow_id"],
              this.assigneeFieldItem
          );
          if(this.assigneeDisabled === true || this.showAssigneeField === false){
            this.confirmationDialogObject["display"] = false;
            this.showSpinner = true;
            this.clickSave()
          }else{
            this.showAssigneeSection = true;
          }
        })
        .catch((apiError) => {
          if (
            apiError.response &&
            apiError.response.status &&
            apiError.response.status == 401
          ) {
            this.$root.$emit('update_session_value', true);
          }
          else {
            this.errorMessageShow = true;
            if(apiError.response && apiError.response.data && apiError.response.data.errorObject){
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: apiError.response.data.errorObject.error_code,
              error_uuid: apiError.response.data.errorObject.error_uuid,
            };
          }
          else{
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
          this.showMessageDialog = true;
          }
          this.showSpinner = false;
          this.showAssigneeSection = true;
        });
    },
    addCurrencyDetails(value,key){
      this.additionalFieldInformation[key] = {}
      this.additionalFieldInformation[key]["currencyCode"] = value;
    },
    async getMasterViewFields(){
      for (let fieldItem of this.fieldDetails) {
        let userGroup = getFromLocalStorage("userGroup");
        if (fieldItem.isMasterViewConfig 
          && fieldItem.masterViewConfig 
          && this.fieldUpdateItem.workflow_id in fieldItem.masterViewConfig 
          && this.$props.fieldUpdateItem.current_status in fieldItem.masterViewConfig[this.fieldUpdateItem.workflow_id] 
          && userGroup in fieldItem.masterViewConfig[this.fieldUpdateItem.workflow_id][this.$props.fieldUpdateItem.current_status]
          && !this.masterDataList[fieldItem.view_id]
        ) {
          this.masterViewAccessFields[fieldItem.key] = fieldItem.masterViewConfig[this.fieldUpdateItem.workflow_id][this.$props.fieldUpdateItem.current_status][userGroup];
          try {
            let payload = {
              method: "post",
              url: process.env.VUE_APP_BACKEND_URL + "/displayMasterValue",
              data: {
                applicationId: this.$route.query.applicationId,
                token: getFromLocalStorage("token"),
                authType: getFromLocalStorage("authType"),
                viewId: fieldItem.view_id
              },
              headers: {
                "Content-Type": "application/json",
              },
            };
            let apiResponse = await axios(payload);
            if (apiResponse.data && apiResponse.data.data) {
              this.masterDataList[fieldItem.view_id] = apiResponse.data.data;
              this.masterDisplayNames[fieldItem.view_id] = apiResponse.data.displayNames;
              this.displayNames[fieldItem.key] = this.masterDisplayNames[fieldItem.view_id];
              if(this.fields.field_name[fieldItem.key] && this.masterDataList[fieldItem.view_id]){
                let masterViewField = this.masterDataList[fieldItem.view_id].find((field) => field._id === this.fields.field_name[fieldItem.key]);
                if (masterViewField && this.masterViewAccessFields[fieldItem.key]) {
                  this.$set(this.viewData, fieldItem.key, {});
                  this.$set(this.viewData[fieldItem.key], 'display_name', {});
                  for (let key of this.masterViewAccessFields[fieldItem.key]) {
                    if (masterViewField[key]) {
                      this.$set(this.viewData[fieldItem.key], key, masterViewField[key]);
                      this.$set(this.viewData[fieldItem.key]['display_name'], key, this.displayNames[fieldItem.key][key]);
                    }
                  }
                }
                this.showMasterViewFields = true;
              } else if (fieldItem.isMasterViewConfig && !this.fields.field_name[fieldItem.key]){
                this.showMasterViewFields = false;
              }
            }
          } catch (apiError) {
            if (
              apiError.response &&
              apiError.response.status &&
              apiError.response.status == 401
            ) {
              this.$root.$emit('update_session_value', true);
            }
            else {
              this.errorMessageShow = true;
              if(apiError.response && apiError.response.data && apiError.response.data.errorObject){
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: apiError.response.data.errorObject.error_code,
                  error_uuid: apiError.response.data.errorObject.error_uuid,
                };
              }
              else{
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: "",
                  error_uuid: "",
                };
              }
              this.showMessageDialog = true;
            }
            this.showSpinner = false;
            this.showAssigneeSection = true;
          }
        }
      }
    },
    hideFields() {
      for(let fieldItem of this.fieldDefinition) {
        if(!this.checkFieldHide(this.fieldType, fieldItem, this.fieldUpdateItem.workflow_id ,this.fieldUpdateItem.current_status)) {
          fieldItem.renderField = false;
        }
      } 
    },
  },
  async mounted() {
    this.userRoleGroup = getFromLocalStorage("userGroup");
    var historyComment = [];
    let secondarySupportLanguageDetails = getFromLocalStorage('secondarySupportLanguage');
    if (secondarySupportLanguageDetails && secondarySupportLanguageDetails.secondarySupportLanguage === 'Burmese' && secondarySupportLanguageDetails.applicationId == this.$route.query.applicationId) {
      this.isMMSecondarySupportLang = true; 
      this.rulesForRequired = [(v) => !!v || "Input is required (ဖြည့်စွက်ရန် လိုအပ်ပါသည်။)"],
      this.rulesForCurrencyRequired =  [
        (v) => !!v || "Enter the Range (အတိုင်းအထွာ ပမာဏကို သတ်မှတ်ဖြည့်စွက်ပါ။)",
        (v) =>
          (parseInt(v) >= 100 && parseInt(v) <= 100000) ||
          "Enter a proper range (အတိုင်းအထွာ ပမာဏကို သတ်မှတ်ဖြည့်စွက်ပါ။)",
      ];
      this.rulesForEmailRequired= [
        (v) => !!v || "Enter the email ID (e-mail ID ဖြည့်စွက်ပါ။)",
        (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || 
        "Enter a valid email ID (မှန်ကန်သည့် e-mail ID ဖြည့်စွက်ပါ။)",
      ];
      this.rulesForURLRequired=[
        (v) => !!v || "Enter the URL (လင့်ခ်ကို ဖြည့်စွက်ပါ။)",
        (v) =>
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(v) ||
        "Enter a valid URL (မှန်ကန်သည့် လင့်ခ် ဖြည့်စွက်ပါ။)"
      ];
    } else {
      this.rulesForRequired = [(v) => !!v || "Input is required"],
      this.rulesForCurrencyRequired =  [
        (v) => !!v || "Enter the Range",
        (v) =>
          (parseInt(v) >= 100 && parseInt(v) <= 100000) ||
          "Enter a proper range",
      ];
      this.rulesForEmailRequired= [
        (v) => !!v || "Enter the email ID",
        (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || 
        "Enter a valid email ID",
      ];
      this.rulesForURLRequired=[
        (v) => !!v || "Enter the URL",
        (v) =>
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(v) ||
        "Enter a valid URL"
      ];
    }
    if('Comments' in this.$props.fieldUpdateItem){
      this.$props.fieldUpdateItem.Comments.forEach(element => {
      historyComment.push(element)
      this.historyComments = historyComment
      });
    } 
    this.assignedToUserGroup = this.$props.fieldUpdateItem.assignee_user_group;
    // if (this.assignedToUserGroup) {
    //   this.loadAssigneeUserList(this.assignedToUserGroup);
    // }
    this.dialog = this.$props.updateFieldDialogObject.display;
    this.fieldDefinition = this.$props.fieldDetails;
    for (var item in this.fieldDefinition) {
      if (this.fieldDefinition[item].field_access.hasOwnProperty("update")) {
        if (
          this.fieldDefinition[item].field_access.update.hasOwnProperty(
            this.$props.fieldUpdateItem.workflow_id
          )
        ) {
          if (
            this.fieldDefinition[item].field_access.update[
              this.$props.fieldUpdateItem.workflow_id
            ].hasOwnProperty(this.$props.fieldUpdateItem.current_status)
          ) {
            if (
              this.fieldDefinition[item].field_access.update[
                this.$props.fieldUpdateItem.workflow_id
              ][this.$props.fieldUpdateItem.current_status].includes(
                getFromLocalStorage("userGroup")
              )
            ) {
              if(this.fieldDefinition[item].isDisabled || this.fieldDefinition[item].isComputed || this.fieldDefinition[item].isApiComputed){
                this.fieldDefinition[item].disable = true
              }
              else{
                this.fieldDefinition[item]["disable"] = false;
              }
            } else {
              this.fieldDefinition[item]["disable"] = true;
            }
          } else {
            this.fieldDefinition[item]["disable"] = true;
          }
        } else {
          this.fieldDefinition[item]["disable"] = true;
        }
      } else {
        this.fieldDefinition[item]["disable"] = true;
      }
      if(this.fieldDefinition[item].input_type === "assigneeOptions"){
        this.assigneeFieldItem = this.fieldDefinition[item];
      }
      if (this.fieldDefinition[item].input_type === "hierarchicalDropdown") {
        if (
          this.fieldDefinition[item].options_object &&
          typeof(this.fieldDefinition[item].options_object) == 'object'
        ) {
          this.fieldDefinition[item]['option_values'] = [];
          Object.entries(this.fieldDefinition[item].options_object).map(([key, values]) => {
            this.fieldDefinition[item]['option_values'].push(...values);
          })
        }
      }
    }
    this.updateData = this.$props.fieldUpdateItem;
    this.fields.field_name = JSON.parse(JSON.stringify(this.$props.fieldUpdateItem));
    if(this.fields.field_name.hasOwnProperty("AdditionalFieldInformation")){
      this.currencyData = this.fields.field_name.AdditionalFieldInformation;
      this.additionalFieldInformation = this.fields.field_name.AdditionalFieldInformation;
    }
    if (
      this.$props.screenDefinition &&
      this.$props.screenDefinition.scopeDefinition &&
      this.$props.screenDefinition.scopeDefinition.length > 0 &&
      this.$props.selectedScope
    ) {
      this.currentScope = this.$props.screenDefinition.scopeDefinition.find(
        (scope) => scope.key === this.$props.selectedScope
      );
      for (var fieldItem of this.fieldDefinition) {
        if (fieldItem.key in this.currentScope.fieldKeys) {
          fieldItem.disable = true;
        }
        if(fieldItem.isDisabled && fieldItem.isDependent === false) {
          if(fieldItem.input_type === "currency") {
            if(fieldItem.currency_configuration && fieldItem.currency_configuration.defaultCurrency) {
              this.additionalFieldInformation[fieldItem.key] = {
                "currencyCode": fieldItem.currency_configuration.defaultCurrency
              }
            } else{
              this.additionalFieldInformation[fieldItem.key] = {
                "currencyCode": "USD"
              }
            }
          }

          if(fieldItem.input_type === "phonenumber") {
            if(fieldItem.phonenumberConfiguration && fieldItem.phonenumberConfiguration.defaultCountryCode) {
              this.additionalFieldInformation[fieldItem.key] = {
                "countryCode": fieldItem.phonenumberConfiguration.defaultCountryCode,
                "callingCode":""
              }
            } else {
              this.additionalFieldInformation[fieldItem.key] = {
                "countryCode": "MM",
                "callingCode":""
              }
            }
          }
        }
      }
    }
    for (let fieldItem of this.fieldDefinition) {
      if (fieldItem.input_type === "table") {
        this.$set(this.showTable, fieldItem.key, true);
        this.dialogWidth = '70%';
      }
    }
    let isTableAvailable = this.fieldDefinition.some((field)=> field.input_type == 'table');
    if(isTableAvailable){
      this.$nextTick(()=>{
        var div = document.querySelector('#divSelector');
        div.addEventListener('scroll', this.handleScroll);
      })
    }
    await this.getMasterViewFields();

    for (let fieldItem of this.fieldDefinition) {
      if ((!fieldItem.isDependent) || 
          ((fieldItem.dependentField == "issue_type") &&
          (this.fields.field_name[fieldItem.dependentField] == fieldItem.dependentValue ||
          fieldItem.dependentValue.includes(this.fields.field_name[fieldItem.dependentField])))
        ) {
        fieldItem.renderField = true;
      }
      if (fieldItem.renderField && fieldItem.input_type === 'datetime') {
        if (!this.additionalFieldInformation[fieldItem.key]){
              if (fieldItem.validators && fieldItem.validators.date 
              && fieldItem.validators.date.timezone ){
                this.additionalFieldInformation[fieldItem.key] = {
                  "timezone": fieldItem.validators.date.timezone
                }
              } else {
                let localTZ = moment.tz.guess(true);
                this.additionalFieldInformation[fieldItem.key] = {
                  "timezone": localTZ
                }
              }
            }
        if (fieldItem.validators && fieldItem.validators.date) {
          if(fieldItem.validators.date.hidePastDates === true) {
              this.startDate[fieldItem.key] = Number(moment().unix());
          }
          if(fieldItem.validators.date.range) {
            if(fieldItem.validators.date.range.from){
              if(fieldItem.validators.date.range.from.type == 'absolute'){
                let startDateEpoch = Number(fieldItem.validators.date.range.from.value);
                this.startDate[fieldItem.key] = startDateEpoch;
              }
              else if(fieldItem.validators.date.range.from.type == 'reference'){ 
                if(this.fields.field_name[fieldItem.validators.date.range.from.value] != null){
                  let startDateEpoch = Number(this.fields.field_name[fieldItem.validators.date.range.from.value]);
                  this.startDate[fieldItem.key] = startDateEpoch;
                }
                else{
                  delete this.startDate[fieldItem.key];
                }
              }
            }
            if(fieldItem.validators.date.range.to){
              if(fieldItem.validators.date.range.to.type == 'absolute'){
                let endDateEpoch = Number(fieldItem.validators.date.range.to.value);
                this.endDate[fieldItem.key] = endDateEpoch;
              }
              else if(fieldItem.validators.date.range.to.type == 'reference'){
                if(this.fields.field_name[fieldItem.validators.date.range.to.value]!= null){
                  let endDateEpoch = Number(this.fields.field_name[fieldItem.validators.date.range.to.value]);
                  this.endDate[fieldItem.key] = endDateEpoch;
                }else {
                  delete this.endDate[fieldItem.key];
                }
              }
            }
          }
        }
      }
      if(
        fieldItem.hasOwnProperty('parentKey') && 
        fieldItem.hasOwnProperty('options_object')
      ) {
        if(this.fields.field_name.hasOwnProperty(fieldItem.parentKey)) {
          fieldItem.option_values = [];
          if (this.fields.field_name[fieldItem.parentKey] != "") {
            Object.entries(fieldItem.options_object).map(([key, values]) => {
              if (this.fields.field_name[fieldItem.parentKey] === key) {
                fieldItem['option_values'] = values
              }
            })
          } else {
            Object.entries(fieldItem.options_object).map(([key, values]) => {
              fieldItem['option_values'].push(...values);
            })
          }
          if(fieldItem.option_values.length === 1) {
            this.fields.field_name[fieldItem.key] = fieldItem.option_values[0].key;
          }
          fieldItem.renderField = true;
        } else {
          fieldItem.renderField = false;
        }
      }
      if (fieldItem.input_type === "optionsGroup") {
        if(this.fields.field_name[fieldItem.options_parent] && this.fields.field_name[fieldItem.options_parent]!=null){
          fieldItem.option_values = fieldItem.options_mapping[this.fields.field_name[fieldItem.options_parent]];
          fieldItem.renderField = true;
        } else {
          this.fields.field_name[fieldItem.key] = null;
          fieldItem.renderField = false;
        }
      }
      if(fieldItem.isComputed && 'evaluation' in fieldItem){
        let computedValue = this.getComputedValue(fieldItem.evaluation);
        if(!Number.isNaN(parseFloat(computedValue)) && Number.isFinite(parseFloat(computedValue))){
          this.fields.field_name[fieldItem.key] = computedValue;
        }
        else{
          delete this.fields.field_name[fieldItem.key];
        }
      }
      if (
        fieldItem.isDependent && 
        fieldItem.dependentField !== 'issue_type' && 
        fieldItem.dependentField !== 'current_status'
      ) {
        if (
          fieldItem.dependentField in this.fields.field_name &&
          fieldItem.dependentValue.includes(this.fields.field_name[fieldItem.dependentField]) &&
          this.fieldDefinition.find(field => field.key == fieldItem.dependentField) &&
          this.fieldDefinition.find(field => field.key == fieldItem.dependentField).renderField
        ) {
          fieldItem.renderField = true;
        } else {
          fieldItem.renderField = false;
        }
      }
      if(!this.checkFieldHide(this.fieldType, fieldItem ,this.fieldUpdateItem.workflow_id ,this.fieldUpdateItem.current_status)) {
        fieldItem.renderField = false;
      }
      if(fieldItem.copyFieldValueConfig){
        fieldItem.disable = true;
      }
    }
  },
};
</script>

<style scoped>
.scroll-2::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.scroll-2::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0;
  background-color: rgb(42, 64, 154);
}
.dateTime {
  float: left;
  margin: 9px;
  padding: 8px;
}

.hideScroll::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.hideScroll::-webkit-scrollbar-track {
  border-radius: 0px;
}
 
/* Handle */
.hideScroll::-webkit-scrollbar-thumb {
  background: rgb(42, 64, 154); 
  border-radius: 0px;
}

/* Handle on hover */
.hideScroll::-webkit-scrollbar-thumb:hover {
  background: rgb(42, 64, 154); 
}
</style>