<template>
  <v-dialog
    persistent
    v-model="dialog"
    :width="dialogWidth"
    content-class="workflowFieldDialog"
  >
    <div
      class="dialogWrapper flex-grow-0 pa-0"
      :class="showValidationPopup ? expendDialog : dialogCols"
      :style="{ width: showHistoryDialog || (showValidationPopup || isTableError) ? '58%' : '100%' }"
    >
    <div v-if="spinner">
      <v-overlay>
        <SpinnerComponent
          @changeSelect="
            loadAssigneeUserList(
              fields.field_name['assignee_user_group'],
              fields.field_name
            )
          "
        ></SpinnerComponent>
      </v-overlay>
    </div>
      <v-card :class="{ hideRightBorderRadius: showSpotlightSection }">
        <v-card-title style="background-color: #ffffff;  color: #2a409a;  display: flex;  flex-direction: row;   align-items: flex-start;  justify-content: space-between; ">
          {{ $t("UpdateStage") }}
          <v-btn v-if="showHistoryButton" @click="showHistoryComponent" color="rgb(42, 64, 154)" style="color:white;"> <v-icon>mdi-history</v-icon> </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text
          class="scroll-2"
          style="overflow-y: scroll; overflow-x: hidden" id="divSelector"
        >
          <v-slide-y-transition hide-on-leave>
            <div class="p-2" v-show="!showAssigneeSection">
              <div class="flexColumn" v-for="fieldItem in fieldDefinition">
                <div
                  v-if= "fieldItem.renderField">
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'label'
                    "
                    class="mb-2 pl-2 pb-1" :style="getLabelStyle(fieldItem)"
                  >
                    <div style="width: 100%; margin-bottom: 6px" align="left" class= "pl-5 pr-5">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'input'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-text-field
                        outlined
                        background-color="#F2F2F2"
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                      ></v-text-field>
                      <v-btn
                        :disabled="!trimData(fields.field_name[fieldItem.key])"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}</v-btn
                      >
                    </div>
                  </div>
                  <div
                    v-if="fieldItem.isVisible && fieldItem.input_type === 'priority'"
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >
                        <div  style="display: flex; flex-direction: row;">
                          <div>{{ fieldItem.display_name[$i18n.locale] }}</div>
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span
                            ><HelpToolTip
                              v-if="fieldItem.helpTooltipText"
                              :ToolMessage="fieldItem.helpTooltipText"
                            ></HelpToolTip
                          ></span>
                          <img v-if="fieldItem.clearable"  src="../assets/close.png" style=" cursor: pointer; width: 25px; height: 90%;" @click="clearInputData(fieldItem.key)">
                        </div>
                      </label>
                    </div>
                    <div
                      v-if="fieldItem.view_type === 'reference'"
                      style="width: 100%"
                      align="right"
                    >
                      <v-radio-group style="margin-top: 8px;" v-model="fields.field_name[fieldItem.key]" :disabled="fieldItem.isDisabled">
                        <v-radio
                          v-for="radioItem in fieldItem.option_values"
                          :key="radioItem.key"
                          :label="radioItem.displayText"
                          :value="radioItem.key"
                          :disabled="radioItem.isDisabled"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          @change="
                          optionsChangingFn(
                            $event,
                            fieldItem.option_values,
                            fieldItem
                          )
                        "
                        ></v-radio>
                      </v-radio-group>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                    <div v-else style="width: 100%" align="right">
                      <v-radio-group  style="margin-top: 8px;" v-model="fields.field_name[fieldItem.key]" :disabled="fieldItem.isDisabled">
                        <v-radio
                          v-for="radioItem in fieldItem.option_values"
                          :key="radioItem.key"
                          :label="radioItem.displayText"
                          :value="radioItem.key"
                          :disabled="radioItem.isDisabled"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          @change="
                          optionsChangingFn(
                            $event,
                            fieldItem.option_values,
                            fieldItem
                          )
                        "
                        ></v-radio>
                      </v-radio-group>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                    fieldItem.isVisible && fieldItem.input_type === 'number'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <NumberInputComponent
                      :updateAccess = "fieldItem.isDisabled"
                      :numberConfiguration = "fieldItem.number_configuration"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "                      
                        v-model="fields.field_name[fieldItem.key]"
                      ></NumberInputComponent>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'table'
                    "
                    class="input_field_div" @click="renderComponent(fieldItem.key)" :id="'click'+fieldItem.key"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div  v-if="showTable[fieldItem.key]">
                      <TableComponentVue
                        :mode = "tableMode"
                        :workFlowId = "fields.field_name['workflow_id']"
                        :roleGroup = "userRoleGroup"
                        :currentStatus = "fields.field_name['current_status']"
                        :addNewRow = "fieldItem.table_configuration.addNewRow"
                        :tableConfiguration = "fieldItem.table_configuration"
                        v-model="fields.field_name[fieldItem.key]"
                        :showFooter=false
                        :key = "fields.field_name[fieldItem.key]"
                      ></TableComponentVue>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type == 'nrc'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <NRCNumberComponent
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                      ></NRCNumberComponent>
                      
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'date'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-menu
                        v-model="dateMenu[fieldItem.key]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            background-color="#F2F2F2"
                            v-model="fields.field_name[fieldItem.key]"
                            :disabled="fieldItem.isDisabled"
                            prepend-icon=""
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            persistent-hint
                            :rules="
                              fieldItem.validators &&
                              fieldItem.validators.required === true
                                ? rulesForRequired
                                : rulesForNotRequired
                            "
                          ></v-text-field>
                          <v-btn
                            :disabled="
                              !trimData(fields.field_name[fieldItem.key])
                            "
                            v-if="fieldItem.isSpotlight"
                            @click="openSpotlight(fieldItem)"
                            >{{
                              fieldItem.spotlightConfiguration.buttonText[
                                $i18n.locale
                              ]
                            }}</v-btn
                          >
                        </template>
                        <v-date-picker
                          v-model="fields.field_name[fieldItem.key]"
                          @input="dateMenu[fieldItem.key] = false"
                          :min="startDate[fieldItem.key]"
                          :max="endDate[fieldItem.key]"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'workflowoptions'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-autocomplete
                        outlined
                        background-color="#F2F2F2"
                        :items="fieldItem.option_values"
                        item-value="value"
                        item-text="value"
                        v-model="fields.field_name[fieldItem.key]"
                        @change="
                          selectWorkflow(
                            $event,
                            fieldItem.option_values,
                            fieldItem
                          )
                        "
                        :disabled="fieldItem.isDisabled"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                      ></v-autocomplete>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}</v-btn
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'multiselect'
                      "
                    class="input_field_div"
                    >
                    <div style="width: 100%; margin-bottom: 8px;">
                      <label>
                        {{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                    </div>
                    <div style="width: 100%; margin-bottom:20px;">
                      <MultiselectComponent
                      :multiselectOptionsData = "fieldItem.option_values"
                      :setPreselect = true
                      v-model="fields.field_name[fieldItem.key]"
                      >
                      </MultiselectComponent>
                    </div>
                  </div>
                  <div
                    style="margin-bottom:15px;"
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'checklist'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%;" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      
                      <div style="width: 100%" align="right"> 
                        <ChecklistComponent
                          :ChecklistOptionsData = "fieldItem.option_values"
                          :addAllowBtn = "fieldItem"
                          v-model="fields.field_name[fieldItem.key]"
                        >
                        </ChecklistComponent>
                      </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'toggle'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-switch
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-switch>
                      <v-btn
                        :disabled="
                          fields.field_name[fieldItem.key] === null ||
                          fields.field_name[fieldItem.key] === undefined
                        "
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}</v-btn
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'ConditionalOptions'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-autocomplete
                        outlined
                        background-color="#F2F2F2"
                        :items="fieldItem.option_values"
                        v-model="fields.field_name[fieldItem.key]"
                        @change="
                          conditionalOptionChange(
                            fields.field_name[fieldItem.key],
                            fieldItem
                          )
                        "
                        :disabled="fieldItem.isDisabled"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                      ></v-autocomplete>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}</v-btn
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'apiOptions'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-autocomplete
                        outlined
                        background-color="#F2F2F2"
                        :items="apiOptionValues"
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                      ></v-autocomplete>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}</v-btn
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'options'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div
                      v-if="fieldItem.view_type === 'reference'"
                      style="width: 100%"
                      align="right"
                    >
                      <div class="pb-7">
                        <v-autocomplete
                          outlined
                          background-color="#F2F2F2"
                          :items="fieldItem.option_values"
                          item-value="key"
                          item-text="value"
                          v-model="fields.field_name[fieldItem.key]"
                          :disabled="fieldItem.isDisabled"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          hide-details
                        ></v-autocomplete>
                        <v-btn
                          :disabled="!fields.field_name[fieldItem.key]"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}</v-btn
                        >
                        <MasterViewComponent  v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] && showMasterViewFields" v-model="viewData"
                        :keyName="fieldItem.key" 
                        ></MasterViewComponent>
                      </div>
                    </div>
                    <div v-else style="width: 100%" align="right">
                      <v-autocomplete
                        outlined
                        background-color="#F2F2F2"
                        :items="fieldItem.option_values"
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                      ></v-autocomplete>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}</v-btn
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'optionsGroup'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px;" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-autocomplete
                        style="border-radius: 10px;"
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :items="fieldItem.option_values"
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-autocomplete>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'hierarchicalDropdown'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-autocomplete
                        style="border-radius: 10px"
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        v-bind="{ ...(fieldItem.view_type === 'reference' ? { 'itemValue':'key', 'itemText':'value' } : {})}"
                        :items="fieldItem.option_values"
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-autocomplete>
                    </div>
                     <MasterViewComponent style="margin-bottom: 20px; margin-top: -15px;" v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] && showMasterViewFields" v-model="viewData"
                     :keyName="fieldItem.key"
                     ></MasterViewComponent>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'multifileupload'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <MultiFileUploadComponent
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :fieldKey="fieldItem.key"
                        :workFlowId="fields.field_name['workflow_id']"
                        :viewid="fieldObject.viewId"
                        :fileDefinition="fieldItem"
                        @multifileCheck="multifileCheck"
                        @multiFileRemove="multiFileRemove"
                      ></MultiFileUploadComponent>
                    </div>
                    <div style="padding-bottom:10px">
                      <div v-if="fields.field_name[fieldItem.key]!=='' && fields.field_name[fieldItem.key]!==undefined">
                        <div v-if="(typeof fields.field_name[fieldItem.key]!=='string')">
                          <v-chip
                            v-for="(file,index) in fields.field_name[fieldItem.key]" :key="index"
                            v-if="checkFile(file,fieldItem.key) && checkMultiFileExits(file,fieldItem.key)"
                            style="width:260px;margin:5px"
                            class="mr-1"
                            :title="formatFileTitle(file,fieldItem.key)"
                            >
                              {{formatName(file,fieldItem.key)}}
                            <v-icon style="font-size: 18px; padding-left: 4px;"  @click="clickView(file)">
                                mdi mdi-eye
                              </v-icon>&nbsp;
                            <v-icon style="font-size: 18px;" v-if="!fieldItem.isDisabled"   @click="removeFile(index,fieldItem.key)">
                              mdi mdi-close-circle
                            </v-icon>
                          </v-chip>
                        </div>
                        <div v-else>
                          <v-container>
                            <v-row>
                              <v-col class="text-left"
                              >
                                <div v-if="fields.field_name[fieldItem.key]!==undefined && fields.field_name[fieldItem.key]!==''" style="padding:10px;">
                                  <v-label class="pa-2" tile >
                                    {{formatName(fields.field_name[fieldItem.key])}} <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                                  </v-label>
                                </div>
                              </v-col>
                              <v-col class="text-right"
                              >
                                <v-btn
                                  v-if= "fields.field_name[fieldItem.key] && checkFileExits(fields.field_name[fieldItem.key])"
                                  class="pa-2"
                                  style="
                                  background-color: rgb(42, 64, 154);
                                  color: rgb(255, 255, 255);
                                  margin: 8px;width:140px"
                                  elevation="0"
                                  align="right"
                                  @click="clickView(fields.field_name[fieldItem.key])"
                                  :disabled="fields.field_name[fieldItem.key]==undefined ||fields.field_name[fieldItem.key]==''"
                                >
                                view
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'fileupload'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div v-if="fields.field_name[fieldItem.key]">
                    <v-container>
                      <v-row>
                        <v-col class="text-left"
                        >
                        <div v-if="fields.field_name[fieldItem.key]!=='' && fields.field_name[fieldItem.key]!==undefined" style="padding:10px">
                          <v-label class="pa-2" tile >
                            {{formatName(fields.field_name[fieldItem.key],fieldItem.key)}} <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>                 
                          </v-label>
                        </div>
                        <div v-else  style="padding:10px">
                          No File attachment
                        </div>
                        </v-col>
                        <v-col class="text-right"
                        >
                          <v-btn
                            v-if= "fields.field_name[fieldItem.key] && checkFileExits(fields.field_name[fieldItem.key])"
                            class="pa-2"
                            style="
                            background-color: rgb(42, 64, 154);
                            color: rgb(255, 255, 255);
                            margin: 8px;width:140px"
                            elevation="0"
                            align="right"
                            @click="clickView(fields.field_name[fieldItem.key])"
                            :disabled="fields.field_name[fieldItem.key]==undefined ||fields.field_name[fieldItem.key]==''"
                          >
                            view
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                    <div style="width: 100%" align="right">
                      <FileUploadComponent
                        :fieldKey="fieldItem.key"
                        :fileDefinition="fieldItem"
                        :viewid="fieldObject.viewId"
                        :workFlowId="fields.field_name['workflow_id']"
                        @getBase64="getBase64"
                        @revertOldFile="revertOldFile"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                      ></FileUploadComponent>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'radio'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >
                        <div style='display: flex; flex-direction: row;'>
                          <div>{{ fieldItem.display_name[$i18n.locale] }}</div>
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                          <img v-if="fieldItem.clearable" src="../assets/close.png" style=" cursor: pointer; width: 25px; height: 90%;" @click="clearInputData(fieldItem.key)">
                        </div>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-radio-group v-model="fields.field_name[fieldItem.key]">
                        <v-radio
                          v-for="radioItem in fieldItem.option_values"
                          :key="radioItem"
                          :label="radioItem"
                          :value="radioItem"
                          :disabled="fieldItem.isDisabled"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                        ></v-radio>
                      </v-radio-group>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}</v-btn
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'textarea'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-textarea
                        outlined
                        background-color="#F2F2F2"
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                      ></v-textarea>
                      <v-btn
                        :disabled="!trimData(fields.field_name[fieldItem.key])"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}</v-btn
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'phone'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%; margin-bottom: 8px" align="right">
                      <VuePhoneNumberInput
                        defaultCountryCode="MM"
                        outlined
                        background-color="#F2F2F2"
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                      />
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}</v-btn
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'phonenumber'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        >
                        <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <PhonenumberComponent
                        :additionalFieldInformation="fields.field_name.AdditionalFieldInformation[fieldItem.key]"
                        :phonenumberDetail="fieldItem"
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                        @additionalField="additionalField(fieldItem.key, ...arguments)"
                      >
                      </PhonenumberComponent>
                      <v-btn
                          :disabled="!trimData(fields.field_name[fieldItem.key])"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'email'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-text-field
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForEmailRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-text-field>
                      <v-btn
                        :disabled="!trimData(fields.field_name[fieldItem.key])"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'URL'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-text-field
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForURLRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-text-field>
                      <v-btn
                        :disabled="!trimData(fields.field_name[fieldItem.key])"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'alert'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        >
                        <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right"> 
                      <AlertDateTimeComponent 
                      :alertDetails="fieldItem"
                      v-model="fields.field_name[fieldItem.key]"
                      >
                      </AlertDateTimeComponent>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'datetime'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        >
                        <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right"> 
                      <DateTimeComponent 
                        :dateTimeDetails="fieldItem"
                        :min="startDate[fieldItem.key]"
                        :max="endDate[fieldItem.key]"
                        v-model="fields.field_name[fieldItem.key]"
                        :disable="fieldItem.isDisabled"
                        :tzConfig="additionalFieldInformation[fieldItem.key]"
                      >
                      </DateTimeComponent>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'currency'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <CurrencyComponent
                        :fileDefinition = "fieldItem"
                        :currencyData="currencyData"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForCurrencyRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        @addCurrencyDetails="addCurrencyDetails"
                        :isdisabled="fieldItem.isDisabled"
                      >
                      </CurrencyComponent>
                      <v-btn
                        :disabled="!trimData(fields.field_name[fieldItem.key])"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>

              <div class="input_field_div">
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label>{{ $t("Comments") }} </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-textarea
                    outlined
                    background-color="#F2F2F2"
                    v-model="commentfield"
                  ></v-textarea>
                </div>
              </div>
              <div class="flexColumn" v-for="fieldItem in fieldDefinition">
                <div
                  v-if="
                    fieldItem.isVisible &&
                    fieldItem.input_type === 'AssigneeUseroptions'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >{{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators && fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-autocomplete
                      outlined
                      background-color="#F2F2F2"
                      :items="assigneeGroup"
                      v-model="fields.field_name[fieldItem.key]"
                      :disabled="
                        assigneeUserGroupOptionsDisabled || fieldItem.isDisabled
                      "
                      :rules="
                        fieldItem.validators &&
                        fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      @change="
                        loadAssigneeUserList(
                          fields.field_name['assignee_user_group'],
                          fields.field_name
                        )
                      "
                    ></v-autocomplete>
                  </div>
                </div>
              </div>
              <!-- <div>
                <v-btn
                  outlined
                  style="
                    background-color: rgb(42, 64, 154);
                    color: #ffffff;
                    width: 140px;
                  "
                  @click="openAssigneeSection()"
                >
                  Assign
                </v-btn>
              </div> -->
            </div>
          </v-slide-y-transition>
          <v-slide-y-reverse-transition hide-on-leave>
            <div v-show="showAssigneeSection">
              <div class="flexColumn" v-for="fieldItem in fieldDefinition">
                <div
                  v-if="
                    fieldItem.isVisible &&
                    fieldItem.input_type === 'AssigneeUseroptions'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >{{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators && fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-autocomplete
                      outlined
                      background-color="#F2F2F2"
                      :items="assigneeGroup"
                      v-model="fields.field_name[fieldItem.key]"
                      :disabled="
                        assigneeUserGroupOptionsDisabled || fieldItem.isDisabled
                      "
                      :rules="
                        fieldItem.validators &&
                        fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      @change="
                        loadAssigneeUserList(
                          fields.field_name['assignee_user_group'],
                          fields.field_name
                        )
                      "
                    ></v-autocomplete>
                    <v-btn
                      :disabled="!fields.field_name[fieldItem.key]"
                      v-if="fieldItem.isSpotlight"
                      @click="openSpotlight(fieldItem)"
                      >{{
                        fieldItem.spotlightConfiguration.buttonText[
                          $i18n.locale
                        ]
                      }}</v-btn
                    >
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible &&
                    fieldItem.input_type === 'assigneeOptions' &&
                    showAssigneeField
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >{{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators && fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-autocomplete
                      outlined
                      background-color="#F2F2F2"
                      :items="assigneeUserList"
                      item-text="name"
                      item-value="email"
                      v-model="fields.field_name[fieldItem.key]"
                      :disabled="assigneeDisabled || fieldItem.isDisabled"
                      :rules="
                        fieldItem.validators &&
                        fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                    >
                      <template slot="item" slot-scope="data">
                        <div class="d-flex flex-row" style=" height:70px; padding:5px; width:100%;">
                          <div style="width:40px; height:40px;  border-radius:50%; background-color:rgb(42, 64, 154); color:white;">
                            <p style="font-weight:500; font-size:1.2rem; padding-top:5px; text-align:center;">{{ data.item.name.charAt(0) }}</p>
                          </div>
                          <div class="d-flex flex-column" style="justify-content:space-around; padding-left:10px;">
                            <div class="font-weight-medium">{{ data.item.name }}</div>
                            <div class="font-weight-light">{{ data.item.email }}</div>
                          </div>
                        </div>
                      </template>
                    </v-autocomplete>
                    <v-btn
                      :disabled="!fields.field_name[fieldItem.key]"
                      v-if="fieldItem.isSpotlight"
                      @click="openSpotlight(fieldItem)"
                      >{{
                        fieldItem.spotlightConfiguration.buttonText[
                          $i18n.locale
                        ]
                      }}</v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
          </v-slide-y-reverse-transition>
        </v-card-text>
        <div v-if="showConfirmationDialog" style="position: relative">
          <ConfirmationDialog
            :confirmationDialogObject="confirmationDialogObject"
            @closeDialog="closeDialog"
          />
        </div>
        <div v-if="showErrorValidationDialog">
          <ErrorValidationDialog
            :displayErrorValidationDialog="showErrorValidationDialog"
            :errorValidationDialogObject="errorValidationDialogObject"
            @closeErrorValidationDialog="closeErrorValidationDialog"
          />
        </div>
        <v-divider />
        <v-card-actions style="display: flex; justify-content: right">
          <div v-if="showAssigneeSection">
            <v-btn
              outlined
              style="
                background-color: rgb(42, 64, 154);
                color: #ffffff;
                width: 140px;
              "
              @click="closeAssigneeSection()"
            >
              {{ $t("Edit Fields") }}
            </v-btn>
          </div>
          <v-spacer></v-spacer>

          <div>
            <v-btn outlined class="cancelButtonWhite" @click="clickCancel()">{{
              $t("Cancel")
            }}</v-btn>
          </div>
          <div
            v-if="!showAssigneeSection"
            style="margin-right: 30px; margin-left: 30px"
          >
            <v-btn
              outlined
              class="buttonClassBlue"
              @click="openAssigneeSection()"
            >
              {{ $t("Next") }}
            </v-btn>
          </div>
          <div v-if="showAssigneeSection" style="margin-left: 30px">
            <v-btn class="buttonClassBlue" @click="clickSave()">{{
              $t("Save")
            }}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </div>
    <div v-if="this.showValidationPopup"  class="spotlightSection flex-grow-0 flex-shrink-0 pa-0 col-5" style="min-width: 100px">
      <ValidationErrorAlertVue
        :errorValidationDialogObject="errorValidationDialogObject"
        @closeErrorExpend="closeErrorExpend"
      >
      </ValidationErrorAlertVue>
    </div>
    <div
      v-if="showSpotlightSection"
      class="spotlightSection flex-grow-0 flex-shrink-0 pa-0 col-7"
      style="min-width: 200px"
    >
      <SpotlightSection
        :spotlightDialogObject="spotlightDialogObject"
        @closeSpotlightDialog="closeSpotlightDialog"
      />
    </div>
    <div v-if="confirmCancel">
      <CloseDialogBox
        @closeCancelDialogBox="closeCancelDialogBox"
        @closeCancelOperation="closeCancelOperation"
      />
    </div>
    <div v-if="showMessageDialog">
      <!-- :dialogText="dialogText" -->
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
    <div v-if="showHistoryDialog"  class="spotlightSection flex-grow-0 flex-shrink-0 pa-0 col-7" style="min-width: 100px">
      <HistoryComponent
        :historyComments="historyComments"
        :screenDefinition="screenDefinition"
        @closeHistory="closeHistory"
        :dataItem="itemDetails"
        :currentStatusColor="currentStatusColor"
      ></HistoryComponent>
    </div>
  </v-dialog>
</template>

<script>
import {getFromLocalStorage, setToLocalStorage} from "../store/localStorageInterface"
import axios from "axios";
import VuePhoneNumberInput from "vue-phone-number-input";
import HelpToolTip from "../components/HelpToolTip.vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import ErrorValidationDialog from "./ErrorValidationDialog.vue";
import FileUploadComponent from "./FileUploadComponent.vue";
import SpotlightSection from "./SpotlightSection.vue";
import SpinnerComponent from "../components/SpinnerComponent.vue";
import CloseDialogBox from "../components/CloseDialogBox.vue";
import MessageDialog from "./MessageDialog.vue";
import NRCNumberComponent from "./NRCNumberComponent.vue";
import MultiFileUploadComponent from "./MultiFileUploadComponent.vue";
import { nrcValidateMixin } from "../mixins/nrcValidation.js";
import { checkFieldHide } from "../mixins/checkFieldHide.js";
import ChecklistComponent from "../components/ChecklistComponent.vue";
import CurrencyComponent  from "../components/CurencyComponent.vue";
import PhonenumberComponent from "../components/PhonenumberComponent.vue"
import AlertDateTimeComponent from "../components/AlertDateTimeComponent.vue"
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'
import NumberInputComponent from "../components/NumberInputComponent.vue"
import ValidationErrorAlertVue from "../components/ValidationErrorAlert.vue";
import { formatFileNameMixin } from "../mixins/formatFileName.js";
import HistoryComponent from "../components/HistoryForStages.vue"
import TableComponentVue from "./TableComponent.vue";
import validator from "validator";
import MasterViewComponent from "./MasterViewComponent.vue";
import { labelStyleMixin } from "../mixins/generateLabelStyle.js";
import { getComputedValue } from "../mixins/getComputedValue";
import DateTimeComponent from "./DateTimeComponent.vue";
import moment from "moment-timezone";
import { commonFunction } from "../mixins/commonFunction.js"
import MultiselectComponent from "./MultiselectComponent.vue";
import { checkTableConditionalValidations } from "../mixins/checkTableConditionalValidations";
export default {
  props: ["itemDetails", "screenDefinition", "fieldObject", "selectedScope", "showIndependentFields"],
  components: {
    ConfirmationDialog,
    FileUploadComponent,
    ErrorValidationDialog,
    SpotlightSection,
    SpinnerComponent,
    CloseDialogBox,
    MessageDialog,
    NRCNumberComponent,
    MultiFileUploadComponent,
    ChecklistComponent,
    CurrencyComponent,
    HelpToolTip,
    VuePhoneNumberInput,
    PhonenumberComponent,
    isPossiblePhoneNumber, 
    isValidPhoneNumber,
    NumberInputComponent,
    ValidationErrorAlertVue,
    HistoryComponent,
    TableComponentVue,
    AlertDateTimeComponent,
    MasterViewComponent,
    DateTimeComponent,
    MultiselectComponent
  },
  mixins: [nrcValidateMixin, formatFileNameMixin, labelStyleMixin, checkFieldHide, getComputedValue, commonFunction, checkTableConditionalValidations],
  data() {
    return {
      userRoleGroup: "",
      fieldDefinition: [],
      screenDetails: "",
      dialog: false,
      fileAttachmentArray: [],
      showConfirmationDialog: false,
      confirmationDialogObject: {},
      showErrorValidationDialog: false,
      showAssigneeField: false,
      errorValidationDialogObject: {},
      assigneeGroup: [],
      multiFileArray:{},
      assigneeUserGroupOptionsDisabled: false,
      assigneeDisabled: false,
      selectedKey: "",
      commentfield: "",
      assigneeUserList: [],
      valueValidationErrorList: [],
      valueValidationErrorListInMM: [],
      errorListInMM:[],
      tableValidationErrorList: [],
      isMMSecondarySupportLang:false,
      fields: {
        field_name: {},
      },
      spotlightDialogObject: {},
      dialogWidth: "35%",
      showSpotlightSection: false,
      dialogCols: "col-12",
      spinner: false,
      confirmCancel: false,
      considerPreviousAssignee:false,
      dateMenu: {},
      assigneeFieldItem: {},
      rulesForRequired: [],
      rulesForNotRequired: [],
      rulesForCurrencyRequired: [],
      rulesForEmailRequired: [],
      rulesForURLRequired:[],
      showAssigneeSection: false,
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      userId:"",
      additionalFieldInformation:{},
      currentScope: {},
      showValidationPopup:false,
      expendDialog:'',
      errorList:[],
      showHistoryDialog:false,
      showHistoryButton:true,
      historyComments:[],
      tableMode:'STAGEUPDATE',
      masterViewAccessFields: {},
      masterDataList: {},
      currencyData:{},
      displayNames: {},
      viewData: {
      },
      showMasterViewFields: false,
      masterDisplayNames: {},
      alreadyRendered: {},
      showTable: {
        
      },
      isTableError : false,
      fieldType : 'STAGEUPDATE',
      isConditionalAssigneeCheckPass: false,
      startDate: {},
      endDate: {},
      isConditionalAssigneeCheck: {
        status : false
      }
    };
  },
  watch: {
    "fields.field_name": {
      handler: async function (after, before) {
        let operands, conditionMatched, conditionEnabled;
        let itemDetails = this.$props.itemDetails;
        for (var fieldItem of this.fieldDefinition) {
          if(fieldItem.key === 'current_status'){
              this.currentStatusColor = fieldItem.color
            }
          if (
              fieldItem.isDependent &&
              fieldItem.dependentField !== "current_status" &&
              this.fields.field_name[fieldItem.dependentField] != fieldItem.dependentValue &&
              !fieldItem.dependentValue.includes(this.fields.field_name[fieldItem.dependentField])
          ) {
            delete this.fields.field_name[fieldItem.key];
          }
          if (fieldItem.input_type === "assigneeOptions") {
            this.assigneeFieldItem = fieldItem;
          }
          if ( 
            fieldItem.key === 'status_action' && fieldItem.action_items &&
            fieldItem.action_items[this.fields.field_name.workflow_id] &&
            fieldItem.action_items[this.fields.field_name.workflow_id][this.fields.field_name.current_status]
          ) {
            let stageObject = fieldItem.action_items[this.fields.field_name.workflow_id][this.fields.field_name.current_status].filter((element) => {
              return element.key === this.fields.field_name.next_status;
            });
          
            if ( 
              stageObject && stageObject[0].isConditionalChange && 
              stageObject[0].conditionalStageConfig && 
              stageObject[0].conditionalStageConfig.conditions
            ) {
               let conditionCheck = false;
               this.isConditionalAssigneeCheck=  {
                 status : false
               };
               for (let condition of stageObject[0].conditionalStageConfig.conditions) {
                 if (this.getValue(condition.conditionalStatement)) {
                  if (condition.validators && condition.validators.assignee && condition.validators.assignee.required) {
                       this.isConditionalAssigneeCheck['status'] = true;
                       this.isConditionalAssigneeCheck['condition'] = condition;
                    }
                   if(condition.returnToPreviousAssignee && condition.returnToPreviousAssignee.stage) {
                    this.assigneeGroup = [];
                    this.assigneeGroup = itemDetails.RecentAssignee[condition.returnToPreviousAssignee.stage][0].roleGroup;
                    this.fields.field_name["assignee"] = itemDetails.RecentAssignee[condition.returnToPreviousAssignee.stage][0].userId;
                    this.fields.field_name['assignee_user_group'] = itemDetails.RecentAssignee[condition.returnToPreviousAssignee.stage][0].roleGroup;
                    this.fields.field_name['status_action'][0].text = condition.stage;
                    fieldItem.isDisabled = true;
                    conditionCheck = true;
                    break;
                    }
                    this.assigneeGroup = [];
                    this.assigneeGroup = [condition.assigneeGroup];
                    this.fields.field_name['assignee_user_group'] = condition.assigneeGroup;
                    this.fields.field_name['status_action'][0].text = condition.stage;
                    fieldItem.isDisabled = true;
                    conditionCheck = true;
                    break;
                  }
                }
                if(!conditionCheck) {
                  this.assigneeGroup = [];
                  this.assigneeGroup = [stageObject[0].defaultAssigneeGroup];
                  if(stageObject[0].assignToCreator && 
                    'assignToCreator' in this.fieldObject &&
                    itemDetails && itemDetails.RecentAssignee && 
                    itemDetails.RecentAssignee.default && 
                    itemDetails.RecentAssignee.default[0].userId && 
                    itemDetails.RecentAssignee.default[0].roleGroup
                  ){ 
                    if(typeof this.fieldObject['assignToCreator'] === 'object' && this.fieldObject['assignToCreator'].setAssignee) {
                        this.fields.field_name["assignee"] = itemDetails.RecentAssignee.default[0].userId;
                        this.assigneeDisabled = true; 
                    } else if(this.fieldObject['assignToCreator'] === true) {
                        this.fields.field_name["assignee"] = itemDetails.RecentAssignee.default[0].userId;
                        this.assigneeDisabled = true; 
                    }
                    this.assigneeGroup = [];
                    this.assigneeGroup = [itemDetails.RecentAssignee.default[0].roleGroup];
                    this.fields.field_name['assignee_user_group'] = itemDetails.RecentAssignee.default[0].roleGroup;
                  } else if ('returnAction' in stageObject[0] && Object.keys(stageObject[0].returnAction) > 0 &&
                    this.fieldObject['returnAction'] && this.fieldObject['lastStage'] &&
                    itemDetails && itemDetails.RecentAssignee &&
                    itemDetails.RecentAssignee[this.fieldObject['lastStage']] &&
                    itemDetails.RecentAssignee[this.fieldObject['lastStage']][0].roleGroup
                  ) {
                    this.assigneeGroup = [itemDetails.RecentAssignee[this.fieldObject['lastStage']][0].roleGroup];
                    this.fields.field_name['assignee_user_group'] = itemDetails.RecentAssignee[this.fieldObject['lastStage']][0].roleGroup;
                    if ('setAssignee' in this.fieldObject['returnAction'] &&
                      this.fieldObject['setAssignee'] &&
                      itemDetails.RecentAssignee[this.fieldObject['lastStage']][0].userId
                    ) {
                      this.fields.field_name["assignee"] = itemDetails.RecentAssignee[this.fieldObject['lastStage']][0].userId;
                      this.assigneeDisabled = true;
                    }
                  } else {
                    this.assigneeGroup = [];
                    this.assigneeGroup = [stageObject[0].defaultAssigneeGroup];
                    this.fields.field_name['assignee_user_group'] = stageObject[0].defaultAssigneeGroup;
                  }
                  this.fields.field_name['status_action'][0].text = stageObject[0].text;
               }
            }
          }
          if (["optionsWithSearch", "workflowoptions", "apiOptions", "ConditionalOptions", "options", "hierarchicalDropdown"].includes(fieldItem.input_type)) {
            let selected_fields_children = this.fieldDefinition.filter((field)=> 
              this.fields.field_name.hasOwnProperty(field.key) && 
              field.hasOwnProperty("parentKey") && 
              field.parentKey === fieldItem.key
            )
            for(let child of selected_fields_children) {
              if(this.fields.field_name[fieldItem.key]){
                let child_value = child.options_object[this.fields.field_name[fieldItem.key]]
                if (
                  !this.fields.field_name[fieldItem.key] || 
                  !child_value || 
                  (child_value && !child_value.map(child_option=>child_option.key).includes(this.fields.field_name[child.key]))
                ) {
                  delete this.fields.field_name[child.key];
                  delete child["isDisabled"];
                }
              }
            }
          }
          let oldValue = this.fields.field_name[fieldItem.key];
          if (
            fieldItem.conditionalValue &&
            fieldItem.conditionalValue.conditions &&
            fieldItem.conditionalValue.conditions.length > 0 &&
            fieldItem.conditionalValue.stages
          ) {
            conditionEnabled = false;
            for (let stageObject of fieldItem.conditionalValue.stages) {
              for (let workflow in stageObject) {
                if (workflow == this.fields.field_name["workflow_id"]) {
                  if (
                    stageObject[workflow].includes(
                      this.fields.field_name["current_status"]
                    )
                  ) {
                    conditionEnabled = true;
                  }
                }
              }
            }
            if (!conditionEnabled) {
              continue;
            }
            fieldItem.isDisabled = true;
            for (var condition of fieldItem.conditionalValue.conditions) {
              operands = [];
              conditionMatched = false;
              if (this.getValue(condition.conditionalStatement)) {
                if (oldValue != condition.resultValue) {
                  if (fieldItem.key == "assignee_user_group") {
                    this.assigneeGroup = [];
                    this.assigneeGroup = [condition.resultValue];
                    this.fields.field_name[fieldItem.key] =
                      condition.resultValue;
                    // this.loadAssigneeUserList(this.fields.field_name["assignee_user_group"], this.fields.field_name);
                  } else {
                    this.fields.field_name[fieldItem.key] =
                      condition.resultValue;
                  }
                }
                conditionMatched = true;
                break;
              }
            }
            if (!conditionMatched) {
              if (oldValue != fieldItem.defaultValue) {
                if (fieldItem.key == "assignee_user_group") {
                  this.assigneeGroup = [];
                  this.assigneeGroup = [fieldItem.defaultValue];
                  this.fields.field_name[fieldItem.key] =
                    fieldItem.defaultValue;
                  // this.loadAssigneeUserList(this.fields.field_name["assignee_user_group"], this.fields.field_name);
                } else {
                  this.fields.field_name[fieldItem.key] =
                    fieldItem.defaultValue;
                }
              }
            }
          }
        }
        for (var fieldItem of this.fieldDefinition) {
          // For showIndependentFields true
          if(this.$props.showIndependentFields){
            if (
              (!fieldItem.isDependent ||   //Either independent
                (
                  (
                    fieldItem.dependentField == "issue_type" && // Or dependent on the current issue_type
                    (
                      this.fields.field_name[fieldItem.dependentField] ==
                      fieldItem.dependentValue ||
                      fieldItem.dependentValue.includes(
                        this.fields.field_name[fieldItem.dependentField]
                      )
                    )
                  )
                  || 
                  (
                    fieldItem.dependentField == "current_status" && // Or dependent on the current status and correct action if the showIfKey is enabled
                    (
                      this.fields.field_name[fieldItem.dependentField] ==
                      fieldItem.dependentValue ||
                      fieldItem.dependentValue.includes(
                        this.fields.field_name[fieldItem.dependentField]
                      )
                    ) 
                    && 
                    (
                      fieldItem.hasOwnProperty('showIfKey') ? this.isExist(fieldItem) : true
                    )
                  )
                )
              ) && fieldItem.input_type !== 'hierarchicalDropdown'
            ) {
              if(fieldItem.dependentField !== 'current_status' && this.checkFieldAccess(fieldItem)){
                fieldItem.renderField = true;
              }
              if (fieldItem.input_type === "table") {
                this.$set(this.showTable, fieldItem.key, true);
                this.dialogWidth = '70%';
              }
            }
            else {
              fieldItem.renderField = false;

            }
          }
          // When showIndependentFields is not true
          else if (
            fieldItem.input_type !== 'hierarchicalDropdown' &&
            fieldItem.isDependent &&
            fieldItem.dependentField == "current_status" && // Dependent on the current status and correct action if the showIfKey is enabled
            (
              this.fields.field_name[fieldItem.dependentField] ==
              fieldItem.dependentValue ||
              fieldItem.dependentValue.includes(
                this.fields.field_name[fieldItem.dependentField]
              )
            )
            && 
            (
              fieldItem.hasOwnProperty('showIfKey') ? this.isExist(fieldItem) : true
            )
          ) {
            
            fieldItem.renderField = true;
          } else {
            fieldItem.renderField = false;
          }
          if( // For hierarchial dropdown
            fieldItem.hasOwnProperty('parentKey') && 
            fieldItem.hasOwnProperty('options_object') && 
            (
              fieldItem.hasOwnProperty('showIfKey') ? this.isExist(fieldItem) : true
            )
          ) {
            if(
              this.fields.field_name.hasOwnProperty(fieldItem.parentKey) && 
              this.fields.field_name[fieldItem.parentKey] !== ''
            ) {
              let parentField = this.fieldDefinition.find((field)=> field.key === fieldItem.parentKey)
              if (parentField.renderField) {
                fieldItem.option_values = [];
                if (this.fields.field_name[fieldItem.parentKey] != "") {
                  Object.entries(fieldItem.options_object).map(([key, values]) => {
                    if (this.fields.field_name[fieldItem.parentKey] === key) {
                      fieldItem['option_values'] = values
                    }
                  })
                  if (
                    this.currentScope && 'fieldKeys' in this.currentScope && 
                    fieldItem.key in this.currentScope.fieldKeys && 
                    !this.currentScope.fieldKeys[fieldItem.key].includes("ALL")
                  ) {
                    this.filterFieldItemOptionValuesByScope(fieldItem);
                  }
                } else {
                  Object.entries(fieldItem.options_object).map(([key, values]) => {
                    fieldItem['option_values'].push(...values);
                  })
                }
                if(fieldItem.option_values.length === 1){
                    this.fields.field_name[fieldItem.key] = fieldItem.option_values[0].key;
                  }
                fieldItem.renderField = true;
              } else {
                fieldItem.renderField = false;
              }
            }
          }
          if (fieldItem.isDependent && fieldItem.dependentField != 'current_status' && fieldItem.dependentField != "issue_type") {
            if (
              fieldItem.dependentField in this.fields.field_name &&
              fieldItem.dependentValue.includes(
                this.fields.field_name[fieldItem.dependentField]
              ) &&
              this.fieldDefinition.find(
                (field) => field.key == fieldItem.dependentField
              ) &&
              this.fieldDefinition.find(
                (field) => field.key == fieldItem.dependentField
              ).renderField
            ) {
              fieldItem.renderField = true;
              if (fieldItem.input_type === "table") {
                this.$set(this.showTable, fieldItem.key, true);
                this.dialogWidth = '70%';
              }
            } else {
              fieldItem.renderField = false;
            }
          }
          if (this.fields.field_name[fieldItem.key] && this.masterDataList[fieldItem.view_id] && fieldItem.isMasterViewConfig ) {
            this.showMasterViewFields = false;
            let masterViewField = this.masterDataList[fieldItem.view_id].find((field) => field._id === this.fields.field_name[fieldItem.key]);
            this.displayNames[fieldItem.key] = this.masterDisplayNames[fieldItem.view_id];
            if (masterViewField && this.masterViewAccessFields[fieldItem.key]) {
              this.$set(this.viewData, fieldItem.key, {});
              this.$set(this.viewData[fieldItem.key], 'display_name', {});
              for (let key of this.masterViewAccessFields[fieldItem.key]) {
                if (masterViewField[key]) {
                  this.$set(this.viewData[fieldItem.key], key, masterViewField[key]);
                  this.$set(this.viewData[fieldItem.key]['display_name'], key, this.displayNames[fieldItem.key][key]);
                }
              }
            }
            this.$nextTick(() => {
              this.showMasterViewFields = true;
            });
          } else if (fieldItem.isMasterViewConfig && !this.fields.field_name[fieldItem.key]){
            this.showMasterViewFields = false;
          }
		      if (fieldItem.isDependent && !fieldItem.renderField) {
            // delete this.fields.field_name[fieldItem.key];
			      delete this.additionalFieldInformation[fieldItem.key];
          }
          if(fieldItem.renderField && fieldItem.input_type === 'date') {
            if (
              fieldItem.validators &&
              fieldItem.validators.date &&
              fieldItem.validators.date.hideFutureDates === true
            ) {
              this.endDate[fieldItem.key] =
                moment().format("YYYY-MM-DD");
            } 
            else if (
              fieldItem.validators &&
              fieldItem.validators.date &&
              fieldItem.validators.date.hidePastDates === true
            ) {
              this.startDate[fieldItem.key] =
                moment().format("YYYY-MM-DD");
            }
            else if (
              fieldItem.default_value &&
              fieldItem.default_value.type &&
              fieldItem.default_value.type === 'today') {
                this.fields.field_name[fieldItem.key] = moment().format("YYYY-MM-DD");
            }
            else if (
              fieldItem.default_value &&
              fieldItem.default_value.type &&
              fieldItem.default_value.type === 'absolute'
            ) {
                let epoch = fieldItem.default_value.value;
                epoch = Number(epoch);
                this.fields.field_name[fieldItem.key] = moment(epoch).format("YYYY-MM-DD");
            } else if (
                fieldItem.validators &&
                fieldItem.validators.date &&
                fieldItem.validators.date.range && 
                fieldItem.validators.date.range.from &&
                fieldItem.validators.date.range.from.value &&
                fieldItem.validators.date.range.to.value
            ) {
                let startDateEpoch = Number(fieldItem.validators.date.range.from.value);
                this.startDate[fieldItem.key] = moment(startDateEpoch).format("YYYY-MM-DD");
                var epoch = Number(fieldItem.validators.date.range.to.value);
                this.endDate[fieldItem.key] = moment(epoch).format("YYYY-MM-DD");
            }
          }
          if(fieldItem.renderField && fieldItem.input_type === "datetime") {
            if (!this.additionalFieldInformation[fieldItem.key]){
              if (fieldItem.validators && fieldItem.validators.date 
              && fieldItem.validators.date.timezone ){
                this.additionalFieldInformation[fieldItem.key] = {
                  "timezone": fieldItem.validators.date.timezone
                };
              } else {
                let localTZ = moment.tz.guess(true);
                this.additionalFieldInformation[fieldItem.key] = {
                  "timezone": localTZ
                };
              }
            }
            if (fieldItem.validators && fieldItem.validators.date) {
              if(fieldItem.validators.date.hidePastDates === true) {
                this.startDate[fieldItem.key] = Number(moment().unix());
              }
              if(fieldItem.validators.date.range) {
                if(fieldItem.validators.date.range.from){
                  if(fieldItem.validators.date.range.from.type == 'absolute'){
                  let startDateEpoch = Number(fieldItem.validators.date.range.from.value);
                  this.startDate[fieldItem.key] = startDateEpoch;
                }
                else if(fieldItem.validators.date.range.from.type == 'reference'){ 
                  if(this.fields.field_name[fieldItem.validators.date.range.from.value] != null){
                    let startDateEpoch = Number(this.fields.field_name[fieldItem.validators.date.range.from.value]);
                    this.startDate[fieldItem.key] = startDateEpoch;
                  }
                  else{
                    delete this.startDate[fieldItem.key];
                  }
                }
              }
              if(fieldItem.validators.date.range.to){
                if(fieldItem.validators.date.range.to.type == 'absolute'){
                  let endDateEpoch = Number(fieldItem.validators.date.range.to.value);
                  this.endDate[fieldItem.key] = endDateEpoch;
                }
                else if(fieldItem.validators.date.range.to.type == 'reference'){
                  if(this.fields.field_name[fieldItem.validators.date.range.to.value]!= null){
                  let endDateEpoch = Number(this.fields.field_name[fieldItem.validators.date.range.to.value]);
                  this.endDate[fieldItem.key] = endDateEpoch;
                  }else {
                    delete this.endDate[fieldItem.key];
                  }
                }
              }
            }
          }
          if (!(fieldItem.key in this.fields.field_name) && 
            fieldItem.default_value && 
            fieldItem.default_value.type) {
            let datetimeValue;
              if(fieldItem.default_value.type === "today") {
                datetimeValue = Number(moment().unix());
              }
              else if(fieldItem.default_value.type === "absolute") {
                datetimeValue = Number(fieldItem.default_value.value);
              }
            if(datetimeValue && 
              !(this.startDate[fieldItem.key] && Number(this.startDate[fieldItem.key]) > datetimeValue) &&
              !(this.endDate[fieldItem.key] && Number(this.endDate[fieldItem.key]) < datetimeValue)
            ) {
                this.fields.field_name[fieldItem.key] = datetimeValue;
            }
        
      }
        }
        
          if (fieldItem.renderField && fieldItem.input_type === "optionsGroup") {
            if(this.fields.field_name[fieldItem.options_parent] && this.fields.field_name[fieldItem.options_parent]!=null){
              if(fieldItem.option_values != fieldItem.options_mapping[this.fields.field_name[fieldItem.options_parent]]){
                fieldItem.option_values = fieldItem.options_mapping[this.fields.field_name[fieldItem.options_parent]];
                this.fields.field_name[fieldItem.key] = null;
              }
            } else {
              this.fields.field_name[fieldItem.key] = null;
              fieldItem.renderField = false;
            }  
          }
          if(fieldItem.isComputed && 'evaluation' in fieldItem){
            let computedValue = this.getComputedValue(fieldItem.evaluation);
            if(!Number.isNaN(parseFloat(computedValue)) && Number.isFinite(parseFloat(computedValue))){
              this.fields.field_name[fieldItem.key] = computedValue;
            }
            else{
              delete this.fields.field_name[fieldItem.key];
            }
          }
		      if(fieldItem.isDependent && fieldItem.renderField && fieldItem.isDisabled && !fieldItem.copyFieldValueConfig) {
			      if(fieldItem.input_type === "currency") {
				      if(fieldItem.currency_configuration && fieldItem.currency_configuration.defaultCurrency) {
                if(this.additionalFieldInformation[fieldItem.key]['currencyCode'] !== fieldItem.currency_configuration.defaultCurrency) {
                  this.additionalFieldInformation[fieldItem.key] = {
                    "currencyCode": fieldItem.currency_configuration.defaultCurrency
                  }
                }
				      } else if(this.additionalFieldInformation[fieldItem.key]['currencyCode'] !== 'USD'){
					      this.additionalFieldInformation[fieldItem.key] = {
						      "currencyCode": "USD"
					      }
				      }
			      }

            if(fieldItem.input_type === "phonenumber") {
              if(fieldItem.phonenumberConfiguration && fieldItem.phonenumberConfiguration.defaultCountryCode) {
                if(this.additionalFieldInformation[fieldItem.key]['countryCode'] !== fieldItem.phonenumberConfiguration.defaultCountryCode) {
                  this.additionalFieldInformation[fieldItem.key] = {
                    "countryCode": fieldItem.phonenumberConfiguration.defaultCountryCode,
                    "callingCode":""
                  }
                }
              } else if(this.additionalFieldInformation[fieldItem.key]['countryCode'] !== 'MM') {
                this.additionalFieldInformation[fieldItem.key] = {
                  "countryCode": "MM",
                  "callingCode":""
                }
              }
            }
    		  }
          if(['number','currency','options', 'input'].includes(fieldItem.input_type) && fieldItem.copyFieldValueConfig){
            let copyValueConfig = fieldItem.copyFieldValueConfig
            let canCopyFieldApply = this.copyFieldAccessCheck(fieldItem, this.fields.field_name["workflow_id"]);
            if(canCopyFieldApply) {
              let parentKey = copyValueConfig['fieldKey'];
              if(this.fields.field_name[parentKey] != null && this.fields.field_name[fieldItem.key] !== this.fields.field_name[parentKey]){
                await this.handleCopyFieldValueConfig(fieldItem, copyValueConfig, parentKey);
              }
            }
          }
        }
        for (var fieldItem of this.fieldDefinition) {
          if (
            fieldItem.hasOwnProperty("defaultValue") && fieldItem.isDependent
            && (this.fields.field_name[fieldItem.dependentField] === fieldItem.dependentValue
            || fieldItem.dependentValue.includes(this.fields.field_name[fieldItem.dependentField])
            && 
            (
              fieldItem.hasOwnProperty('showIfKey') ? this.isExist(fieldItem) : true
            ))
          ) 
          {
            if(!(this.currentScope && 'fieldKeys' in this.currentScope && fieldItem.key in this.currentScope.fieldKeys))
            {
               if(this.fields.field_name[fieldItem.key] === undefined) {
              if(
                fieldItem.input_type === "toggle" ||
                fieldItem.input_type === "textarea" ||
                fieldItem.input_type === "email" ||
                fieldItem.input_type === "currency" ||
                fieldItem.input_type === "input" ||
                fieldItem.input_type === "nrc" ||
                fieldItem.input_type === "radio" ||
                fieldItem.input_type === "options" ||
                fieldItem.input_type === "date" ||
                fieldItem.input_type === "phonenumber" ||
                fieldItem.input_type === "alert" ||
                fieldItem.input_type === "priority" ||
                fieldItem.input_type === "URL" ||
                fieldItem.input_type === "number"){
                if(fieldItem.input_type == 'toggle'){
                  this.fields.field_name[fieldItem.key] = new Set([true, false]).has(fieldItem.defaultValue) ? fieldItem.defaultValue : false;
                } else if(fieldItem.input_type == 'priority'){
                  let priorityOptions = new Set(fieldItem.option_values.filter(obj => obj.key).map(obj => obj.key));
                  if(priorityOptions.has(fieldItem.defaultValue)){
                    this.fields.field_name[fieldItem.key] = fieldItem.defaultValue
                  }
                } else if (fieldItem.input_type == 'currency') {
                  this.fields.field_name[fieldItem.key] = fieldItem.defaultValue.toString();
                } else {
                  this.$set(this.fields.field_name, fieldItem.key, fieldItem.defaultValue);
                }
              }
            }
            }
            else if (this.currentScope && this.currentScope.fieldKeys && 
              this.currentScope.fieldKeys[fieldItem.key] && 
              this.currentScope.fieldKeys[fieldItem.key].length == 1)  {
                this.$set(this.fields.field_name, fieldItem.key, this.currentScope.fieldKeys[fieldItem.key][0]);
           }
          }
        }
        this.hideFields();
      },
      deep: true,
    },
  },
  methods: {
    async handleCopyFieldValueConfig(fieldItem, copyValueConfig, parentKey){
      let copyValue;
      let currencyCode;
      if(copyValueConfig.type == 'entity'){
        copyValue = this.fields.field_name[parentKey]
      } else if(copyValueConfig.type == 'master'){
        let masterViewId = copyValueConfig.viewId;
        let masterReferenceKey = copyValueConfig.referenceKey;
        await this.fetchMasterData(masterViewId)
        let stringifiedMasterData = JSON.parse(JSON.stringify(this.masterDataList));
        let masterViewField = {};
        if(masterViewId){
          if(stringifiedMasterData[masterViewId]){
            masterViewField = stringifiedMasterData[masterViewId].find((field) => field._id === this.fields.field_name[parentKey]);
          }
        }
        copyValue= masterViewField ? masterViewField[masterReferenceKey] : "";
      }
      if(fieldItem.input_type == 'input' || fieldItem.input_type == 'options'){
        this.fields.field_name[fieldItem.key] = copyValue ? copyValue : "";
      } else if(fieldItem.input_type == 'currency'){
        if(this.additionalFieldInformation[parentKey] && Object.keys(this.additionalFieldInformation[parentKey]).includes('currencyCode')) {
          currencyCode = this.additionalFieldInformation[parentKey]['currencyCode'];
        } else if(fieldItem.currency_configuration && fieldItem.currency_configuration.defaultCurrency){
          currencyCode = fieldItem.currency_configuration.defaultCurrency
        }
        this.additionalFieldInformation[fieldItem.key] = {"currencyCode": currencyCode ? currencyCode : ''};
        this.currencyData = this.additionalFieldInformation;
        this.fields.field_name[fieldItem.key] = typeof copyValue === "number" ? copyValue || 0 : parseInt(copyValue) || 0;
      } else if(fieldItem.input_type == 'number'){
        this.fields.field_name[fieldItem.key] = typeof copyValue === "number" ? copyValue || 0 : parseInt(copyValue) || 0;
      }
    },
    async fetchMasterData(masterViewId){
      try {
        let payload = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL + "/displayMasterValue",
            data: {
                applicationId: this.$route.query.applicationId,
                token: getFromLocalStorage("token"),
                authType: getFromLocalStorage("authType"),
                viewId: masterViewId
            },
            headers: {
                "content-type": "application/json",
            },
        };
        let apiResponse = await axios(payload);
        if (apiResponse.data && apiResponse.data.data) {
          this.masterDataList[masterViewId] = apiResponse.data.data;
        }
      } catch (apiError) {
        if (
        apiError.response &&
        apiError.response.status &&
        apiError.response.status == 401
        ) {
          this.$root.$emit('update_session_value', true);
        }
        else {
          this.errorMessageShow = true;
          if(apiError.response && apiError.response.data && apiError.response.data.errorObject){
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: apiError.response.data.errorObject.error_code,
              error_uuid: apiError.response.data.errorObject.error_uuid,
            };
          } else{
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
        }
      }
    },
    copyFieldAccessCheck(fieldItem, workflowId){
      if(fieldItem.field_access && fieldItem.field_access.update){
        var roleGroup = getFromLocalStorage("userGroup");
        let fieldAccessConfig = fieldItem.field_access.update
        for(let key in fieldAccessConfig){
          if(key == workflowId && Object.keys(fieldAccessConfig[key]).includes(this.fields.field_name["current_status"]) && Object.values(fieldAccessConfig[key][this.fields.field_name["current_status"]]).includes(roleGroup)){
            return true;
          }
        }
        return false;
      }
    },
    calculateDialogWidth(){
      if(Object.keys(this.showTable).length > 0){
        this.dialogWidth = '70%';
      } else {
        this.dialogWidth = '35%';
      }
    },
    optionsChangingFn(){},
    clearInputData(key){
      this.fields.field_name[key] = undefined;
    },
    renderComponent(item){
      this.$set(this.showTable, item, true);
    },
    handleScroll(){
      let showTableArr = Object.keys(this.showTable);
      let overallDiv = document.querySelector('#divSelector');
      for(let item of showTableArr){
        let btn = document.querySelector('#click' + item);
        if(btn != null && (!(item in this.alreadyRendered) || !this.alreadyRendered[item]) && (overallDiv.clientHeight - ((btn.getBoundingClientRect().top + 80) - overallDiv.getBoundingClientRect().top) > 0)){
          this.alreadyRendered[item] = true;
          this.$set(this.showTable, item, false);
          this.$nextTick(()=>{
            btn.click();
          })
        }
        if(this.alreadyRendered[item] && !(overallDiv.clientHeight - ((btn.getBoundingClientRect().top + 80) - overallDiv.getBoundingClientRect().top) > 0)){
          this.alreadyRendered[item] = false;
        }
      }
    },
    showHistoryComponent(){
      this.showHistoryDialog = true; 
      this.showValidationPopup = false;
      this.showSpotlightSection = false;      
      this.dialogWidth = '90%';
      this.dialogCols = 'col-5'
    },
    closeHistory(){ 
      this.showHistoryDialog = false;
      this.calculateDialogWidth();
      this.dialogCols = 'col-12'
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if (emitObject && emitObject.errorDisplayMessage) {
        closeDialogFlag = emitObject.errorDisplayMessage;
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    trimData(fielddata) {
      if (fielddata != undefined) {
        return fielddata.trim();
      } else {
        return fielddata;
      }
    },
    getValue(statement) {
      let comparisonOperator;
      if (typeof statement == "object" && Object.keys(statement).length > 1) {
        let truthValues = [];
        for (var key in statement) {
          truthValues.push(this.getValue({ [key]: statement[key] }));
        }
        return truthValues.every((v) => v === true);
      }
      for (var key in statement) {
        comparisonOperator = "";
        if (key instanceof Object && !Array.isArray(key)) {
          return this.getValue(key);
        } else if (this.isLogicalOperator(key)) {
          if (key == "$and") {
            return statement[key].every((v) => this.getValue(v) === true);
          } else if (key == "$not") {
            return !this.getValue(statement[key]);
          } else if (key == "$or") {
            return statement[key].reduce(
              (a, b) => this.getValue(b) || this.getValue(a)
            );
          } else {
            return false;
          }
        } else if (
          statement[key] instanceof Object &&
          Object.keys(statement[key]).length == 1 &&
          this.isComparisonOperator(Object.keys(statement[key])[0])
        ) {
          comparisonOperator = Object.keys(statement[key])[0];
          if (comparisonOperator == "$ne") {
            return (
              this.fields.field_name[key] != statement[key][comparisonOperator]
            );
          } else if (comparisonOperator == "$in") {
            return statement[key][comparisonOperator].includes(
              this.fields.field_name[key]
            );
          } else if (comparisonOperator == "$nin") {
            return !statement[key][comparisonOperator].includes(
              this.fields.field_name[key]
            );
          } else if (comparisonOperator == "$lt") {
            if (this.isNumeric(this.fields.field_name[key])) {
              return (
                Number(this.fields.field_name[key]) <
                Number(statement[key][comparisonOperator])
              );
            }
            return false;
          } else if (comparisonOperator == "$lte") {
            if (this.isNumeric(this.fields.field_name[key])) {
              return (
                Number(this.fields.field_name[key]) <=
                Number(statement[key][comparisonOperator])
              );
            }
            return false;
          } else if (comparisonOperator == "$gt") {
            if (this.isNumeric(this.fields.field_name[key])) {
              return (
                Number(this.fields.field_name[key]) >
                Number(statement[key][comparisonOperator])
              );
            }
            return false;
          } else if (comparisonOperator == "$gte") {
            if (this.isNumeric(this.fields.field_name[key])) {
              return (
                Number(this.fields.field_name[key]) >=
                Number(statement[key][comparisonOperator])
              );
            }
            return false;
          } else {
            return false;
          }
        } else if (
          typeof statement[key] == "boolean" ||
          typeof statement[key] == "string" ||
          (typeof statement[key] == "object" &&
            statement[key].constructor === String) ||
          this.isNumeric(statement[key])
        ) {
          return this.fields.field_name[key] == statement[key];
        } else {
          return false;
        }
      }
    },
    isNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
    isLogicalOperator(value) {
      if (value == "$and" || value == "$not" || value == "$or") {
        return true;
      } else {
        return false;
      }
    },
    isComparisonOperator(value) {
      if (
        value == "$ne" ||
        value == "$in" ||
        value == "$nin" ||
        value == "$lt" ||
        value == "$lte" ||
        value == "$gt" ||
        value == "$gte"
      ) {
        return true;
      } else {
        return false;
      }
    },
    clickCancel() {
      this.confirmCancel = true;
      this.showSpotlightSection = false;
      this.showValidationPopup = false;
      this.calculateDialogWidth();
      this.dialogCols = "col-12"
    },
    closeCancelOperation(flag) {
      this.confirmCancel = flag;
    },
    closeCancelDialogBox(flag) {
      this.confirmCancel = flag;
      this.dialog = false;
      this.$emit("closeWorkFlowDialog", false);
    },
    closeDialog(flag) {
      this.spinner = false;
      this.dialog = flag;
      this.showConfirmationDialog = false;
      this.$emit("closeWorkFlowDialog", flag);
    },
    closeErrorValidationDialog(flag) {
      this.showErrorValidationDialog = flag;
      this.valueValidationErrorList = [];
      this.valueValidationErrorListInMM = [];
      this.tableValidationErrorList = [];
      this.$emit("closeErrorValidationDialog", false);
    },
    closeErrorExpend(){ 
      if(Object.keys(this.showTable).length > 0){
        this.dialogWidth = '70%';
      } else {
        this.dialogWidth = '35%';
      }
      this.showValidationPopup = false;
    },
    isExist(fieldItem) {
      if (fieldItem.showIfKey.includes(this.selectedKey)) {
        return true;
      } else {
        return false;
      }
    },
    conditionalOptionChange(value, fieldItem) {
      for (var i = 0; i < this.fieldDefinition.length; i++) {
        if (this.fieldDefinition[i].isDependent) {
          if (
            this.fieldDefinition[i].dependentField !== fieldItem.key &&
            this.fieldDefinition[i].dependentField !== "issue_type"
          ) {
            delete this.fields.field_name[
              this.fieldDefinition[i].dependentField
            ];
          } else if (this.fieldDefinition[i].dependentField === fieldItem.key) {
            delete this.fields.field_name[this.fieldDefinition[i].key];
          }
        }
      }
      this.assigneeGroup = [];
      if (fieldItem.condition.key === "assigneeGroupCondition") {
        this.assigneeGroup.push(
          fieldItem.condition.value[value]["defaultAssigneeGroup"]
        );
        this.fields.field_name["assignee_user_group"] =
          fieldItem.condition.value[value]["defaultAssigneeGroup"][0];
      }
    },
    revertOldFile(key) {
      this.fields.field_name[key] = this.$props.itemDetails[key]
      if(this.$props.itemDetails['AdditionalFieldInformation'] && this.$props.itemDetails['AdditionalFieldInformation'][key]) {
        this.additionalFieldInformation[key] = this.$props.itemDetails['AdditionalFieldInformation'][key];
      }
    },
    getBase64(value) {
      if (value.status) {
        this.fileAttachmentArray = this.fileAttachmentArray.filter(
          (fileAttachmentObject) => fileAttachmentObject.key != value.key
        );
        this.fileAttachmentArray.push(value);
        if (value.file) {
          this.fields.field_name[value.key] = value.file;
        }
        let folderUrl = value.file.split('/').slice(-1)[0];
        let fileObject = {};
        fileObject[folderUrl] = value.currentfileName;
        this.additionalFieldInformation[value.key] = fileObject;
      } else {
        this.fileAttachmentArray = this.fileAttachmentArray.filter(
          (fileAttachmentObject) => fileAttachmentObject.key != value.key
        );
        if(this.fields && this.fields.field_name && this.fields.field_name[value.key]) {
          delete this.fields.field_name[value.key];
        }
      }
    },
    multifileCheck(value) {
      if (value.status) {
        if(this.fields.field_name[value.key]!=="" && this.fields.field_name[value.key] !== undefined){
          this.fields.field_name[value.key].push(value.file);
          this.fileAttachmentArray.push(value);
        }
        else{
          this.fields.field_name[value.key]=[];
          this.fields.field_name[value.key].push(value.file);
          this.fileAttachmentArray.push(value);
        }
        let folderUrl = value.file.split('/').slice(-1)[0];
        let fileObject = {};
        fileObject[folderUrl] = value.currentfileName;
        this.additionalFieldInformation[value.key] = {...this.additionalFieldInformation[value.key], ...fileObject};
      } else {
        this.fileAttachmentArray = this.fileAttachmentArray.filter(
          (fileAttachmentObject) => fileAttachmentObject.key != value.key
        );
        if(this.fields && this.fields.field_name && this.fields.field_name[value.key]) {
          delete this.fields.field_name[value.key];
        }
      }
    },
    formatFileTitle(fileVal ,key = "") {
      var folderUrl = fileVal.split("/").slice(-1);
      if(this.fields.field_name["AdditionalFieldInformation"] && this.fields.field_name["AdditionalFieldInformation"][key] && this.fields.field_name["AdditionalFieldInformation"][key][folderUrl]) {
        return this.fields.field_name["AdditionalFieldInformation"][key][folderUrl];
      }
      var [first,...rest]= folderUrl[0].split("_")
      var fileName = rest.join('');
      if(fileName) {
        return fileName;
      }
    },
    formatName(fileVal ,key = "") {
      var folderUrl = fileVal.split("/").slice(-1);
      if(this.fields.field_name["AdditionalFieldInformation"] && this.fields.field_name["AdditionalFieldInformation"][key] && this.fields.field_name["AdditionalFieldInformation"][key][folderUrl]) {
        return this.fileNameFormat(this.fields.field_name["AdditionalFieldInformation"][key][folderUrl]);
      }
      var [first,...rest]= folderUrl[0].split("_")
      var fileName = rest.join('');
      if(fileName) {
        return this.fileNameFormat(fileName);
      }
    },
    checkFile(file,key) {
      if(this.multiFileArray[key]===undefined){
        return true
      }
      else{
        if(this.multiFileArray[key].includes(file)){
          return false
        }
        else{
          return true
        }
      }
    },
    async fileIndexFinder(file ,key){
      var folderUrl=file.split("/").slice(-1);
      var [first,...rest]= folderUrl[0].split("_")
      var fileName = rest.join('_');
      if(fileName) {
          return fileName;
      } else if(this.additionalFieldInformation[key] && this.additionalFieldInformation[key][folderUrl]) {
          return this.additionalFieldInformation[key][folderUrl];
      }
    },  
    async removefileAttachemnts(files,fileName,key) {
      if(files.hasOwnProperty(key)) {
        for(var val in files[key]) {
          var file = await this.fileIndexFinder(files[key][val]);
          if(file === fileName){
            files[key].splice(val,1);
          }
        } 
      }
      return files
    },
    async multiFileRemove(fileName,key) {
      this.multiFileArray = await this.removefileAttachemnts(this.multiFileArray,fileName,key);
      for(var value in this.fileAttachmentArray){
        var file = await this.fileIndexFinder(this.fileAttachmentArray[value].file ,key);
        if(file === fileName && this.fileAttachmentArray[value]['key'] === key){
          let fileIndex  = this.fields.field_name[key].indexOf(this.fileAttachmentArray[value].file);
          this.fileAttachmentArray.splice(value,1);
          if(fileIndex > -1) {
            this.fields.field_name[key].splice(fileIndex,1);
          }
        }
      }
    },
    checkMultiFileExits(fileName,fieldkey) {
      for(let item of this.fileAttachmentArray){
        if(item.file === fileName && item.key === fieldkey){
          return false
        }
      }
      return true
    },
    removeFile(index,key) {
      let fileToRemove = this.fields.field_name[key][index].split("/").slice(-1)[0];
      this.fields.field_name[key].splice(index, 1);
      if(this.additionalFieldInformation[key] && this.additionalFieldInformation[key][fileToRemove]) {
        delete this.additionalFieldInformation[key][fileToRemove];
      }
    },
    validateAssignee(){
      this.errorList = [];
      this.errorListInMM = [];
      let actions = this.fields.field_name["status_action"];
      actions.forEach((action) => {
        // Check for validation inside the condition
        // If any conditon pass it doesn't check for outside validations
        if (action.key == [this.fields.field_name["next_status"]] && 
          action.isConditionalChange &&
          action.conditionalStageConfig   // Check for Condition Validation
        ) {
            if (this.isConditionalAssigneeCheck.status && 
                this.isConditionalAssigneeCheck.condition &&
                this.isConditionalAssigneeCheck.condition.validators &&
                this.isConditionalAssigneeCheck.condition.validators.assignee && 
                this.isConditionalAssigneeCheck.condition.validators.assignee.required)
                {
                  this.isConditionalAssigneeCheckPass = true;
                  if (this.fields.field_name['assignee'] == undefined ||
                  this.fields.field_name['assignee'] == "" ||
                  this.fields.field_name['assignee'] == null) { 
                    this.errorList.push("Assignee should not be empty. Please select an user.");
                      if (this.isMMSecondarySupportLang) {
                        this.errorListInMM.push('Assignee');
                    }
                  }
                }
              }
         if (!(this.isConditionalAssigneeCheckPass) && action.key == [this.fields.field_name["next_status"]] && 'validators' in action &&
         'assignee' in action['validators'] && 'required' in action['validators']['assignee'] &&
          action['validators']['assignee']['required']) { // Check for out side Validation
          if (this.fields.field_name['assignee'] == undefined ||
              this.fields.field_name['assignee'] == "" ||
              this.fields.field_name['assignee'] == null) {
                this.errorList.push("Assignee should not be empty. Please select an user.");
            if (this.isMMSecondarySupportLang) {
              this.errorListInMM.push('Assignee');
            }
          }
        }
      });
      if (this.errorList.length > 0 ) {
        this.errorValidationDialogObject["data"] = { errorList: this.errorList };
        this.errorValidationDialogObject["valueValidationErrorList"] = [];
        this.errorValidationDialogObject["tableValidationErrorList"] = [];
        if (this.isMMSecondarySupportLang) {
          this.errorValidationDialogObject["data"] = { errorList: this.errorList, errorListInMM: this.errorListInMM}    ;
          this.errorValidationDialogObject["valueValidationErrorListInMM"] = [];
          this.errorValidationDialogObject["displayTextInMM"] = "အောက်ပါ အကွက်များတွင် လိုအပ်သော အချက်အလက်များ ဖြည့်စွက်ပါ။ ";
        }
        this.errorValidationDialogObject["viewId"] = this.$props.fieldObject.viewId;
        this.errorValidationDialogObject["display"] = true;
        this.errorValidationDialogObject["displayText"] = "Enter the value(s) into the following field(s): ";
        this.showValidationPopup = true;
        this.dialogWidth = '70%';
        return false;
      } else {
        this.showValidationPopup = false;
        this.dialogWidth = '35%',
        this.confirmationDialogObject["display"] = true;
        if (this.assigneeDisabled === true || this.showAssigneeField === false){
          this.confirmationDialogObject["display"] = false;
        }
        return true;
      }
    },
    clickSave() {
      this.showConfirmationDialog = false;
      this.spinner = false;
      let isValid = this.validateAssignee();
      if (isValid) {
        this.showConfirmationDialog = true;
        this.spinner = true;
      }
    },
    validateCurrency(element){
      if(element.validators.range){
        var minValue=element.validators.range.min;
        var maxValue=element.validators.range.max;
        if(this.fields.field_name[element.key]>Number(maxValue)){
          this.valueValidationErrorList.push("Value of "+ element.display_name.en +" should not be greater then "+maxValue);
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm +" သတ်မှတ်ထားသော အများဆုံးတန်ဖိုးထက် မကျော်လွန်ရပါ "+maxValue);
          }
        }
        else if(this.fields.field_name[element.key]<Number(minValue)){
          this.valueValidationErrorList.push("Value of "+ element.display_name.en +" should not be less then "+minValue);
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm +" သတ်မှတ်ထားသော အနည်းဆုံးတန်ဖိုးထက် မနည်းစေရပါ "+minValue);
          }
        }
      }
    },
    deleteEmptyRow(element) {
      if(this.fields.field_name[element.key].length > 1){
        let result = this.removeEmptyObjects(this.fields.field_name[element.key]);
        this.$set(this.fields.field_name, element.key, result);
      }
    },
    validateTableValue(element){
      let optionErrorList = [];
      let futureDateErrorList = [];
      let pastDateErrorList = [];
      let numberRangeErrorList=[];
      let previousField = true;
      for(let value in this.fields.field_name[element.key]) {
        previousField = element.display_name.en;
        for(let item in element.table_configuration.columns) {
            let hasColumnAccess =  this.checkColumnAccess(element.table_configuration.columns[item].access, this.fields.field_name["workflow_id"], this.fields.field_name["current_status"], getFromLocalStorage("userGroup"));
            let columnName = element.table_configuration.columns[item].name;
            if (hasColumnAccess) {
              let validators = element.table_configuration.columns[item].validators;
              if(element.table_configuration.columns[item].conditionalValidations){
                for (let conditionalStatement of element.table_configuration.columns[item].conditionalValidations) {
                  if (this.checkTableConditionalValidations(conditionalStatement.condition, this.fields.field_name[element.key][value])) {
                    validators = element.table_configuration.columns[item].validatorSet[conditionalStatement.validationSet]
                    break;
                  }
                }
              }
              if (!(this.fields.field_name[element.key][value][columnName])){
                if(validators.required) {
                  if(previousField) {
                    this.errorList.push({
                      'fieldName': element.display_name.en,
                      'type': 'tableError',
                      'columnName': columnName,
                      'row': Number(value)+1
                    });
                    previousField = false;
                  }
                  this.errorList.push({
                    'type': 'tableError',
                    'columnName': columnName,
                    'row': Number(value)+1
                  });
                }
              } else {
                if (this.fields.field_name[element.key][value][columnName] && element.table_configuration.columns[item].type === 'number') {
                  if(validators.range){
                    let numberRangeError= this.checkColumnRange(validators.range, this.fields.field_name[element.key][value][columnName], value, columnName);
                    numberRangeErrorList = numberRangeErrorList.concat(numberRangeError);
                  } 
                }           
                if (element.table_configuration.columns[item].type == 'select') {
                  if (!((element.table_configuration.columns[item].option_values).includes(this.fields.field_name[element.key][value][columnName]))) {
                      optionErrorList.push({
                      'columnName': columnName,
                      'row': Number(value) + 1
                    });
                  }
                }
                if(element.table_configuration.columns[item].type == 'date') {
                  if('date' in validators) { 
                    if(('hideFutureDates' in validators["date"]) 
                    && validators["date"]["hideFutureDates"]) {
                      if(new Date(new Date(this.fields.field_name[element.key][value][columnName]).toDateString()) > new Date(new Date().toDateString())){
                        futureDateErrorList.push({
                          'columnName': columnName,
                          'row': Number(value) + 1,
                        });
                      }
                    }
                    if(('hidePastDates' in validators["date"]) 
                    && validators["date"]["hidePastDates"]) {
                      if(new Date(new Date(this.fields.field_name[element.key][value][columnName]).toDateString()) < new Date(new Date().toDateString())){
                        pastDateErrorList.push({
                          'columnName': columnName,
                          'row': Number(value) + 1,
                        });
                      }
                    }                   
                  }
                }
              }
            }
          }
      }
      if(optionErrorList.length > 0 || futureDateErrorList.length > 0 || pastDateErrorList.left > 0 || numberRangeErrorList.length>0) {
        let tableFieldName = element.display_name.en;
        let tableError = {};
        tableError[tableFieldName] = {};
        if(optionErrorList.length > 0) { tableError[tableFieldName]["optionError"] = optionErrorList}
        if(futureDateErrorList.length > 0) { tableError[tableFieldName]["futureDateError"] = futureDateErrorList}
        if(pastDateErrorList.length > 0) { tableError[tableFieldName]["pastDateError"] = pastDateErrorList}
        if(numberRangeErrorList.length > 0) { tableError[tableFieldName]["numberRangeErrorList"] = numberRangeErrorList}
        this.tableValidationErrorList.push(tableError);
      }
    },
    checkFileExits(fileName){
      for(let value of this.fileAttachmentArray){
        if(value.file === fileName){
          return false
        }
      }
      return true
    },
    validateLengthAndNumber(element){
      var returnValue = true;
      if(element.validators.number){
        const regexExp = /^[0-9]*$/g;
        const str = this.fields.field_name[element.key];
        returnValue = regexExp.test(str);
      }
      if(!returnValue){
        this.valueValidationErrorList.push("Please enter only numbers for " + element.display_name.en)
        if (this.isMMSecondarySupportLang) {
          this.valueValidationErrorListInMM.push("ကျေးဇူးပြု၍ နံပါတ်များကိုသာ ဖြည့်စွက်ပါ " + element.display_name.mm)
          }
      }
      if(element.validators.length){
        if(this.fields.field_name[element.key].length > element.validators.length.max){
          this.valueValidationErrorList.push("Length of " + element.display_name.en +  " should not exceed " + element.validators.length.max)
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm +  " သတ်မှတ်ထားသော အများဆုံးစာလုံးအရေအတွက်ထက် မကျော်လွန်ရပါ။ " + element.validators.length.max)
          }
        }
        if(this.fields.field_name[element.key].length < element.validators.length.min){
          this.valueValidationErrorList.push("Length of " + element.display_name.en +  " should be at least " + element.validators.length.min)
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm +  " သတ်မှတ်ထားသော အနည်းဆုံးစာလုံးအရေအတွက်ထက် မနည်းစေရပါ။ " + element.validators.length.min)
          }
        }
      }
    },
    validateMultiSelect(element){
      var isvalid = true;
      if(this.fields.field_name[element.key]){
        if(element.validators.list){
          if(element.validators.list.type == 'all'){
            let allSelectedOptions = this.fields.field_name[element.key].map(option => {
              return option.value
            });
            for(let option of element.option_values) {
              if(! (allSelectedOptions.includes(option.value))){
                isvalid = false;
                break;
              }
            }
            if(!isvalid){
              if(element.validators.list.errorMessage){
                this.valueValidationErrorList.push(element.display_name.en + " - " + element.validators.list.errorMessage.en);
                if (this.isMMSecondarySupportLang) {
                  this.valueValidationErrorListInMM.push(element.display_name.mm + " - " + element.validators.list.errorMessage.mm);
                }
              } else {
                this.valueValidationErrorList.push("Please select all items for " + element.display_name.en);
                if (this.isMMSecondarySupportLang) {
                  this.valueValidationErrorListInMM.push("သတ်မှတ်ထားသည်များ အားလုံးကို ရွေးပါ။ " + element.display_name.mm);
                }
              } 
            }
          }
          else if(element.validators.list.type == 'range'){
            if(element.validators.list.config){
              let itemsSelected = this.fields.field_name[element.key].length;
              if(element.validators.list.config.min && itemsSelected < element.validators.list.config.min){
                if(element.validators.list.errorMessage){
                  this.valueValidationErrorList.push(element.display_name.en + " - " + element.validators.list.errorMessage.en);
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(element.display_name.mm + " - " + element.validators.list.errorMessage.mm);
                  }
                } else {
                  this.valueValidationErrorList.push("Selected items for " + element.display_name.en + " should be at least " + element.validators.list.config.min)
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push("Selected items for " + element.display_name.mm + " should be at least " + element.validators.list.config.min)
                  }
                }
              }
              if(element.validators.list.config.max && itemsSelected > element.validators.list.config.max){
                if(element.validators.list.errorMessage){
                  this.valueValidationErrorList.push(element.display_name.en + " - " + element.validators.list.errorMessage.en);
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(element.display_name.mm + " - " + element.validators.list.errorMessage.mm);
                  }
                } else { 
                  this.valueValidationErrorList.push("Selected items for " + element.display_name.en + " should not exceed " + element.validators.list.config.max)
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push("Selected items for " + element.display_name.mm + " should not exceed " + element.validators.list.config.max)
                  }
                }
              }
            }
          }
        }
      }
    },
    validateCheckList(element){
      var returnValue = true;
      if(element.validators.list){
        if(element.validators.list.type == 'all'){
          returnValue = true;
          for(var configOption of element.option_values){
            if(configOption.value){
              for(let dataOption of this.fields.field_name[element.key]){
                if(dataOption.value == configOption.value && !dataOption.checked){
                  returnValue = false;
                  break;
                }
              }
            }
          }
          if(returnValue){
            for(let dataOption of this.fields.field_name[element.key]){
              if(dataOption.checked == false){
                returnValue = false;
                break
              }
            }
          }else{
            this.valueValidationErrorList.push("Please select all items for " + element.display_name.en)
            if (this.isMMSecondarySupportLang) {
              this.valueValidationErrorListInMM.push("သတ်မှတ်ထားသည်များ အားလုံးကို ရွေးပါ။ " + element.display_name.mm);
          }
          }

        }else if(element.validators.list.type == 'range'){
          returnValue = true;
          if(element.validators.list.config){
            var checkCount = 0;
            for(let item of this.fields.field_name[element.key]){
              if(item.checked == true){
                checkCount += 1;
              }
            }
            if(checkCount > element.validators.list.config.max){
              returnValue = false
              this.valueValidationErrorList.push("Selected items for " + element.display_name.en + " should not exceed " + element.validators.list.config.max)
              if (this.isMMSecondarySupportLang) {
                this.valueValidationErrorListInMM.push("Selected items for " + element.display_name.mm + " should not exceed " + element.validators.list.config.max)
              }
            }
            if(checkCount < element.validators.list.config.min){
              returnValue = false
              this.valueValidationErrorList.push("Selected items for " + element.display_name.en + " should be at least " + element.validators.list.config.min)
              if (this.isMMSecondarySupportLang) {
                this.valueValidationErrorListInMM.push("Selected items for " + element.display_name.mm + " should be at least " + element.validators.list.config.min)
              }
            }
          }
        }
      }            
    },
    validateNumberField(element){
      if(isNaN(this.fields.field_name[element.key]) ){
        this.valueValidationErrorList.push("Invalid input. " + element.display_name.en + " should be a number")
        if (this.isMMSecondarySupportLang) {
          this.valueValidationErrorListInMM.push("ထည့်သွင်းမှု မမှန်ကန်ပါ။ " + element.display_name.mm + "နံပါတ်များသာ ဖြည့်စွက်ပါ")
          }
      }
      else if(element.validators.required && element.validators.numberType){
        if(element.validators.numberType == "integer" && this.fields.field_name[element.key] != Math.floor(this.fields.field_name[element.key])){
          this.valueValidationErrorList.push(element.display_name.en + " should be Integer")
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm + " ထည့်သွင်းသည့်နံပါတ်မှာ ကိန်းပြည့်ဖြစ်ရပါမည်။ (ဒဿမကိန်းမဖြစ်ရ)")
          }
        }
      }
      if(element.validators.required && element.validators.range){
        if(this.fields.field_name[element.key] > element.validators.range.max){
          this.valueValidationErrorList.push(element.display_name.en + " should not exceed " + element.validators.range.max)
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm + " သတ်မှတ်ထားသော အများဆုံးစာလုံးအရေအတွက်ထက် မကျော်လွန်ရပါ " + element.validators.range.max)
          }
        }
        else if(this.fields.field_name[element.key] < element.validators.range.min){
          this.valueValidationErrorList.push(element.display_name.en + " should be atleast " + element.validators.range.min)
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm + " သတ်မှတ်ထားသော အနည်းဆုံးစာလုံးအရေအတွက်ထက် မနည်းစေရပါ " + element.validators.range.min)
          }
        }
      }
    },
    validateDateTime(element){      
      if(this.fields.field_name[element.key]) {
        if(!this.additionalFieldInformation[element.key].timezone){
          this.valueValidationErrorList.push("Timezone of "+ element.display_name.en +" should not be empty ");
        }
        let timezone = this.additionalFieldInformation[element.key].timezone;
        var value = moment.unix(Number(this.fields.field_name[element.key])).tz(timezone).format("YYYY-MM-DD");
        if(this.startDate[element.key]){
          var min = moment.unix(Number(this.startDate[element.key])).tz(timezone).format("YYYY-MM-DD");
          if(min > value){
            this.valueValidationErrorList.push("Value of "+ element.display_name.en +" should not be lesser then "+ moment.unix(this.startDate[element.key]).tz(timezone).format("DD-MM-YYYY") );
          }
        }
        if(this.endDate[element.key]){
          var max = moment.unix(Number(this.endDate[element.key])).tz(timezone).format("YYYY-MM-DD");
          if(max < value){
            this.valueValidationErrorList.push("Value of "+ element.display_name.en +" should not be greater then "+moment.unix(this.endDate[element.key]).tz(timezone).format("DD-MM-YYYY"));
          }
        }
      }
    },
    additionalField(key, data) { 
      this.additionalFieldInformation[key] = {
        "callingCode":data.callingCode,
        "countryCode":data.countryCode
      }
    },
    async validateFields() {
      this.errorList = [];
      this.errorListInMM = [];
      this.valueValidationErrorList = []
      this.valueValidationErrorListInMM = []
      this.tableValidationErrorList = [];
      for (var element of this.fieldDefinition) {
        if (element.isVisible && element.validators && element.validators.required && 
          (
            !element.hasOwnProperty("showIfKey") ||
            (element.hasOwnProperty("showIfKey") && element.showIfKey.includes(this.fields.field_name["next_status"]))
          )
        ) {
          if (element.input_type != "autoincrement" &&
            element.key != "current_status" &&
            element.key != "status_action" &&
            element.key != "consolidated_overall_breach_status" &&
            element.key != "consolidated_stage_breach_status" &&
            element.key != "stage_breach_status" &&
            element.key != "overall_breach_status" &&
            element.key != "assignee" &&
            element.key != "assignee_user_group" &&
            element.input_type != "slaBreachTime" &&
            element.input_type != "label"
          ) {
            if(element.renderField){
              if(element.input_type == "checklist"){
                this.validateCheckList(element);
              }
              //currency Validation
              else if(element.input_type == "currency") {
                if(!this.fields.field_name[element.key] && this.fields.field_name[element.key] != 0){
                  this.errorList.push(element.display_name.en);
                  if (this.isMMSecondarySupportLang) {
                    this.errorListInMM.push(element.display_name.mm);
                  }
                }
                if(this.additionalFieldInformation[element.key]){
                  if(!this.additionalFieldInformation[element.key]['currencyCode']){
                    this.valueValidationErrorList.push("Currency Code of "+ element.display_name.en +" should not be empty ");
                    if (this.isMMSecondarySupportLang) {
                      this.valueValidationErrorListInMM.push("Currency Code of "+ element.display_name.mm +" should not be empty ");
                    }
                  }
                }else{
                  this.valueValidationErrorList.push("Currency Code of "+ element.display_name.en +" should not be empty ");
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push("Currency Code of "+ element.display_name.mm +" should not be empty ");
                  }
                }
                this.validateCurrency(element);
              }
              else if(element.input_type === "URL") {
                if(!validator.isURL(this.fields.field_name[element.key])){
                  this.valueValidationErrorList.push(
                    "Please enter a valid URL for " + element.display_name.en
                  );
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(
                      "မှန်ကန်သည့် လင့်ခ် ဖြည့်စွက်ပါ။ " + element.display_name.mm
                    );
                  }
                }
              }
              else if(element.input_type == "multifileupload") {
                this.validateMultiFileupload(element);
              }
              else if(element.input_type == "number") {
                this.validateNumberField(element);
              }
              //Required validation
              else if (
                this.fields.field_name[element.key] == undefined ||
                this.fields.field_name[element.key] == "" ||
                this.fields.field_name[element.key] == null
              ) {
                if(element.input_type == 'table'){
                  this.isTableError = true;
                }
                this.errorList.push(element.display_name.en);
                if (this.isMMSecondarySupportLang) {
                  this.errorListInMM.push(element.display_name.mm);
                }
              }
              //Value validation
              else if(element.validators.value && !(element.validators.value == this.fields.field_name[element.key]) && !element.validators.value.includes(this.fields.field_name[element.key])){
                if (element.validators.value.length == 1) {
                  this.valueValidationErrorList.push(element.display_name.en + " should be " + element.validators.value);
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(element.display_name.mm + " should be " + element.validators.value);
                  }
                } else {
                  this.valueValidationErrorList.push(element.display_name.en + " should match one of the following: " + element.validators.value.join(", "));
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(element.display_name.mm + " should match one of the following: " + element.validators.value.join(", "));
                  }
                }
              } else if(element.input_type == "phonenumber"){ 
                if(isPossiblePhoneNumber(this.fields.field_name[element.key], this.additionalFieldInformation[element.key].countryCode) === false || 
                isValidPhoneNumber(this.fields.field_name[element.key], this.additionalFieldInformation[element.key].countryCode) === false){
                  this.valueValidationErrorList.push("Please enter valid phone number for " + element.display_name.en)
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push("ဖုန်းနံပါတ်များကို စနစ်မှန်ကန်စွာထည့်သွင်းပါ။ ဖုန်းနံပါတ်ရှေ့ဆုံးတွင် (သုည - 0 ) မထည့်ရ။ " + element.display_name.mm);
                  }
                }
              }
              else if(element.input_type == "input"){
                if (element.validators && element.validators.regex) {
                    let value = this.fields.field_name[element.key];
                    let regex = new RegExp(element.validators.regex.expression);
                    let errorMessage;
                    if (element.validators.regex.errorMessage) {
                      errorMessage = element.validators.regex.errorMessage;
                    } else {
                      errorMessage = {
                        'en' : "Please enter valid input for " + element.display_name.en,
                        'mm' : "ကျေးဇူးပြု၍ မှန်ကန်သော ထည့်သွင်းမှုကို ထည့်သွင်းပါ။" + element.display_name.mm
                      }
                    }
                    if (!regex.test(value)) {
                        this.valueValidationErrorList.push(
                            errorMessage.en
                        );
                        if (this.isMMSecondarySupportLang) {
                            this.valueValidationErrorListInMM.push(
                                errorMessage.mm
                            );
                        }
                    }
                  }
                this.validateLengthAndNumber(element);
              }
            }
          }
        }
        if(element.isVisible && element.renderField &&  element.input_type == "datetime") {
          this.validateDateTime(element);
        }
        if(element.isVisible && element.renderField &&  element.input_type == "multiselect") {
          this.validateMultiSelect(element);
        }
        if(element.isVisible && element.renderField &&  element.input_type == "table") {
          this.deleteEmptyRow(element);
          this.validateTableValue(element)
        }
        //NRC validation
        if(element.isVisible && element.renderField
        &&  element.input_type == "nrc" &&
          (
            !element.hasOwnProperty("showIfKey") ||
            (element.hasOwnProperty("showIfKey") && element.showIfKey.includes(this.fields.field_name["next_status"]))
          )
        ) {
          this.showSpinner = true;
          this.dialogWidth = "35%";
          let validNRC = true;
          if(element.validators.required) {
            validNRC = await this.validateNRC(this.fields.field_name[element.key]);
          }
          if(!validNRC){
            this.valueValidationErrorList.push(
              "Please enter a valid NRC for " + element.display_name.en
            );
            if (this.isMMSecondarySupportLang) {
              this.valueValidationErrorListInMM.push(
                "မှတ်ပုံတင်နံပါတ်အား မှန်ကန်စွာဖြည့်သွင်းပါ။ " + element.display_name.mm
              );
            }
          }
          this.showSpinner = false;
          this.dialogWidth = "70%";
        }
      };
      if (this.errorList.length > 0 || this.valueValidationErrorList.length > 0 || this.tableValidationErrorList.length > 0) {
        this.dialogWidth = '70%'
        this.showHistoryButton = true
        this.errorValidationDialogObject["data"] = { errorList: this.errorList };
        if (this.isMMSecondarySupportLang) {
          this.errorValidationDialogObject["data"] = { errorList: this.errorList, errorListInMM: this.errorListInMM}    ;
          this.errorValidationDialogObject["valueValidationErrorListInMM"] = this.valueValidationErrorListInMM;
          this.errorValidationDialogObject["displayTextInMM"] = "အောက်ပါ အကွက်များတွင် လိုအပ်သော အချက်အလက်များ ဖြည့်စွက်ပါ။ ";
        }
        this.errorValidationDialogObject["viewId"] =
          this.$props.fieldObject.viewId;
        this.errorValidationDialogObject["display"] = true;
        this.errorValidationDialogObject["valueValidationErrorList"] =
          this.valueValidationErrorList;
        this.errorValidationDialogObject["tableValidationErrorList"] = this.tableValidationErrorList;
        this.errorValidationDialogObject["displayText"] =
          "Enter the value(s) into the following field(s): ";
          if(this.isTableError && this.showValidationPopup){
          this.width = "59%";
          this.dialogWidth = "70%";
        } else {
          this.dialogWidth = "70%";
        }
        return false;
      } else {
        this.showValidationPopup = false;
		    this.fields.field_name["AdditionalFieldInformation"] = this.additionalFieldInformation
        this.dialogWidth = '35%'
        var options = {
          applicationId: this.$route.query.applicationId,
          viewId: this.$props.fieldObject.viewId,
          userId: getFromLocalStorage("userId"),
          data: this.fields.field_name,
          commentText: this.commentfield,
        };
        this.confirmationDialogObject["data"] = options;
        this.confirmationDialogObject["additionalFieldInformation"] = this.additionalFieldInformation;
        this.confirmationDialogObject["fileAttachments"] =
          this.fileAttachmentArray;
        this.confirmationDialogObject["action"] = "Workflow";
        this.confirmationDialogObject["viewId"] =
          this.$props.fieldObject.viewId;
        this.confirmationDialogObject["display"] = true;
        return true;
      }
    },
    getFormatedFiles(files ,key ="") {
      let formatedFiles = [];
      for (let file of files) {
        let folderUrl = file.split('/').slice(-1);
        let [, ...rest] = folderUrl[0].split('_');
        let fileName = rest.join('_');
        if(fileName) {
          formatedFiles.push(fileName);
        } else if(this.fields.field_name["AdditionalFieldInformation"][key] && this.fields.field_name["AdditionalFieldInformation"][key][folderUrl[0]]) {
          formatedFiles.push(this.fields.field_name["AdditionalFieldInformation"][key][folderUrl[0]])
        }
      }
      return formatedFiles;
    },
    validateMultiFileupload(fieldItem) {
      if (!(this.fields.field_name[fieldItem.key]) || this.fields.field_name[fieldItem.key].length === 0) {
        this.valueValidationErrorList.push("Please upload the file for " + fieldItem.display_name.en);
        this.valueValidationErrorListInMM.push("Please upload the file for " + fieldItem.display_name.en);
      }
      else {
        let filesToValidate = this.getFormatedFiles(this.fields.field_name[fieldItem.key],fieldItem.key);
        if (new Set(filesToValidate).size !== filesToValidate.length) {
          this.valueValidationErrorList.push("Duplicates files are not allowed in " + fieldItem.display_name.en);
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push("Duplicates files are not allowed in " + fieldItem.display_name.mm);
          }
        }
      }
    },
    openSpotlight(fieldItem) {
      this.showValidationPopup = false;
      this.showSpotlightSection = false;
      this.showHistoryDialog = false; 
      this.$nextTick(() => {
        this.spotlightDialogObject = {
          spotlightURL: fieldItem.spotlightConfiguration.spotlightUrl,
          spotlightTitle: fieldItem.spotlightConfiguration.spotlightTitle,
          queryParamKey: fieldItem.spotlightConfiguration.queryParamKey,
          queryParamValue: this.fields.field_name[fieldItem.key],
          appID: this.$route.query.applicationId,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
          display: true,
        };
        this.dialogWidth = "90%";
        this.dialogCols = "col-5";
        this.showSpotlightSection = true;
      });
    },
    closeSpotlightDialog() {
      this.showSpotlightSection = false;
      this.spotlightDialogObject.display = false;
      this.calculateDialogWidth();
      this.dialogCols = "col-12";
    },
    loadAssigneeUserList(value, full_definiton) {
      this.spinner = true;
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/getUserList",
        data: {
          applicationId: this.$route.query.applicationId,
          userGroup: value,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
          definition: full_definiton,
        },
        headers: {
          "content-type": "application/json",
        },
      })
        .then((apiResponse) => {
          this.assigneeUserList = [];
          this.assigneeUserList = apiResponse.data.data;
          if(this.$props.fieldObject.selfAssign){           
              if(this.$props.fieldObject.next_status === this.$props.fieldObject.selfAssign.key){
                for(var assignee of this.assigneeUserList){
                   var tempAssigneeList = []
                   if(assignee.email === this.userId){
                    tempAssigneeList.push(assignee)
                    this.assigneeUserList = tempAssigneeList
                   }
                }
              }       
          }
          if(this.$props.fieldObject.excludeSelfInAssignment){      
            var tempUserList = this.assigneeUserList.filter((assigneeObject) => {
              return (
                assigneeObject.email !== this.userId
              );
            });
            this.assigneeUserList = [];
            this.assigneeUserList = tempUserList;           
          }
          if (this.assigneeUserList.length == 1) {
            this.fields.field_name["assignee"] = this.assigneeUserList[0];
            this.assigneeDisabled = true;
          } else if (
            this.fields.field_name["assignee"] &&
            this.fields.field_name["assignee"] != ""
          ) {
            if (
              typeof this.fields.field_name["assignee"] === "string" ||
              this.fields.field_name["assignee"] instanceof String
            ) {
              if(this.considerPreviousAssignee) {
                let previousAssigneeData  = this.$props.fieldObject['previousAssignee'];
                if (previousAssigneeData && 
                    previousAssigneeData[0].userId && 
                    previousAssigneeData[0].roleGroup && 
                    this.fieldObject['assigneeGroup'] === this.fields.field_name["assignee_user_group"]) {
                  this.fields.field_name['assignee'] = previousAssigneeData[0].userId;
                  this.assigneeDisabled = true;
                } else {
                  this.fields.field_name['assignee'] = this.assigneeUserList.find((assigneeObject)=>{
                    return (assigneeObject.email === previousAssigneeData[0].userId)
                  })
                }
              } else {
                let itemDetails = this.$props.itemDetails;
                if ('assignToCreator' in this.fieldObject &&
                    (
                    (typeof this.fieldObject['assignToCreator'] === 'object' && 
                    this.fieldObject['assignToCreator'].setAssignee) || 
                    this.fieldObject['assignToCreator'] === true
                    ) &&
                    itemDetails && itemDetails.RecentAssignee && 
                    itemDetails.RecentAssignee.default && 
                    itemDetails.RecentAssignee.default[0].userId && 
                    itemDetails.RecentAssignee.default[0].roleGroup &&
                    this.fieldObject['assigneeGroup'] === this.fields.field_name["assignee_user_group"]) {
                  this.fields.field_name["assignee"] = itemDetails.RecentAssignee.default[0].userId;
                  this.assigneeDisabled = true;
                } else {
                  this.fields.field_name["assignee"] = this.assigneeUserList.find(
                    (assigneeObject) => {
                      return (
                        assigneeObject.email == this.fields.field_name["assignee"]
                      );
                    }
                  );
                }
              }
            }
            if (this.fieldObject.persistAssignee) {
              this.assigneeDisabled = true;
            }
          } else if (this.assigneeUserList.length >0 && 
                !this.fields.field_name["assignee"]
            ) {
            if(this.considerPreviousAssignee) {
              let previousAssigneeData  = this.$props.fieldObject['previousAssignee'];
                if (previousAssigneeData && 
                    previousAssigneeData[0].userId && 
                    previousAssigneeData[0].roleGroup && 
                    this.fieldObject['assigneeGroup'] === this.fields.field_name["assignee_user_group"]) {
                  this.fields.field_name['assignee'] = previousAssigneeData[0].userId;
                  this.assigneeDisabled = true;
                } else {
                  this.fields.field_name['assignee'] = this.assigneeUserList.find((assigneeObject)=>{
                  return (assigneeObject.email === previousAssigneeData[0].userId)
                  })
                }
            }
            let itemDetails = this.$props.itemDetails;
            if('assignToCreator' in this.fieldObject &&
                (
                (typeof this.fieldObject['assignToCreator'] === 'object' && 
                this.fieldObject['assignToCreator'].setAssignee) || 
                this.fieldObject['assignToCreator'] === true
                ) &&
                itemDetails && 
                itemDetails.RecentAssignee && itemDetails.RecentAssignee.default && 
                itemDetails.RecentAssignee.default[0].userId && itemDetails.RecentAssignee.default[0].roleGroup && 
                this.fieldObject['assigneeGroup'] === this.fields.field_name["assignee_user_group"] ) {
              this.fields.field_name["assignee"] = itemDetails.RecentAssignee.default[0].userId;
              this.assigneeDisabled = true;
            }
          }
          if(this.$props.fieldObject.LastAssignee && this.$props.fieldObject.LastAssignee.status &&  this.$props.fieldObject.LastAssignee.assignToUser && this.$props.itemDetails.LastAssignee) {
            this.fields.field_name["assignee"] = this.$props.itemDetails.LastAssignee.userId;
            this.assigneeDisabled = true;
          }
          this.hideShowAssigneeField(
              this.fields.field_name["workflow_id"],
              this.assigneeFieldItem
            );
          if(this.assigneeDisabled === true || this.showAssigneeField === false){
            this.confirmationDialogObject["display"] = false;
            this.clickSave();
          }else{
            this.showAssigneeSection = true;
            this.spinner = false;
          }
        })
        .catch((apiError) => {
          this.showAssigneeSection = true;
          this.spinner = false;
          if (
            apiError.response &&
            apiError.response.status &&
            apiError.response.status == 401
          ) {
            this.$root.$emit("update_session_value", true);
          } else {
            this.errorMessageShow = true;
            if (
              apiError.response &&
              apiError.response.data &&
              apiError.response.data.errorObject
            ) {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: apiError.response.data.errorObject.error_code,
                error_uuid: apiError.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },
    clickView(fileLink) {
      var name = fileLink;
      var read_body = {
        file_name: name,
        token: getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/getPresignedURLRead",
          read_body
        )
        .then((readSuccess) => {
          window.open(readSuccess.data.data, "_blank");
        })
        .catch((readError) => {
          if (
            readError.response &&
            readError.response.status &&
            readError.response.status == 401
          ) {
            this.$root.$emit('update_session_value', true);
          }
          else{
          this.errorMessageShow = true;
           if(readError.response && readError.response.data && readError.response.data.errorObject){
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: readError.response.data.errorObject.error_code,
              error_uuid: readError.response.data.errorObject.error_uuid,
            };
          }
          else{
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
          this.showMessageDialog = true;
          }
        });
    },
    hideShowAssigneeField(workflowId, fieldItem) {
      if (this.assigneeDisabled) {
        this.showAssigneeField = true;
      } else {
        if (fieldItem.hasOwnProperty("isShown")) {
          if (fieldItem["isShown"].hasOwnProperty(workflowId)) {
            if (
              fieldItem["isShown"][workflowId].hasOwnProperty(
                this.fields.field_name["current_status"]
              )
            ) {
              if (
                fieldItem["isShown"][workflowId][
                  this.fields.field_name["current_status"]
                ].includes(getFromLocalStorage("userGroup"))
              ) {
                this.showAssigneeField = true;
              } else {
                this.showAssigneeField = false;
              }
            } else {
              this.showAssigneeField = false;
            }
          } else {
            this.showAssigneeField = false;
          }
        } else {
          this.showAssigneeField = true;
        }
      }
    },
    async openAssigneeSection() {
      this.showHistoryButton = false;
      this.showSpotlightSection = false;
      this.showHistoryDialog = false; 
      this.showValidationPopup = false;
      this.errorList = [];
      this.valueValidationErrorList = [];
      this.valueValidationErrorListInMM = [];
      this.tableValidationErrorList = [];
      var isFormValid = await this.validateFields();
      if (isFormValid) {
        this.closeSpotlightDialog();
        this.loadAssigneeUserList(
          this.fields.field_name["assignee_user_group"],
          this.fields.field_name
        );
      }else{
        this.showValidationPopup = true;
        this.showSpotlightSection = false;
      }
    },
    addCurrencyDetails(value,key) {
      this.additionalFieldInformation[key] = {
        "currencyCode": value
      }
    },
    closeAssigneeSection() {
      this.showHistoryButton = true;
      this.showAssigneeSection = false;
    },
    checkFieldAccess(fieldItem){
      if (fieldItem.field_access.hasOwnProperty("update")) {
        if (fieldItem.field_access.update.hasOwnProperty(this.$props.itemDetails.workflow_id)) {
          if (fieldItem.field_access.update[this.$props.itemDetails.workflow_id].hasOwnProperty(this.$props.itemDetails.current_status)) {
            if (fieldItem.field_access.update[this.$props.itemDetails.workflow_id]
              [this.$props.itemDetails.current_status].includes(getFromLocalStorage("userGroup")))
            {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async getMasterViewFields() {
      for (let fieldItem of this.fieldDefinition) {
        if (fieldItem.isMasterViewConfig) {
          let userGroup = getFromLocalStorage("userGroup");
          if (fieldItem.masterViewConfig
            && this.$props.itemDetails.workflow_id in fieldItem.masterViewConfig
            && this.$props.itemDetails.current_status in fieldItem.masterViewConfig[this.$props.itemDetails.workflow_id]
            && userGroup in fieldItem.masterViewConfig[this.$props.itemDetails.workflow_id][this.$props.itemDetails.current_status]
            && !this.masterDataList[fieldItem.view_id]
          ) {
            this.masterViewAccessFields[fieldItem.key] = fieldItem.masterViewConfig[this.$props.itemDetails.workflow_id][this.$props.itemDetails.current_status][userGroup];
            try {
              let payload = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL + "/displayMasterValue",
                data: {
                  applicationId: this.$route.query.applicationId,
                  token: getFromLocalStorage("token"),
                  authType: getFromLocalStorage("authType"),
                  viewId: fieldItem.view_id
                },
                headers: {
                  "content-type": "application/json",
                },
              };
              let apiResponse = await axios(payload);
              if (apiResponse.data && apiResponse.data.data) {
                this.masterDataList[fieldItem.view_id] = apiResponse.data.data;
                this.masterDisplayNames[fieldItem.view_id] = apiResponse.data.displayNames;
                this.displayNames[fieldItem.key] = this.masterDisplayNames[fieldItem.view_id];
                if (this.fields.field_name[fieldItem.key] && this.masterDataList[fieldItem.view_id]) {
                  let masterViewField = this.masterDataList[fieldItem.view_id].find((field) => field._id === this.fields.field_name[fieldItem.key]);
                  if (masterViewField && this.masterViewAccessFields[fieldItem.key]) {
                    this.$set(this.viewData, fieldItem.key, {});
                    this.$set(this.viewData[fieldItem.key], 'display_name', {});
                    // this.viewData[fieldItem.key] = {}
                    for (let key of this.masterViewAccessFields[fieldItem.key]) {
                      if (masterViewField[key]) {
                        this.$set(this.viewData[fieldItem.key], key, masterViewField[key]);
                        this.$set(this.viewData[fieldItem.key]['display_name'], key, apiResponse.data.displayNames[key]);
                        // this.viewData[fieldItem.key][key] = masterViewField[key];
                      }
                    }
                  }
                }
              }
            } catch (apiError) {
              if (
                apiError.response &&
                apiError.response.status &&
                apiError.response.status == 401
              ) {
                this.$root.$emit('update_session_value', true);
              }
              else {
                this.errorMessageShow = true;
                if (apiError.response && apiError.response.data && apiError.response.data.errorObject) {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: apiError.response.data.errorObject.error_code,
                    error_uuid: apiError.response.data.errorObject.error_uuid,
                  };
                }
                else {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: "",
                    error_uuid: "",
                  };
                }
                this.showMessageDialog = true;
              }
              this.showSpinner = false;
              this.showAssigneeSection = true;
            }
          }
        }
      }
      this.showMasterViewFields = true;
    },
    filterFieldItemOptionValuesByScope(fieldItem) {
      if (fieldItem.view_type !== "reference") {
        fieldItem.option_values = this.currentScope.fieldKeys[fieldItem.key];
        if (fieldItem.option_values.length == 1) {
          this.$set(this.fields.field_name, fieldItem.key, fieldItem.option_values[0]);
          fieldItem.isDisabled = true;
        }
      } else {
        fieldItem.option_values = fieldItem.option_values.filter((item) =>
          this.currentScope.fieldKeys[fieldItem.key].includes(item.key)
        );
        if (fieldItem.option_values.length == 1) {
          this.$set(this.fields.field_name, fieldItem.key, fieldItem.option_values[0].key);
          fieldItem.isDisabled = true;
        }
      }
    },
    filterScopedFields() {
      for (var [index, fieldItem] of Object.entries(this.fieldDefinition)) {
        if (fieldItem.key in this.currentScope.fieldKeys) {
          if (["toggle", "date", "textarea", "phone", "email", "currency", "input", "priority", "number", "phonenumber"].includes(fieldItem.input_type)) {
            if (this.currentScope.fieldKeys[fieldItem.key] !== "ALL") {
              this.$set(this.fields.field_name, fieldItem.key, this.currentScope.fieldKeys[fieldItem.key]);
              fieldItem.isDisabled = true;
            }
          } else if (["options", "radio"].includes(fieldItem.input_type)) {
            this.filterFieldItemOptionValuesByScope(fieldItem);
          }
        }
      }
    },
    hideFields() {
      for(let fieldItem of this.fieldDefinition) {
        if(!this.checkFieldHide(this.fieldType, fieldItem ,this.$props.itemDetails.workflow_id, this.$props.itemDetails.current_status )) {
          fieldItem.renderField = false;
        }
      } 
    },
  },
  async mounted() {
    let secondarySupportLanguageDetails = getFromLocalStorage('secondarySupportLanguage');
    if (secondarySupportLanguageDetails && secondarySupportLanguageDetails.secondarySupportLanguage === 'Burmese' && secondarySupportLanguageDetails.applicationId == this.$route.query.applicationId) {
      this.isMMSecondarySupportLang = true; 
      this.rulesForRequired = [(v) => !!v || "Input is required (ဖြည့်စွက်ရန် လိုအပ်ပါသည်။)"],
      this.rulesForCurrencyRequired =  [
        (v) => !!v || "Enter the Range (အတိုင်းအထွာ ပမာဏကို သတ်မှတ်ဖြည့်စွက်ပါ။)",
        (v) =>
          (parseInt(v) >= 100 && parseInt(v) <= 100000) ||
          "Enter a proper range (အတိုင်းအထွာ ပမာဏကို သတ်မှတ်ဖြည့်စွက်ပါ။)",
      ];
      this.rulesForEmailRequired= [
      (v) => !!v || "Enter the email ID (e-mail ID ဖြည့်စွက်ပါ။)",
        (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || 
        "Enter a valid email ID (မှန်ကန်သည့် e-mail ID ဖြည့်စွက်ပါ။)",
      ];
      this.rulesForURLRequired=[
        (v) => !!v || "Enter the URL (လင့်ခ်ကို ဖြည့်စွက်ပါ။)",
        (v) =>
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(v) ||
        "Enter a valid URL (မှန်ကန်သည့် လင့်ခ် ဖြည့်စွက်ပါ။)"
      ];
    } else {
      this.rulesForRequired = [(v) => !!v || "Input is required"],
      this.rulesForCurrencyRequired =  [
        (v) => !!v || "Enter the Range",
        (v) =>
          (parseInt(v) >= 100 && parseInt(v) <= 100000) ||
          "Enter a proper range",
      ];
      this.rulesForEmailRequired= [
        (v) => !!v || "Enter the email ID",
        (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || 
        "Enter a valid email ID",
      ];
      this.rulesForURLRequired=[
        (v) => !!v || "Enter the URL",
        (v) =>
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(v) ||
        "Enter a valid URL"
      ];
    }
    this.userRoleGroup = getFromLocalStorage("userGroup");
    var historyComment = [];
    if('Comments' in this.$props.itemDetails) {
      this.$props.itemDetails.Comments.forEach(element => {
      historyComment.push(element)
      this.historyComments = historyComment 
      });
    } 
    this.userId = getFromLocalStorage("userId"),
    this.selectedKey = this.fieldObject["next_status"];
    this.dialog = this.$props.fieldObject.display;
    this.assigneeGroup.push(this.$props.fieldObject.assigneeGroup);
    if (this.assigneeGroup.length === 1) {
      this.assigneeUserGroupOptionsDisabled = true;
    }
    this.fields.field_name["assignee_user_group"] = this.assigneeGroup[0];
    this.screenDetails = this.$props.screenDefinition;
    
    this.fieldDefinition = this.screenDetails.fieldDetails.fields;
    this.fields.field_name = JSON.parse(JSON.stringify(this.$props.itemDetails));
    if(!this.fields.field_name['AdditionalFieldInformation']){
      this.fields.field_name['AdditionalFieldInformation'] = {};
    }
    for(var fieldItem of this.fieldDefinition){
      if(fieldItem.input_type == 'currency' && fieldItem.copyFieldValueConfig){
        let currencyCode;
        let copyValueConfig = fieldItem.copyFieldValueConfig
        let canCopyFieldApply = this.copyFieldAccessCheck(fieldItem, this.fields.field_name["workflow_id"]);
        if(canCopyFieldApply) {
          let parentKey = copyValueConfig['fieldKey'];
          if(fieldItem.input_type == 'currency'){
            if(!this.fields.field_name['AdditionalFieldInformation'][parentKey]){
              let parentField = this.fieldDefinition.find((field) => field.key == parentKey)
              if(parentField.currency_configuration && parentField.currency_configuration.defaultCurrency){
                currencyCode = parentField.currency_configuration.defaultCurrency
              }
              this.fields.field_name['AdditionalFieldInformation'][parentKey] = {"currencyCode": currencyCode ? currencyCode : ''};
            } else if(this.fields.field_name['AdditionalFieldInformation'][parentKey] && Object.keys(this.fields.field_name['AdditionalFieldInformation'][parentKey]).includes('currencyCode')) {
              currencyCode = this.fields.field_name['AdditionalFieldInformation'][parentKey]['currencyCode'];
            } else if(fieldItem.currency_configuration && fieldItem.currency_configuration.defaultCurrency){
              currencyCode = fieldItem.currency_configuration.defaultCurrency
            }
            this.fields.field_name['AdditionalFieldInformation'][fieldItem.key] = {"currencyCode": currencyCode ? currencyCode : ''};
          }
        }
      }
    }
    this.currencyData = this.fields.field_name['AdditionalFieldInformation'];
    if(this.fields.field_name['AdditionalFieldInformation']) {
      this.additionalFieldInformation = this.fields.field_name['AdditionalFieldInformation'];
    }
    if(this.$props.fieldObject.previousAssignee.length > 0){
      this.considerPreviousAssignee = true;
    }
    this.fields.field_name["assignee_user_group"] =
      this.$props.fieldObject.assigneeGroup;
    this.fields.field_name["next_status"] =
      this.$props.fieldObject["next_status"];
    this.spinner = true;
    let conditionEnabled = false;
    let exceptionList = new Set();
    for (var fieldItem of this.fieldDefinition) {
      if(
        (
          fieldItem.isDependent &&
          (
            (
              fieldItem.dependentField == "current_status" &&
              (
                this.fields.field_name[fieldItem.dependentField] == fieldItem.dependentValue ||
                fieldItem.dependentValue.includes(this.fields.field_name[fieldItem.dependentField])
              )
            )
            ||
            exceptionList.has(fieldItem.dependentField) 
          )
        ) || 
        (fieldItem.input_type === 'assigneeOptions' || fieldItem.input_type === 'AssigneeUseroptions')
      )
        {
          exceptionList.add(fieldItem.key)
        }
    }
    for (var fieldItem of this.fieldDefinition) {
      if(!exceptionList.has(fieldItem.key)){
        if (fieldItem.field_access.hasOwnProperty("update")) {
          if (
            fieldItem.field_access.update.hasOwnProperty(
              this.$props.itemDetails.workflow_id
            )
          ) {
            if (
            fieldItem.field_access.update[
                this.$props.itemDetails.workflow_id
              ].hasOwnProperty(this.$props.itemDetails.current_status)
            ) {
              if (
                fieldItem.field_access.update[
                  this.$props.itemDetails.workflow_id
                ][this.$props.itemDetails.current_status].includes(
                  getFromLocalStorage("userGroup")
                )
              ) {

                if(fieldItem.isDisabled || fieldItem.isComputed || fieldItem.isApiComputed){

                  fieldItem.isDisabled = true
                }
                else{
                  fieldItem["isDisabled"] = false;
                }
              } else {
                fieldItem["isDisabled"] = true;
              }
            } else {
              fieldItem["isDisabled"] = true;
            }
          } else {
            fieldItem["isDisabled"] = true;
          }
        } else {
          fieldItem["isDisabled"] = true;
        }
      }

      if (fieldItem.input_type === "hierarchicalDropdown") {
        if (
          fieldItem.options_object &&
          typeof(fieldItem.options_object) == 'object'
        ) {
          fieldItem['option_values'] = [];
          Object.entries(fieldItem.options_object).map(([key, values]) => {
            fieldItem['option_values'].push(...values);
          })
        }
      }

      if (fieldItem.input_type === "datetime") {
        if (!this.additionalFieldInformation[fieldItem.key]){
          if (fieldItem.validators && fieldItem.validators.date 
          && fieldItem.validators.date.timezone ){
            this.additionalFieldInformation[fieldItem.key] = {
              "timezone": fieldItem.validators.date.timezone
            }
          } else {
            let localTZ = moment.tz.guess(true);
            this.additionalFieldInformation[fieldItem.key] = {
              "timezone": localTZ
            }
          }
        }
        if (
          fieldItem.validators &&
          fieldItem.validators.date) {
            if(fieldItem.validators.date.hidePastDates === true) {
              this.startDate[fieldItem.key] = Number(moment().unix());
            }
            if(fieldItem.validators.date.range) {
              if(fieldItem.validators.date.range.from){
                if(fieldItem.validators.date.range.from.type == 'absolute'){
                  let startDateEpoch = Number(fieldItem.validators.date.range.from.value);
                  this.startDate[fieldItem.key] = startDateEpoch;
                }
                else if(fieldItem.validators.date.range.from.type == 'reference' &&
                this.fields.field_name[fieldItem.validators.date.range.from.value]){
                  let startDateEpoch = Number(this.fields.field_name[fieldItem.validators.date.range.from.value]);
                  this.startDate[fieldItem.key] = startDateEpoch;
                }
              }
              if(fieldItem.validators.date.range.to){
                if(fieldItem.validators.date.range.to.type == 'absolute'){
                  let endDateEpoch = Number(fieldItem.validators.date.range.to.value);
                  this.endDate[fieldItem.key] = endDateEpoch;
                }
                else if(fieldItem.validators.date.range.to.type == 'reference' &&
                this.fields.field_name[fieldItem.validators.date.range.to.value]){
                  let endDateEpoch = Number(this.fields.field_name[fieldItem.validators.date.range.to.value]);
                  this.endDate[fieldItem.key] = endDateEpoch;
                }
              }
            }
          }
        if (!(fieldItem.key in this.fields.field_name) && fieldItem.default_value && 
        fieldItem.default_value.type) {
          let datetimeValue;
          if(fieldItem.default_value.type === "today") {
            datetimeValue = Number(moment().unix());
          }
          else if(fieldItem.default_value.type === "absolute") {
            datetimeValue = Number(fieldItem.default_value.value);
          }
          if(datetimeValue && 
          !(this.startDate[fieldItem.key] && Number(this.startDate[fieldItem.key]) > Number(datetimeValue)) &&
          !(this.endDate[fieldItem.key] && Number(this.endDate[fieldItem.key]) < Number(datetimeValue))
          ) {
            this.fields.field_name[fieldItem.key] = datetimeValue;
          }
        }
      }  

      if (fieldItem.input_type === "optionsGroup") {
        if(this.fields.field_name[fieldItem.options_parent] && this.fields.field_name[fieldItem.options_parent]!=null){
          fieldItem.option_values = fieldItem.options_mapping[this.fields.field_name[fieldItem.options_parent]];
          fieldItem.renderField = true;
        } else {
          this.fields.field_name[fieldItem.key] = null;
          fieldItem.renderField = false;
        }
      }
      if(fieldItem.isComputed && 'evaluation' in fieldItem){
        let computedValue = this.getComputedValue(fieldItem.evaluation);
        if(!Number.isNaN(parseFloat(computedValue)) && Number.isFinite(parseFloat(computedValue))){
          this.fields.field_name[fieldItem.key] = computedValue;
        }
        else{
          delete this.fields.field_name[fieldItem.key];
        }
      }
      let oldValue = this.fields.field_name[fieldItem.key];
      if (
        fieldItem.conditionalValue &&
        fieldItem.conditionalValue.conditions &&
        fieldItem.conditionalValue.conditions.length > 0 && 
        fieldItem.conditionalValue.stages
      ) {
        conditionEnabled = false;
        for (let stageObject of fieldItem.conditionalValue.stages) {
          for (let workflow in stageObject) {
            if (workflow == this.fields.field_name["workflow_id"]) {
              if (
                stageObject[workflow].includes(
                  this.fields.field_name["current_status"]
                )
              ) {
                conditionEnabled = true;
              }
            }
          }
        }
        if (!conditionEnabled) {
          continue;
        }
        fieldItem.isDisabled = true;
        if (oldValue != fieldItem.defaultValue) {
          if (fieldItem.key == "assignee_user_group") {
            this.assigneeGroup = [];
            this.assigneeGroup = [fieldItem.defaultValue];
            this.fields.field_name[fieldItem.key] = fieldItem.defaultValue;
          } else {
            this.fields.field_name[fieldItem.key] = fieldItem.defaultValue;
          }
        }
      }
      if(fieldItem.isComputed || fieldItem.isApiComputed){
        fieldItem.isDisabled = true
      }
      if (
        fieldItem.key === 'status_action' && fieldItem.action_items &&
        fieldItem.action_items[this.fields.field_name.workflow_id] &&
        fieldItem.action_items[this.fields.field_name.workflow_id][this.fields.field_name.current_status]
      ) {
        let stageObject = fieldItem.action_items[this.fields.field_name.workflow_id][this.fields.field_name.current_status].filter((element) => {
          return element.key === this.fields.field_name.next_status;
        });

        if ( 
          stageObject && stageObject[0].isConditionalChange && 
          stageObject[0].conditionalStageConfig && 
          stageObject[0].conditionalStageConfig.conditions
        ) {
           for (let condition of stageObject[0].conditionalStageConfig.conditions) {
            if (this.getValue(condition.conditionalStatement)) {
              this.assigneeGroup = [];
              this.assigneeGroup = [condition.assigneeGroup];
              this.fields.field_name['assignee_user_group'] = condition.assigneeGroup;
              this.fields.field_name['status_action'][0].text = condition.stage;
              fieldItem.isDisabled = true;
              break;
            }
          }
        }
      }
    }
    if (
      this.$props.screenDefinition.scopeDefinition &&
      this.$props.screenDefinition.scopeDefinition.length > 0 &&
      this.$props.selectedScope
    ) {
      this.currentScope = this.$props.screenDefinition.scopeDefinition.find(
        (scope) => scope.key === this.$props.selectedScope
      );
      if (this.currentScope && 'fieldKeys' in this.currentScope) {
        this.filterScopedFields();
      }
    }
    for (var fieldItem of this.fieldDefinition) {
      if(fieldItem.hasOwnProperty("defaultValue")) {
        if(
          fieldItem.isDependent && fieldItem.dependentField == "current_status" 
          && 
          (
            this.fields.field_name[fieldItem.dependentField] == fieldItem.dependentValue ||
            fieldItem.dependentValue.includes(this.fields.field_name[fieldItem.dependentField])
          )
          && 
          (
            fieldItem.hasOwnProperty('showIfKey') ? this.isExist(fieldItem) : true
          )
        )
        {
          if (
            [
              "toggle", "textarea", "email", "currency", "input", "nrc", "date",
              "priority", "URL", "options", "alert", "phonenumber", "number" ,"radio"
            ].includes(fieldItem.input_type)
          ) {
            if(this.fields.field_name[fieldItem.key] === undefined || this.fields.field_name[fieldItem.key] === "" || this.fields.field_name[fieldItem.key] === null ) {
              if(fieldItem.input_type === 'toggle') {
                this.fields.field_name[fieldItem.key] = new Set([true, false]).has(fieldItem.defaultValue) ? fieldItem.defaultValue : false;
              } else if (fieldItem.input_type == 'currency') {
                  this.fields.field_name[fieldItem.key] = fieldItem.defaultValue.toString();
              } else {
                this.$set(this.fields.field_name, fieldItem.key, fieldItem.defaultValue);
              }
            }
          }
        }
      }
      if(fieldItem.isDisabled ) {
        if(fieldItem.input_type === "currency"){
          if(fieldItem.currency_configuration && fieldItem.currency_configuration.defaultCurrency){
            this.additionalFieldInformation[fieldItem.key] = {
              "currencyCode": fieldItem.currency_configuration.defaultCurrency
            }
          }else{
            this.additionalFieldInformation[fieldItem.key] = {
              "currencyCode": "USD"
            }
          }
        }

        if(fieldItem.input_type === "phonenumber"){
          if(fieldItem.phonenumberConfiguration && fieldItem.phonenumberConfiguration.defaultCountryCode){
            this.additionalFieldInformation[fieldItem.key] = {
              "countryCode": fieldItem.phonenumberConfiguration.defaultCountryCode,
              "callingCode":""
            }
          }else{
            this.additionalFieldInformation[fieldItem.key] = {
              "countryCode": "MM",
              "callingCode":""
            }
          }
        }
      }
    }

    for (var fieldItem of this.fieldDefinition) {
      // Setting default value for dropdown if there is just one option
      if (
        fieldItem.isDependent &&
        fieldItem.dependentField == "current_status" &&
        (this.fields.field_name[fieldItem.dependentField] ==
          fieldItem.dependentValue ||
          fieldItem.dependentValue.includes(
            this.fields.field_name[fieldItem.dependentField]
          ))
      ) {
        fieldItem.renderField = true;
        if (
          fieldItem.input_type == "options" &&
          fieldItem.option_values.length == 1
        ) {
          if (fieldItem.view_type === "reference") {
            this.fields.field_name[fieldItem.key] =
              fieldItem.option_values[0].key;
          } else {
            this.fields.field_name[fieldItem.key] = fieldItem.option_values[0];
          }
        }
        if (fieldItem.input_type === "table") {
          this.$set(this.showTable, fieldItem.key, true);
          this.dialogWidth = '70%';
        }
      }
      if(fieldItem.copyFieldValueConfig){
        fieldItem.isDisabled = true;
      }
    }
    for(var fieldItem of this.fieldDefinition){
      if(fieldItem.input_type == 'currency' && fieldItem.copyFieldValueConfig){
        let currencyCode;
        let copyValueConfig = fieldItem.copyFieldValueConfig
        let canCopyFieldApply = this.copyFieldAccessCheck(fieldItem, this.fields.field_name["workflow_id"]);
        if(canCopyFieldApply) {
          let parentKey = copyValueConfig['fieldKey'];
          if(fieldItem.input_type == 'currency'){
            if(!this.additionalFieldInformation[parentKey]){
              let parentField = this.fieldDefinition.find((field) => field.key == parentKey)
              if(parentField.currency_configuration && parentField.currency_configuration.defaultCurrency){
                currencyCode = parentField.currency_configuration.defaultCurrency
              }
              this.additionalFieldInformation[parentKey] = {"currencyCode": currencyCode ? currencyCode : ''};
            } else if(this.additionalFieldInformation[parentKey] && Object.keys(this.additionalFieldInformation[parentKey]).includes('currencyCode')) {
              currencyCode = this.additionalFieldInformation[parentKey]['currencyCode'];
            } else if(fieldItem.currency_configuration && fieldItem.currency_configuration.defaultCurrency){
              currencyCode = fieldItem.currency_configuration.defaultCurrency
            }
            this.additionalFieldInformation[fieldItem.key] = {"currencyCode": currencyCode ? currencyCode : ''};
          }
        }
      }
    }
    this.currencyData = this.additionalFieldInformation;
    let isTableAvailable = this.fieldDefinition.some((field)=> field.input_type == 'table');
    if(isTableAvailable){
      this.$nextTick(()=>{
        var div = document.querySelector('#divSelector');
        div.addEventListener('scroll', this.handleScroll);
      })
    }
    this.spinner = false;
    await this.getMasterViewFields();
  },
};
</script>
<style scoped>
.dateTime {
  float: left;
  margin: 9px;
  padding: 8px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.input_field_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: 1px;
  color: #4f4f4f;
}

.scroll-2::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.scroll-2::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0;
  background-color: rgb(42, 64, 154);
}

.dialogWrapper {
  display: flex;
}

.dialogWrapper > .v-card {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
}

.dialogWrapper > .v-card > .v-card__text {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  flex: 1 1 auto;
  overflow-y: auto;
}
.dialogWrapper > .v-card > .v-card__actions,
.dialogWrapper > .v-card > .v-card__title {
  flex: 0 0 auto;
}
.hideRightBorderRadius {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.dialogWrapper.col-5,
.spotlightSection {
  transition: flex-basis 0.3s;
}
.buttonClassBlue {
  background-color: rgb(42, 64, 154) !important;
  color: #ffffff !important;
  width: 100px !important;
}
.cancelButtonWhite {
  background-color: #ffffff !important;
  color: rgb(42, 64, 154) !important;
  border: 1px solid rgb(42, 64, 154) !important;
  width: 100px !important;
}
</style>
<style>
.workflowFieldDialog {
  transition: width 0.5s;
  overflow-y: hidden !important;
  display: flex;
}
</style>