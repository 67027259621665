import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        locale: '',
        notificationsRead: {
            'notifications': [],
            'lastEvaluatedKey': '',
        },
        notificationsUnRead: {
            'notifications': [],
            'lastEvaluatedKey': '',
        },
        notificationsBreach: {
            'notifications': [],
            'lastEvaluatedKey': '',
        }
    },
    mutations: {
        UPDATE_LOCALE(state, newLocale) {
            state.locale = newLocale;
            location.reload();
        },
        MARK_AS_READ(state, payload) {
            let notificationTypeObj = payload.type === 'unread' ? state.notificationsUnRead : state.notificationsBreach;
            let removedNotification = notificationTypeObj.notifications.splice(payload.index, 1)[0];
            state.notificationsRead.notifications.unshift(removedNotification);
        },
        MARK_ALL_AS_READ(state, payload) {
            let notificationTypeObj = payload.type === 'unread' ? state.notificationsUnRead : state.notificationsBreach;
            let notificationsTokeep = [];
            let notificationsToMove = [];
            for (let notification of notificationTypeObj.notifications) {
                if (payload.notificationIds.includes(notification.id)) {
                    notificationsToMove.push(notification);
                } else {
                    notificationsTokeep.push(notification);
                }
            }
            notificationTypeObj.notifications = notificationsTokeep;
            state.notificationsRead.notifications.unshift(...notificationsToMove);
        },
        REPLACE_NOTIFICATIONS(state, notificationsObj) {
            state.notificationsRead = notificationsObj['read'];
            state.notificationsUnRead = notificationsObj['unread'];
            state.notificationsBreach = notificationsObj['breach'];
        },
        ADD_NEW_NOTIFICATION(state, notification) {
            if (notification['slaBreach']) {
                state.notificationsBreach.notifications = [...state.notificationsBreach.notifications, notification];
            } else {
                state.notificationsUnRead.notifications = [notification, ...state.notificationsUnRead.notifications];
            }
        },
        SOCKET_ONOPEN(state, event) {
            Vue.prototype.$socket = event.currentTarget;
            Vue.prototype.$socket.isConnected = true
        },
        SOCKET_ONCLOSE(state, event) {
            console.log('Websocket connection is closed'); // Log added to monitor the WebSocket connection status
            Vue.prototype.$socket.isConnected = false
        },
        SOCKET_ONERROR(state, event) {
            console.log('ERROR occured while connecting to websocket'); // Log added to monitor the WebSocket connection status
        },
        SOCKET_ONMESSAGE (state, message)  {
            let notification = JSON.parse(message.data);
            if (notification['slaBreach']) {
                state.notificationsBreach.notifications = [...state.notificationsBreach.notifications, notification];
            } else {
                state.notificationsUnRead.notifications = [notification, ...state.notificationsUnRead.notifications];
            }           
          },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            console.log('Websocket Reconnection Success'); // Log added to monitor the WebSocket connection status
        },
        SOCKET_RECONNECT_ERROR(state) {
            console.log('ERROR occured while reconnecting to websocket'); // Log added to monitor the WebSocket connection status
            Vue.prototype.$socket.reconnectError = true;
        },
    },
    actions: {
        changeLocale({ commit }, newLocale) {
            commit('UPDATE_LOCALE', newLocale);
        },
        markAsRead({ commit }, payload) {
            commit('MARK_AS_READ', payload);
        },
        markAllAsRead({ commit }, payload) {
            commit('MARK_ALL_AS_READ', payload);
        },
        replaceNotifications({ commit }, notificationsObj) {
            commit('REPLACE_NOTIFICATIONS', notificationsObj);
        },
        addNewNotification({ commit }, notification) {
            commit('ADD_NEW_NOTIFICATION', notification);
        },
    },
    getters: {
        getLocale(state) {
            return state.locale;
        },
    },
    modules: {
    },
    plugins: [createPersistedState()]
});
