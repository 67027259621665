<template>
  <v-dialog persistent scrollable v-model="dialog" :width="dialogWidth">
    <v-card
      :class="dialogCols"
      style="max-width: 100%; padding: 0px; overflow: visible"
    >
      <v-card-title
        style="
          background-color: #ffffff;
          color: #2a409a;
          font-size: 20px;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          align-items: flex-start;
        "
      >
        <p>Update Assignee</p>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="scroll-2 dialogWrapper flex-grow-0 pa-4"
        style="overflow-y: scroll; overflow-x: hidden"
      >
        <v-slide-y-transition hide-on-leave>
          <div v-show="updateFieldDialogObject.viewType == 'workflow'">
            <div class="flexColumn" v-for="fieldItem in fieldDefinition">
              <div
                v-if="
                  fieldItem.isVisible &&
                  fieldItem.input_type === 'AssigneeUseroptions'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label
                    >{{ fieldItem.display_name[$i18n.locale] }}
                    <span
                      v-if="
                        fieldItem.validators && fieldItem.validators.required
                      "
                      style="color: red"
                      >*</span
                    ><span
                      ><HelpToolTip
                        v-if="fieldItem.helpTooltipText"
                        :ToolMessage="fieldItem.helpTooltipText"
                      ></HelpToolTip
                    ></span>
                  </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-text-field
                    outlined
                    background-color="#F2F2F2"
                    v-model="assignedToUserGroup"
                    :disabled="true"
                    :rules="
                      fieldItem.validators &&
                      fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                  ></v-text-field>
                </div>
              </div>
              <div
                v-if="
                  fieldItem.isVisible &&
                  fieldItem.input_type === 'assigneeOptions'
                "
                class="input_field_div"
              >
                <div style="width: 100%; margin-bottom: 8px" align="left">
                  <label
                    >{{ fieldItem.display_name[$i18n.locale] }}
                    <span
                      v-if="
                        fieldItem.validators && fieldItem.validators.required
                      "
                      style="color: red"
                      >*</span
                    ><span
                      ><HelpToolTip
                        v-if="fieldItem.helpTooltipText"
                        :ToolMessage="fieldItem.helpTooltipText"
                      ></HelpToolTip
                    ></span>
                  </label>
                </div>
                <div style="width: 100%" align="right">
                  <v-autocomplete
                    style="border-radius: 10px"
                    outlined
                    :disabled="assigneeDisabled || fieldItem.disable"
                    background-color="#F2F2F2"
                    item-text="name"
                    item-value="email"
                    :items="assigneeUserList"
                    :rules="
                      fieldItem.validators &&
                      fieldItem.validators.required === true
                        ? rulesForRequired
                        : rulesForNotRequired
                    "
                    v-model="fields.field_name[fieldItem.key]"
                  >
                    <template slot="item" slot-scope="data" style="width:100%:">
                      <div
                        class="d-flex flex-row"
                        style="height: 70px; padding: 5px; width: 100%"
                      >
                        <div
                          style="
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: rgb(42, 64, 154);
                            color: white;
                          "
                        >
                          <p
                            style="
                              font-weight: 500;
                              font-size: 1.2rem;
                              padding-top: 5px;
                              text-align: center;
                            "
                          >
                            {{ data.item.name.charAt(0) }}
                          </p>
                        </div>
                        <div
                          class="d-flex flex-column"
                          style="
                            justify-content: space-around;
                            padding-left: 10px;
                          "
                        >
                          <div class="font-weight-medium">
                            {{ data.item.name }}
                          </div>
                          <div class="font-weight-light">
                            {{ data.item.email }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                </div>
              </div>
            </div>
          </div>
        </v-slide-y-transition>
        <div v-if="showConfirmationDialog">
          <ConfirmationDialog
            :confirmationDialogObject="confirmationDialogObject"
            @closeDialog="closeDialog"
          />
        </div>
        <div v-if="showErrorValidationDialog">
          <ErrorValidationDialog
            :displayErrorValidationDialog="showErrorValidationDialog"
            :errorValidationDialogObject="errorValidationDialogObject"
            @closeErrorValidationDialog="closeErrorValidationDialog"
          />
        </div>
      </v-card-text>

      <v-divider />
      <v-card-actions style="display: flex; justify-content: right">
        <div v-if="showAssigneeSection"></div>
        <v-spacer></v-spacer>
        <div style="margin-right: 30px; margin-left: 30px">
          <v-btn
            outlined
            style="
              background-color: #fffff;
              color: rgb(42, 64, 154);
              border: 1px solid rgb(42, 64, 154);
              width: 100px;
            "
            @click="clickCancel()"
            >{{ $t("Cancel") }}</v-btn
          >
        </div>
        <div
          v-if="
            showAssigneeSection || updateFieldDialogObject.viewType == 'master'
          "
        >
          <v-btn
            style="
              background-color: rgb(42, 64, 154);
              color: #ffffff;
              width: 100px;
            "
            @click="clickSave()"
            >{{ $t("Save") }}</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>

    <div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
    <v-overlay :value="showSpinner">
      <SpinnerComponent></SpinnerComponent>
    </v-overlay>
  </v-dialog>
</template>

<script>
import axios from "axios";
import HelpToolTip from "./HelpToolTip.vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import ErrorValidationDialog from "./ErrorValidationDialog.vue";
import MessageDialog from "./MessageDialog.vue";
import SpinnerComponent from "./SpinnerComponent.vue";
import { getFromLocalStorage } from "../store/localStorageInterface";

export default {
  props: ["updateFieldDialogObject", "fieldDefinition"],
  components: {
    ConfirmationDialog,
    ErrorValidationDialog,
    MessageDialog,
    SpinnerComponent,
    HelpToolTip,
  },
  data() {
    return {
      dialog: true,
      fields: {
        field_name: {},
      },
      assigneeDisabled: false,
      showConfirmationDialog: false,
      confirmationDialogObject: {},
      showErrorValidationDialog: false,
      errorValidationDialogObject: {},
      assignedToUserGroup: "",
      assigneeUserList: [],
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      showAssigneeSection: false,
      errorExpendDialog: "35%",
      dialogWidth: "35%",
      dialogCols: "col-12",
      errorList: [],
      showSpinner: true,
      rulesForNotRequired: [],
      rulesForRequired: [(v) => !!v || "Input is required"],
      assignedToUserGroup: "",
    };
  },
  methods: {
    async openAssigneeSection() {
      this.showHistoryButton = false;
      this.showHistoryDialog = false;
      this.showValidationPopup = false;
      this.valueValidationErrorList = [];
      this.errorList = [];
      var isFormValid = await this.validateFields();
      if (isFormValid) {
        this.loadAssigneeUserList(
          this.fields.field_name["assignee_user_group"],
          this.fields.field_name
        );
      } else {
        this.showValidationPopup = true;
        this.showSpotlightSection = false;
      }
    },
    async multiFileRemove(fileName, key) {
      this.multiFileArray = await this.removefileAttachemnts(
        this.multiFileArray,
        fileName,
        key
      );
      for (var value in this.fileAttachmentArray) {
        var file = await this.fileIndexFinder(
          this.fileAttachmentArray[value].file
        );
        if (
          file === fileName &&
          this.fileAttachmentArray[value]["key"] === key
        ) {
          let fileIndex = this.fields.field_name[key].indexOf(
            this.fileAttachmentArray[value].file
          );
          this.fileAttachmentArray.splice(value, 1);
          if (fileIndex > -1) {
            this.fields.field_name[key].splice(fileIndex, 1);
          }
        }
      }
    },
    closeAssigneeSection() {
      this.showHistoryButton = true;
      this.showAssigneeSection = false;
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if (emitObject && emitObject.errorDisplayMessage) {
        closeDialogFlag = emitObject.errorDisplayMessage;
      }
      this.showPaginationSpinner = emitObject.flag;
      this.showSpinner = emitObject.flag;
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    clickCancel() {
      this.showSpotlightSection = false;
      this.showValidationPopup = false;
      (this.dialogWidth = "35%"),
        (this.dialogCols = "col-12"),
        (this.dialog = false);
      this.$emit("closeReAssigneeUpdate", false);
    },
    clickSave() {
      this.showSpinner = true;
      this.confirmationDialogObject["display"] = true;
      this.confirmationDialogObject["action"] = "ASSIGNEEUPDATE";
      this.confirmationDialogObject["viewId"] =
        this.updateFieldDialogObject.viewId;
      this.confirmationDialogObject["fileAttachments"] = [];
      this.confirmationDialogObject["data"] = this.fields.field_name;
      this.showConfirmationDialog = true;
    },
    closeDialog(flag) {
      this.showSpinner = false;
      this.showConfirmationDialog = false;
      this.$emit("closeReAssigneeUpdate", flag);
    },
    closeErrorValidationDialog(flag) {
      this.showValidationPopup = flag;
      this.valueValidationErrorList = [];
      this.$emit("closeErrorValidationDialog", false);
    },
    async loadAssigneeUserList(value, full_definiton) {
      this.dialogWidth = "35%";
      this.showSpinner = true;
      this.showHistoryButton = false;
      await axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/getUserList",
        data: {
          applicationId: this.$route.query.applicationId,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
          userGroup: value,
          definition: full_definiton,
        },
        headers: {
          "content-type": "application/json",
        },
      })
        .then((apiResponse) => {
          this.showSpinner = false;
          this.assigneeUserList = apiResponse.data.data;
          if (this.assigneeUserList && this.assigneeUserList.length == 1) {
            this.fields.field_name["assignee"] = this.assigneeUserList[0];
            this.assigneeDisabled = true;
          } else if (
            this.fields.field_name["assignee"] &&
            this.fields.field_name["assignee"] != ""
          ) {
            if (
              typeof this.fields.field_name["assignee"] === "string" ||
              this.fields.field_name["assignee"] instanceof String
            ) {
              this.fields.field_name["assignee"] = this.assigneeUserList.find(
                (assigneeObject) => {
                  return (
                    assigneeObject.email == this.fields.field_name["assignee"]
                  );
                }
              );
            }
          }
          this.showSpinner = false;
          this.showAssigneeSection = true;
        })
        .catch((apiError) => {
          if (
            apiError.response &&
            apiError.response.status &&
            apiError.response.status == 401
          ) {
            this.$root.$emit("update_session_value", true);
          } else {
            this.errorMessageShow = true;
            if (
              apiError.response &&
              apiError.response.data &&
              apiError.response.data.errorObject
            ) {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: apiError.response.data.errorObject.error_code,
                error_uuid: apiError.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
          this.showSpinner = false;
          this.showAssigneeSection = true;
        });
    },
  },
  mounted() {
    this.showSpinner = false;
    this.fields.field_name = this.updateFieldDialogObject.fields;
    this.assignedToUserGroup = this.fields.field_name.assignee_user_group;
    this.loadAssigneeUserList(
      this.fields.field_name["assignee_user_group"],
      this.fields.field_name
    );
  },
};
</script>

<style scoped>
.scroll-2::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.scroll-2::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0;
  background-color: rgb(42, 64, 154);
}
.dateTime {
  float: left;
  margin: 9px;
  padding: 8px;
}

.hideScroll::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.hideScroll::-webkit-scrollbar-track {
  border-radius: 0px;
}

/* Handle */
.hideScroll::-webkit-scrollbar-thumb {
  background: rgb(42, 64, 154);
  border-radius: 0px;
}

/* Handle on hover */
.hideScroll::-webkit-scrollbar-thumb:hover {
  background: rgb(42, 64, 154);
}
</style>