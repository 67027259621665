<template>
  <div v-if="showSpinner">
    <SpinnerComponent></SpinnerComponent>
  </div>
  <div v-else>
    <v-overlay :value="showPaginationSpinner">
      <SpinnerComponent></SpinnerComponent>
    </v-overlay>
    <v-data-table
      height="calc(90vh - 180px)"
      :headers="headers"
      :items="dataItems"
      class="elevation-2 customStyle rounded-lg"
      hide-default-footer
      disable-pagination
      fixed-header
      multi-sort
    >
      <template v-slot:[`item.actions`]="{ item }">
        <div style="display:flex; flex-wrap:no-wrap; flex-direction: row-reverse; width:120px;  justify-content:space-evenly;">
          <div v-if="showDeleteButton" @click="clickDelete(item)" style="width:45px;  padding:5px;">
            <img style=" width:100%;" src="../assets/Delete.svg" alt=""/>
          </div>
          <div @click="clickView(item)" style="width:45px;  padding:5px;">
            <img style=" width:100%;" src="../assets/View.svg" alt="" />
          </div>
          <div v-if="showEditButton" @click="clickUpdate(item)" style="width:45px;  padding:5px;">
            <img style=" width:100%;" src="../assets/Edit.svg" alt="" />
          </div>
        </div>
      </template>
      <template v-slot:[`item.created_by`]="{ item }">
        <div v-if="item.created_by">
            {{item.createdByName}}
        </div>
      </template>
      <template v-slot:[`item.CreatedAt`]="{ item }">
        <div v-if="item.CreatedAt">
          {{moment(new Date(item.CreatedAt)).format("DD/MM/YYYY")}}
        </div>
      </template>
      <template v-slot:[`item.updated_by`]="{ item }">
        <div v-if="item.updated_by">
            {{item.updatedByName}}
        </div>
      </template>
      <template v-slot:[`item.UpdatedAt`]="{ item }">
        <div v-if="item.UpdatedAt">
          {{moment(new Date(item.UpdatedAt)).format("DD/MM/YYYY")}}
        </div>
      </template>
      <template v-slot:footer>
        <div style="text-align: right" class="pt-5" v-if="showLoadMore">
            <v-btn
              depressed
              class="rounded-lg"
              color="#2A409A"
              style="color: white; width: 20%"
              @click="getMoreData()"
              >{{$t("Load More")}}
            </v-btn>
          </div>
        </template>
    </v-data-table>
    <div v-if="showSidePanel">
      <DetailsComponent
        :isDownload = "isDownload"
        :screenDefinition="screenDefinition"
        :itemDetails="itemDetails"
        :viewId="viewId"
        @closeSidePanel="closeSidePanel"
        @closeDetailsComponent="closeDetailsComponent"
      >
      </DetailsComponent>
    </div>
    <div v-if="showConfirmationDialog">
      <ConfirmationDialog
        :confirmationDialogObject="confirmationDialogObject"
        @closeDialog="closeDialog"
      ></ConfirmationDialog>
    </div>
    <div v-if="showUpdateDialog">
      <UpdateFieldDialog
        :fieldDetails="fieldArray"
        :fieldUpdateItem="fieldUpdateItem"
        :updateFieldDialogObject="updateFieldDialogObject"
        :screenDefinition="screenDefinition"
        @closeUpdateFieldDialog="closeUpdateFieldDialog"
      ></UpdateFieldDialog>
    </div>
  </div>
</template>


<script>
import moment from 'moment';
import ConfirmationDialog from "./ConfirmationDialog.vue";
import SpinnerComponent from "./SpinnerComponent.vue";
import UpdateFieldDialog from "./UpdateFieldDialog.vue";
import DetailsComponent from "./DetailsComponent.vue";
export default {
  props: ["screenDefinition", "viewId"],
  components: {
    ConfirmationDialog,
    SpinnerComponent,
    UpdateFieldDialog,
    DetailsComponent
  },
  data() {
    return {
      fieldArray: [],
      headers: [],
      dataItems: [],
      showLoadMore: false,
      showEditButton: false,
      showDeleteButton: false,
      showConfirmationDialog: false,
      showPaginationSpinner: false,
      showSpinner: true,
      showUpdateDialog: false,
      fieldUpdateItem: "",
      updateFieldDialogObject: {},
      confirmationDialogObject: {},
      showSidePanel:false,
      isDownload: false
    };
  },
  methods: {
    clickView(item){
      this.showSidePanel=true;
      this.itemDetails = item;
    },
    closeSidePanel(data) {
      this.showSidePanel = data;
    },
    closeDetailsComponent(flag) {
      this.showSidePanel = flag;
    },
    clickUpdate(item) {
      this.updateFieldDialogObject["display"] = true;
      this.updateFieldDialogObject["viewId"] = this.$props.viewId;
      this.updateFieldDialogObject["viewType"] = "master";
      this.fieldUpdateItem = item;
      this.fieldUpdateItem['workflow_id'] = 'defaultWorkflow';
      this.fieldUpdateItem['current_status'] = 'defaultStage';
      this.showUpdateDialog = true;
    },
    clickDelete(item) {
      this.showConfirmationDialog = true;
      this.confirmationDialogObject["data"] = item;
      this.confirmationDialogObject["action"] = "DELETE";
      this.confirmationDialogObject["viewId"] = this.$props.viewId;
      this.confirmationDialogObject["display"] = true;
    },
    closeUpdateFieldDialog(flag) {
      this.showUpdateDialog = flag;
      this.$emit("refreshTable", true);
    },
    closeDialog(flag) {
      this.showConfirmationDialog = false;
      this.$emit("refreshTable", true);
    },
    getMoreData() {
      this.showPaginationSpinner = true;
      this.$emit("loadMoreList", this.$props.screenDefinition.LastEvaluatedKey);
      if (!this.$props.screenDefinition.LastEvaluatedKey) {
        this.showLoadMore = false;
      }
      // this.showPaginationSpinner = false;
    },
  },
  mounted() {
    this.showSpinner = true;
    this.fieldArray = this.$props.screenDefinition.fieldDetails.fields;
    this.dataItems = this.$props.screenDefinition.data;
    // this.dataItems.forEach((data, index) => {
    //   var CreatedAtDate = new Date(data.CreatedAt);
    //   var UpdatedAtDate = new Date(data.UpdatedAt);
    //   this.dataItems[index]["CreatedAt"] =
    //     moment(CreatedAtDate).format("DD/MM/YYYY");
    //   this.dataItems[index]["UpdatedAt"] =
    //     moment(UpdatedAtDate).format("DD/MM/YYYY");
    // });
    if (this.$props.screenDefinition.LastEvaluatedKey) {
      this.showLoadMore = true;
    }
    for (var fieldItem in this.fieldArray) {
      var fieldDetails =  this.fieldArray[fieldItem];
      if ( fieldDetails.isMainField && fieldDetails.isVisible &&(
          (
            (fieldDetails.hasOwnProperty("mainFieldViewAccess")) && 
            (fieldDetails.mainFieldViewAccess.includes(this.userGroup))
          ) || !fieldDetails.hasOwnProperty("mainFieldViewAccess")
        )
      ) {
        var headerObject = {};
        if (this.$i18n.locale === "mm") {
          headerObject["text"] = fieldDetails.display_name.mm;
        } else {
          headerObject["text"] = fieldDetails.display_name.en;
        }
        if (this.fieldArray[fieldItem].view_type === "reference") {
          headerObject["value"] = this.fieldArray[fieldItem].key.concat("_reference");
        } else {
          headerObject["value"] = this.fieldArray[fieldItem].key;
        }
        if (fieldDetails.tableProperties && fieldDetails.tableProperties.columnWidth) {
          headerObject['width'] = fieldDetails.tableProperties.columnWidth;
        }
        this.headers.push(headerObject);
        this.headers.forEach(element => {  
          if(element.value === "status_action"){ 
            element.sortable=false
          }
        });
      }
    }
    // this.headers.push({
    //   text: "CreatedAt",
    //   value: "CreatedAt",
    //   align: " d-none",
    // });
    // this.headers.push({
    //   text: "CreatedBy",
    //   value: "CreatedBy",
    //   align: " d-none",
    // });
    if (
      this.$props.screenDefinition.action.includes("update") ||
      this.$props.screenDefinition.action.includes("delete") ||
      this.$props.screenDefinition.action.includes("view")
    ) {
      this.headers.push({
        text: "Actions",
        value: "actions",
        align: "center",
        fixed: true,
        width: "10%",
        sortable: false
      });
      if (this.$props.screenDefinition.action.includes("update")) {
        this.showEditButton = true;
      }
      if (this.$props.screenDefinition.action.includes("delete")) {
        this.showDeleteButton = true;
      }
    }
    this.showSpinner = false;
  },
};
</script>

<style>
.customStyle .v-data-table-header tr,
.customStyle .v-data-table-header tr th {
  background: #FFFFFF !important;
  color: #000000 !important;
}
.customStyle .v-data-table__wrapper::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.customStyle .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0;
  background-color: rgb(42, 64, 154);
}
</style>
