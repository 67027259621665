var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showSpinner)?_c('div',[_c('SpinnerComponent')],1):_c('div',[_c('v-overlay',{attrs:{"value":_vm.showPaginationSpinner}},[_c('SpinnerComponent')],1),_c('v-data-table',{staticClass:"elevation-2 customStyle rounded-lg",attrs:{"height":"calc(90vh - 180px)","headers":_vm.headers,"items":_vm.dataItems,"hide-default-footer":"","disable-pagination":"","fixed-header":"","multi-sort":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","flex-wrap":"no-wrap","flex-direction":"row-reverse","width":"120px","justify-content":"space-evenly"}},[(_vm.showDeleteButton)?_c('div',{staticStyle:{"width":"45px","padding":"5px"},on:{"click":function($event){return _vm.clickDelete(item)}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/Delete.svg"),"alt":""}})]):_vm._e(),_c('div',{staticStyle:{"width":"45px","padding":"5px"},on:{"click":function($event){return _vm.clickView(item)}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/View.svg"),"alt":""}})]),(_vm.showEditButton)?_c('div',{staticStyle:{"width":"45px","padding":"5px"},on:{"click":function($event){return _vm.clickUpdate(item)}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/Edit.svg"),"alt":""}})]):_vm._e()])]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [(item.created_by)?_c('div',[_vm._v(" "+_vm._s(item.createdByName)+" ")]):_vm._e()]}},{key:"item.CreatedAt",fn:function(ref){
var item = ref.item;
return [(item.CreatedAt)?_c('div',[_vm._v(" "+_vm._s(_vm.moment(new Date(item.CreatedAt)).format("DD/MM/YYYY"))+" ")]):_vm._e()]}},{key:"item.updated_by",fn:function(ref){
var item = ref.item;
return [(item.updated_by)?_c('div',[_vm._v(" "+_vm._s(item.updatedByName)+" ")]):_vm._e()]}},{key:"item.UpdatedAt",fn:function(ref){
var item = ref.item;
return [(item.UpdatedAt)?_c('div',[_vm._v(" "+_vm._s(_vm.moment(new Date(item.UpdatedAt)).format("DD/MM/YYYY"))+" ")]):_vm._e()]}},{key:"footer",fn:function(){return [(_vm.showLoadMore)?_c('div',{staticClass:"pt-5",staticStyle:{"text-align":"right"}},[_c('v-btn',{staticClass:"rounded-lg",staticStyle:{"color":"white","width":"20%"},attrs:{"depressed":"","color":"#2A409A"},on:{"click":function($event){return _vm.getMoreData()}}},[_vm._v(_vm._s(_vm.$t("Load More"))+" ")])],1):_vm._e()]},proxy:true}],null,true)}),(_vm.showSidePanel)?_c('div',[_c('DetailsComponent',{attrs:{"isDownload":_vm.isDownload,"screenDefinition":_vm.screenDefinition,"itemDetails":_vm.itemDetails,"viewId":_vm.viewId},on:{"closeSidePanel":_vm.closeSidePanel,"closeDetailsComponent":_vm.closeDetailsComponent}})],1):_vm._e(),(_vm.showConfirmationDialog)?_c('div',[_c('ConfirmationDialog',{attrs:{"confirmationDialogObject":_vm.confirmationDialogObject},on:{"closeDialog":_vm.closeDialog}})],1):_vm._e(),(_vm.showUpdateDialog)?_c('div',[_c('UpdateFieldDialog',{attrs:{"fieldDetails":_vm.fieldArray,"fieldUpdateItem":_vm.fieldUpdateItem,"updateFieldDialogObject":_vm.updateFieldDialogObject,"screenDefinition":_vm.screenDefinition},on:{"closeUpdateFieldDialog":_vm.closeUpdateFieldDialog}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }