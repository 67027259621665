<template>
  <v-dialog
    class="dialogAddNew"
    persistent
    v-model="dialog"
    :width="dialogWidth"
    content-class="dialogAddNewContent"
  >
    <div
      class="dialogWrapper flex-grow-0 pa-0"
      :class="showValidationPopup ? errorExpendDialog : dialogCols"
      :style="{ width: (showValidationPopup || isTableError) ? '58%' : '100%', overflow: 'visible' }"
    >
      <v-card :class="{ hideRightBorderRadius: showSpotlightSection }">
        <v-card-title
          style="
            background-color: #ffffff;
            color: #2a409a;
            font-size: 20px;
            font-weight: 500;
          "
          >{{ dialogObject.title }}</v-card-title
        >
        <v-divider />
        <v-card-text
          class="scroll-2"
          style="overflow-y: scroll; overflow-x: hidden" id="divSelector"
        >
          <v-slide-y-transition hide-on-leave>
            <v-container
              v-show="!showAssigneeSection || dialogObject.viewType == 'master'"
            >
              <div class="flexColumn" v-for="fieldItem in fieldDefinition">
                <div v-if="!fieldItem.isDependent && !fieldItem.hasOwnProperty('parentKey') && fieldItem.renderField">
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'label'
                    "
                    class="mb-2 pl-2 pb-1" :style="getLabelStyle(fieldItem)"
                  >
                    <div style="width: 100%; margin-bottom: 6px" align="left" class= "pl-5 pr-5">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'input'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 2px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >* </span
                        >
                        <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-text-field
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-text-field>
                      <v-btn
                        :disabled="!trimData(fields.field_name[fieldItem.key])"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'number'
                    "
                    class="input_field_div"
                  >
                    
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*
                          </span
                        >
                         <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <NumberInputComponent
                      :updateAccess = "fieldItem.isDisabled"
                      :numberConfiguration = "fieldItem.number_configuration"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "                      
                        v-model="fields.field_name[fieldItem.key]"
                      ></NumberInputComponent>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'table'"
                      class="input_field_div" @click="renderComponent(fieldItem.key)" :id="'click'+fieldItem.key"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div v-if="showTable[fieldItem.key]">
                      <TableComponentVue
                        :mode = "tableMode"
                        :key = "fields.field_name[fieldItem.key]"
                        :workFlowId = "workFlowId"
                        :roleGroup = "userRoleGroup"
                        :tableConfiguration = "fieldItem.table_configuration"
                        :addNewRow = "fieldItem.table_configuration.addNewRow"
                        v-model="fields.field_name[fieldItem.key]"
                        :showFooter=false
                      ></TableComponentVue>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'optionsWithSearch'
                    "
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left"   >
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        >
                        <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-autocomplete
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :items="fieldItem.option_values"
                        attach
                        :disabled="fieldItem.isDisabled"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-text-field
                                outlined
                                background-color="#F2F2F2"
                                :rules="
                                  fieldItem.validators &&
                                  fieldItem.validators.required === true
                                    ? rulesForRequired
                                    : rulesForNotRequired
                                "
                                v-model="searchTerm"
                                placeholder="Search"
                              ></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-autocomplete>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'workflowoptions'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        >
                        <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-autocomplete
                        style="border-radius: 10px"
                        outlined
                        background-color="#F2F2F2"
                        :items="fieldItem.option_values"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :disabled="disableSelect || fieldItem.isDisabled"
                        item-value="value"
                        item-text="value"
                        v-model="fields.field_name[fieldItem.key]"
                        @change="
                          selectWorkflow(
                            $event,
                            fieldItem.option_values,
                            fieldItem
                          )
                        "
                      ></v-autocomplete>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'priority'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >
                        <div style='display: flex; flex-direction: row;'>
                          <div>{{ fieldItem.display_name[$i18n.locale] }}</div>
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span
                            ><HelpToolTip
                              v-if="fieldItem.helpTooltipText"
                              :ToolMessage="fieldItem.helpTooltipText"
                            ></HelpToolTip
                          ></span>
                          <img v-if="fieldItem.clearable" src="../assets/close.png" style=" cursor: pointer; width: 25px; height: 90%;" @click="clearInputData(fieldItem.key)"></img>
                        </div>
                      </label>
                    </div>
                    <div
                      v-if="fieldItem.view_type === 'reference'"
                      style="width: 100%; "
                      align="right"
                    >
                      <v-radio-group style="margin-top: 8px;" v-model="fields.field_name[fieldItem.key]" :disabled="fieldItem.isDisabled">
                        <v-radio
                          v-for="radioItem in fieldItem.option_values"
                          :key="radioItem.key"
                          :label="radioItem.displayText"
                          :value="radioItem.key"
                          :disabled="radioItem.isDisabled"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          @change="
                          optionsChangingFn(
                            $event,
                            fieldItem.option_values,
                            fieldItem
                          )
                        "
                          
                        ></v-radio>
                      </v-radio-group>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                    <div v-else style="width: 100%" align="right">
                      <v-radio-group style="margin-top: 8px;" v-model="fields.field_name[fieldItem.key]" :disabled="fieldItem.isDisabled">
                        <v-radio
                          v-for="radioItem in fieldItem.option_values"
                          :key="radioItem.key"
                          :label="radioItem.displayText"
                          :value="radioItem.key"
                          :disabled="radioItem.isDisabled"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          @change="
                          optionsChangingFn(
                            $event,
                            fieldItem.option_values,
                            fieldItem
                          )
                        "
                        ></v-radio>
                      </v-radio-group>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'apiOptions'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-autocomplete
                        style="border-radius: 10px"
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :items="apiOptionValues"
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-autocomplete>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'ConditionalOptions'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-autocomplete
                        style="border-radius: 10px"
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :items="fieldItem.option_values"
                        v-model="fields.field_name[fieldItem.key]"
                        @change="
                          conditionalOptionChange(
                            fields.field_name[fieldItem.key],
                            fieldItem
                          )
                        "
                        :disabled="fieldItem.isDisabled"
                      ></v-autocomplete>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'options'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px;" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div
                      v-if="fieldItem.view_type === 'reference'"
                      style="width: 100%"
                      align="right"
                    >
                      <div class="pb-7">
                        <v-autocomplete
                          style="border-radius: 10px;"
                          outlined
                          background-color="#F2F2F2"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          :items="fieldItem.option_values"
                          item-value="key"
                          item-text="value"
                          v-model="fields.field_name[fieldItem.key]"
                          @change="
                            optionsChangingFn(
                              $event,
                              fieldItem.option_values,
                              fieldItem
                            )
                          "
                          hide-details
                          :disabled="fieldItem.isDisabled"
                        ></v-autocomplete>
                        <v-btn
                          :disabled="!fields.field_name[fieldItem.key]"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                        <MasterViewComponent style="" v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] && showMasterViewFields" v-model="viewData"
                        :keyName="fieldItem.key" 
                        ></MasterViewComponent>
                      </div>
                    </div>
                    <div v-else style="width: 100%" align="right">
                      <v-autocomplete
                        style="border-radius: 10px;"
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :items="fieldItem.option_values"
                        v-model="fields.field_name[fieldItem.key]"
                        @change="
                          optionsChangingFn(
                            $event,
                            fieldItem.option_values,
                            fieldItem
                          )
                        "
                        :disabled="fieldItem.isDisabled"
                      ></v-autocomplete>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'optionsGroup'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px;" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-autocomplete
                        style="border-radius: 10px;"
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :items="fieldItem.option_values"
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-autocomplete>
                    </div>
                  </div>
                  <div
                    style="margin-bottom:15px;"
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'checklist'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%;" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%;margin-bottom:20px" align="right"> 
                        <ChecklistComponent
                          :ChecklistOptionsData = "fieldItem.option_values"
                          :addAllowBtn = "fieldItem"
                          v-model="fields.field_name[fieldItem.key]"
                        >
                       </ChecklistComponent>
                    </div>
                  </div>
                  <div
                    v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'multiselect'
                      "
                      class="input_field_div"
                    >
                    <div style="width: 100%; margin-bottom: 8px;">
                      <label>
                        {{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                            "
                          style="color: red"
                        >*</span>
                        <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%; margin-bottom:20px;">
                      <MultiselectComponent
                      :multiselectOptionsData = "fieldItem.option_values"
                      :setPreselect = true
                      v-model="fields.field_name[fieldItem.key]"
                      >
                      </MultiselectComponent>
                    </div>
                  </div>
                  <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'phonenumber'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          >
                          <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right"> 
                        <PhonenumberComponent
                          :phonenumberDetail="fieldItem"
                          v-model="fields.field_name[fieldItem.key]"
                          :disabled="fieldItem.isDisabled"
                          @additionalField="additionalField(fieldItem.key, ...arguments)"
                        >
                        </PhonenumberComponent>
                        <v-btn
                          :disabled="!trimData(fields.field_name[fieldItem.key])"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                  </div>
                  <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'alert'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          >
                          <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right"> 
                        <AlertDateTimeComponent 
                        :alertDetails="fieldItem"
                        v-model="fields.field_name[fieldItem.key]"
                        >
                        </AlertDateTimeComponent>
                      </div>
                  </div>
                  <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'datetime'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          >
                          <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right"> 
                        <DateTimeComponent 
                        :dateTimeDetails="fieldItem"
                        :min="startDate[fieldItem.key]"
                        :max="endDate[fieldItem.key]"
                        v-model="fields.field_name[fieldItem.key]"
                        :disable="fieldItem.isDisabled"
                        :tzConfig="additionalFieldInformation[fieldItem.key]"
                        >
                        </DateTimeComponent>
                      </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'hierarchicalDropdown'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-autocomplete
                        style="border-radius: 10px"
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :items="fieldItem.option_values"
                        v-bind="{ ...(fieldItem.view_type === 'reference' ? { 'itemValue':'key', 'itemText':'value' } : {})}"
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-autocomplete>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                    <MasterViewComponent  style="margin-bottom: 20px; margin-top: -15px;" v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] && showMasterViewFields" v-model="viewData"
                    :keyName="fieldItem.key" 
                    ></MasterViewComponent>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'toggle'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-switch
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-switch>
                      <v-btn
                        :disabled="
                          fields.field_name[fieldItem.key] === null ||
                          fields.field_name[fieldItem.key] === undefined
                        "
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'date'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-menu
                        v-model="dateMenu[fieldItem.key]"
                        :close-on-content-click="true"
                        :nudge-right="60"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            background-color="#F2F2F2"
                            v-model="fields.field_name[fieldItem.key]"
                            :disabled="fieldItem.isDisabled"
                            prepend-icon=""
                            append-icon="mdi-calendar"
                            :rules="
                              fieldItem.validators &&
                              fieldItem.validators.required === true
                                ? rulesForRequired
                                : rulesForNotRequired
                            "
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            persistent-hint
                          ></v-text-field>
                          <v-btn
                            :disabled="
                              !trimData(fields.field_name[fieldItem.key])
                            "
                            v-if="fieldItem.isSpotlight"
                            @click="openSpotlight(fieldItem)"
                            >{{
                              fieldItem.spotlightConfiguration.buttonText[
                                $i18n.locale
                              ]
                            }}
                          </v-btn>
                        </template>

                        <v-date-picker
                          outlined
                          background-color="#F2F2F2"
                          :min="startDate[fieldItem.key]"
                          :max="endDate[fieldItem.key]"
                          v-model="fields.field_name[fieldItem.key]"
                          @change="(newDate) => {
                            fields.field_name[fieldItem.key] = newDate;
                            dateMenu[fieldItem.key] = false;
                            dateValidation(fieldItem.key, newDate);
                          }"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type == 'nrc'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <NRCNumberComponent
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "                      
                        v-model="fields.field_name[fieldItem.key]"
                        :nrcConfig="fieldItem.nrcConfig"
                      ></NRCNumberComponent>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'multifileupload'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <MultiFileUploadComponent
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :fieldKey="fieldItem.key"
                        :workFlowId="workFlowId"
                        :viewid="viewId"
                        :fileDefinition="fieldItem"
                        @multifileCheck="multifileCheck"
                        @multiFileRemove="multiFileRemove"
                      ></MultiFileUploadComponent>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'fileupload'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <FileUploadComponent
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :fieldKey="fieldItem.key"
                        :workFlowId="workFlowId"
                        :viewid="viewId"
                        :fileDefinition="fieldItem"
                        @getBase64="getBase64"
                      ></FileUploadComponent>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'radio'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label>
                        <div style="display: flex; flex-direction: row;">
                          <div align="left">{{ fieldItem.display_name[$i18n.locale] }}</div>
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                          <img v-if="fieldItem.clearable" src="../assets/close.png" style=" cursor: pointer; width: 25px; height: 90%;" @click="clearInputData(fieldItem.key)"></img>
                        </div>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-radio-group v-model="fields.field_name[fieldItem.key]">
                        <v-radio
                          v-for="radioItem in fieldItem.option_values"
                          :key="radioItem"
                          :label="radioItem"
                          :value="radioItem"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          :disabled="fieldItem.isDisabled"
                        ></v-radio>
                      </v-radio-group>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'textarea'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-textarea
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-textarea>
                      <v-btn
                        :disabled="!trimData(fields.field_name[fieldItem.key])"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'phone'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%; margin-bottom: 16px" align="right">
                      <VuePhoneNumberInput
                        defaultCountryCode="MM"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      />
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'email'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-text-field
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForEmailRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-text-field>
                      <v-btn
                        :disabled="!trimData(fields.field_name[fieldItem.key])"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'URL' "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-text-field
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForURLRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-text-field>
                      <v-btn
                        :disabled="!trimData(fields.field_name[fieldItem.key])"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'currency'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <CurrencyComponent
                        :fileDefinition = "fieldItem"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForCurrencyRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        @addCurrencyDetails="addCurrencyDetails"
                        :isdisabled="fieldItem.isDisabled"
                      >
                      </CurrencyComponent>
                      <v-btn
                        :disabled="!trimData(fields.field_name[fieldItem.key])"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div v-else-if="fieldItem.renderField">
                  <div
                    v-if="
                      (
                        fieldItem.isDependent &&
                        fieldItem.dependentValue.includes(fields.field_name[fieldItem.dependentField])
                      ) || 
                      (
                        fieldItem.hasOwnProperty('parentKey') && fields.field_name.hasOwnProperty(fieldItem.parentKey)
                      )
                    "
                  >
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'label'
                    "
                    class="mb-2 pl-2 pb-1" :style="getLabelStyle(fieldItem)"
                  >
                    <div style="width: 100%; margin-bottom: 6px" align="left" class= "pl-5 pr-5">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'number'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 6px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <NumberInputComponent
                      :updateAccess = "fieldItem.isDisabled"
                      :numberConfiguration = "fieldItem.number_configuration"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "                      
                        v-model="fields.field_name[fieldItem.key]"
                      ></NumberInputComponent>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'table'
                    "
                    class="input_field_div" :id="'click'+fieldItem.key" @click= "renderComponent(fieldItem.key)"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div v-if="showTable[fieldItem.key]">
                      <TableComponentVue
                        :mode = "tableMode"
                        :key = "fields.field_name[fieldItem.key]"
                        :workFlowId = "workFlowId"
                        :roleGroup = "userRoleGroup"
                        :tableConfiguration = "fieldItem.table_configuration"
                        :addNewRow = "fieldItem.table_configuration.addNewRow"
                        v-model="fields.field_name[fieldItem.key]"
                        :showFooter=false
                      ></TableComponentVue>
                    </div>
                  </div>
                  <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type == 'nrc'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <NRCNumberComponent
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :nrcConfig="fieldItem.nrcConfig"
                      ></NRCNumberComponent>
                      
                    </div>
                  </div>
                    <div
                      v-if="
                        fieldItem.isVisible && fieldItem.input_type === 'input'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right">
                        <v-text-field
                          outlined
                          background-color="#F2F2F2"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          v-model="fields.field_name[fieldItem.key]"
                          :disabled="fieldItem.isDisabled"
                        ></v-text-field>
                        <v-btn
                          :disabled="
                            !trimData(fields.field_name[fieldItem.key])
                          "
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>
                    <div
                      v-if="
                        fieldItem.isVisible && fieldItem.input_type === 'date'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right">
                        <v-menu
                          v-model="dateMenu[fieldItem.key]"
                          :close-on-content-click="true"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                              background-color="#F2F2F2"
                              :rules="
                                fieldItem.validators &&
                                fieldItem.validators.required === true
                                  ? rulesForRequired
                                  : rulesForNotRequired
                              "
                              v-model="fields.field_name[fieldItem.key]"
                              :disabled="fieldItem.isDisabled"
                              prepend-icon=""
                              append-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              persistent-hint
                          ></v-text-field>
                          <v-btn
                            :disabled="!trimData(fields.field_name[fieldItem.key])"
                              v-if="fieldItem.isSpotlight"
                              @click="openSpotlight(fieldItem)"
                              >{{
                                fieldItem.spotlightConfiguration.buttonText[
                                  $i18n.locale
                                ]
                              }}
                            </v-btn>
                          </template>

                          <v-date-picker
                            outlined
                            background-color="#F2F2F2"
                            :min="startDate[fieldItem.key]"
                            :max="endDate[fieldItem.key]"
                            v-model="fields.field_name[fieldItem.key]"
                            @change="(newDate) => {
                              fields.field_name[fieldItem.key] = newDate;
                              dateMenu[fieldItem.key] = false;
                              dateValidation(fieldItem.key, newDate);
                            }"
                          >
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </div>
                    <div
                      v-if="
                        fieldItem.isVisible && fieldItem.input_type === 'priority'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >
                          <div style='display: flex; flex-direction: row;'>
                            <div>{{ fieldItem.display_name[$i18n.locale] }}</div>
                            <span
                              v-if="
                                fieldItem.validators &&
                                fieldItem.validators.required
                              "
                              style="color: red"
                              >*</span
                            ><span
                              ><HelpToolTip
                                v-if="fieldItem.helpTooltipText"
                                :ToolMessage="fieldItem.helpTooltipText"
                              ></HelpToolTip
                            ></span>
                            <img  v-if="fieldItem.clearable" src="../assets/close.png" style=" cursor: pointer; width: 25px; height: 90%;" @click="clearInputData(fieldItem.key)"></img>
                          </div>
                        </label>
                      </div>
                      <div
                        v-if="fieldItem.view_type === 'reference'"
                        style="width: 100%"
                        align="right"
                      >
                        <v-radio-group v-model="fields.field_name[fieldItem.key]" :disabled="fieldItem.isDisabled">
                          <v-radio
                            v-for="radioItem in fieldItem.option_values"
                            :key="radioItem.key"
                            :label="radioItem.displayText"
                            :value="radioItem.key"
                            :disabled="radioItem.isDisabled"
                            :rules="
                              fieldItem.validators &&
                              fieldItem.validators.required === true
                                ? rulesForRequired
                                : rulesForNotRequired
                            "
                            @change="
                            optionsChangingFn(
                              $event,
                              fieldItem.option_values,
                              fieldItem
                            )
                          "
                          ></v-radio>
                        </v-radio-group>
                        <v-btn
                          :disabled="!fields.field_name[fieldItem.key]"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                      <div v-else style="width: 100%" align="right">
                        <v-radio-group v-model="fields.field_name[fieldItem.key]" :disabled="fieldItem.isDisabled">
                          <v-radio
                            v-for="radioItem in fieldItem.option_values"
                            :key="radioItem.key"
                            :label="radioItem.displayText"
                            :value="radioItem.key"
                            :disabled="radioItem.isDisabled"
                            :rules="
                              fieldItem.validators &&
                              fieldItem.validators.required === true
                                ? rulesForRequired
                                : rulesForNotRequired
                            "
                            @change="
                            optionsChangingFn(
                              $event,
                              fieldItem.option_values,
                              fieldItem
                            )
                          "
                          ></v-radio>
                        </v-radio-group>
                        <v-btn
                          :disabled="!fields.field_name[fieldItem.key]"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>
                    <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'apiOptions'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right">
                        <v-autocomplete
                          style="border-radius: 10px"
                          outlined
                          background-color="#F2F2F2"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          :items="apiOptionValues"
                          v-model="fields.field_name[fieldItem.key]"
                          :disabled="fieldItem.isDisabled"
                        ></v-autocomplete>
                        <v-btn
                          :disabled="!fields.field_name[fieldItem.key]"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>
                    <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'options'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div
                        v-if="fieldItem.view_type === 'reference'"
                        style="width: 100%"
                        align="right"
                      >
                        <div class="pb-7">
                          <v-autocomplete
                            style="border-radius: 10px"
                            outlined
                            background-color="#F2F2F2"
                            :rules="
                              fieldItem.validators &&
                              fieldItem.validators.required === true
                                ? rulesForRequired
                                : rulesForNotRequired
                            "
                            :items="fieldItem.option_values"
                            item-value="key"
                            item-text="value"
                            v-model="fields.field_name[fieldItem.key]"
                            @change="
                              optionsChangingFn(
                                $event,
                                fieldItem.option_values,
                                fieldItem
                              )
                            "
                            hide-details
                            :disabled="fieldItem.isDisabled"
                          ></v-autocomplete>
                          <v-btn
                            :disabled="!fields.field_name[fieldItem.key]"
                            v-if="fieldItem.isSpotlight"
                            @click="openSpotlight(fieldItem)"
                            >{{
                              fieldItem.spotlightConfiguration.buttonText[
                                $i18n.locale
                              ]
                            }}
                          </v-btn>
                          <MasterViewComponent  style="" v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] && showMasterViewFields" v-model="viewData"
                          :keyName="fieldItem.key" 
                          ></MasterViewComponent>
                        </div>
                      </div>
                      <div v-else style="width: 100%" align="right">
                        <v-autocomplete
                          style="border-radius: 10px"
                          outlined
                          background-color="#F2F2F2"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          :items="fieldItem.option_values"
                          v-model="fields.field_name[fieldItem.key]"
                          @change="
                            optionsChangingFn(
                              $event,
                              fieldItem.option_values,
                              fieldItem
                            )
                          "
                          :disabled="fieldItem.isDisabled"
                        ></v-autocomplete>
                        <v-btn
                          :disabled="!fields.field_name[fieldItem.key]"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>
                    <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'optionsGroup' 
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px;" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }} 
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-autocomplete
                        style="border-radius: 10px;"
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :items="fieldItem.option_values"
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-autocomplete>
                    </div>
                  </div>
                    <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'checklist'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%;margin-bottom:20px" align="right"> 
                        <ChecklistComponent
                          :ChecklistOptionsData = "fieldItem.option_values"
                          :addAllowBtn = "fieldItem"
                          v-model="fields.field_name[fieldItem.key]"
                        >
                        </ChecklistComponent>
                      </div>
                    </div>
                  <div
                    v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'multiselect'
                      "
                    class="input_field_div"
                    >
                    <div style="width: 100%; margin-bottom: 8px;">
                      <label>
                        {{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                        >*</span>
                        <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%; margin-bottom:20px;">
                      <MultiselectComponent
                      :multiselectOptionsData = "fieldItem.option_values"
                      v-model="fields.field_name[fieldItem.key]"
                      :setPreselect = true
                      >
                      </MultiselectComponent>
                    </div>
                  </div>

                    <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'phonenumber'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          >
                          <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right"> 
                        <PhonenumberComponent
                          :phonenumberDetail= "fieldItem"
                          v-model="fields.field_name[fieldItem.key]"
                          :disabled="fieldItem.isDisabled"
                          @additionalField="additionalField(fieldItem.key, ...arguments)" 
                        >
                        </PhonenumberComponent>
                        <v-btn
                          :disabled="!trimData(fields.field_name[fieldItem.key])"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>
                    <div
                      v-if="
                        fieldItem.isVisible && fieldItem.input_type === 'hierarchicalDropdown'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                          >*</span><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right">
                        <v-autocomplete
                          style="border-radius: 10px"
                          outlined
                          background-color="#F2F2F2"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          :items="fieldItem.option_values"
                          v-bind="{ ...(fieldItem.view_type === 'reference' ? { 'itemValue':'key', 'itemText':'value' } : {})}"
                          v-model="fields.field_name[fieldItem.key]"
                          :disabled="fieldItem.isDisabled"
                        ></v-autocomplete>
                        <v-btn
                          :disabled="!fields.field_name[fieldItem.key]"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                      <MasterViewComponent  style="margin-bottom: 20px; margin-top: -15px;" v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] && showMasterViewFields" v-model="viewData"
                        :keyName="fieldItem.key" 
                      ></MasterViewComponent>
                    </div>
                    <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'ConditionalOptions'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right">
                        <v-autocomplete
                          outlined
                          background-color="#F2F2F2"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          :items="fieldItem.option_values"
                          v-model="fields.field_name[fieldItem.key]"
                          @change="
                            conditionalOptionChange(
                              fields.field_name[fieldItem.key],
                              fieldItem
                            )
                          "
                          :disabled="fieldItem.isDisabled"
                        ></v-autocomplete>
                        <v-btn
                          :disabled="!fields.field_name[fieldItem.key]"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>
                    <div
                      v-if="
                        fieldItem.isVisible && fieldItem.input_type === 'toggle'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right">
                        <v-switch
                          v-model="fields.field_name[fieldItem.key]"
                          :disabled="fieldItem.isDisabled"
                        ></v-switch>
                        <v-btn
                          :disabled="!fields.field_name[fieldItem.key]"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>
                    <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'fileupload'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right">
                        <FileUploadComponent
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          :fieldKey="fieldItem.key"
                          :workFlowId="workFlowId"
                          :viewid="viewId"
                          :fileDefinition="fieldItem"
                          @getBase64="getBase64"
                        ></FileUploadComponent>
                        <v-btn
                          :disabled="!fields.field_name[fieldItem.key]"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>
                    <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type === 'multifileupload'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <MultiFileUploadComponent
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        :fieldKey="fieldItem.key"
                        :workFlowId="workFlowId"
                        :viewid="viewId"
                        :fileDefinition="fieldItem"
                        @multifileCheck="multifileCheck"
                        @multiFileRemove="multiFileRemove"
                      ></MultiFileUploadComponent>
                      <v-btn
                        :disabled="!fields.field_name[fieldItem.key]"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                    <div
                      v-if="
                        fieldItem.isVisible && fieldItem.input_type === 'radio'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >
                          <div style="display: flex; flex-direction: row;">
                            <div align="left">{{ fieldItem.display_name[$i18n.locale] }}</div>
                            <span
                              v-if="
                                fieldItem.validators &&
                                fieldItem.validators.required
                              "
                              style="color: red"
                              >*</span
                            ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                            <img v-if="fieldItem.clearable" src="../assets/close.png" style=" cursor: pointer; width: 25px; height: 90%;" @click="clearInputData(fieldItem.key)"></img>
                          </div>                        
                        </label>
                      </div>
                      <div style="width: 100%" align="right">
                        <v-radio-group
                          v-model="fields.field_name[fieldItem.key]"
                        >
                          <v-radio
                            v-for="radioItem in fieldItem.option_values"
                            :key="radioItem"
                            :label="radioItem"
                            :value="radioItem"
                            :disabled="fieldItem.isDisabled"
                          ></v-radio>
                        </v-radio-group>
                        <v-btn
                          :disabled="!fields.field_name[fieldItem.key]"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>
                    <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'textarea'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right">
                        <v-textarea
                          outlined
                          background-color="#F2F2F2"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          v-model="fields.field_name[fieldItem.key]"
                          :disabled="fieldItem.isDisabled"
                        ></v-textarea>
                        <v-btn
                          :disabled="
                            !trimData(fields.field_name[fieldItem.key])
                          "
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>

                    <div
                      v-if="
                        fieldItem.isVisible && fieldItem.input_type === 'phone'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div
                        style="width: 100%; margin-bottom: 16px"
                        align="right"
                      >
                        <VuePhoneNumberInput
                          defaultCountryCode="MM"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForRequired
                              : rulesForNotRequired
                          "
                          v-model="fields.field_name[fieldItem.key]"
                          :disabled="fieldItem.isDisabled"
                        />
                        <v-btn
                          :disabled="!fields.field_name[fieldItem.key]"
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>
                    <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'alert'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          >
                          <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right"> 
                        <AlertDateTimeComponent 
                        :alertDetails="fieldItem"
                        v-model="fields.field_name[fieldItem.key]"
                        >
                        </AlertDateTimeComponent>
                      </div>
                    </div>
                    <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'datetime'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          >
                          <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right"> 
                        <DateTimeComponent 
                        :dateTimeDetails="fieldItem"
                        :min="startDate[fieldItem.key]"
                        :max="endDate[fieldItem.key]"
                        v-model="fields.field_name[fieldItem.key]"
                        :disable="fieldItem.isDisabled"
                        :tzConfig="additionalFieldInformation[fieldItem.key]"
                        >
                        </DateTimeComponent>
                      </div>
                  </div>
                    <div
                      v-if="
                        fieldItem.isVisible && fieldItem.input_type === 'email'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right">
                        <v-text-field
                          outlined
                          background-color="#F2F2F2"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForEmailRequired
                              : rulesForNotRequired
                          "
                          v-model="fields.field_name[fieldItem.key]"
                          :disabled="fieldItem.isDisabled"
                        ></v-text-field>
                        <v-btn
                          :disabled="
                            !trimData(fields.field_name[fieldItem.key])
                          "
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>
                    <div
                    v-if="
                      fieldItem.isVisible && fieldItem.input_type === 'URL' "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <v-text-field
                        outlined
                        background-color="#F2F2F2"
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForURLRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                        :disabled="fieldItem.isDisabled"
                      ></v-text-field>
                      <v-btn
                        :disabled="!trimData(fields.field_name[fieldItem.key])"
                        v-if="fieldItem.isSpotlight"
                        @click="openSpotlight(fieldItem)"
                        >{{
                          fieldItem.spotlightConfiguration.buttonText[
                            $i18n.locale
                          ]
                        }}
                      </v-btn>
                    </div>
                  </div>
                    <div
                      v-if="
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'currency'
                      "
                      class="input_field_div"
                    >
                      <div style="width: 100%; margin-bottom: 8px" align="left">
                        <label
                          >{{ fieldItem.display_name[$i18n.locale] }}
                          <span
                            v-if="
                              fieldItem.validators &&
                              fieldItem.validators.required
                            "
                            style="color: red"
                            >*</span
                          ><span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                        </label>
                      </div>
                      <div style="width: 100%" align="right">
                        <CurrencyComponent
                          :fileDefinition = "fieldItem"
                          :rules="
                            fieldItem.validators &&
                            fieldItem.validators.required === true
                              ? rulesForCurrencyRequired
                              : rulesForNotRequired
                          "
                          v-model="fields.field_name[fieldItem.key]"
                          @addCurrencyDetails="addCurrencyDetails"
                          :isdisabled="fieldItem.isDisabled"
                        >
                        </CurrencyComponent>
                        <v-btn
                          :disabled="
                            !trimData(fields.field_name[fieldItem.key])
                          "
                          v-if="fieldItem.isSpotlight"
                          @click="openSpotlight(fieldItem)"
                          >{{
                            fieldItem.spotlightConfiguration.buttonText[
                              $i18n.locale
                            ]
                          }}
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>                
              </div>
              <div class="flexColumn" v-for="fieldItem in fieldDefinition">
                <div
                  v-if="
                    fieldItem.isVisible &&
                    fieldItem.input_type === 'AssigneeUseroptions'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >{{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators && fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-autocomplete
                      style="border-radius: 10px"
                      outlined
                      background-color="#F2F2F2"
                      :rules="
                        fieldItem.validators &&
                        fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      :items="assigneeUserGroupOptions"
                      v-model="fields.field_name[fieldItem.key]"
                      @change="loadAssigneeUserList"
                      :disabled="
                        assigneeUserGroupOptionsDisabled || fieldItem.isDisabled
                      "
                    ></v-autocomplete>
                  </div>
                </div>
              </div>              
              <!-- <div>
                <v-btn
                  class="btn_disable_assign"
                  outlined
                  style="
                   
                  "
                  :disabled="disabledBtn"
                  v-if="dialogObject.viewType != 'master'"
                  @click="openAssigneeSection()"
                >
                  Assign
                </v-btn>
              </div> -->
            </v-container>
          </v-slide-y-transition>

          <v-slide-y-reverse-transition hide-on-leave>
            <div
              v-show="showAssigneeSection || dialogObject.viewType == 'master'"
            >
              <div class="flexColumn" v-for="fieldItem in fieldDefinition">
                <div
                  v-if="
                    fieldItem.isVisible &&
                    fieldItem.input_type === 'AssigneeUseroptions'
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >{{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators && fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-autocomplete
                      style="border-radius: 10px"
                      outlined
                      background-color="#F2F2F2"
                      :rules="
                        fieldItem.validators &&
                        fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      :items="assigneeUserGroupOptions"
                      v-model="fields.field_name[fieldItem.key]"
                      @change="loadAssigneeUserList"
                      :disabled="
                        assigneeUserGroupOptionsDisabled || fieldItem.isDisabled
                      "
                    ></v-autocomplete>
                    <v-btn
                      :disabled="!fields.field_name[fieldItem.key]"
                      v-if="fieldItem.isSpotlight"
                      @click="openSpotlight(fieldItem)"
                      >{{
                        fieldItem.spotlightConfiguration.buttonText[
                          $i18n.locale
                        ]
                      }}
                    </v-btn>
                  </div>
                </div>
                <div
                  v-if="
                    fieldItem.isVisible &&
                    fieldItem.input_type === 'assigneeOptions' &&
                    showAssigneeField
                  "
                  class="input_field_div"
                >
                  <div style="width: 100%; margin-bottom: 8px" align="left">
                    <label
                      >{{ fieldItem.display_name[$i18n.locale] }}
                      <span
                        v-if="
                          fieldItem.validators && fieldItem.validators.required
                        "
                        style="color: red"
                        >*</span
                      > <span><HelpToolTip v-if="fieldItem.helpTooltipText" :ToolMessage="fieldItem.helpTooltipText"></HelpToolTip></span>
                    </label>
                  </div>
                  <div style="width: 100%" align="right">
                    <v-autocomplete
                      style="border-radius: 10px"
                      outlined
                      background-color="#F2F2F2"
                      :items="assigneeUserList"
                      item-text="name"
                      item-value="email"
                      :rules="
                        fieldItem.validators &&
                        fieldItem.validators.required === true
                          ? rulesForRequired
                          : rulesForNotRequired
                      "
                      v-model="fields.field_name[fieldItem.key]"
                      :disabled="
                        assigneeDisabled ||
                        fieldItem.isDisabled 
                      "
                    >
                      <template
                        slot="item"
                        slot-scope="data"
                        style="width: 100%"
                      >
                        <div
                          class="d-flex flex-row"
                          style="height: 70px; padding: 5px; width: 100%"
                        >
                          <div
                            style="
                              width: 40px;
                              height: 40px;
                              border-radius: 50%;
                              background-color: rgb(42, 64, 154);
                              color: white;
                            "
                          >
                            <p
                              style="
                                font-weight: 500;
                                font-size: 1.2rem;
                                padding-top: 5px;
                                text-align: center;
                              "
                            >
                              {{ data.item.name.charAt(0) }}
                            </p>
                          </div>
                          <div
                            class="d-flex flex-column"
                            style="
                              justify-content: space-around;
                              padding-left: 10px;
                            "
                          >
                            <div class="font-weight-medium">
                              {{ data.item.name }}
                            </div>
                            <div class="font-weight-light">
                              {{ data.item.email }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-autocomplete>
                    <v-btn
                      :disabled="!fields.field_name[fieldItem.key]"
                      v-if="fieldItem.isSpotlight"
                      @click="openSpotlight(fieldItem)"
                      >{{
                        fieldItem.spotlightConfiguration.buttonText[
                          $i18n.locale
                        ]
                      }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-slide-y-reverse-transition>

          <div v-if="showConfirmationDialog">
            <ConfirmationDialog
              :confirmationDialogObject="confirmationDialogObject"
              @closeDialog="closeDialog"
            />
          </div>
          <div v-if="showErrorValidationDialog">
            <ErrorValidationDialog
              :displayErrorValidationDialog="showErrorValidationDialog"
              :errorValidationDialogObject="errorValidationDialogObject"
              @closeErrorValidationDialog="closeErrorValidationDialog"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions style="display: flex; justify-content: right">
          <div v-if="showAssigneeSection">
            <v-btn
              outlined
              style="
                background-color: rgb(42, 64, 154);
                color: #ffffff;
                width: 140px;
              "
              @click="closeAssigneeSection()"
              v-if="dialogObject.viewType != 'master'"
            >
              {{ $t("Edit Fields") }}
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <div
            v-if="showCancelButton"
            style="margin-right: 30px; margin-left: 30px"
          >
            <v-btn outlined class="cancelButtonStyle" @click="clickCancel()">{{
              $t("Cancel")
            }}</v-btn>
          </div>
          <div v-if="!showAssigneeSection">
            <v-btn
              class="btn_disable_assign"
              outlined
              style="width: 100px"
              v-if="dialogObject.viewType != 'master'"
              @click="openAssigneeSection()"
            >
              {{ $t("Next") }}
            </v-btn>
          </div>
          <div
            v-if="
              (showSaveButton && showAssigneeSection) ||
              (showSaveButton && dialogObject.viewType == 'master')
            "
          >
            <v-btn
              outlined
              style="
                background-color: rgb(42, 64, 154);
                color: #ffffff;
                width: 100px;
              "
              @click="clickSave()"
              >{{ $t("ADD") }}</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </div>
    <div
      v-if="showSpotlightSection"
      class="spotlightSection flex-grow-0 flex-shrink-0 pa-0 col-7"
      style="min-width: 200px"
    >
      <SpotlightSection
        :spotlightDialogObject="spotlightDialogObject"
        @closeSpotlightDialog="closeSpotlightDialog"
      />
    </div>
    <div v-if="confirmCancel">
      <CloseDialogBox
        @closeCancelOperation="closeCancelOperation"
        @closeCancelDialogBox="closeCancelDialogBox"
      />
    </div>
    <div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>

    <div v-if="showValidationPopup"  class="spotlightSection flex-grow-0 flex-shrink-0 pa-0 col-5"
      style="min-width: 100px">
      <ValidationErrorAlert
        :errorValidationDialogObject="errorValidationDialogObject"
        @closeErrorExpend="closeErrorExpend"
      >
      </ValidationErrorAlert>
    </div>

    <v-overlay :value="showSpinner">
      <SpinnerComponent></SpinnerComponent>
    </v-overlay>
  </v-dialog>
</template>

<script>
import {getFromLocalStorage, setToLocalStorage} from "../store/localStorageInterface"
import VuePhoneNumberInput from "vue-phone-number-input";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import FileUploadComponent from "./FileUploadComponent.vue";
import ErrorValidationDialog from "./ErrorValidationDialog.vue";
import SpotlightSection from "./SpotlightSection.vue";
import CloseDialogBox from "../components/CloseDialogBox.vue";
import SpinnerComponent from "../components/SpinnerComponent.vue";
import HelpToolTip from "../components/HelpToolTip.vue";
import NRCNumberComponent from "./NRCNumberComponent.vue"
import axios from "axios";
import moment from "moment-timezone";
import MessageDialog from "./MessageDialog.vue";
import { nrcValidateMixin } from "../mixins/nrcValidation.js";
import { checkFieldHide } from "../mixins/checkFieldHide.js";
import MultiFileUploadComponent from "./MultiFileUploadComponent.vue";
import ChecklistComponent from "../components/ChecklistComponent.vue";
import NumberInputComponent from "../components/NumberInputComponent.vue";
import CurrencyComponent  from "../components/CurencyComponent.vue";
import PhonenumberComponent from "../components/PhonenumberComponent.vue"
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'
import ValidationErrorAlert from "../components/ValidationErrorAlert.vue";
import TableComponentVue from "./TableComponent.vue";
import AlertDateTimeComponent from "./AlertDateTimeComponent.vue";
import DateTimeComponent from "./DateTimeComponent.vue";
import validator from "validator";
import MasterViewComponent from "./MasterViewComponent.vue";
import { labelStyleMixin } from "../mixins/generateLabelStyle.js";
import { getComputedValue } from "../mixins/getComputedValue";
import {commonFunction} from "../mixins/commonFunction.js"
import MultiselectComponent from "./MultiselectComponent.vue";
import { checkTableConditionalValidations } from "../mixins/checkTableConditionalValidations";
export default {
  props: ["screenDefinition", "dialogObject", "workflow_id", "selectedScope"],
  components: {
    VuePhoneNumberInput,
    ConfirmationDialog,
    ErrorValidationDialog,
    FileUploadComponent,
    SpotlightSection,
    CloseDialogBox,
    SpinnerComponent,
    MessageDialog,
    NRCNumberComponent,
    MultiFileUploadComponent,
    ChecklistComponent,
    HelpToolTip,
    NumberInputComponent,
    CurrencyComponent,
    HelpToolTip,
    PhonenumberComponent,
    isPossiblePhoneNumber,
    isValidPhoneNumber,
    ValidationErrorAlert,
    TableComponentVue,
    AlertDateTimeComponent,
    MasterViewComponent,
    DateTimeComponent,
    MultiselectComponent
},
  mixins: [nrcValidateMixin, labelStyleMixin, checkFieldHide, getComputedValue , commonFunction, checkTableConditionalValidations],
  data() {
    return {
      userRoleGroup: "",
      disabledBtn: true,
      fieldDefinition: [],
      dateMenu: {},
      assigneeUserGroupOptionsDisabled: false,
      assigneeDisabled: false,
      oldData: "",
      newData: "",
      valueValidationErrorList: [],
      valueValidationErrorListInMM: [],
      tableValidationErrorList: [],
      isMMSecondarySupportLang: false,
      errorListInMM:[],
      confirmCancel: false,
      disableSelect: false,
      showConfirmationDialog: false,
      confirmationDialogObject: {},
      showErrorValidationDialog: false,
      errorValidationDialogObject: {},
      showCancelButton: false,
      showSaveButton: false,
      showAssigneeField: false,
      searchTerm: "",
      dateVal: "",
      files: [],
      dialog: false,
      fileAttachmentArray: [],
      workFlowId: "",
      apiOptionValues: [],
      assigneeUserGroupOptions: [],
      assigneeUserList: [],
      okSave: false,
      fields: {
        field_name: {},
      },
      spotlightDialogObject: {},
      menu: "",
      mobileNumber: "",
      rulesForNotRequired: [],
      rulesForRequired: [],
      rulesForCurrencyRequired: [],
      rulesForEmailRequired: [],
      rulesForURLRequired:[],
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dialogWidth: "35%",
      showSpotlightSection: false,
      dialogCols: "col-12",
      startDate: {},
      endDate: {},
      assigneeFieldItem: {},
      dateVmodel: "",
      showAssigneeSection: false,
      showSpinner: false,
      currentScope: {},
      viewId: "",
      radioKeyList: {},
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      userId: "",
      isSelfAssignOnCreate: false,
      additionalFieldInformation:{},
      errorExpendDialog:'',
      showValidationPopup:false,
      errorList:[],
      tableMode: 'ADD',
      defaultGroup:'',
      render: 1,
      masterViewAccessFields: {},
      masterDataList: {},
      displayNames: {},
      viewData: {},
      showMasterViewFields: false,
      masterDisplayNames: {},
      isConditionalAssigneeCheck: {
        status : false
      },
      isConditionalAssigneeCheckPass: false,
      showTable: {
        
      },
      alreadyRendered: {},
      isTableError : false,
      fieldType : 'ADD'
    };
  },
  watch: {
    "fields.field_name": {
      handler: function (after, before) {        
        let operands, conditionMatched;
        for (var fieldItem of this.fieldDefinition) {
          if (
            fieldItem.isDependent &&
            fieldItem.dependentField !== "issue_type" &&
            this.fields.field_name[fieldItem.dependentField] != fieldItem.dependentValue &&
            !fieldItem.dependentValue.includes(this.fields.field_name[fieldItem.dependentField])
          ) {
            delete this.fields.field_name[fieldItem.key];
          }

          if (["optionsWithSearch", "workflowoptions", "apiOptions", "ConditionalOptions", "options","checklist", "hierarchicalDropdown","priority"].includes(fieldItem.input_type)) {
            let selected_fields_children = this.fieldDefinition.filter((field)=> 
              this.fields.field_name.hasOwnProperty(field.key) && 
              field.hasOwnProperty("parentKey") && 
              field.parentKey === fieldItem.key
            )
            for(let child of selected_fields_children) {
              if(this.fields.field_name[fieldItem.key]){
                let child_value = child.options_object[this.fields.field_name[fieldItem.key]];
                if (
                  !this.fields.field_name[fieldItem.key] || !child_value || 
                  (child_value && !child_value.map(child_option=>child_option.key).includes(this.fields.field_name[child.key]))
                ) {
                  delete this.fields.field_name[child.key];
                  delete child["isDisabled"];
                }
              }
            }
          }
        else if (fieldItem.input_type === "datetime") {
          if (!this.additionalFieldInformation[fieldItem.key]){
            if (fieldItem.validators && fieldItem.validators.date 
            && fieldItem.validators.date.timezone ){
              this.additionalFieldInformation[fieldItem.key] = {
                "timezone": fieldItem.validators.date.timezone
              };
            } else {
              let localTZ = moment.tz.guess(true);
              this.additionalFieldInformation[fieldItem.key] = {
                "timezone": localTZ
              };
            }
          }
          if (
            fieldItem.validators &&
            fieldItem.validators.date) {
            if(fieldItem.validators.date.hidePastDates === true) {
              this.startDate[fieldItem.key] = Number(moment().unix());
            }
            if(fieldItem.validators.date.range) {
              if(fieldItem.validators.date.range.from){
                if(fieldItem.validators.date.range.from.type == 'absolute'){
                  let startDateEpoch = Number(fieldItem.validators.date.range.from.value);
                  this.startDate[fieldItem.key] = startDateEpoch;
                }
                else if(fieldItem.validators.date.range.from.type == 'reference'){ 
                  if(this.fields.field_name[fieldItem.validators.date.range.from.value] != null){
                    let startDateEpoch = Number(this.fields.field_name[fieldItem.validators.date.range.from.value]);
                    this.startDate[fieldItem.key] = startDateEpoch;
                  }
                  else{
                    delete this.startDate[fieldItem.key];
                  }
                }
              }
              if(fieldItem.validators.date.range.to){
                if(fieldItem.validators.date.range.to.type == 'absolute'){
                  let endDateEpoch = Number(fieldItem.validators.date.range.to.value);
                  this.endDate[fieldItem.key] = endDateEpoch;
                }
                else if(fieldItem.validators.date.range.to.type == 'reference'){
                  if(this.fields.field_name[fieldItem.validators.date.range.to.value]!= null){
                  let endDateEpoch = Number(this.fields.field_name[fieldItem.validators.date.range.to.value]);
                  this.endDate[fieldItem.key] = endDateEpoch;
                  }else {
                    delete this.endDate[fieldItem.key];
                  }
                }
              }
            }
          }
          if (!(fieldItem.key in this.fields.field_name) && 
            fieldItem.default_value && 
            fieldItem.default_value.type) {
            let datetimeValue;
            if(fieldItem.default_value.type === "today") {
              datetimeValue = Number(moment().unix());
            }
            else if(fieldItem.default_value.type === "absolute") {
              datetimeValue = Number(fieldItem.default_value.value);
            }
            if(datetimeValue && 
              !(this.startDate[fieldItem.key] && Number(this.startDate[fieldItem.key]) > datetimeValue) &&
              !(this.endDate[fieldItem.key] && Number(this.endDate[fieldItem.key]) < datetimeValue)
            ) {
                this.fields.field_name[fieldItem.key] = datetimeValue;
              }
          }
        } 
          if (
            fieldItem.key === 'current_status' &&
            fieldItem[this.workFlowId] &&
            fieldItem[this.workFlowId]['isConditionalChange'] && 
            fieldItem[this.workFlowId]['conditionalStageConfig']
          ) {
            let groupChanged = false;
            this.isConditionalAssigneeCheck=  {
              status : false
            };
            let conditionObject = fieldItem[this.workFlowId]['conditionalStageConfig'];
            for (let condition of conditionObject.conditions) {
              if (this.getValue(condition.conditionalStatement)) {
                if (condition.validators && 
                    condition.validators.assignee && 
                    condition.validators.assignee.required) {
                      this.isConditionalAssigneeCheck['status'] = true;
                      this.isConditionalAssigneeCheck['condition'] = condition;
                  }
                this.assigneeUserGroupOptions = [];
                this.assigneeUserGroupOptions = [condition.assigneeGroup];
                this.fields.field_name['assignee_user_group'] = condition.assigneeGroup;
                fieldItem.isDisabled = true;
                groupChanged = true;
                break;
              }
            }
            if(!groupChanged) {
              this.assigneeUserGroupOptions = [];
              this.assigneeUserGroupOptions = [conditionObject.defaultAssigneeGroup];
              this.fields.field_name['assignee_user_group'] = conditionObject.defaultAssigneeGroup;
            }
          }
          if (fieldItem.input_type === "assigneeOptions") {
            this.assigneeFieldItem = fieldItem;
          }
          let oldValue = this.fields.field_name[fieldItem.key];
          if (
            fieldItem.conditionalValue &&
            fieldItem.conditionalValue.conditions &&
            fieldItem.conditionalValue.conditions.length > 0
          ) {
            if (!fieldItem.conditionalValue.applyOnCreate) {
              continue;
            }
            fieldItem.isDisabled = true;
            for (var condition of fieldItem.conditionalValue.conditions) {
              operands = [];
              conditionMatched = false;
              //On condition success
              if (this.getValue(condition.conditionalStatement)) {
                if (oldValue != condition.resultValue) {
                  if (fieldItem.key == "assignee_user_group") {
                    this.assigneeUserGroupOptions = [];
                    this.assigneeUserGroupOptions = [condition.resultValue];
                    this.fields.field_name[fieldItem.key] =
                      condition.resultValue;
                  } else {
                    this.fields.field_name[fieldItem.key] =
                      condition.resultValue;
                  }
                }
                conditionMatched = true;
                break;
              }
            }
            if (!conditionMatched) {
              if (oldValue != fieldItem.defaultValue) {
                if (fieldItem.key == "assignee_user_group") {
                  this.assigneeUserGroupOptions = [];
                  this.assigneeUserGroupOptions = [fieldItem.defaultValue];
                  this.fields.field_name[fieldItem.key] =
                    fieldItem.defaultValue;
                } else {
                  this.fields.field_name[fieldItem.key] =
                    fieldItem.defaultValue;
                }
              }
            }
          }
        }
        for (var fieldItem of this.fieldDefinition) {
          if (
            !fieldItem.isDependent ||
            (fieldItem.dependentField == "issue_type" &&
              (this.fields.field_name[fieldItem.dependentField] ==
                fieldItem.dependentValue ||
                fieldItem.dependentValue.includes(
                  this.fields.field_name[fieldItem.dependentField]
                )))
          ) {
            fieldItem.renderField = true;
          } else {
            fieldItem.renderField = false;
          }
          if(
            fieldItem.hasOwnProperty('parentKey') && 
            fieldItem.hasOwnProperty('options_object')
          ) {
            if(this.fields.field_name.hasOwnProperty(fieldItem.parentKey)) {
              fieldItem.option_values = [];
              if (this.fields.field_name[fieldItem.parentKey] != "") {
                Object.entries(fieldItem.options_object).map(([key, values]) => {
                  if (this.fields.field_name[fieldItem.parentKey] === key) {
                    fieldItem['option_values'] = values
                  }
                });
                if (
                  this.currentScope && 'fieldKeys' in this.currentScope && 
                  fieldItem.key in this.currentScope.fieldKeys && 
                  !this.currentScope.fieldKeys[fieldItem.key].includes("ALL")
                ) {
                  this.filterFieldItemOptionValuesByScope(fieldItem);
                }
              } else {
                Object.entries(fieldItem.options_object).map(([key, values]) => {
                  fieldItem['option_values'].push(...values);
                })
              }
              if(fieldItem.option_values.length === 1){
                this.fields.field_name[fieldItem.key] = fieldItem.option_values[0].key;
              }
              fieldItem.renderField = true;
            } else {
              fieldItem.renderField = false;
            }
          }
          if (fieldItem.isDependent) {
            if (
              fieldItem.dependentField != "current_status" &&
              fieldItem.dependentField != "issue_type"
            ) {
              if (
                fieldItem.dependentField in this.fields.field_name &&
                fieldItem.dependentValue.includes(
                  this.fields.field_name[fieldItem.dependentField]
                ) &&
                this.fieldDefinition.find(
                  (field) => field.key == fieldItem.dependentField
                ) &&
                this.fieldDefinition.find(
                  (field) => field.key == fieldItem.dependentField
                ).renderField
              ) {
                fieldItem.renderField = true;
              } else {
                fieldItem.renderField = false;
              }
            }
          }
          if(this.fields.field_name[fieldItem.key] && 
          fieldItem.isMasterViewConfig && 
          fieldItem.view_id in this.masterDataList && 
          this.workFlowId in fieldItem.masterViewConfig && 
          'defaultStage' in fieldItem.masterViewConfig[this.workFlowId]) {
            this.masterViewAccessFields[fieldItem.key] = fieldItem.masterViewConfig[this.workFlowId]['defaultStage'][getFromLocalStorage("userGroup")];
            this.showMasterViewFields = false;
            let masterViewField = this.masterDataList[fieldItem.view_id].find((field) => field._id === this.fields.field_name[fieldItem.key]);
            this.displayNames[fieldItem.key] = this.masterDisplayNames[fieldItem.view_id];
            if(masterViewField && this.masterViewAccessFields[fieldItem.key]){
              this.$set(this.viewData, fieldItem.key, {});
              this.$set(this.viewData[fieldItem.key], 'display_name', {});
              for(let key of this.masterViewAccessFields[fieldItem.key]){
                if(masterViewField[key]){
                  this.$set(this.viewData[fieldItem.key], key, masterViewField[key]);
                  this.$set(this.viewData[fieldItem.key]['display_name'], key, this.displayNames[fieldItem.key][key]);
                }
              }
            }
            this.$nextTick(() => {
              this.showMasterViewFields = true;
            });
          } else if (fieldItem.isMasterViewConfig && !this.fields.field_name[fieldItem.key]){
            this.showMasterViewFields = false;
          }
        } 
        for (var fieldItem of this.fieldDefinition) {
          if (
            fieldItem.hasOwnProperty("defaultValue") && fieldItem.isDependent
            && (this.fields.field_name[fieldItem.dependentField] === fieldItem.dependentValue
            || fieldItem.dependentValue.includes(this.fields.field_name[fieldItem.dependentField]))
          ) 
          {
            if(!(this.currentScope && 'fieldKeys' in this.currentScope && fieldItem.key in this.currentScope.fieldKeys))
            {
               if(this.fields.field_name[fieldItem.key] === undefined) {
              if(
                fieldItem.input_type === "toggle" ||
                fieldItem.input_type === "textarea" ||
                fieldItem.input_type === "email" ||
                fieldItem.input_type === "currency" ||
                fieldItem.input_type === "input" ||
                fieldItem.input_type === "nrc" ||
                fieldItem.input_type === "radio" ||
                fieldItem.input_type === "options" ||
                fieldItem.input_type === "date" ||
                fieldItem.input_type === "phonenumber" ||
                fieldItem.input_type === "alert" ||
                fieldItem.input_type === "priority" ||
                fieldItem.input_type === "URL" ||
                fieldItem.input_type === "number"){
                if(fieldItem.input_type == 'toggle'){
                  this.fields.field_name[fieldItem.key] = new Set([true, false]).has(fieldItem.defaultValue) ? fieldItem.defaultValue : false;
                } else if(fieldItem.input_type == 'priority'){
                  let priorityOptions = new Set(fieldItem.option_values.filter(obj => obj.key).map(obj => obj.key));
                  if(priorityOptions.has(fieldItem.defaultValue)){
                    this.fields.field_name[fieldItem.key] = fieldItem.defaultValue
                  }
                } else if (fieldItem.input_type == 'currency') {
                  this.fields.field_name[fieldItem.key] = fieldItem.defaultValue.toString();
                } else {
                  this.$set(this.fields.field_name, fieldItem.key, fieldItem.defaultValue);
                }
              }
            }
            }
           else if (this.currentScope && this.currentScope.fieldKeys && 
           this.currentScope.fieldKeys[fieldItem.key] && 
           this.currentScope.fieldKeys[fieldItem.key].length == 1)  {
            this.$set(this.fields.field_name, fieldItem.key, this.currentScope.fieldKeys[fieldItem.key][0]);
           }
          }
          if (fieldItem.renderField && this.currentScope && this.currentScope.fieldKeys && fieldItem.key in this.currentScope.fieldKeys) {
            if (!(fieldItem.key in this.fields.field_name) && (this.currentScope.defaultScopeValue && fieldItem.key in this.currentScope.defaultScopeValue)) {
              if (this.currentScope.fieldKeys[fieldItem.key] == this.currentScope.defaultScopeValue[fieldItem.key] || 
              this.currentScope.fieldKeys[fieldItem.key].includes(this.currentScope.defaultScopeValue[fieldItem.key])) {
                this.fields.field_name[fieldItem.key] = this.currentScope.defaultScopeValue[fieldItem.key];
              }
            }
          }
        }
        for (var fieldItem of this.fieldDefinition) {
          if(fieldItem.isComputed && 'evaluation' in fieldItem){
            let computedValue = this.getComputedValue(fieldItem.evaluation);
            if(!Number.isNaN(parseFloat(computedValue)) && Number.isFinite(parseFloat(computedValue))){
              this.fields.field_name[fieldItem.key] = computedValue;
            }
            else{
              delete this.fields.field_name[fieldItem.key];
            }
          }
          if (fieldItem.isDependent && !fieldItem.renderField) {
            delete this.fields.field_name[fieldItem.key];
			      delete this.additionalFieldInformation[fieldItem.key];
          }
          if (fieldItem.input_type === "optionsGroup") {
            if(this.fields.field_name[fieldItem.options_parent] && (!fieldItem.isDependent || 
            (fieldItem.isDependent && fieldItem.dependentField!='current_status'))){
              if(fieldItem.option_values !== fieldItem.options_mapping[this.fields.field_name[fieldItem.options_parent]]){
                fieldItem.option_values = fieldItem.options_mapping[this.fields.field_name[fieldItem.options_parent]];
                delete this.fields.field_name[fieldItem.key];
              }
              fieldItem.renderField = true;
            } else {
              delete this.fields.field_name[fieldItem.key];
              fieldItem.renderField = false;
            }   
          }
		      if(fieldItem.isDependent && fieldItem.renderField && fieldItem.isDisabled){
            if(fieldItem.input_type === "currency"){
              if(fieldItem.currency_configuration && fieldItem.currency_configuration.defaultCurrency){
                this.additionalFieldInformation[fieldItem.key] = {
                  "currencyCode": fieldItem.currency_configuration.defaultCurrency
                }
              } else{
                this.additionalFieldInformation[fieldItem.key] = {
                  "currencyCode": "USD"
                }
              }
            }
            if(fieldItem.input_type === "phonenumber"){
              if(fieldItem.phonenumberConfiguration && fieldItem.phonenumberConfiguration.defaultCountryCode){
                this.additionalFieldInformation[fieldItem.key] = {
                  "countryCode": fieldItem.phonenumberConfiguration.defaultCountryCode,
                  "callingCode":""
                }
              } else{
                this.additionalFieldInformation[fieldItem.key] = {
                  "countryCode": "MM",
                  "callingCode":""
                }
              }
            }
		      }
          if(['number','currency','options','input'].includes(fieldItem.input_type) && fieldItem.copyFieldValueConfig){
            let copyValueConfig = fieldItem.copyFieldValueConfig
            let canCopyFieldApply = this.copyFieldAccessCheck(fieldItem, this.workFlowId);
            let copyValue;
            let currencyCode;
            if(canCopyFieldApply) {
              let parentKey = copyValueConfig['fieldKey'];
              if(this.fields.field_name[parentKey] != null){
                if(copyValueConfig.type == 'entity'){
                  copyValue = this.fields.field_name[parentKey]
                } else if(copyValueConfig.type == 'master'){
                  let masterViewId = copyValueConfig.viewId;
                  let masterReferenceKey = copyValueConfig.referenceKey;
                  let masterData = JSON.parse(JSON.stringify(this.masterDataList));
                  let masterViewField = {};
                  if(masterViewId){
                    if(masterData[masterViewId]){
                      masterViewField = masterData[masterViewId].find((field) => field._id === this.fields.field_name[parentKey]);
                    }
                  }
                  copyValue= masterViewField ? masterViewField[masterReferenceKey] : "";
                }
                if(fieldItem.input_type == 'input' || fieldItem.input_type == 'options'){
                  this.fields.field_name[fieldItem.key] = copyValue ? copyValue : "";
                } else if(fieldItem.input_type == 'currency'){
                  if(this.additionalFieldInformation[parentKey] && Object.keys(this.additionalFieldInformation[parentKey]).includes('currencyCode')) {
                    currencyCode = this.additionalFieldInformation[parentKey]['currencyCode'];
                  } else if(fieldItem.currency_configuration && fieldItem.currency_configuration.defaultCurrency){
                    currencyCode = fieldItem.currency_configuration.defaultCurrency
                  }
                  this.additionalFieldInformation[fieldItem.key] = {"currencyCode": currencyCode ? currencyCode : 'USD'};
                  if(typeof copyValue == 'number'){
                    this.fields.field_name[fieldItem.key] = copyValue ? copyValue : 0;
                  } else {
                    this.fields.field_name[fieldItem.key] = copyValue ? parseInt(copyValue) : 0;
                  }
                } else if(fieldItem.input_type == 'number'){
                  if(typeof copyValue == 'number'){
                    this.fields.field_name[fieldItem.key] = copyValue ? copyValue : 0;
                  } else {
                    this.fields.field_name[fieldItem.key] = copyValue ? parseInt(copyValue) : 0;
                  }
                }
              }
            }
          }
        }
        this.hideFields()
      },
      deep: true,
    },   
  },
  methods: {
    copyFieldAccessCheck(fieldItem, workflowId){
      if(fieldItem.field_access && fieldItem.field_access.update){
        var roleGroup = getFromLocalStorage("userGroup");
        let fieldAccessConfig = fieldItem.field_access.update
        for(let key in fieldAccessConfig){
          if(key == workflowId && Object.keys(fieldAccessConfig[key]).includes('defaultStage') && Object.values(fieldAccessConfig[key]['defaultStage']).includes(roleGroup)){
            return true;
          }
        }
        return false;
      }
    },
    calculateDialogWidth(){
      if(Object.keys(this.showTable).length > 0){
        this.dialogWidth = '70%';
      } else {
        this.dialogWidth = '35%';
      }
    },
    clearInputData(key){
      this.fields.field_name[key] = undefined;
    },
    renderComponent(item){
      this.$set(this.showTable, item, true);
    },
    handleScroll(){
      let showTableArr = Object.keys(this.showTable);
      let overallDiv = document.querySelector('#divSelector');
      for(let item of showTableArr){
        let btn = document.querySelector('#click' + item);
        if(btn != null && (!(item in this.alreadyRendered) || !this.alreadyRendered[item]) && (overallDiv.clientHeight - ((btn.getBoundingClientRect().top + 80) - overallDiv.getBoundingClientRect().top) > 0)){
          this.alreadyRendered[item] = true;
          this.$set(this.showTable, item, false);
          this.$nextTick(()=>{
            btn.click();
          })
        }
        if(this.alreadyRendered[item] && !(overallDiv.clientHeight - ((btn.getBoundingClientRect().top + 80) - overallDiv.getBoundingClientRect().top) > 0)){
          this.alreadyRendered[item] = false;
        }
      }
    },
    async openAssigneeSection() {
      this.showValidationPopup = false
      this.valueValidationErrorList = [];
      this.valueValidationErrorListInMM = [];
      this.tableValidationErrorList = [];
      this.errorList = [];
      this.errorListInMM = [];
      var isFormValid = await this.validateFields();
      if (isFormValid) {
        this.closeSpotlightDialog();
        this.loadAssigneeUserList(
          this.fields.field_name["assignee_user_group"],
          this.fields.field_name
        );
      }
      else{
        this.showValidationPopup = true;
        this.showSpotlightSection = false;
      }
    },
    closeAssigneeSection() {
      this.showAssigneeSection = false;
      this.calculateDialogWidth();
    },
    trimData(fielddata) {
      if (fielddata != undefined) {
        return fielddata.trim();
      } else {
        return fielddata;
      }
    },
    hideShowAssigneeField(workflowId, fieldItem) {
      if (this.assigneeDisabled) {
        this.showAssigneeField = true;
      } else {
        if (fieldItem.hasOwnProperty("isShown")) {
          if (fieldItem["isShown"].hasOwnProperty(workflowId)) {
            if (
              fieldItem["isShown"][workflowId].hasOwnProperty("defaultStage")
            ) {
              if (
                fieldItem["isShown"][workflowId]["defaultStage"].includes(
                  getFromLocalStorage("userGroup")
                )
              ) {
                this.showAssigneeField = true;
              } else {
                this.showAssigneeField = false;
              }
            } else {
              this.showAssigneeField = false;
            }
          } else {
            this.showAssigneeField = false;
          }
        } else {
          this.showAssigneeField = true;
        }
      }
    },
    dateValidation(fieldItemKey, dateInput) {
      this.dateMenu[fieldItemKey] = false;
      for (var field of this.fieldDefinition) {
        if (field.input_type === "date") {
          if (field.validators) {
            if (field.validators.date) {
              if (field.validators.date.range) {
                if (field.validators.date.range.from.type === "reference") {
                  var bool =
                    new Date(this.fields.field_name[field.key]) <
                    new Date(
                      this.fields.field_name[
                        field.validators.date.range.from.value
                      ]
                    );
                  if (bool) {
                    this.fields.field_name[field.key] = "";
                  }
                  this.dateVmodel = field.key;
                  var boolNew =
                    new Date(this.fields.field_name[field.key]) <
                    new Date(dateInput);
                  if (boolNew) {
                    this.fields.field_name[this.dateVmodel] = "";
                    this.dateVmodel = fieldItemKey;
                    this.startDate[field.key] =
                      this.fields.field_name[
                        field.validators.date.range.from.value
                      ];
                  } else if (field.key != fieldItemKey) {
                    this.startDate[field.key] =
                      this.fields.field_name[
                        field.validators.date.range.from.value
                      ];
                  }
                }
                if (field.validators.date.range.to.type === "reference") {
                  var bool =
                    new Date(this.fields.field_name[field.key]) <
                    new Date(
                      this.fields.field_name[
                        field.validators.date.range.from.value
                      ]
                    );
                  if (bool) {
                    this.fields.field_name[field.key] = "";
                  }
                  this.dateVmodel = field.key;
                  var boolNew =
                    new Date(this.fields.field_name[field.key]) <
                    new Date(dateInput);
                  if (boolNew) {
                    this.fields.field_name[this.dateVmodel] = "";
                    this.dateVmodel = fieldItemKey;
                    this.endDate[field.key] =
                      this.fields.field_name[
                        field.validators.date.range.to.value
                      ];
                  } else if (field.key != fieldItemKey) {
                    this.endDate[field.key] =
                      this.fields.field_name[
                        field.validators.date.range.to.value
                      ];
                  }
                }
              }
            }
          }
        }
      }
    },
    getValue(statement) {
      let comparisonOperator;
      if (statement == true) {
        return true;
      }
      if (statement == undefined) {
        return false;
      }
      if (typeof statement == "object" && Object.keys(statement).length > 1) {
        let truthValues = [];
        for (var key in statement) {
          truthValues.push(this.getValue({ [key]: statement[key] }));
        }
        return truthValues.every((v) => v === true);
      }
      for (var key in statement) {
        comparisonOperator = "";
        if (key instanceof Object && !Array.isArray(key)) {
          return this.getValue(key);
        } else if (this.isLogicalOperator(key)) {
          if (key == "$and") {
            return statement[key].every((v) => this.getValue(v) === true);
          } else if (key == "$not") {
            return !this.getValue(statement[key]);
          } else if (key == "$or") {
            return statement[key].reduce(
              (a, b) => this.getValue(b) || this.getValue(a)
            );
          } else {
            return false;
          }
        } else if (
          statement[key] instanceof Object &&
          Object.keys(statement[key]).length == 1 &&
          this.isComparisonOperator(Object.keys(statement[key])[0])
        ) {
          comparisonOperator = Object.keys(statement[key])[0];
          if (comparisonOperator == "$ne") {
            return (
              this.fields.field_name[key] != statement[key][comparisonOperator]
            );
          } else if (comparisonOperator == "$in") {
            return statement[key][comparisonOperator].includes(
              this.fields.field_name[key]
            );
          } else if (comparisonOperator == "$nin") {
            return !statement[key][comparisonOperator].includes(
              this.fields.field_name[key]
            );
          } else if (comparisonOperator == "$lt") {
            if (this.isNumeric(this.fields.field_name[key])) {
              return (
                Number(this.fields.field_name[key]) <
                Number(statement[key][comparisonOperator])
              );
            }
            return false;
          } else if (comparisonOperator == "$lte") {
            if (this.isNumeric(this.fields.field_name[key])) {
              return (
                Number(this.fields.field_name[key]) <=
                Number(statement[key][comparisonOperator])
              );
            }
            return false;
          } else if (comparisonOperator == "$gt") {
            if (this.isNumeric(this.fields.field_name[key])) {
              return (
                Number(this.fields.field_name[key]) >
                Number(statement[key][comparisonOperator])
              );
            }
            return false;
          } else if (comparisonOperator == "$gte") {
            if (this.isNumeric(this.fields.field_name[key])) {
              return (
                Number(this.fields.field_name[key]) >=
                Number(statement[key][comparisonOperator])
              );
            }
            return false;
          } else {
            return false;
          }
        } else if (
          typeof statement[key] == "boolean" ||
          typeof statement[key] == "string" ||
          (typeof statement[key] == "object" &&
            statement[key].constructor === String) ||
          this.isNumeric(statement[key]) ||
          typeof statement[key] == "boolean"
        ) {
          return this.fields.field_name[key] == statement[key];
        } else {
          return false;
        }
      }
    },
    isNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
    isLogicalOperator(value) {
      if (value == "$and" || value == "$not" || value == "$or") {
        return true;
      } else {
        return false;
      }
    },
    isComparisonOperator(value) {
      if (
        value == "$ne" ||
        value == "$in" ||
        value == "$nin" ||
        value == "$lt" ||
        value == "$lte" ||
        value == "$gt" ||
        value == "$gte"
      ) {
        return true;
      } else {
        return false;
      }
    },
    getBase64(value) {
      if (value.status) {
        this.fileAttachmentArray = this.fileAttachmentArray.filter(
          (fileAttachmentObject) => fileAttachmentObject.key != value.key
        );
        this.fileAttachmentArray.push(value);
        this.fields.field_name[value.key] = value.file;
        let folderUrl = value.file.split('/').slice(-1)[0];
        let fileObject = {};
        fileObject[folderUrl] = value.currentfileName;
        this.additionalFieldInformation[value.key] = fileObject;
      } else {
        this.fileAttachmentArray = this.fileAttachmentArray.filter(
          (fileAttachmentObject) => fileAttachmentObject.key != value.key
        );
        delete this.fields.field_name[value.key];
      }
    },
    multifileCheck(value) {
      if (value.status) {
        if(this.fields.field_name[value.key]!==undefined){
          this.fields.field_name[value.key].push(value.file);
          this.fileAttachmentArray.push(value);
        }
        else{
          this.fields.field_name[value.key]=[];
          this.fields.field_name[value.key].push(value.file);
          this.fileAttachmentArray.push(value);
        }
        let folderUrl = value.file.split('/').slice(-1)[0];
        let fileObject = {};
        fileObject[folderUrl] = value.currentfileName;
        this.additionalFieldInformation[value.key] = {...this.additionalFieldInformation[value.key], ...fileObject};
      } else {
        this.fileAttachmentArray = this.fileAttachmentArray.filter(
          (fileAttachmentObject) => fileAttachmentObject.key != value.key
        );
      }
    },
    async fileIndexFinder(file, key){
      var folderUrl=file.split("/").slice(-1);
      var [first,...rest]= folderUrl[0].split("_")
      var fileName = rest.join('_');
      if(fileName) {
        return fileName;
      } else if(this.additionalFieldInformation[key] && this.additionalFieldInformation[key][folderUrl[0]]) {
        return this.additionalFieldInformation[key][folderUrl[0]];
      }
    }, 
    async multiFileRemove(fileName,key) {
      let fileToRemove;
      for(var value in this.fileAttachmentArray) {
        var file = this.fileAttachmentArray[value].currentfileName;
        if (file === fileName) {
          fileToRemove = this.fileAttachmentArray[value].file;
          this.fileAttachmentArray.splice(value,1);
        }
      }
      for(var val in this.fields.field_name[key]){
        if (fileToRemove && (this.fields.field_name[key][val] === fileToRemove)) {
          this.fields.field_name[key].splice(val,1);
          let value = fileToRemove.split("/").slice(-1)[0];
          if(this.additionalFieldInformation[key][value]) {
            delete this.additionalFieldInformation[key][value];
          }
        }
      }
    },
    clickCancel() {
      this.showValidationPopup = false;
      this.showSpotlightSection = false;
      this.calculateDialogWidth();
      this.dialogCols = "col-12",
      this.cancelOperation();
    },
    isExternalEmbedPostMessage() {
    // checking for the external embeded dialog
      let isExternalEmbedDialog = getFromLocalStorage("authType")
      if (isExternalEmbedDialog === 'externalEmbed') {
        let externalEmbedUrl = getFromLocalStorage("externalEmbedUrl");
        // reporting to the client on successfull add record
        window.top.postMessage({ messageType: 'close', message: 'BluJ Dialog has been closed' }, externalEmbedUrl);
      }
    },
    closeErrorExpend(){ 
      if(Object.keys(this.showTable).length > 0){
        this.dialogWidth = '70%';
      } else {
        this.dialogWidth = '35%';
      }
      this.showValidationPopup = false;
      this.valueValidationErrorList = [];
      this.valueValidationErrorListInMM = [];
      this.tableValidationErrorList = [];
      this.errorList = [];
      this.errorListInMM = [];
    },
    cancelOperation() {
      this.newData = JSON.stringify(this.fields.field_name);
      if (this.newData != this.oldData) {
        this.confirmCancel = true;
      } else {
        this.isExternalEmbedPostMessage();
        this.$emit("closeDialog", false);
      }
    },
    closeCancelOperation(flag) {
      this.confirmCancel = flag;
    },
    closeCancelDialogBox() {
      this.isExternalEmbedPostMessage();
      this.$emit("closeDialog", false);
      this.disableSelect = false;
    },
    closeDialog(flag) {
      this.showSpinner = false;
      this.dialog = flag;
      this.showConfirmationDialog = false;
      this.$emit("closeDialog", flag);
    },
    closeErrorValidationDialog(flag) {
      // this.showErrorValidationDialog = flag;
      this.showValidationPopup = flag;
      this.valueValidationErrorList = [];
      this.valueValidationErrorListInMM = [];
      this.tableValidationErrorList = [],
      this.$emit("closeErrorValidationDialog", false);
    },
    fileChange(event, fieldName) {
      var fieldKey = fieldName;
      var file = this.fields.field_name[fieldKey];
      let reader = new FileReader();
      reader.onload = (e) => {
        var base64image = e.target.result;
        this.fields.field_name[fieldKey] = base64image;
      };
      reader.readAsDataURL(event);
    },
    createBase64String(file, fieldKey) {},
    selectWorkflow(event, optionValue, fieldItem) {
      this.disabledBtn = false;
      for (var fieldItem of this.fieldDefinition) {
        if (fieldItem.isDependent && !fieldItem.renderField) {
          delete this.fields.field_name[fieldItem.key];
        }
      }
      for (var item in optionValue) {
        if (optionValue[item].value === event) {
          this.assigneeUserGroupOptions = [];
          var defaultAssigneeGroup;
          if (optionValue[item].hasOwnProperty("assigneeGroupCondition")) {
            if (optionValue[item]["assigneeGroupCondition"].key === "userId") {
              var roleGroup = getFromLocalStorage("userGroup");
              defaultAssigneeGroup =
                optionValue[item]["assigneeGroupCondition"]["condition"][
                  roleGroup
                ].defaultAssigneeGroup;
            }
          } else {
            defaultAssigneeGroup = optionValue[item].defaultAssigneeGroup;
          }
          this.assigneeUserGroupOptions.push(defaultAssigneeGroup);
          this.workFlowId = "";
          this.workFlowId = optionValue[item].workflow_id;
          this.fields.field_name["assignee_user_group"] = defaultAssigneeGroup;
          if (this.assigneeUserGroupOptions.length === 1) {
            this.assigneeUserGroupOptionsDisabled = true;
          }
        }
      }
      this.render++;
      this.getMasterViewFields();
    },
    async getMasterViewFields(){
      for (let fieldItem of this.fieldDefinition) {
        let userGroup = getFromLocalStorage("userGroup");
        if (fieldItem.isMasterViewConfig){
          if(fieldItem.masterViewConfig
          && this.workFlowId in fieldItem.masterViewConfig 
          && 'defaultStage' in fieldItem.masterViewConfig[this.workFlowId] 
          && userGroup in fieldItem.masterViewConfig[this.workFlowId]['defaultStage']
          && !this.masterDataList[fieldItem.view_id]
          ) {
            this.masterViewAccessFields[fieldItem.key] = fieldItem.masterViewConfig[this.workFlowId]['defaultStage'][userGroup];
            try {
              let payload = {
                  method: "post",
                  url: process.env.VUE_APP_BACKEND_URL + "/displayMasterValue",
                  data: {
                      applicationId: this.$route.query.applicationId,
                      token: getFromLocalStorage("token"),
                      authType: getFromLocalStorage("authType"),
                      viewId: fieldItem.view_id
                  },
                  headers: {
                      "content-type": "application/json",
                  },
              };
              let apiResponse = await axios(payload);
              if (apiResponse.data && apiResponse.data.data) {
                this.masterDataList[fieldItem.view_id] = apiResponse.data.data;
                this.masterDisplayNames[fieldItem.view_id] = apiResponse.data.displayNames;
                this.displayNames[fieldItem.key] = this.masterDisplayNames[fieldItem.view_id];
                if (this.fields.field_name[fieldItem.key] && this.masterDataList[fieldItem.view_id]) {
                  this.showMasterViewFields = false;
                  let masterViewField = this.masterDataList[fieldItem.view_id].find((field) => field._id === this.fields.field_name[fieldItem.key]);
                  if (masterViewField && this.masterViewAccessFields[fieldItem.key]) {
                    this.$set(this.viewData, fieldItem.key, {});
                    this.$set(this.viewData[fieldItem.key], 'display_name', {});
                    for (let key of this.masterViewAccessFields[fieldItem.key]) {
                      if (masterViewField[key]) {
                        this.$set(this.viewData[fieldItem.key], key, masterViewField[key]);
                        this.$set(this.viewData[fieldItem.key]['display_name'], key, this.displayNames[fieldItem.key][key]);
                      }
                    }
                  }
                  this.showMasterViewFields = true;
                } else if (fieldItem.isMasterViewConfig && !this.fields.field_name[fieldItem.key]){
                  this.showMasterViewFields = false;
                }
              }
            } catch (apiError) {
              if (
              apiError.response &&
              apiError.response.status &&
              apiError.response.status == 401
              ) {
                this.$root.$emit('update_session_value', true);
              }
              else {
                this.errorMessageShow = true;
                if(apiError.response && apiError.response.data && apiError.response.data.errorObject){
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: apiError.response.data.errorObject.error_code,
                  error_uuid: apiError.response.data.errorObject.error_uuid,
                };
              }
              else{
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: "",
                  error_uuid: "",
                };
              }
              this.showMessageDialog = true;
              }
              this.showSpinner = false;
              this.showAssigneeSection = true;
            }
          }
        }
      }
    },
    conditionalOptionChange(value, fieldItem) {
      for (var i = 0; i < this.fieldDefinition.length; i++) {
        if (this.fieldDefinition[i].isDependent) {
          if (
            this.fieldDefinition[i].dependentField !== fieldItem.key &&
            this.fieldDefinition[i].dependentField !== "issue_type"
          ) {
            delete this.fields.field_name[
              this.fieldDefinition[i].dependentField
            ];
          } else if (this.fieldDefinition[i].dependentField === fieldItem.key) {
            delete this.fields.field_name[this.fieldDefinition[i].key];
          }
          if (this.fieldDefinition[i].input_type === "date") {
            this.dateValidation();
            if (
              this.fieldDefinition[i].default_value &&
              this.fieldDefinition[i].default_value.type &&
              this.fieldDefinition[i].default_value.type === "today"
            ) {
              this.fields.field_name[this.fieldDefinition[i].key] =
                moment().format("YYYY-MM-DD");
            } else if (
              this.fieldDefinition[i].default_value &&
              this.fieldDefinition[i].default_value.type === "absolute"
            ) {
              let epoch = this.fieldDefinition[i].default_value.value;
              epoch = Number(epoch);
              this.fields.field_name[fieldItem.key] =
                moment(epoch).format("YYYY-MM-DD");
            }
          }
        }
      }
      if (fieldItem.condition.key === "assigneeGroupCondition") {
        this.assigneeUserGroupOptions.push(
          fieldItem.condition.value[value]["defaultAssigneeGroup"]
        );
        this.fields.field_name["assignee_user_group"] =
          fieldItem.condition.value[value]["defaultAssigneeGroup"];
      }
    },
    optionsChangingFn(event, optionValue, fieldItem) {
      // this.fields.field_name[fieldItem.key] = event;
      // for (var i = 0; i < this.fieldDefinition.length; i++) {
      //   if (this.fieldDefinition[i].isDependent) {
      //     if (
      //       this.fieldDefinition[i].dependentField !== fieldItem.key &&
      //       this.fieldDefinition[i].dependentField !== "issue_type" &&
      //       this.fieldDefinition[i].dependentField !== fieldItem.dependentField
      //     ) {
      //       delete this.fields.field_name[
      //         this.fieldDefinition[i].dependentField
      //       ];
      //       delete this.fields.field_name[this.fieldDefinition[i].key];
      //     } else if (this.fieldDefinition[i].dependentField === fieldItem.key) {
      //       delete this.fields.field_name[this.fieldDefinition[i].key];
      //     }
      //   }
      // }
    },
    loadAssigneeUserList(value, full_definiton) {
      this.showSpinner = true;
      this.isSelfAssignOnCreate = false;
      this.isExcludeSelfInAssignement = false;
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/getUserList",
        data: {
          applicationId: this.$route.query.applicationId,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
          userGroup: value,
          definition: full_definiton,
        },
        headers: {
          "content-type": "application/json",
        },
      })
        .then((apiResponse) => {
          this.assigneeUserList = [];
          this.assigneeUserList = apiResponse.data.data;
          for (var fieldItem of this.fieldDefinition) {
            if (fieldItem.key === "current_status") {
              if (
                fieldItem[this.workFlowId].hasOwnProperty(
                  "selfAssignOnCreate"
                ) &&
                fieldItem[this.workFlowId]["selfAssignOnCreate"]
              ) {
                this.isSelfAssignOnCreate = true;
              }
              if ("excludeSelfInAssignment" in fieldItem[this.workFlowId]
                && fieldItem[this.workFlowId]["excludeSelfInAssignment"]
              ) {
                this.isExcludeSelfInAssignement = true;
              }
            }
          }
          if (this.isSelfAssignOnCreate) {
            var tempUserList = this.assigneeUserList.find((assigneeObject) => {
              return (
                assigneeObject.email === this.userId
              );
            });
            this.assigneeUserList = [];
            this.assigneeUserList.push(tempUserList);
          }  
          if (this.isExcludeSelfInAssignement) {
            var tempUserList = this.assigneeUserList.filter((assigneeObject) => {
              return (
                assigneeObject.email !== this.userId
              );
            });
            this.assigneeUserList = [];
            this.assigneeUserList = tempUserList;
          }
          if (
            apiResponse &&
            apiResponse.data &&
            apiResponse.data.data &&
            apiResponse.data.data.length === 1
          ) {
            this.fields.field_name["assignee"] = apiResponse.data.data[0];
            this.assigneeDisabled = true;
          }else {
            this.fields.field_name["assignee"] = {};
            this.assigneeDisabled = false;
          }
          if(this.assigneeUserList.length === 1){
            this.fields.field_name["assignee"] = this.assigneeUserList[0];
            this.assigneeDisabled = true;
          }
          if(this.isExcludeSelfInAssignement && this.fields.field_name["assignee"].email == this.userId ) {
            this.fields.field_name["assignee"] = {};
          }
          this.hideShowAssigneeField(this.workFlowId, this.assigneeFieldItem);
          if(this.assigneeDisabled === true || this.isSelfAssignOnCreate === true || this.showAssigneeField === false){
            this.clickSave()
            this.showSpinner = true;
          }else{
            this.showSpinner = false;
            this.showAssigneeSection = true;
          }
        })
        .catch((apiError) => {
          this.showSpinner = false;
          this.showAssigneeSection = true;
          if (
            apiError.response &&
            apiError.response.status &&
            apiError.response.status == 401
          ) {
            this.$root.$emit("update_session_value", true);
          } else {
            this.errorMessageShow = true;
            if (
              apiError.response &&
              apiError.response.data &&
              apiError.response.data.errorObject
            ) {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: apiError.response.data.errorObject.error_code,
                error_uuid: apiError.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },
    clickSave() {
      this.showConfirmationDialog = false;
      if (this.dialogObject.viewType === "master") {
        this.confirmationDialogObject["data"] = this.fields.field_name;
        this.confirmationDialogObject["fileAttachments"] =
        this.fileAttachmentArray;
        this.confirmationDialogObject["action"] = "ADD";
        this.confirmationDialogObject["viewId"] = this.dialogObject.viewId;
        this.confirmationDialogObject["workFlowId"] = this.workFlowId;
        this.confirmationDialogObject["display"] = true;
      }
      let isValid = this.validateAssigneeAndGroup();
      if (isValid) {
        this.showConfirmationDialog = true;
      }
    },
    additionalField(key, data){
      this.additionalFieldInformation[key]  = {
        "callingCode":data.callingCode,
        "countryCode":data.countryCode
      }
    },
    checkConditionForAssignee(element) {
      return element.isVisible && element.validators &&
            element.validators.required &&
            (element.key === "assignee" || element.key === "assignee_user_group");
    },
    validateAssigneeAndGroup() {
      this.errorList = [];
      this.errorListInMM = [];
      this.valueValidationErrorList = [];
      this.valueValidationErrorListInMM = [];
      this.tableValidationErrorList = [];
      this.fieldDefinition.forEach((element) => {
          // Check for validation inside the condition
          // If any conditon pass it doesn't check for outside validations
         if (element.key === 'current_status' && 
         element[this.workFlowId] &&
         element[this.workFlowId]['isConditionalChange'] &&
         element[this.workFlowId]['conditionalStageConfig']
         ) {
          // Check for Condition Validation
          if (this.isConditionalAssigneeCheck.status &&
                this.isConditionalAssigneeCheck.condition &&
                this.isConditionalAssigneeCheck.condition.validators &&
                this.isConditionalAssigneeCheck.condition.validators.assignee && 
                this.isConditionalAssigneeCheck.condition.validators.assignee.required
              ) {
                  this.isConditionalAssigneeCheckPass = true;
                  const fieldValue = this.fields.field_name['assignee'];
                  const isEmptyObject = fieldValue && Object.keys(fieldValue).length === 0 && fieldValue.constructor === Object;
                  if (fieldValue == undefined || fieldValue == "" || fieldValue == null || isEmptyObject) {
                  if (!element.isDependent || element.renderField) {
                    if(this.errorList.length === 0){
                      this.errorList.push("Assignee should not be empty. Please select an Assignee.");
                      if (this.isMMSecondarySupportLang) {
                        this.errorListInMM.push("တာဝန်ပေးအပ်သူသည် ဗလာမဖြစ်သင့်ပါ။ ကျေးဇူးပြု၍ တာဝန်ပေးသူကို ရွေးပါ။");
                      }
                    }
                  }
                }
              }
            }
            if (!(this.isConditionalAssigneeCheckPass) && element.key === "current_status" &&
                element[this.workFlowId] &&
                element[this.workFlowId].validators &&
                element[this.workFlowId].validators['assignee'] &&
                element[this.workFlowId].validators['assignee'].required ) {
                  this.isConditionalAssigneeCheckPass = true;
                  const fieldValue = this.fields.field_name['assignee'];
                  const isEmptyObject = fieldValue && Object.keys(fieldValue).length === 0 && fieldValue.constructor === Object;
                  if (fieldValue == undefined || fieldValue == "" || fieldValue == null || isEmptyObject) {
                    if (!element.isDependent || element.renderField) {
                      if(this.errorList.length === 0){
                        this.errorList.push("Assignee should not be empty. Please select an Assignee.");
                        if (this.isMMSecondarySupportLang) {  
                          this.errorListInMM.push("တာဝန်ပေးအပ်သူသည် ဗလာမဖြစ်သင့်ပါ။ ကျေးဇူးပြု၍ တာဝန်ပေးသူကို ရွေးပါ။");
                        }
                      }
                    }
                  }
              }
            // Check for out side Validation
            if (!this.isConditionalAssigneeCheckPass) {
              this.fieldDefinition.forEach((element) => {
                if(this.checkConditionForAssignee(element)){
                  const fieldValue = this.fields.field_name[element.key];
                  const isEmptyObject = fieldValue && Object.keys(fieldValue).length === 0 && fieldValue.constructor === Object;
                  if (fieldValue == undefined || fieldValue == "" || fieldValue == null || isEmptyObject) {
                    if (!element.isDependent || element.renderField) {
                      if(this.errorList.length === 0){
                        this.errorList.push("Assignee should not be empty. Please select an Assignee.");
                        if (this.isMMSecondarySupportLang) {
                          this.errorListInMM.push("တာဝန်ပေးအပ်သူသည် ဗလာမဖြစ်သင့်ပါ။ ကျေးဇူးပြု၍ တာဝန်ပေးသူကို ရွေးပါ။");
                        }
                      }
                    }
                  }
                }
              });
            }
        });
      if (this.errorList.length > 0 || this.valueValidationErrorList.length > 0 || this.tableValidationErrorList.length > 0) {
        this.errorValidationDialogObject["data"] = { errorList: this.errorList };
        if (this.isMMSecondarySupportLang) {
          this.errorValidationDialogObject["data"] = { errorList: this.errorList, errorListInMM: this.errorListInMM };
          this.errorValidationDialogObject["valueValidationErrorListInMM"] = this.valueValidationErrorListInMM;
          this.errorValidationDialogObject["displayTextInMM"] = "အောက်ပါ အကွက်များတွင် လိုအပ်သော အချက်အလက်များ ဖြည့်စွက်ပါ။ ";
        }
        this.errorValidationDialogObject["viewId"] = this.dialogObject.viewId;
        this.errorValidationDialogObject["valueValidationErrorList"] = this.valueValidationErrorList;
        this.errorValidationDialogObject["tableValidationErrorList"] = this.tableValidationErrorList;
        this.errorValidationDialogObject["workFlowId"] = this.workFlowId;
        this.errorValidationDialogObject["display"] = true;
        this.errorValidationDialogObject["displayText"] = "Enter the value(s) into the following field(s): ";
        this.showValidationPopup = true;
        this.dialogWidth = '70%';
        return false;
      } else {
        this.showValidationPopup = false;
        this.dialogWidth = '35%',
        this.confirmationDialogObject["data"] = this.fields.field_name;
        this.confirmationDialogObject["additionalFieldInformation"] = this.additionalFieldInformation;
        this.confirmationDialogObject["fileAttachments"] = this.fileAttachmentArray;
        this.confirmationDialogObject["action"] = "ADD";
        this.confirmationDialogObject["viewId"] = this.dialogObject.viewId;
        this.confirmationDialogObject["workFlowId"] = this.workFlowId;
        this.confirmationDialogObject["display"] = true;
        if (this.assigneeDisabled === true || this.isSelfAssignOnCreate === true || this.showAssigneeField === false) {
          this.confirmationDialogObject["display"] = false;
        }
        return true;
      }
    },
	  validateLengthAndNumber(element){
      var returnValue = true;
      if(element.validators.number){
        const regexExp = /^[0-9]*$/g;
        const str = this.fields.field_name[element.key];
        returnValue = regexExp.test(str);
      }
      if(!returnValue){
        this.valueValidationErrorList.push("Please enter only numbers for " + element.display_name.en)
        if (this.isMMSecondarySupportLang) {
          this.valueValidationErrorListInMM.push("ကျေးဇူးပြု၍ နံပါတ်များကိုသာ ဖြည့်စွက်ပါ " + element.display_name.mm)
        }
      }
      if(element.validators.length){
        if(this.fields.field_name[element.key].length > element.validators.length.max){
          this.valueValidationErrorList.push("Length of " + element.display_name.en +  " should not exceed " + element.validators.length.max)
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm +  " သတ်မှတ်ထားသော အများဆုံးစာလုံးအရေအတွက်ထက် မကျော်လွန်ရပါ။ " + element.validators.length.max)
          }
        }
        if(this.fields.field_name[element.key].length < element.validators.length.min){
          this.valueValidationErrorList.push("Length of " + element.display_name.en +  " should be at least " + element.validators.length.min)
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm +  " သတ်မှတ်ထားသော အနည်းဆုံးစာလုံးအရေအတွက်ထက် မနည်းစေရပါ။ " + element.validators.length.min)
          }
        }
      }
    },
   validateCheckList(element){
        var returnValue = true;
		    if(element.validators.list){
			    if(element.validators.list.type == 'all'){
				    returnValue = true;
            for(var configOption of element.option_values){
            if(configOption.value){
              for(let dataOption of this.fields.field_name[element.key]){
                if(dataOption.value == configOption.value && !dataOption.checked){
                  returnValue = false;
                  break;
                }
              }
            }
          }
				  if(returnValue){
					for(let dataOption of this.fields.field_name[element.key]){
						if(dataOption.checked == false){
							returnValue = false;
							break
						}
					}
				}else{
					this.valueValidationErrorList.push("Please select all items for " + element.display_name.en);
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push("သတ်မှတ်ထားသည်များ အားလုံးကို ရွေးပါ။ " + element.display_name.mm);
          }
				}

			}else if(element.validators.list.type == 'range'){
				returnValue = true;
				if(element.validators.list.config){
					var checkCount = 0;
					for(let item of this.fields.field_name[element.key]){
						if(item.checked == true){
							checkCount += 1;
						}
					}
					if(checkCount > element.validators.list.config.max){
						returnValue = false
						this.valueValidationErrorList.push("Selected items for " + element.display_name.en + " should not exceed " + element.validators.list.config.max)
            if (this.isMMSecondarySupportLang) {
              this.valueValidationErrorListInMM.push("Selected items for " + element.display_name.mm + " should not exceed " + element.validators.list.config.max)
            }
					}
					if(checkCount < element.validators.list.config.min){
						returnValue = false
						this.valueValidationErrorList.push("Selected items for " + element.display_name.en + " should be at least " + element.validators.list.config.min)
            if (this.isMMSecondarySupportLang) {
              this.valueValidationErrorListInMM.push("Selected items for " + element.display_name.mm + " should be at least " + element.validators.list.config.min)
            }

				  }  
				}
			}
		}            
    },
    validateMultiSelect(element){
      var isvalid = true;
      if(this.fields.field_name[element.key]){
        if(element.validators.list){
          if(element.validators.list.type == 'all'){
            let allSelectedOptions = this.fields.field_name[element.key].map(option => {
              return option.value
            });
            for(let option of element.option_values) {
              if(! (allSelectedOptions.includes(option.value))){
                isvalid = false;
                break;
              }
            }
            if(!isvalid){
              if(element.validators.list.errorMessage){
                this.valueValidationErrorList.push(element.display_name.en + " - " + element.validators.list.errorMessage.en);
                if (this.isMMSecondarySupportLang) {
                  this.valueValidationErrorListInMM.push(element.display_name.mm + " - " + element.validators.list.errorMessage.mm);
                }
              } else {
                this.valueValidationErrorList.push("Please select all items for " + element.display_name.en);
                if (this.isMMSecondarySupportLang) {
                  this.valueValidationErrorListInMM.push("သတ်မှတ်ထားသည်များ အားလုံးကို ရွေးပါ။ " + element.display_name.mm);
                }
              } 
            }
          }
          else if(element.validators.list.type == 'range'){
            if(element.validators.list.config){
              let itemsSelected = this.fields.field_name[element.key].length;
              if(element.validators.list.config.min && itemsSelected < element.validators.list.config.min){
                if(element.validators.list.errorMessage){
                  this.valueValidationErrorList.push(element.display_name.en + " - " + element.validators.list.errorMessage.en);
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(element.display_name.mm + " - " + element.validators.list.errorMessage.mm);
                  }
                } else {
                  this.valueValidationErrorList.push("Selected items for " + element.display_name.en + " should be at least " + element.validators.list.config.min)
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push("Selected items for " + element.display_name.mm + " should be at least " + element.validators.list.config.min)
                  }
                }
              }
              if(element.validators.list.config.max && itemsSelected > element.validators.list.config.max){
                if(element.validators.list.errorMessage){
                  this.valueValidationErrorList.push(element.display_name.en + " - " + element.validators.list.errorMessage.en);
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(element.display_name.mm + " - " + element.validators.list.errorMessage.mm);
                  }
                } else { 
                  this.valueValidationErrorList.push("Selected items for " + element.display_name.en + " should not exceed " + element.validators.list.config.max)
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push("Selected items for " + element.display_name.mm + " should not exceed " + element.validators.list.config.max)
                  }
                }
              }
            }
          }
        }
      }            
    },
    validateMultifile(element){
      if (!(this.fields.field_name[element.key]) || 
      this.fields.field_name[element.key].length === 0) {
        this.valueValidationErrorList.push("Please upload the file for " +element.display_name.en);
        this.valueValidationErrorListInMM.push("Please upload the file for " +element.display_name.mm);
      }
  },
    validateNumberField(element){
      if(isNaN(this.fields.field_name[element.key]) ){
        this.valueValidationErrorList.push("Invalid input. " + element.display_name.en + " should be a number")
        if (this.isMMSecondarySupportLang) {
          this.valueValidationErrorListInMM.push("ထည့်သွင်းမှု မမှန်ကန်ပါ။ " + element.display_name.mm + "နံပါတ်များသာ ဖြည့်စွက်ပါ")
        }
      }
      else if(element.validators.required && element.validators.numberType){
        if(element.validators.numberType == "integer" && this.fields.field_name[element.key] != Math.floor(this.fields.field_name[element.key])){
          this.valueValidationErrorList.push(element.display_name.en + " should be Integer")
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm + " ထည့်သွင်းသည့်နံပါတ်မှာ ကိန်းပြည့်ဖြစ်ရပါမည်။ (ဒဿမကိန်းမဖြစ်ရ)")
          }
        }
      }
      if(element.validators.required && element.validators.range){
        if(this.fields.field_name[element.key] > element.validators.range.max){
          this.valueValidationErrorList.push(element.display_name.en + " should not exceed " + element.validators.range.max)
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm + " သတ်မှတ်ထားသော အများဆုံးစာလုံးအရေအတွက်ထက် မကျော်လွန်ရပါ " + element.validators.range.max)
          }
        }
        else if(this.fields.field_name[element.key] < element.validators.range.min){
          this.valueValidationErrorList.push(element.display_name.en + " should be atleast " + element.validators.range.min)
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm + " သတ်မှတ်ထားသော အနည်းဆုံးစာလုံးအရေအတွက်ထက် မနည်းစေရပါ " + element.validators.range.min)
          }
        }
      }
    },
    validateCurrency(element){
      if(element.validators.range){
        var minValue=element.validators.range.min;
        var maxValue=element.validators.range.max;
        if(Number(this.fields.field_name[element.key])>maxValue){
          this.valueValidationErrorList.push("Value of "+ element.display_name.en +" should not be greater then "+maxValue);
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm +" သတ်မှတ်ထားသော အများဆုံးတန်ဖိုးထက် မကျော်လွန်ရပါ "+maxValue);
          }
        }
        else if(Number(this.fields.field_name[element.key])<minValue){
          this.valueValidationErrorList.push("Value of "+ element.display_name.en +" should not be less then "+minValue);
          if (this.isMMSecondarySupportLang) {
            this.valueValidationErrorListInMM.push(element.display_name.mm +" သတ်မှတ်ထားသော အနည်းဆုံးတန်ဖိုးထက် မနည်းစေရပါ "+minValue);
          }
        }
      }
    },
    deleteEmptyRow(element) {
      if(this.fields.field_name[element.key].length > 1){
        let result = this.removeEmptyObjects(this.fields.field_name[element.key]);
        this.$set(this.fields.field_name, element.key, result);
      }
    },
    validateTable(element) {
      let previousField = true;
      for(let value in this.fields.field_name[element.key]) {
        if(element.validators.required) {
          previousField = element.display_name.en;
          for(let item in element.table_configuration.columns) {
            let hasColumnAccess =  this.checkColumnAccess(element.table_configuration.columns[item].access, this.workFlowId, 'default_value', getFromLocalStorage("userGroup"));
            let columnName = element.table_configuration.columns[item].name;
            if(hasColumnAccess) {
              let isRequired = element.table_configuration.columns[item].validators.required;
              if(element.table_configuration.columns[item].validators.conditionalValidations){
                for (let conditionalStatement of element.table_configuration.columns[item].validators.conditionalValidations) {
                  if (this.checkTableConditionalValidations(conditionalStatement.condition, this.fields.field_name[element.key][value])) {
                    isRequired = condition.required
                    break;
                  }
                }
              }
              if(isRequired) {
                if(!(this.fields.field_name[element.key][value][columnName])) {
                  if(previousField) {
                    this.errorList.push({
                      'fieldName': element.display_name.en,
                      'type': 'tableError',
                      'columnName': columnName,
                      'row': Number(value)+1
                    });
                    previousField = false;
                  }
                  this.errorList.push({
                    'type': 'tableError',
                    'columnName': columnName,
                    'row': Number(value)+1
                  });
                }
              }
            }
          }
        }
      }
    },
    validateTableValue(element){
      let optionErrorList = [];
      let futureDateErrorList = [];
      let pastDateErrorList = [];
      let numberRangeErrorList=[];
      let previousField = true;
      for(let value in this.fields.field_name[element.key]) {
        previousField = element.display_name.en;
        for(let item in element.table_configuration.columns) {
            let hasColumnAccess =  this.checkColumnAccess(element.table_configuration.columns[item].access, this.workFlowId, 'default_value', getFromLocalStorage("userGroup"));
            let columnName = element.table_configuration.columns[item].name;
            if (hasColumnAccess) {
              let validators = element.table_configuration.columns[item].validators;
              if(element.table_configuration.columns[item].conditionalValidations){
                for (let conditionalStatement of element.table_configuration.columns[item].conditionalValidations) {
                  if (this.checkTableConditionalValidations(conditionalStatement.condition, this.fields.field_name[element.key][value])) {
                    validators = element.table_configuration.columns[item].validatorSet[conditionalStatement.validationSet]
                    break;
                  }
                }
              }
              if (!(this.fields.field_name[element.key][value][columnName])){
                if(validators.required) {
                  if(previousField) {
                    this.errorList.push({
                      'fieldName': element.display_name.en,
                      'type': 'tableError',
                      'columnName': columnName,
                      'row': Number(value)+1
                    });
                    previousField = false;
                  }
                  this.errorList.push({
                    'type': 'tableError',
                    'columnName': columnName,
                    'row': Number(value)+1
                  });
                }
              } else {
                if (this.fields.field_name[element.key][value][columnName] && element.table_configuration.columns[item].type === 'number') {
                  if(validators.range){
                    let numberRangeError= this.checkColumnRange(validators.range, this.fields.field_name[element.key][value][columnName], value, columnName);
                    numberRangeErrorList = numberRangeErrorList.concat(numberRangeError);
                  }
                }           
                if (element.table_configuration.columns[item].type == 'select') {
                  if (!((element.table_configuration.columns[item].option_values).includes(this.fields.field_name[element.key][value][columnName]))) {
                      optionErrorList.push({
                      'columnName': columnName,
                      'row': Number(value) + 1
                    });
                  }
                }
                if(element.table_configuration.columns[item].type == 'date') {
                  if('date' in validators) { 
                    if(('hideFutureDates' in validators["date"]) 
                    && validators["date"]["hideFutureDates"]) {
                      if(new Date(new Date(this.fields.field_name[element.key][value][columnName]).toDateString()) > new Date(new Date().toDateString())){
                        futureDateErrorList.push({
                          'columnName': columnName,
                          'row': Number(value) + 1,
                        });
                      }
                    }
                    if(('hidePastDates' in validators["date"]) 
                    && validators["date"]["hidePastDates"]) {
                      if(new Date(new Date(this.fields.field_name[element.key][value][columnName]).toDateString()) < new Date(new Date().toDateString())){
                        pastDateErrorList.push({
                          'columnName': columnName,
                          'row': Number(value) + 1,
                        });
                      }
                    }                   
                  }
                }
              }
            }
          }
      }
      if(optionErrorList.length > 0 || futureDateErrorList.length > 0 || pastDateErrorList.left > 0 || numberRangeErrorList.length>0) {
        let tableFieldName = element.display_name.en;
        let tableError = {};
        tableError[tableFieldName] = {};
        if(optionErrorList.length > 0) { tableError[tableFieldName]["optionError"] = optionErrorList}
        if(futureDateErrorList.length > 0) { tableError[tableFieldName]["futureDateError"] = futureDateErrorList}
        if(pastDateErrorList.length > 0) { tableError[tableFieldName]["pastDateError"] = pastDateErrorList}
        if(numberRangeErrorList.length > 0) { tableError[tableFieldName]["numberRangeErrorList"] = numberRangeErrorList}
        this.tableValidationErrorList.push(tableError);
      }
    },
    validateDateTime(element){
      if(this.fields.field_name[element.key]) {
        if(!this.additionalFieldInformation[element.key].timezone){
            this.valueValidationErrorList.push("Timezone of "+ element.display_name.en +" should not be empty");
          }
        let timezone = this.additionalFieldInformation[element.key].timezone;
        var value = moment.unix(Number(this.fields.field_name[element.key])).tz(timezone).format("YYYY-MM-DD");
        if(this.startDate[element.key]){
          var min = moment.unix(Number(this.startDate[element.key])).tz(timezone).format("YYYY-MM-DD");
          if(min > value){
            this.valueValidationErrorList.push("Value of "+ element.display_name.en +" should not be lesser then "+ moment.unix(this.startDate[element.key]).tz(timezone).format("DD-MM-YYYY") );
          }
        }
        if(this.endDate[element.key]){
          var max = moment.unix(Number(this.endDate[element.key])).tz(timezone).format("YYYY-MM-DD");
          if(max < value){
            this.valueValidationErrorList.push("Value of "+ element.display_name.en +" should not be greater then "+moment.unix(this.endDate[element.key]).tz(timezone).format("DD-MM-YYYY"));
          }
        }
      }
    },
    async validateFields() {
      this.errorList = [];
      this.errorListInMM = [];
      this.valueValidationErrorList = []; 
      this.valueValidationErrorListInMM = []; 
      this.tableValidationErrorList = [];
      for(var element of this.fieldDefinition) {
        if (
          element.isVisible &&
          element.validators &&
          element.validators.required
        ) {
          if (
            element.input_type != "autoincrement" &&
            element.key != "current_status" &&
            element.key != "status_action" &&
            element.key != "consolidated_overall_breach_status" &&
            element.key != "consolidated_stage_breach_status" &&
            element.key != "stage_breach_status" &&
            element.key != "overall_breach_status" &&
            element.key != "assignee" &&
            element.key != "assignee_user_group" &&
            element.input_type != "slaBreachTime" &&
            element.input_type !== "label"
          ) {
            if (element.renderField) {
              if(element.input_type == "checklist"){
                this.validateCheckList(element);
              }
              if(element.input_type == 'multifileupload') {
                this.validateMultifile(element);
              }
              else if(element.input_type == "number"){
                this.validateNumberField(element);
              }
              //currency Validation
              else if(element.input_type == "currency"
              ) {
                if(!this.fields.field_name[element.key] && this.fields.field_name[element.key] != 0){
                  this.errorList.push(element.display_name.en);
                  if (this.isMMSecondarySupportLang) {
                    this.errorListInMM.push(element.display_name.mm);
                  }
                }
                if(this.additionalFieldInformation && this.additionalFieldInformation[element.key]){
                    if(!this.additionalFieldInformation[element.key]['currencyCode']){
                      this.valueValidationErrorList.push("Currency Code of "+ element.display_name.en +" should not be empty ");
                      if (this.isMMSecondarySupportLang) {
                        this.valueValidationErrorListInMM.push("Currency Code of "+ element.display_name.mm +" should not be empty ");
                      }
                    }
                  }else{
                    this.valueValidationErrorList.push("Currency Code of "+ element.display_name.en +" should not be empty ");
                    if (this.isMMSecondarySupportLang) {
                      this.valueValidationErrorListInMM.push("Currency Code of "+ element.display_name.mm +" should not be empty ");
                    }

                }
                this.validateCurrency(element);
              }
              else if(element.input_type === "URL") {
                if(!validator.isURL(this.fields.field_name[element.key])){
                  this.valueValidationErrorList.push(
                    "Please enter a valid URL for " + element.display_name.en
                  );
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(
                      "မှန်ကန်သည့် လင့်ခ် ဖြည့်စွက်ပါ။ " + element.display_name.mm
                    );
                  }
                }
              }
              else if (element.input_type === 'toggle'){
                if(!new Set([true, false]).has(this.fields.field_name[element.key])){
                  this.valueValidationErrorList.push(
                    "Please check toggle value for " + element.display_name.en
                  );
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(
                      "ကျေးဇူးပြု၍ အဖွင့်တန်ဖိုးကို စစ်ဆေးပါ။" + element.display_name.mm
                    );
                  }
                }
              }
              //Required validation
              else if (
                this.fields.field_name[element.key] == undefined ||
                this.fields.field_name[element.key] == "" ||
                this.fields.field_name[element.key] == null
              ) {
                if(element.input_type == 'table'){
                  this.isTableError = true;
                }
                this.errorList.push(element.display_name.en);
                if (this.isMMSecondarySupportLang) {
                  this.errorListInMM.push(element.display_name.mm);
                }
              }
              //Value validation
              else if (
                element.validators.value &&
                !(
                  element.validators.value ==
                  this.fields.field_name[element.key]
                ) &&
                !element.validators.value.includes(
                  this.fields.field_name[element.key]
                )
              ) {
                if (element.validators.value.length == 1) {
                  this.valueValidationErrorList.push(
                    element.display_name.en +
                      " should be " +
                      element.validators.value
                  );
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(
                      element.display_name.mm +
                        " should be " +
                        element.validators.value
                    );
                  }
                } else {
                  this.valueValidationErrorList.push(
                    element.display_name.en +
                      " should match one of the following: " +
                      element.validators.value.join(", ")
                  );
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push(
                      element.display_name.mm +
                        " should match one of the following: " +
                        element.validators.value.join(", ")
                    );
                  }
                }
              } else if(element.input_type == "phonenumber"){ 
                if(isPossiblePhoneNumber(this.fields.field_name[element.key], this.additionalFieldInformation[element.key].countryCode) === false || 
                isValidPhoneNumber(this.fields.field_name[element.key], this.additionalFieldInformation[element.key].countryCode) === false){
                  this.valueValidationErrorList.push("Please enter valid phone number for " + element.display_name.en);
                  if (this.isMMSecondarySupportLang) {
                    this.valueValidationErrorListInMM.push("ဖုန်းနံပါတ်များကို စနစ်မှန်ကန်စွာထည့်သွင်းပါ။ ဖုန်းနံပါတ်ရှေ့ဆုံးတွင် (သုည - 0 ) မထည့်ရ။ " + element.display_name.mm);
                  }
                }
              }
              else if(element.input_type == "input"){
                if (element.validators && element.validators.regex) {
                  let value = this.fields.field_name[element.key];
                  let regex = new RegExp(element.validators.regex.expression);
                  let errorMessage;
                  if(element.validators.regex.errorMessage) {
                    errorMessage = element.validators.regex.errorMessage;
                  } else {
                    errorMessage = {
                      'en' : "Please enter valid input for " + element.display_name.en,
                      'mm' : "ကျေးဇူးပြု၍ မှန်ကန်သော ထည့်သွင်းမှုကို ထည့်သွင်းပါ။" + element.display_name.mm
                    }
                  }
                  
                  if (!regex.test(value)) {
                      this.valueValidationErrorList.push(
                          errorMessage.en
                      );
                      if (this.isMMSecondarySupportLang) {
                          this.valueValidationErrorListInMM.push(
                              errorMessage.mm
                          );
                      }
                  }
                }
                this.validateLengthAndNumber(element);
              }
            }
          }
        }
        if(element.isVisible && element.renderField &&  element.input_type == "datetime") {
          this.validateDateTime(element);
        }
        if(element.isVisible && element.renderField &&  element.input_type == "multiselect") {
          this.validateMultiSelect(element);
        }
        if(element.isVisible && element.renderField &&  element.input_type == "table") {
          this.deleteEmptyRow(element);
          this.validateTableValue(element)
        }
        //NRC validation
        if(element.isVisible && element.renderField
        &&  element.input_type == "nrc"
        ) {
          this.showSpinner = true;
          this.dialogWidth = "35%";
          let validNRC = true;
          if(element.validators.required) {
            validNRC = await this.validateNRC(this.fields.field_name[element.key]);
          }
          if(!validNRC){
            this.valueValidationErrorList.push(
              "Please enter a valid NRC for " + element.display_name.en
            );
            if (this.isMMSecondarySupportLang) {
              this.valueValidationErrorListInMM.push(
                "မှတ်ပုံတင်နံပါတ်အား မှန်ကန်စွာဖြည့်သွင်းပါ။  " + element.display_name.mm
              );
            }
          }
          this.showSpinner = false;
          this.dialogWidth = "70%";
        }
      };
      if (this.errorList.length > 0 || this.valueValidationErrorList.length > 0 || this.tableValidationErrorList.length > 0) {
        this.errorValidationDialogObject["data"] = { errorList: this.errorList };
        if (this.isMMSecondarySupportLang) {
          this.errorValidationDialogObject["data"] = { errorList: this.errorList, errorListInMM: this.errorListInMM}    ;
          this.errorValidationDialogObject["valueValidationErrorListInMM"] = this.valueValidationErrorListInMM;
          this.errorValidationDialogObject["displayTextInMM"] = "အောက်ပါ အကွက်များတွင် လိုအပ်သော အချက်အလက်များ ဖြည့်စွက်ပါ။ ";
        }
        this.errorValidationDialogObject["viewId"] = this.dialogObject.viewId;
        this.errorValidationDialogObject["valueValidationErrorList"] =
        this.valueValidationErrorList;
        this.errorValidationDialogObject["tableValidationErrorList"] = this.tableValidationErrorList;
        this.errorValidationDialogObject["workFlowId"] = this.workFlowId;
        this.errorValidationDialogObject["display"] = true;
        this.errorValidationDialogObject["displayText"] =
        "Enter the value(s) into the following field(s): ";
        if(this.isTableError && this.showValidationPopup){
          this.width = "59%";
          this.dialogWidth = "70%";
        } else {
          this.dialogWidth = "70%";
        }
        return false;
      } else {
        this.showValidationPopup = false;
        this.dialogWidth = '35%',
        this.confirmationDialogObject["data"] = this.fields.field_name;
        this.confirmationDialogObject["additionalFieldInformation"] = this.additionalFieldInformation;
        this.confirmationDialogObject["fileAttachments"] =
          this.fileAttachmentArray;
        this.confirmationDialogObject["action"] = "ADD";
        this.confirmationDialogObject["viewId"] = this.dialogObject.viewId;
        this.confirmationDialogObject["workFlowId"] = this.workFlowId;
        this.confirmationDialogObject["display"] = true;
        return true;
      }
    },
    openSpotlight(fieldItem) {
      this.showValidationPopup = false;
      this.showSpotlightSection = false;
      this.$nextTick(() => {
        this.spotlightDialogObject = {
          spotlightURL: fieldItem.spotlightConfiguration.spotlightUrl,
          spotlightTitle: fieldItem.spotlightConfiguration.spotlightTitle,
          queryParamKey: fieldItem.spotlightConfiguration.queryParamKey,
          queryParamValue: this.fields.field_name[fieldItem.key],
          appID: this.$route.query.applicationId,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
          display: true,
        };
        this.dialogWidth = "90%";
        this.dialogCols = "col-5";
        this.showSpotlightSection = true;
      });
    },
    closeSpotlightDialog() {
      this.showSpotlightSection = false;
      this.spotlightDialogObject.display = false;
      this.calculateDialogWidth();
      this.dialogCols = "col-12";
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if (emitObject && emitObject.errorDisplayMessage) {
        closeDialogFlag = emitObject.errorDisplayMessage;
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    addCurrencyDetails(value,key) {
      this.additionalFieldInformation[key] = {
        "currencyCode": value
      }
    },
    filterFieldItemOptionValuesByScope(fieldItem) {
      if (fieldItem.view_type !== "reference") {
        fieldItem.option_values = this.currentScope.fieldKeys[fieldItem.key];
        if (fieldItem.option_values.length == 1) {
          this.$set(this.fields.field_name, fieldItem.key, fieldItem.option_values[0]);
          fieldItem.isDisabled = true;
        }
      } else {
        fieldItem.option_values = fieldItem.option_values.filter((item) =>
          this.currentScope.fieldKeys[fieldItem.key].includes(item.key)
        );
        if (fieldItem.option_values.length == 1) {
          this.$set(this.fields.field_name, fieldItem.key, fieldItem.option_values[0].key);
          fieldItem.isDisabled = true;
        }
      }
    },
    filterScopedFields() {
      for (var [index, fieldItem] of Object.entries(this.fieldDefinition)) {
        if (fieldItem.key in this.currentScope.fieldKeys) {
          if (
            [
              "toggle", "date", "textarea", "phone", "email", "currency", "input", 
              "checklist", "priority", "number", "phonenumber", "table", "alert"
            ].includes(fieldItem.input_type)
          ) {
            if (!(this.currentScope.fieldKeys[fieldItem.key] == "ALL")) {
              this.$set(this.fields.field_name, fieldItem.key, this.currentScope.fieldKeys[fieldItem.key]);
              fieldItem.isDisabled = true;
            }
          } else if (!this.currentScope.fieldKeys[fieldItem.key].includes("ALL")) {
            if (["options", "radio"].includes(fieldItem.input_type)) {
              this.filterFieldItemOptionValuesByScope(fieldItem);
            } else if (fieldItem.input_type === "workflowoptions") {
              if (this.currentScope.fieldKeys[fieldItem.key].length == 1) {
                fieldItem.option_values = fieldItem.option_values.filter(
                  (item) => this.currentScope.fieldKeys[fieldItem.key].includes(item.value)
                );
                this.$set(this.fields.field_name, fieldItem.key, this.currentScope.fieldKeys[fieldItem.key][0]);
                fieldItem.isDisabled = true;
                this.selectWorkflow(fieldItem.option_values[0].value, fieldItem.option_values, fieldItem);
              } else {
                fieldItem.option_values = fieldItem.option_values.filter((item) => 
                  this.currentScope.fieldKeys[fieldItem.key].includes(item.value)
                );
              }
            }
          }
        }
      }
    },
    hideFields() {
      for(let fieldItem of this.fieldDefinition) {
        if(!this.checkFieldHide(this.fieldType , fieldItem, this.workFlowId)) {
          fieldItem.renderField = false;
        }
      } 
    },
  },
  mounted() {
    let secondarySupportLanguageDetails = getFromLocalStorage('secondarySupportLanguage');
    if (secondarySupportLanguageDetails && secondarySupportLanguageDetails.secondarySupportLanguage === 'Burmese' && secondarySupportLanguageDetails.applicationId == this.$route.query.applicationId) {
      this.isMMSecondarySupportLang = true; 
      this.rulesForRequired = [(v) => !!v || "Input is required (ဖြည့်စွက်ရန် လိုအပ်ပါသည်။)"],
      this.rulesForCurrencyRequired =  [
        (v) => !!v || "Enter the Range (အတိုင်းအထွာ ပမာဏကို သတ်မှတ်ဖြည့်စွက်ပါ။)",
        (v) =>
          (parseInt(v) >= 100 && parseInt(v) <= 100000) ||
          "Enter a proper range (အတိုင်းအထွာ ပမာဏကို သတ်မှတ်ဖြည့်စွက်ပါ။)",
      ];
      this.rulesForEmailRequired= [
      (v) => !!v || "Enter the email ID (e-mail ID ဖြည့်စွက်ပါ။)",
        (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || 
        "Enter a valid email ID (မှန်ကန်သည့် e-mail ID ဖြည့်စွက်ပါ။)",
      ];
      this.rulesForURLRequired=[
        (v) => !!v || "Enter the URL (လင့်ခ်ကို ဖြည့်စွက်ပါ။)",
        (v) =>
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(v) ||
        "Enter a valid URL (မှန်ကန်သည့် လင့်ခ် ဖြည့်စွက်ပါ။)"
      ];
    } else {
      this.rulesForRequired = [(v) => !!v || "Input is required"],
      this.rulesForCurrencyRequired =  [
        (v) => !!v || "Enter the Range",
        (v) =>
          (parseInt(v) >= 100 && parseInt(v) <= 100000) ||
          "Enter a proper range",
      ];
      this.rulesForEmailRequired= [
        (v) => !!v || "Enter the email ID",
        (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || 
        "Enter a valid email ID",
      ];
      this.rulesForURLRequired=[
        (v) => !!v || "Enter the URL",
        (v) =>
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(v) ||
        "Enter a valid URL"
      ];
    }
    this.userId = getFromLocalStorage("userId");
    this.userRoleGroup = getFromLocalStorage("userGroup");
    this.disableSelect = false;
    this.viewId = this.dialogObject.viewId;
    this.fieldDefinition = this.$props.screenDefinition.fieldDetails.fields;
    for (var fieldItem in this.fieldDefinition) {
      if (this.fieldDefinition[fieldItem].input_type === "date") {
        this.dateValidation();
        if (
          this.fieldDefinition[fieldItem].default_value &&
          this.fieldDefinition[fieldItem].default_value.type &&
          this.fieldDefinition[fieldItem].default_value.type === "today"
        ) {
          this.fields.field_name[this.fieldDefinition[fieldItem].key] =
            moment().format("YYYY-MM-DD");
        } else if (
          this.fieldDefinition[fieldItem].default_value &&
          this.fieldDefinition[fieldItem].default_value.type === "absolute"
        ) {
          let epoch = this.fieldDefinition[fieldItem].default_value.value;
          epoch = Number(epoch);
          this.fields.field_name[this.fieldDefinition[fieldItem].key] =
            moment(epoch).format("YYYY-MM-DD");
        }
        if (
          this.fieldDefinition[fieldItem].validators &&
          this.fieldDefinition[fieldItem].validators.date &&
          this.fieldDefinition[fieldItem].validators.date.hidePastDates === true
        ) {
          this.startDate[this.fieldDefinition[fieldItem].key] =
            moment().format("YYYY-MM-DD");
        }
        if (
          this.fieldDefinition[fieldItem].validators &&
          this.fieldDefinition[fieldItem].validators.date &&
          this.fieldDefinition[fieldItem].validators.date.hideFutureDates === true
        ) {
          this.endDate[this.fieldDefinition[fieldItem].key] =
            moment().format("YYYY-MM-DD");
        } else {
          if (
            this.fieldDefinition[fieldItem].validators &&
            this.fieldDefinition[fieldItem].validators.date &&
            this.fieldDefinition[fieldItem].validators.date.range
          ) {
            let startDateEpoch = Number(this.fieldDefinition[fieldItem].validators.date.range.from.value);
            this.startDate[this.fieldDefinition[fieldItem].key] = moment(startDateEpoch).format("YYYY-MM-DD");
            var epoch = this.fieldDefinition[fieldItem].validators.date.range.to.value;
            epoch = Number(epoch);
            this.endDate[this.fieldDefinition[fieldItem].key] = moment(epoch).format("YYYY-MM-DD");
          }
        }
      }
      else if (this.fieldDefinition[fieldItem].input_type === "datetime") {
        if (this.fieldDefinition[fieldItem].validators && this.fieldDefinition[fieldItem].validators.date 
        && this.fieldDefinition[fieldItem].validators.date.timezone ){
          this.additionalFieldInformation[this.fieldDefinition[fieldItem].key] = {
              "timezone": this.fieldDefinition[fieldItem].validators.date.timezone
            }
        } else {
          let localTZ = moment.tz.guess(true);
          this.additionalFieldInformation[this.fieldDefinition[fieldItem].key] = {
              "timezone": localTZ
            }
        }
        if (
          this.fieldDefinition[fieldItem].validators &&
          this.fieldDefinition[fieldItem].validators.date) {
            if(this.fieldDefinition[fieldItem].validators.date.hidePastDates === true) {
              this.startDate[this.fieldDefinition[fieldItem].key] = Number(moment().unix());
            }
            if(this.fieldDefinition[fieldItem].validators.date.range) {
              if(this.fieldDefinition[fieldItem].validators.date.range.from){
                if(this.fieldDefinition[fieldItem].validators.date.range.from.type == 'absolute'){
                  let startDateEpoch = Number(this.fieldDefinition[fieldItem].validators.date.range.from.value);
                  this.startDate[this.fieldDefinition[fieldItem].key] = startDateEpoch;
                }
                else if(this.fieldDefinition[fieldItem].validators.date.range.from.type == 'reference' &&
                this.fields.field_name[this.fieldDefinition[fieldItem].validators.date.range.from.value]){
                  let startDateEpoch = Number(this.fields.field_name[this.fieldDefinition[fieldItem].validators.date.range.from.value]);
                  this.startDate[this.fieldDefinition[fieldItem].key] = startDateEpoch;
                }
              }
              if(this.fieldDefinition[fieldItem].validators.date.range.to){
                if(this.fieldDefinition[fieldItem].validators.date.range.to.type == 'absolute'){
                  let endDateEpoch = Number(this.fieldDefinition[fieldItem].validators.date.range.to.value);
                  this.endDate[this.fieldDefinition[fieldItem].key] = endDateEpoch;
                }
                else if(this.fieldDefinition[fieldItem].validators.date.range.to.type == 'reference' &&
                this.fields.field_name[this.fieldDefinition[fieldItem].validators.date.range.to.value]){
                  let endDateEpoch = Number(this.fields.field_name[this.fieldDefinition[fieldItem].validators.date.range.to.value]);
                  this.endDate[this.fieldDefinition[fieldItem].key] = endDateEpoch;
                }
              }
            }
          }
        if (!(fieldItem.key in this.fields.field_name) && this.fieldDefinition[fieldItem].default_value && this.fieldDefinition[fieldItem].default_value.type) {
          let datetimeValue;
          if(this.fieldDefinition[fieldItem].default_value.type === "today") {
            datetimeValue = Number(moment().unix());
          }
          else if(this.fieldDefinition[fieldItem].default_value.type === "absolute") {
            datetimeValue = Number(this.fieldDefinition[fieldItem].default_value.value);
          }
          if(datetimeValue && 
            !(this.startDate[this.fieldDefinition[fieldItem].key] && Number(this.startDate[this.fieldDefinition[fieldItem].key]) > Number(datetimeValue)) &&
            !(this.endDate[this.fieldDefinition[fieldItem].key] && Number(this.endDate[this.fieldDefinition[fieldItem].key]) < Number(datetimeValue))
          ) {
            this.fields.field_name[this.fieldDefinition[fieldItem].key] = datetimeValue;
          }
        }
      }   
      else if (this.fieldDefinition[fieldItem].input_type === "apiOptions") {
        axios({
          method: "post",
          url:
            process.env.VUE_APP_BACKEND_URL +
            "/" +
            this.fieldDefinition[fieldItem].option_values.key,
          data: {
            applicationId: this.$route.query.applicationId,
            token: getFromLocalStorage("token"),
            authType: getFromLocalStorage("authType"),
          },
          headers: {
            "content-type": "application/json",
          },
        })
          .then((apiResponse) => {
            var response = apiResponse.data.data;
            for (var item in response) {
              this.apiOptionValues.push(
                response[item]["Definitions"][
                  this.fieldDefinition[fieldItem].option_values.value
                ]
              );
            }
          })
          .catch((apiError) => {
            if (
              apiError.response &&
              apiError.response.status &&
              apiError.response.status == 401
            ) {
              this.$root.$emit("update_session_value", true);
            } else {
              this.errorMessageShow = true;
              if (
                apiError.response &&
                apiError.response.data &&
                apiError.response.data.errorObject
              ) {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: apiError.response.data.errorObject.error_code,
                  error_uuid: apiError.response.data.errorObject.error_uuid,
                };
              } else {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: "",
                  error_uuid: "",
                };
              }
              this.showMessageDialog = true;
            }
          });
      } else if (
        this.fieldDefinition[fieldItem].input_type === "workflowoptions"
      ) {
        if (
          this.fieldDefinition[fieldItem] &&
          this.fieldDefinition[fieldItem].option_values &&
          this.fieldDefinition[fieldItem].option_values.length == 1
        ) {
          this.disableSelect = true;
          this.fields.field_name[this.fieldDefinition[fieldItem].key] =
            this.fieldDefinition[fieldItem].option_values[0].value;
          this.selectWorkflow(
            this.fieldDefinition[fieldItem].option_values[0].value,
            this.fieldDefinition[fieldItem].option_values,
            this.fieldDefinition[fieldItem]
          );
        }
      } else if (this.fieldDefinition[fieldItem].input_type === "hierarchicalDropdown") {
        if (
          this.fieldDefinition[fieldItem].options_object &&
          typeof(this.fieldDefinition[fieldItem].options_object) == 'object'
        ) {
          this.fieldDefinition[fieldItem]['option_values'] = [];
          Object.entries(this.fieldDefinition[fieldItem].options_object).map(([key, values]) => {
            this.fieldDefinition[fieldItem]['option_values'].push(...values);
          })
        }
      }
    }
    for (var fieldItem of this.fieldDefinition) {
      let oldValue = this.fields.field_name[fieldItem.key];
      if (
        fieldItem.conditionalValue &&
        fieldItem.conditionalValue.conditions &&
        fieldItem.conditionalValue.conditions.length > 0
      ) {
        if (!fieldItem.conditionalValue.applyOnCreate) {
          continue;
        }

        fieldItem.isDisabled = true;
        if (oldValue != fieldItem.defaultValue) {
          if (fieldItem.key == "assignee_user_group") {
            this.assigneeUserGroupOptions = [];
            this.assigneeUserGroupOptions = [fieldItem.defaultValue];
            this.fields.field_name[fieldItem.key] = fieldItem.defaultValue;
          } else {
            this.fields.field_name[fieldItem.key] = fieldItem.defaultValue;
          }
        }
      }
    }
    if (
      this.$props.screenDefinition.scopeDefinition &&
      this.$props.screenDefinition.scopeDefinition.length > 0 &&
      this.$props.selectedScope
    ) {
      this.currentScope = this.$props.screenDefinition.scopeDefinition.find(
        (scope) => scope.key === this.$props.selectedScope
      );
      if (this.currentScope && 'fieldKeys' in this.currentScope) {
        this.filterScopedFields();
      }
    }

    for (var fieldItem of this.fieldDefinition) {
      // Setting default value for dropdown if there is just one option
      if (
        !fieldItem.isDependent &&
        fieldItem.input_type == "options" &&
        fieldItem.option_values.length == 1
      ) {
        if (fieldItem.view_type === "reference") {
          this.fields.field_name[fieldItem.key] =
            fieldItem.option_values[0].key;
        } else {
          this.fields.field_name[fieldItem.key] = fieldItem.option_values[0];
        }
      } else if (
        fieldItem.isDependent &&
        fieldItem.input_type == "options" &&
        (fieldItem.dependentValue ==
          this.fields.field_name[fieldItem.dependentField] ||
          fieldItem.dependentValue.includes(
            this.fields.field_name[fieldItem.dependentField]
          )) &&
        fieldItem.option_values.length == 1
      ) {
        // Setting default value for dropdown which are dependent if there is just one option
        if (fieldItem.view_type === "reference") {
          this.fields.field_name[fieldItem.key] =
            fieldItem.option_values[0].key;
        } else {
          this.fields.field_name[fieldItem.key] = fieldItem.option_values[0];
        }
      }
      if(fieldItem.isComputed || fieldItem.isApiComputed){
        fieldItem.isDisabled = true;
      }
    }

    for (var fieldItem of this.fieldDefinition) {
      if(fieldItem.hasOwnProperty("defaultValue")) {
        if(!(this.currentScope && 'fieldKeys' in this.currentScope && fieldItem.key in this.currentScope.fieldKeys))
        {
          if(fieldItem.isDependent === false) {
          if (
            [
              "toggle", "textarea", "email", "currency", "input", "nrc", "date",
              "priority", "URL", "options", "alert", "phonenumber", "number" ,"radio"
            ].includes(fieldItem.input_type)
          ) {
            if(fieldItem.input_type == 'toggle'){
              this.fields.field_name[fieldItem.key] = new Set([true, false]).has(fieldItem.defaultValue) ? fieldItem.defaultValue : false;
            } else if(fieldItem.input_type == 'priority'){
              let priorityOptions = new Set(fieldItem.option_values.filter(obj => obj.key).map(obj => obj.key));
              if(priorityOptions.has(fieldItem.defaultValue)){
                this.fields.field_name[fieldItem.key] = fieldItem.defaultValue
              }
            } else if (fieldItem.input_type == 'currency') {
                this.fields.field_name[fieldItem.key] = fieldItem.defaultValue.toString();
            } else {
             this.$set(this.fields.field_name, fieldItem.key, fieldItem.defaultValue);
            }
          }
        }
        }
        
      }
      if (this.currentScope && this.currentScope.fieldKeys && fieldItem.key in this.currentScope.fieldKeys && fieldItem.isDependent === false) {
        if (!(fieldItem.key in this.fields.field_name) && (this.currentScope.defaultScopeValue && fieldItem.key in this.currentScope.defaultScopeValue)) {
          if (this.currentScope.fieldKeys[fieldItem.key] == this.currentScope.defaultScopeValue[fieldItem.key] || 
          this.currentScope.fieldKeys[fieldItem.key].includes(this.currentScope.defaultScopeValue[fieldItem.key])) {
            this.fields.field_name[fieldItem.key] = this.currentScope.defaultScopeValue[fieldItem.key];
          }
        }
      }
      if(fieldItem.isDisabled && fieldItem.isDependent === false) {
        if(fieldItem.input_type === "currency"){
          if(fieldItem.currency_configuration && fieldItem.currency_configuration.defaultCurrency){
            this.additionalFieldInformation[fieldItem.key] = {
              "currencyCode": fieldItem.currency_configuration.defaultCurrency
            }
          } else{
            this.additionalFieldInformation[fieldItem.key] = {
              "currencyCode": "USD"
            }
          }
        }
        if(fieldItem.input_type === "phonenumber"){
          if(fieldItem.phonenumberConfiguration && fieldItem.phonenumberConfiguration.defaultCountryCode){
            this.additionalFieldInformation[fieldItem.key] = {
              "countryCode": fieldItem.phonenumberConfiguration.defaultCountryCode,
              "callingCode":""
            }
          } else{
            this.additionalFieldInformation[fieldItem.key] = {
              "countryCode": "MM",
              "callingCode":""
            }
          }
        }
		  }
      if(fieldItem.isComputed && 'evaluation' in fieldItem){
        let computedValue = this.getComputedValue(fieldItem.evaluation);
        if(!Number.isNaN(parseFloat(computedValue)) && Number.isFinite(parseFloat(computedValue))){
          this.fields.field_name[fieldItem.key] = computedValue;
        }
        else{
          delete this.fields.field_name[fieldItem.key];
        }
      }
    }
    for (let fieldItem of this.fieldDefinition) {
      if (fieldItem.input_type === "table") {
        this.$set(this.showTable, fieldItem.key, true);
        this.dialogWidth = '70%';
      }
      if (fieldItem.input_type === "workflowoptions") {
          fieldItem.option_values = fieldItem.option_values.filter(item => {
            if (item.assigneeGroupCondition &&  item.assigneeGroupCondition.condition) {
              const condition = item.assigneeGroupCondition.condition[getFromLocalStorage("userGroup")];
              return condition !== undefined;
            }
          });
          if(fieldItem.option_values.length == 1) {
            this.disableSelect = true;
            this.fields.field_name[fieldItem.key] = fieldItem.option_values[0].value;
            this.selectWorkflow(fieldItem.option_values[0].value, fieldItem.option_values, fieldItem);
          }
      }
    }
    let isTableAvailable = this.fieldDefinition.some((field)=> field.input_type == 'table');
    if(isTableAvailable){
      this.$nextTick(()=>{
        var div = document.querySelector('#divSelector');
        div.addEventListener('scroll', this.handleScroll);
      })
    }
    for (let fieldItem of this.fieldDefinition) {
      if (
        !fieldItem.isDependent ||
        (fieldItem.dependentField == "issue_type" &&
        (this.fields.field_name[fieldItem.dependentField] == fieldItem.dependentValue ||
        fieldItem.dependentValue.includes(this.fields.field_name[fieldItem.dependentField])))
      ) {
        fieldItem.renderField = true;
      } else {
        fieldItem.renderField = false;
      }
      if (
        fieldItem.hasOwnProperty('parentKey') && 
        fieldItem.hasOwnProperty('options_object')
      ) {
        if (this.fields.field_name.hasOwnProperty(fieldItem.parentKey)) {
          fieldItem.option_values = [];
          if (this.fields.field_name[fieldItem.parentKey] != "") {
            Object.entries(fieldItem.options_object).map(([key, values]) => {
              if (this.fields.field_name[fieldItem.parentKey] === key) {
                fieldItem['option_values'] = values
              }
            });
            if (
              this.currentScope && 'fieldKeys' in this.currentScope && 
              fieldItem.key in this.currentScope.fieldKeys && 
              !this.currentScope.fieldKeys[fieldItem.key].includes("ALL")
            ) {
              this.filterFieldItemOptionValuesByScope(fieldItem);
            }
          } else {
            Object.entries(fieldItem.options_object).map(([key, values]) => {
              fieldItem['option_values'].push(...values);
            })
          }
          if(fieldItem.option_values.length === 1){
            this.fields.field_name[fieldItem.key] = fieldItem.option_values[0].key;
          }
          fieldItem.renderField = true;
        } else {
          fieldItem.renderField = false;
        }
      }
      if (fieldItem.input_type === "optionsGroup") {
        if(this.fields.field_name[fieldItem.options_parent] && this.fields.field_name[fieldItem.options_parent]!=null){
          fieldItem.option_values = fieldItem.options_mapping[this.fields.field_name[fieldItem.options_parent]];
          delete this.fields.field_name[fieldItem.key];
          fieldItem.renderField = true;
        } else {
          delete this.fields.field_name[fieldItem.key];
          fieldItem.renderField = false;
        }
      }

      if (fieldItem.isDependent) {
        if (
          fieldItem.dependentField != "current_status" &&
          fieldItem.dependentField != "issue_type"
        ) {
          if (
            fieldItem.dependentField in this.fields.field_name &&
            fieldItem.dependentValue.includes(this.fields.field_name[fieldItem.dependentField]) &&
            this.fieldDefinition.find((field) => field.key == fieldItem.dependentField) &&
            this.fieldDefinition.find((field) => field.key == fieldItem.dependentField).renderField
          ) {
            fieldItem.renderField = true;
          } else {
            fieldItem.renderField = false;
          }
        }
      }
      if (!this.checkFieldHide(this.fieldType , fieldItem ,this.workFlowId)) {
        fieldItem.renderField = false;
      }
      if(fieldItem.copyFieldValueConfig){
        fieldItem.isDisabled = true;
      }
    }
    this.dialog = this.dialogObject.display;
    if (this.dialogObject.buttons.includes("Save")) {
      this.showSaveButton = true;
    }
    if (this.dialogObject.buttons.includes("Cancel")) {
      this.showCancelButton = true;
      this.oldData = JSON.stringify(this.fields.field_name);
    }
  },
};
</script>

<style scoped>
.btn_disable_assign {
  background-color: rgb(42, 64, 154);
  color: #ffffff;
  width: 140px;
}

.btn_disable_assign:disabled {
  background-color: #b2a9a959;
  color: #ffffff;
  width: 140px;
}

input[type="text"]::hint,
input[type="password"]::hint {
  color: #ffffff !important;
  opacity: 1;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.input_field_div {
  /* width: 600px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: 1px;
  color: #4f4f4f;
  /* border: 1px solid;
  padding: 8px;
  margin-top: auto;
  margin-bottom: auto; */
}

.scroll-2::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.scroll-2::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0;
  background-color: rgb(42, 64, 154);
}

.dialogWrapper {
  display: flex;
}

.dialogWrapper > .v-card {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
}

.dialogWrapper > .v-card > .v-card__text {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  flex: 1 1 auto;
  overflow-y: auto;
}
.dialogWrapper > .v-card > .v-card__actions,
.dialogWrapper > .v-card > .v-card__title {
  flex: 0 0 auto;
}
.hideRightBorderRadius {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.dialogWrapper.col-5,
.spotlightSection {
  transition: flex-basis 0.3s;
}
.cancelButtonStyle {
  background-color: #ffffff;
  color: rgb(42, 64, 154);
  border: 1px solid rgb(42, 64, 154);
  width: 100px;
}
.dateTime {
  float: left;
  margin: 9px;
  padding: 8px;
}
</style>
<style>
.dialogAddNewContent {
  transition: width 0.5s;
  overflow-y: hidden !important;
  display: flex;
}
</style>